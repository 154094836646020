import { useState, useEffect } from "react";
import * as UI from '../../libs/libUI';
import { langCodeCt, langCodeEn } from "../../consts/ATConsts";
import { arrayAddOrDelete_ } from "../../libs/libArray";
import { toIdStr } from "../../consts/ATValidate";
import { nestSolid, toObj } from "../../libs/libType";
import { subState } from "./useChain";

export const useLangView = (hasEn, hasCt, init = langCodeEn) => {
  const safeLang = l => {
      const ret = (hasEn && (l !== langCodeCt))? langCodeEn: (hasCt? langCodeCt: langCodeEn);
      
      return ret;
  }
  const [_viewLang, _setViewLang] = useState(safeLang(init));
  const setLang = l => _setViewLang(safeLang(l)); 
  const clickLang = l => e => { UI.stopEvent(e); setLang(l); };
  const lang = safeLang(_viewLang);  
  const showEn = (lang !== langCodeCt);
  useEffect(() => { setLang(_viewLang); }, [hasEn, hasCt]);
  return [lang, setLang, clickLang, showEn, hasEn, hasCt, safeLang];
};

export const useSetFields = setFields => {
  //const setField = k => v => setFields(fs => ({ ...fs, [k]: v }));
  const setField = k => v => setFields(subState(k, v));
  const setTick = k => v => setFields(fs => ({ ...fs, [k]: (v ? 1 : 0) }));
  const setTickAry = k => id => on => {
    setFields(fs => ({ ...fs || {}, [k]: arrayAddOrDelete_(fs?.[k], id, on) }));
  };
  return [setField, setTick, setTickAry];
}

export const useFields = () => {
  const [fields, setFields] = useState({});
  const [fieldErrs, setFieldErrs] = useState({});
  const [opts, setOpts] = useState({});
  const [setField, setTick, setTickAry ] = useSetFields(setFields);
  return [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts]; 
};
export const useEditDraftPub = (_pub, _draft) => {
  const [opts, setOpts] = useState();
  const [fields, setFields] = useState(toObj(_draft));
  const [fieldErrs, setFieldErrs] = useState({});
  const [draft, setDraft] = useState(_draft);
  const [pub, setPub] = useState(_pub);
  const [pubErrs, setPubErrs] = useState({});
  const setField = k => v => setFields(subState(k, v));
  const setTick = k => v => setFields(fs => ({ ...fs, [k]: (v ? 1 : 0) }));
  const setTickAry = k => id => on => {
    setFields(fs => ({ ...fs || {}, [k]: arrayAddOrDelete_(fs?.[k], id, on) }));
  };
  return [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub, pubErrs, setPubErrs]; 
};

export const detectIsNew = (params, pub, draft, idKey) => {
  const id = toIdStr( ((pub || draft)?.[idKey]) || params?.[idKey]);
  const isNew = (draft?.isNew || params?.isNew || (!id))? 1: 0;
  return [isNew, id];
};

//const params = toObj(props?.match?.params);
//const QId = toIdStr( ((pub || draft)?.QId) || params.QId);
//const isNew = (draft?.isNew || params.isNew || (!QId))? 1 : 0;

export const normalizeEdit = (_fields, opts, trace, Normalizer, Validator, setFields, setFieldErrs, fs) => {
  const fields = Normalizer(fs || _fields, opts, trace);
  
  setFields && (!fs) && setFields(fields);
  const fieldErrs = Validator(fields, opts, trace);
  
  setFieldErrs && setFieldErrs(fieldErrs);
  
  return [fields, fieldErrs];
};

export const hasErr = fes => nestSolid(fes);
