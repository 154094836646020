import React, { useEffect, useState, useRef, useMemo } from 'react';
import CpATUploadButton from '../components/CpATUploadBtn';
import { svgIcon2 } from '../components/CpATIcoBtn';

import * as UI from '../../libs/libUI';

import { aTErrDiv1, aTWarnDiv1, CpATBtn, dpDisplay, langDisplay, preJS, QDRCol, QDRTxt, QPUCol, QPUTxt } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import { objKeys, toAry, toObj, toStr } from '../../libs/libType';
import { csvToRows, rowsToCsv } from '../ATMetaEdit/PageATMetaEdit';
import { apiCallLoad, apiCallLoad_ } from '../../libs/awsFuncs';
import { ReduxBind } from '../../saga/ReduxState';
import { QP_DR, QP_PP, QP_PU } from '../../consts/ATConsts';
import { downloadUrl, fAKEdownload } from '../../libs/libDownload';
import { toUniIdAry } from '../../consts/ATValidate';
import { getQSetQs } from './PageATQSetEdit';
import { AllIcons } from '../PageAT';
import { QDPBtn } from '../components/CpDraftPubBtns';
import { QSQErrs } from '../../consts/ATValidateQSet';

import { CpAutoMarkTag, CpManMarkTag } from '../../AppExPFUser/EPAssign/Tags/TagMarkingTag';

const TabQSetQid = ReduxBind(props => {
    const {dispatch, setTick, fieldErrs, setFields, setField, opts, setOpts, showPub, lock} = props; 
    const qs = toObj(props.qset); 
    const qids = toAry(qs.QSQIds);
    const qtns = toObj(opts.QSQs);
    const qerrs = useMemo( ()=> Object.fromEntries(qids.map(i => [i, QSQErrs(qs, qtns[i])])), [qids, qtns]);

    const onLoadQSQs = qids => (res, err) => {
        const Qs = Object.fromEntries(toAry(res.QSetQs).map(q => [q.QId, q]));
        const MSs = Object.fromEntries(toAry(res.QSetMSs).map(m => [m.metaSetId, m]));
        const loadQIds = objKeys(Qs);
        const QSQIds = qids.filter(i => loadQIds.includes(i));
        
        setFields(fs => ( {...fs, QSQIds, QSMSIds: Object.keys(MSs)} ));
        setOpts(o => ( {...o,  QSQs: {...o.QSQs, ...Qs}, QSMSs: {...o.QSMSs, ...MSs} } ) );
    };
    const loadCSV = (txt, err) => {
        if(err) console.error(err);
        const QIds = csv2Ids(txt);
        apiCallLoad_(dispatch, '/getATQSetQs', onLoadQSQs(QIds), {QIds} );
    };
    const downloadCSV = e => { UI.stopEvent(e); 
        const rows = [['Question Ids']];
        qids.forEach(i => rows.push([i]));
        fAKEdownload(rowsToCsv(rows), 'questionset_'+toStr(qs.QSetId)+(showPub?'':'_draft')+'.csv', 'text/csv');
    };
    const donwloadTemplate = e => { UI.stopEvent(e); downloadUrl('/ATTemplates/question_set_qid_template.csv'); };

    const newPOCLayout = 1;

    return <>
        <div>Question Set Question IDs</div>
        <div className="flexRowEnd">
            {lock? '': <CpATUploadButton text='Upload Question ID' onLoad={loadCSV} />}
            {CpATBtn('Export Question IDs', 'btnDL', downloadCSV )}
            {CpATBtn('Download Template', 'btnDLTmp', donwloadTemplate )}
        </div>
        {aTErrDiv1(fieldErrs?.QSQIds)}
        <div className="container-fluid">
        {newPOCLayout?<div className="row">
            <div className={colClassLeft(1)} style={{maxWidth:"50px"}}></div>
            <div className={colClassLeft(4)}>Question ID</div>
            <div className={colClassCenter(2)}>Marking</div>
            <div className={colClassCenter(2)}>Languages</div>
            <div className={colClassLeft(3)}>Verions</div>
        </div>:<div>
            <div className="w m4 p4" style={{width:'48px'}}></div>
            <div className='w40 m4 p4'>Question ID</div> 
            <div className='w20 m4 p4'>Languages</div>
            <div className='w20 m4 p4'>Verions</div>
        </div>}
        <hr/>
        {newPOCLayout?getQSetQs(qids, toObj(opts?.QSQs)).map(q => <QSQItem2 key={q.QId} {...{q, err:qerrs[q.QId]}} />)
        :getQSetQs(qids, toObj(opts?.QSQs)).map(q => <QSQItem key={q.QId} {...{q, err:qerrs[q.QId]}} />) }
        </div>
        </>
});
export default TabQSetQid; 

const colClassCenter = (v) => { return "col-" + v + " d-flex justify-content-center align-items-center my-1"; }
const colClassLeft = (v) => { return "col-" + v + " d-flex justify-content-start align-items-center my-1"; }

const QSQItem2 = ({q, err}) => {
    //general/exclamation
    const {d, p} = toObj(q);
    const qdp = p || d;
    const isAutoMark = p? p.autoMark: d? d.autoMark: 0;
    return <div className="row">
        <div className={colClassLeft(1)} style={{maxWidth:"50px"}}>{err? svgIcon2('general/alert', '#bb0'): ''} </div>
        <div className={colClassLeft(4)}>{q.QId}</div> 
        <div className={colClassCenter(2)}>{qdp? isAutoMark? CpAutoMarkTag(): CpManMarkTag(): ''}</div>
        <div className={colClassCenter(2)}>{ qdp? langDisplay(qdp.QLangEn, qdp.QLangCt): '' }</div>
        <div className={colClassLeft(3)}>{dpDisplay(d?.QVer, d?.drpu, p?.QVer, p?.drpu)}</div>
        {aTWarnDiv1(err && 'Question '+q.QId+': '+err)}
    </div>;
};


const QSQItem = ({q, err}) => {
    //general/exclamation
    const {d, p} = toObj(q);
    const qdp = p || d;
    return <div style={{}}>
        <div className="w m4 p4" style={{width:'48px'}}>{err? svgIcon2('general/alert', '#bb0'): ''} </div>
        <div className='w m4 p4' style={{minWidth:'40%'}}>{q.QId}</div> 
        <div className="w20 f15">{ qdp? langDisplay(qdp.QLangEn, qdp.QLangCt): '' }</div>
        <div className="w20 m4 p4">{dpDisplay(d?.QVer, d?.drpu, p?.QVer, p?.drpu)}</div>
        {aTWarnDiv1(err && 'Question '+q.QId+': '+err)}
    </div>;
};

export const csv2Ids = txt => {
    if(!txt) return [];
    const rows = csvToRows(toStr(txt));
    rows[0] = [];
    return toUniIdAry(rows.map(r => toAry(r)[0]));
};
