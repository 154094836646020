import {useEffect, useRef} from "react";
import {
    DONUT_DEFAULT_SEGMENT_COLOR,
    DONUT_RING_COLOR,
    DONUT_TEXT_COLOR,
    getChartColor,   
} from "../EPReport/ChartTypeConst";
import { toObj } from "../../libs/libType";
import { useUILang } from "../../AppExPf/utils/useUILang";

const initalRadialSetting = {
    circleRange: 0.75,
    outerCircle: 40,
    circleGap: 1,
    maxRing: 6,
    maxTextWidth: 40,
    radiusRangeBase: 10,
    centerValueLabelBase: 8,
    centerTitleBase: 2.5,
    centerTitleYBase: 53,
    strokeTitleBase: 3,//4
    centerValueYBase: 46
};

const ChartRadial = (props) => {
    const {label, overallScore, data, baseIndex = 0, setting = initalRadialSetting} = props;
    const [ t, showEn, uiLang ] = useUILang();
    const {percentage = 0} = overallScore || {};
    const svgRef = useRef(null);
    const svgns = "http://www.w3.org/2000/svg";
    const {
        circleRange = initalRadialSetting.circleRange,
        outerCircle = initalRadialSetting.outerCircle,
        circleGap = initalRadialSetting.circleGap,
        maxRing = initalRadialSetting.maxRing,
        maxTextWidth = initalRadialSetting.maxTextWidth,
        radiusRangeBase = initalRadialSetting.radiusRangeBase,
        centerValueLabelBase = initalRadialSetting.centerValueLabelBase,
        centerTitleBase = initalRadialSetting.centerTitleBase,
        centerTitleYBase = initalRadialSetting.centerTitleYBase,
        strokeTitleBase = initalRadialSetting.strokeTitleBase,
        centerValueYBase = initalRadialSetting.centerValueYBase
    } = setting;

    const emptyContent = () => {
        if (svgRef.current) { svgRef.current.innerHTML = ""; };
    };

    const createOverviewLabel = (label, color) => {
        const dataLength = data?.length || 1;
        if (svgRef.current) {
            //Center Label
            const text = document.createElementNS(svgns, 'text');
            const x = 50, y = centerValueYBase + 0.5 * dataLength, textAnchor = "middle", dominantBaseline = "middle";
            text.setAttributeNS(null, 'x', x.toString());
            text.setAttributeNS(null, 'y', y.toString());
            text.setAttributeNS(null, 'class', 'donut-text');
            text.setAttributeNS(null, 'fill', color);
            text.setAttributeNS(null, 'text-anchor', textAnchor);
            text.setAttributeNS(null, 'dominant-baseline', dominantBaseline);
            text.setAttributeNS(null, 'dominant-baseline', dominantBaseline);
            text.setAttributeNS(null, 'font-size', (centerValueLabelBase + 1.5 * (maxRing - (dataLength < 5 ? 5 : dataLength))).toString());
            text.setAttributeNS(null, 'font-weight', "bold");
            text.innerHTML = `${label}%`;
            svgRef.current.appendChild(text);
        };
    };

    const createTitleLabel = (label) => {
        const dataLength = data?.length || 1;
        if (svgRef.current) {
            //Center Label
            const text = document.createElementNS(svgns, 'text');
            const x = 50, y = 56, textAnchor = "middle", dominantBaseline = "middle"
            text.setAttributeNS(null, 'x', x.toString());
            text.setAttributeNS(null, 'y', y.toString());
            text.setAttributeNS(null, 'fill', DONUT_TEXT_COLOR);
            text.setAttributeNS(null, 'text-anchor', textAnchor);
            text.setAttributeNS(null, 'dominant-baseline', dominantBaseline);
            text.setAttributeNS(null, 'dominant-baseline', dominantBaseline);
            text.setAttributeNS(null, 'font-size', (dataLength < 3 ? '5.5' : (5.5 - (dataLength - 2) * 0.6).toString()));            // text.setAttributeNS(null, 'font-weight', "bold");
            text.innerHTML = label;

            svgRef.current.appendChild(text);

            /*
            const foreignObject = document.createElementNS(svgns, "foreignObject");
            const x = 0, y = centerTitleYBase - (0.3 * (maxRing - dataLength)), textAnchor = "middle",
                dominantBaseline = "middle";
            foreignObject.setAttribute("width", "100%");
            foreignObject.setAttribute("height", "100%");
            const divContainer = document.createElement("div");
            divContainer.className = "overflow-hidden";
            divContainer.style.textAlign = "center";

            const div = document.createElement("div");
            div.className = "donut-text text-truncate text-two-line";
            div.textContent = label;
            div.style.whiteSpace = "pre-wrap";
            div.style.margin = "0 auto";
            div.style.fontSize = `${(centerTitleBase + 0.5 * (maxRing - (dataLength < 4 ? 4 : dataLength)))}px`;
            // div.style.fontWeight = "bold"
            div.style.color = DONUT_TEXT_COLOR;
            div.style.width = `${30 + 5 * (maxRing - dataLength)}%`;
            div.style.height = `${30 + 5 * (maxRing - dataLength)}%`;
            // div.style.lineHeight = "1.1"

            divContainer.appendChild(div);
            foreignObject.appendChild(divContainer);
            foreignObject.setAttribute("transform", `translate(${x}, ${y})`);
            svgRef.current.appendChild(foreignObject);
            */
        };
    };

    const createBackgroundBar = (r, strokeWidth) => {
        if (svgRef.current) {
            //Background Bar
            const circle = document.createElementNS(svgns, 'circle');
            const cx = 50, cy = 50;
            circle.setAttributeNS(null, 'cx', cx.toString());
            circle.setAttributeNS(null, 'cy', cy.toString());
            circle.setAttributeNS(null, 'r', r.toString());
            circle.setAttributeNS(null, 'class', 'progressbar');
            circle.setAttributeNS(null, 'fill', '#fff');
            circle.setAttributeNS(null, 'stroke', DONUT_RING_COLOR);
            circle.setAttributeNS(null, 'transform', 'rotate(-90 50 50)');
            circle.setAttributeNS(null, 'stroke-width', strokeWidth.toString());

            const circumference = 2 * Math.PI * circle.r.baseVal.value;
            const offset = circumference - circleRange * circumference;
            circle.style.strokeDasharray = circumference.toString();
            circle.style.strokeDashoffset = offset.toString();

            svgRef.current.appendChild(circle);
        };
    };

    const fillProgressBar = (label, percent, r, strokeWidth, fontSize, fontPosition, color) => {
        if (svgRef.current) {
            //Color Bar
            const circle = document.createElementNS(svgns, 'circle');
            const cx = 50, cy = 50;
            circle.setAttributeNS(null, 'cx', cx.toString());
            circle.setAttributeNS(null, 'cy', cy.toString());
            circle.setAttributeNS(null, 'r', r.toString());
            circle.setAttributeNS(null, 'class', 'progressbar');
            circle.setAttributeNS(null, 'fill', 'transparent');
            circle.setAttributeNS(null, 'stroke', color);
            circle.setAttributeNS(null, 'transform', 'rotate(-90 50 50)');
            circle.setAttributeNS(null, 'stroke-width', strokeWidth.toString());

            const circumference = 2 * Math.PI * circle.r.baseVal.value;
            const offset = circumference - (percent * circleRange) / 100 * circumference;
            circle.style.strokeDasharray = circumference.toString();
            circle.style.strokeDashoffset = offset.toString();

            svgRef.current.appendChild(circle);

            //Label
            const text = document.createElementNS(svgns, 'text');
            const x = 48, textAnchor = "end";
            text.setAttributeNS(null, 'x', x.toString());
            text.setAttributeNS(null, 'y', fontPosition.toString());
            text.setAttributeNS(null, 'class', 'donut-text');
            text.setAttributeNS(null, 'text-anchor', textAnchor);
            text.setAttributeNS(null, 'font-size', fontSize.toString());
            text.setAttributeNS(null, 'fill', DONUT_TEXT_COLOR);

            text.textContent = label;
            svgRef.current.appendChild(text);

            if (text.textContent) {
                const words = text.textContent.split("");
                let trimmedText = text.textContent;

                while (text.getComputedTextLength() > maxTextWidth && words.length > 1) {
                    words.pop();
                    trimmedText = words.join("") + "...";
                    text.textContent = trimmedText;
                };
            };
        };
    };

    useEffect(() => {
console.log('ChartRadial', {label}, data);
        emptyContent();
        const dataLength = data?.length || 1;
        const radiusRange = radiusRangeBase + 1.5 * dataLength;
        const strokeWidth = radiusRange / (dataLength < 2? 2: dataLength);
        const fontSize = strokeWidth > strokeTitleBase? strokeTitleBase: strokeWidth;

        if (data?.length) {
            data.map((item, idx) => {
                const {title = "", score} = item;
                const {percentage = 0} = toObj(score);
                const r = outerCircle - ((idx === 0) ? 0 : (strokeWidth + circleGap) * idx);
                const fontPosition = 50 - r + fontSize / 3;
                const color = getChartColor(baseIndex + idx);
                createBackgroundBar(r, strokeWidth);
                fillProgressBar(title, percentage, r, strokeWidth, fontSize, fontPosition, color);
            });
            createOverviewLabel(percentage, DONUT_DEFAULT_SEGMENT_COLOR);
        } else {
            const r = outerCircle;
            const fontPosition = 50 - r + fontSize / 3;
            createBackgroundBar(r, strokeWidth);
            fillProgressBar("", percentage, r, strokeWidth, fontSize, fontPosition, DONUT_TEXT_COLOR);
            createOverviewLabel(percentage, DONUT_TEXT_COLOR);
        };
        createTitleLabel(label);
    }, [ data, svgRef.current, uiLang ]);

    return <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" className="progress-svg donut" ref={svgRef} />
};

export default ChartRadial;