import React, { useState, useEffect, useRef, forwardRef } from 'react';
import * as UI from "../../libs/libUI";
import { svgIcon2 } from '../components/CpATIcoBtn';
//import { autoScrollByDropdown } from '../../poc/helper/autoScrollByDropdown';
import { autoScrollByDD } from '../components/autoScrollByDD';
const emptyHead = <div className={'ck-content EditorReadOnly'} 
    style={{padding:'0px 10px', backgroundColor:'transparent'}}>{'---'}</div>;

// dropdown answer
const CpATAnsDD = forwardRef((props, ref) => {
    const { sel, setSel, opts, posCheck, editable, menuStyle, headerStyle, 
        rowStyle, showResult, showCorr, ansCorr, ansContClass, domQuesRef, asmView=0} = props;
    const [show, setShow] = useState(false);
    const [showInfo, setShowInfo] = useState({showL:true,maxWidth:'360px'});
    const headRef = useRef(null);
    const contRef = useRef(null);

    const xx = opts.find((opt) => {return opt.id === sel});
    const isCorrect = (xx && xx.correct) || ansCorr;

    useEffect(()=>{
        
        if (posCheck && show && headRef.current && headRef.current.offsetParent) {
            const pa = headRef.current.offsetParent.getBoundingClientRect();
            const winWidth = window.innerWidth;
            const showL = ((pa.left > (winWidth - pa.right))?false:true);
            let maxWidth = (winWidth > 768)?Math.floor(winWidth*0.5):Math.floor(winWidth*0.75);
            if (showL && ((maxWidth + pa.left) > winWidth)) maxWidth = winWidth - pa.left - 5;
            if (!showL && ((winWidth - pa.right + maxWidth) > winWidth )) maxWidth = pa.right - 5;  
            setShowInfo({showL:showL, maxWidth:(maxWidth+'px')});
        };
        
        if ( show && domQuesRef?.current && headRef.current && contRef.current ) {  
            autoScrollByDD({container: domQuesRef, dropdown: headRef, options: contRef});
        };

        //const ele = headRef.current && findParentWithClass(headRef.current, "EditorReadOnly");
        //if (ele) ele.style.overflowX = show ? "clip" : "auto";
        /*
        if (contRef.current) {            
            //contRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
            contRef.current.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
        };
        */
    },[show]);

    const getHead = () => {
        
        if (showResult || showCorr) {
            return <div className='flexRowBetween'>
                <div className='flexRowStartFit' style={{overflowX:'auto',overflowY:'hidden'}}>{xx ? xx.jsx(true) : <div style={{color:'transparent'}}>{'blank'}</div>}</div>
                <div className='flexRowStartFit' style={{marginRight:'5px'}}>
                {isCorrect?svgIcon2("general/correct","#4eae84",
                    {minWidth:'25px',minHeight:'25px',width:'25px',height:'25px'})
                    :svgIcon2("general/incorrect","#e94929",
                    {minWidth:'30px',minHeight:'30px',width:'30px',height:'30px'})}
                {svgIcon2("general/dropdown","black",{minWidth:"10px",width:"10px"})}
                </div>
            </div>;
        } else {
            const xx = opts.find((opt, ii) => {return opt.id === sel});
            const correctOpt = opts.find(opt=>{return opt.correct});           
            const tmp = (editable)?(xx ? xx.jsx(true) : opts[0].jsx(true))
                :(asmView?(((sel===-1)||(sel===undefined))?emptyHead:xx.jsx(true)):(correctOpt?correctOpt.jsx(true):opts[0].jsx(true)));
            return <div className='flexRowBetween'>
                <div className='flexRowStartFit' style={{overflowX:'auto',overflowY:'hidden'}}>{tmp}</div>
                {svgIcon2("general/dropdown",(showResult || showCorr)?"black":"#5d52ce",{minWidth:"10px",width:"10px", margin:"0 5px 0 5px"})}
            </div>;
        };
    };

    const onBlur = (e) => { 
        UI.stopEvent(e);
        setShow(false);
    };

    const optClick = (e, id) => {
        UI.stopEvent(e);
        if (showResult || showCorr || !editable) return;
        setSel(id);
        setShow(false);
    };

    const clickShow = (e) => {
        UI.stopEvent(e);
        setShow(!show);
    };

    const onMouseDown = (e) => {
        e.preventDefault();
    };

    const posStyle = posCheck?{width:'max-content', maxWidth:showInfo.maxWidth}:{}
    return <div ref={ref} className='ATDDAnsMenu' style={menuStyle?menuStyle:{}}>
            <div ref={headRef} className={'ATDDAnsHeader clickable ' + ((showResult || showCorr)?(isCorrect?' correct':' wrong'):'')} 
                style={headerStyle?headerStyle:{}} onClick={clickShow} tabIndex={0} onBlur={onBlur}>{getHead()}</div>
            {(show && opts && opts.length > 0 && !(isCorrect && opts.length===1)) && 
                <div ref={contRef} className={'ATDDAnsContainer' + (showInfo.showL?"Left ":"Right ") + (ansContClass?ansContClass:'')}
                onMouseDown={onMouseDown} style={posStyle}> 
                <div className='flexColStartFit'>
                {opts.map((opt, ii) => {
                    const colorStyle = (showResult && opt.correct)?{color:'#4eae84'}:{};
                    const exStyle = ((showResult || showCorr) && (opt.id===sel))?{display:'none'}:{};
                    return <div key={"opt" + opt.id + ii} className={'ATDDAnsrow ' + ((showResult || showCorr)?'':' clickable')} tabIndex={0}
                    onClick={(e)=>optClick(e, opt.id)} style={{...rowStyle, ...colorStyle, ...exStyle}}>{opt.jsx()}</div>
                })}  
                </div>             
            </div>}
        </div>;
});

export default CpATAnsDD;

/*
const findParentWithClass = (element, checkClass) => {
    while (element && !element.classList.contains(checkClass)) {
      element = element.parentElement;
    };
    return element;
};
*/