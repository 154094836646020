import { autoId } from "../../../AppExPf/AppUtil";
import { isAry, maxVal, minVal, objEntries, objKeys, objVals, strCmp, toAry, toFactionF, toFix1, toFloat, toInt, toObj, toPercentF, toStr } from "../../../libs/libType";
import { addAsmSum, rptMetaFullTree, rptMetaSubTree, rptMName, sortRptMs, ym2m } from "./dataUtil";

export const toGradeMetaData_ = (rptStuMetas, _mj, _mtype, _msid) => {
  
  
  const clsMset2d = {};
  toAry(rptStuMetas).forEach(rsm => {
    const { classId, clsMsets } = rsm;
    const msets = clsMset2d[classId] || {};
    const rptMSets = (msid, ms) => { 
      //if(msid !== _msid) return;
      const mset = msets[msid] || {};
      objEntries(ms).map(([mid, s]) => {
        const d = mset[mid] || {cnt:0, markp:0, maxp:0, minp:100};
        d.cnt += s.cnt;
        d.markp += s.markp;
        d.maxp = maxVal(d.maxp, s.maxp);
        d.minp = minVal(d.minp, s.minp);
        mset[mid] = d; 
      }); 
      msets[msid] = mset;
    };
    //objEntries(clsMsets).forEach(([i, s]) => remeMSId(i, s));
    rptMSets(_msid, clsMsets[_msid]);
    clsMset2d[classId] = msets;
  });
  

  const gradeMsets = {};  
  objEntries(clsMset2d).forEach(([clsId, ms2d]) => { 
    const rptMSet2 = (msid, ms) => { 
      const mset = gradeMsets[msid] || {};
      objEntries(ms).map(([mid, s]) => {
        const d = mset[mid] || {cnt:0, markp:0, maxp:0, minp:100};
        const markp = toPercentF(s.markp, s.cnt * 100);
        d.cnt++;
        d.markp += markp;
        d.maxp = maxVal(d.maxp, markp);
        d.minp = minVal(d.minp, markp);
        mset[mid] = d; 
      }); 
      gradeMsets[msid] = mset;
    };
    //objEntries(ms2d).forEach(([i, s]) => rptMSet2(i, s));
    rptMSet2(_msid, ms2d[_msid]);
  });
  
  return { clsMset2d, gradeMsets }; 
};


export const toGradeMetaData1 = (gradeMetaData_, gdClsEnts, j, mt, ms, tagMSets, ut) => {
  const gradeMsets = toObj(gradeMetaData_?.gradeMsets);
  const clsMset2d = toObj(gradeMetaData_?.clsMset2d);
  //const clsMset2dEnt = objEntries(clsMset2d);

//console.log('toGradeMetaData1()a', {gradeMsets, clsMset2d,  gdClsEnts});

  //const msids = objKeys(gradeMsets);
  const msets = tagMSets; //>toObj(useTagMSets((msids));
  const mset = toObj(msets[ms]);
      
  const d2d = objEntries(gradeMsets).filter(([msid, mSums]) => (msid === ms)).map(([msid, mSums]) => {
    //const { mset, mids, metas, MRoot, MBranchs } = rptMetaFullTree(msets, msid); 
    const { mset, mids, metas, MRoot, MBranchs } = rptMetaSubTree(msets, msid, objKeys(mSums));

//console.log('toGradeMetaData1()b', {mset, mids, metas, MRoot, MBranchs});

    const makeNode = mid => {
        const msum = toObj(mSums[mid]);
        const {cnt, markp, minp, maxp} = msum;
        const m = toObj(metas[mid]);
        const avgP2 = toFactionF(markp, cnt);
        const subAverageScore = 
        gdClsEnts.map(([clsId, clsName], i) => { 
          const msum = clsMset2d?.[clsId]?.[msid]?.[mid];
          if(!msum) return {
            id: clsId,//refId+','+msid+','+mid,//"m-001-01", 
            referenceId: clsId,
            _rnum: i,
          };
          const {cnt, markp, maxp, minp} = msum;
          const avgP = toFactionF(markp, cnt);
          return {
            id: clsId,//refId+','+msid+','+mid,//"m-001-01", 
            referenceId: clsId,
            _rnum: i,
            scoreRange: { 
              minPercentage: toFix1(minp), 
              maxPercentage: toFix1(maxp), 
              status: (avgP2 > avgP)? 'f': 'p' 
            },
            averageScore: { percentage: toFix1(avgP) },
            _solid:1,
          }
        }).filter(s => s);
        const _subSolid = subAverageScore.some(s => s._solid);
        const mret = {
            id: msid+','+mid,
            title: rptMName(m, ut, mid),
            _rnum: toInt(m.rnum),
            subAverageScore: subAverageScore,
            _subSolid
        };
        if(_subSolid){
          Object.assign(mret, {
            scoreRange: {
              minPercentage: toFix1(minp),
              maxPercentage: toFix1(maxp),
              status: "s"
            },
            averageScore: { percentage: toFix1(avgP2) },
          });
        }
        if(MBranchs[mid]?.length) mret.children = sortRptMs(toAry(MBranchs[mid]).map(makeNode)); 
        return mret;
    };
    return sortRptMs(MRoot.map(makeNode));
  });
  
  //return  MetadataProgressByAllClassesTeacherData;
  const gadeMetaData1 = {
      id: "**GradeMST",
      title: ut(mset.MSNameEn, mset.MSNameCt),// "Grade-MetaSet",
      type: "a",
      items: gdClsEnts.map(([id, title]) => ({id, title})),
      //items: objKeys(clsMset2d).map(id => ({id, title: toStr(clsNames[id]) })),
      data: [].concat(...d2d),
  };
  
  return gadeMetaData1;
  //return MetadataProgressByAllClassesTeacherData;
};

export const MetadataProgressByAllClassesTeacherData = {
  "items": [
    { "id": "c-001", "title": "4A" },
    { "id": "c-002", "title": "4B" }
  ],
  "id": "m-001",
  "title": "DSE Math",
  "type": "a",
  "data": [
    {
      "id": "m-001",
      "title": "Compulsory Part",
      "scoreRange": { "minPercentage": 30, "maxPercentage": 40, "status": "s" },
      "averageScore": { "percentage": 35 },
      "subAverageScore": [
        {
          "id": "m-001-01", 
          "scoreRange": { "minPercentage": 30, "maxPercentage": 40, "status": "p" },
          "averageScore": { "percentage": 35 },
          "referenceId": "c-001"
        },
        {
          "id": "m-001-02",
          "scoreRange": { "minPercentage": 30, "maxPercentage": 40, "status": "f" },
          "averageScore": { "percentage": 35 },
          "referenceId": "c-002"
        }
      ],
      "children": [
        {
          "id": "m-001-01-01",
          "title": "Number and Algebra Strand",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "s"
          },
          "averageScore": {
            "percentage": 35
          },
          "children": [
            {
              "id": "m-001-01-01-01",
              "title": "1. Quadratic equation in one unknown",
              "scoreRange": {
                "minPercentage": 30,
                "maxPercentage": 40,
                "status": "s"
              },
              "averageScore": {
                "percentage": 35
              },
              "children": [
                {
                  "id": "m-001-01-01-01-01",
                  "title": "1.1 solve quadratic equations by the factor method",
                  "scoreRange": {
                    "minPercentage": 30,
                    "maxPercentage": 40,
                    "status": "s"
                  },
                  "averageScore": {
                    "percentage": 35
                  },
                  "subAverageScore": [
                    {
                      "id": "m-001-01-01-01-01-01",
                      "scoreRange": {
                        "minPercentage": 30,
                        "maxPercentage": 40,
                        "status": "p"
                      },
                      "averageScore": {
                        "percentage": 35
                      },
                      "referenceId": "c-001"
                    },
                    {
                      "id": "m-001-01-01-01-01-02",
                      "scoreRange": {
                        "minPercentage": 30,
                        "maxPercentage": 40,
                        "status": "f"
                      },
                      "averageScore": {
                        "percentage": 35
                      },
                      "referenceId": "c-002"
                    }
                  ]
                }
              ],
              "subAverageScore": [
                {
                  "id": "m-001-01-01-01-01",
                  "scoreRange": {
                    "minPercentage": 30,
                    "maxPercentage": 40,
                    "status": "p"
                  },
                  "averageScore": {
                    "percentage": 35
                  },
                  "referenceId": "c-001"
                },
                {
                  "id": "m-001-01-01-01-02",
                  "scoreRange": {
                    "minPercentage": 30,
                    "maxPercentage": 40,
                    "status": "f"
                  },
                  "averageScore": {
                    "percentage": 35
                  },
                  "referenceId": "c-002"
                }
              ]
            }
          ],
          "subAverageScore": [
            {
              "id": "m-001-01-01-01",
              "scoreRange": {
                "minPercentage": 30,
                "maxPercentage": 40,
                "status": "p"
              },
              "averageScore": {
                "percentage": 35
              },
              "referenceId": "c-001"
            },
            {
              "id": "m-001-01-01-02",
              "scoreRange": {
                "minPercentage": 30,
                "maxPercentage": 40,
                "status": "f"
              },
              "averageScore": {
                "percentage": 35
              },
              "referenceId": "c-002"
            }
          ]
        }
      ],

    },
    {
      "id": "m002",
      "title": "Module 1",
      "scoreRange": {
        "minPercentage": 30,
        "maxPercentage": 40,
        "status": "p"
      },
      "averageScore": {
        "percentage": 35
      },
      "subAverageScore": [
        {
          "id": "m002-01",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "f"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "c-001"
        },
        {
          "id": "m002-02",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "p"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "c-002"
        }
      ]
    },
    {
      "id": "m003",
      "title": "Module 2",
      "scoreRange": {
        "minPercentage": 30,
        "maxPercentage": 40,
        "status": "s"
      },
      "averageScore": {
        "percentage": 35
      },
      "subAverageScore": [
        {
          "id": "m003-01",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "f"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "c-001"
        },
        {
          "id": "m003-02",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "p"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "c-002"
        }
      ]
    }
  ]
}

export const MetadataProgressByAllClassesTeacherData1 = {
  "items": [
    {
      "id": "c-001",
      "title": "4A"
    },
    {
      "id": "c-002",
      "title": "4B"
    }
  ],
  "id": "m-001",
  "title": "Curriculum",
  "type": "a",
  "data": [
    {
      "id": "m-001",
      "title": "Compulsory",
      "scoreRange": {
        "minPercentage": 30,
        "maxPercentage": 40,
        "status": "s"
      },
      "averageScore": {
        "percentage": 35
      },
      "children": [
        {
          "id": "m-001-01-01",
          "title": "Number and Algebra Strand",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "s"
          },
          "averageScore": {
            "percentage": 35
          },
          "children": [
            {
              "id": "m-001-01-01-01",
              "title": "1. Quadratic equation in one unknown",
              "scoreRange": {
                "minPercentage": 30,
                "maxPercentage": 40,
                "status": "s"
              },
              "averageScore": {
                "percentage": 35
              },
              "children": [
                {
                  "id": "m-001-01-01-01-01",
                  "title": "1.1 solve quadratic equations by the factor method",
                  "scoreRange": {
                    "minPercentage": 30,
                    "maxPercentage": 40,
                    "status": "s"
                  },
                  "averageScore": {
                    "percentage": 35
                  },
                  "children": [
                    {
                      "id": "m-001-01-01-01-01-01",
                      "scoreRange": {
                        "minPercentage": 30,
                        "maxPercentage": 40,
                        "status": "p"
                      },
                      "averageScore": {
                        "percentage": 35
                      },
                      "referenceId": "c-001"
                    },
                    {
                      "id": "m-001-01-01-01-01-02",
                      "scoreRange": {
                        "minPercentage": 30,
                        "maxPercentage": 40,
                        "status": "f"
                      },
                      "averageScore": {
                        "percentage": 35
                      },
                      "referenceId": "c-002"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "m002",
      "title": "Module 1",
      "scoreRange": {
        "minPercentage": 30,
        "maxPercentage": 40,
        "status": "p"
      },
      "averageScore": {
        "percentage": 35
      }
    },
    {
      "id": "m003",
      "title": "Module 2",
      "scoreRange": {
        "minPercentage": 30,
        "maxPercentage": 40,
        "status": "s"
      },
      "averageScore": {
        "percentage": 35
      },
      "children": [
        {
          "id": "m003-01",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "f"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "c-001"
        },
        {
          "id": "m003-02",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "p"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "c-002"
        }
      ]
    }
  ]
};