import React, { useEffect, useMemo, useState } from 'react';
import * as UI from '../../libs/libUI';
import { svgIcon2 } from '../components/CpATIcoBtn';

import { ATUIRadio0, ATUIText, preJS } from '../AppUtil';
import { subState } from '../utils/useChain';
import { toInt } from '../../libs/libType';
import { langCodeCt, langCodeEn } from '../../consts/ATConsts';

export const initQSetFtrOpt = {qsName:'', qsESAS:'', qMin:0, qMax:99999, lang:'-', pub:'*'};

const LayerATQSetFilter = (props => {
    const {close, qsFilterState, } = props;
    
    const _initOpt = qsFilterState[0] || initQSetFtrOpt;
    const [opts, setOpts] = useState(_initOpt);
    const setOpt = key => val => setOpts(subState(key, val));
    const inpOpt = key => e => setOpts(subState(key, e?.currentTarget?.value)); 

    const tapGo = e => { UI.stopEvent(e); qsFilterState[1](opts); close(); };
    const tapReset = e => { UI.stopEvent(e); setOpts(initQSetFtrOpt); };


    return UI.ScreenMask(<>
        <div className="screenMask2"></div>
        <div className="vam w" style={{backgroundColor:'#fff'}}>
            <div className="filterRow1"><div></div><div className='f18'>Filter</div>
                <div className='clickable' onClick={close}>{svgIcon2("general/close", "white", { width: "30px" })}</div>
            </div>
            <div className='m8'>
                <div className="tal m8">
                    <div className="mx4 vam">ID / Description includes: </div>
                    <div className="m8 vam"> 
                    {ATUIText(opts?.qsName, setOpt('qsName'), 'tn', 0, '', 'w100', {})} 
                    </div>
                </div>
                <div className="tal m8">
                    <div className="mx4 vam">ESAS Code includes: </div>
                    <div className="m8 vam"> 
                    {ATUIText(opts?.qsESAS, setOpt('qsESAS'), 'tn', 0, '', 'w100', {})} 
                    </div>
                </div>
                <div className="tal m8">
                    <div className="mx4 vam">No. of Questions: </div>
                    <div className="w m8">
                        <input type='number' className='' style={{}} disabled={false}
                        onChange={inpOpt('qMin')} value={toInt(opts?.qMin)} min={0} max={99999} />
                    </div>
                    -
                    <div className="w m8">
                    <input type='number' className='' style={{}} disabled={false}
                        onChange={inpOpt('qMax')} value={toInt(opts?.qMax)} min={0} max={99999} />
                    </div>
                </div>
                <div className='tal m8'>
                    <div className="mx4 vam">Language Includes: </div>
                    <div className="w m8">{ATUIRadio0('-', setOpt('lang'), opts?.lang)} Any</div>
                    <div className="w m8">{ATUIRadio0(langCodeEn, setOpt('lang'), opts?.lang)} English</div>
                    <div className="w m8">{ATUIRadio0(langCodeCt, setOpt('lang'), opts?.lang)} Chinese</div>
                    <div className="w m8">{ATUIRadio0('*', setOpt('lang'), opts?.lang)} Both</div>
                </div>        
                <div className='tal m8'>
                    <div className="mx4 vam">Publish State: </div>
                    <div className="w m8">{ATUIRadio0('*', setOpt('pub'), opts?.pub)} All</div>
                    <div className="w m8">{ATUIRadio0('Y', setOpt('pub'), opts?.pub)} Published</div>
                    <div className="w m8">{ATUIRadio0('N', setOpt('pub'), opts?.pub)} Unpublished</div>
                </div>
                <center><div className='ATfilterLine' /></center> 
                <div className='tac'>
                    <div className="m4 w vam">
                        {UI.colorButton('resetBtn', 'Reset', tapReset, '#e3f5ff', '100px', { margin: '10px', color: '#2995cd' })}
                    </div>
                    <div className="m4 w vam">
                        {UI.colorButton('searchBtn', 'Search', tapGo, '#e3f5ff', '100px', { margin: '10px', color: '#2995cd' })}
                    </div>
                </div>
            </div>
        </div>
    </>);
});
export default LayerATQSetFilter;


