import { useEffect, useMemo, useState } from 'react';

import { ReduxBind } from '../../saga/ReduxState';

import { apiCallLoad, apiCallLoad_ } from '../../libs/awsFuncs';
import { aryLen, objKeys, objVals, strCmp, toInt, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { ATErrsDiv, aTUITime, clickUrl, langDisplay } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';
import { ATDo_QSet, _ATCanDo } from '../../consts/ATRoleRights';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import { cpATIcoBtn_, svgIcon2 } from '../components/CpATIcoBtn';
import { QDPBtn } from '../components/CpDraftPubBtns';
import { useDraftPubList } from '../components/CpPubDraftList';
import { isIn, strIncludes, toStrLT } from '../ATMetaEdit/PageATMetas';
import { langCodeCt, langCodeEn, QP_PP } from '../../consts/ATConsts';
import LayerATQSetFilter, { initQSetFtrOpt } from './LayerATQSetFilter';

const toDo = UI.stopEventThen( e => alert('TBC') );

const PageATQSets = ReduxBind((props) => { 
	const dispatch = props.dispatch;
	const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
	const letQSet = _ATCanDo(role, ATDo_QSet);

	const [allQSets, setQSets, err2d, setErr2d, ticks, cntTick, setTicks, setTick, tickAll, setTickAll, clickTickAll, 
		onLoad, onPub, confirmCall, qsids] = useDraftPubList('QSetId', dispatch);

	const [visQSFilter, setVisQSFilter] = useState();
	const clickFilterQS = UI.stopEventThen( e => setVisQSFilter(!visQSFilter) );
	
	const qsFilterState = useState(initQSetFtrOpt);
	const [qsFilter, setQsfilter] = qsFilterState;

	const QSets = useMemo(() => filterQSets(allQSets, qsFilter), [allQSets, qsFilter]);

	const sortIds = useMemo(()=> {
		const sorts = qsids.filter(id => QSets[id]).map( id => {
			const {draft:d, pub:p} = QSets[id]; 
			return toObj(d? d: p);
		}); 
		sorts.sort((a, b) => (b.dateMod - a.dateMod) || strCmp(a.QSetId, b.QSetId));
		return sorts.map(s => s.QSetId); 
	}, [QSets]);
		

	const reload = () => apiCallLoad_( dispatch, '/getATQSets', onLoad, {}); 
	useEffect(() => { letQSet? reload(): dispatch(urlPush_Replace(_ATRoot)); }, []);
  
	const letPub = letQSet;
	const letUnpub = letQSet;
	const letClone = letQSet;
	const letDelete = letQSet;

	const clickNew = letQSet && clickUrl(dispatch, _ATRoot + 'questionset/new');
	const clickDel = letDelete && confirmCall('Please confirm delete', '/deleteATQSets', onLoad, {ticks} );
	const clickPub = letPub && confirmCall('Please confirm publish', '/putATQSetsPublish', onPub, {ticks} );
	const clickUnpub = letUnpub  && confirmCall('Please confirm unpublish', '/putATQSetsUnpublish', onLoad, {ticks} );
	const clickReload = letQSet && UI.stopEventThen(reload);

	return <div className="adminAccountTop">
		<div className="flexColStart w100 p8" style={{height:'100%'}}>
    	{/*<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> */}
		<div className='w100' style={{overflow:'auto'}}> 

		<div className="f16">Questions / Question Sets</div>
		<div className="adminToolsContainer"><div style={{display:"flex"}}> 
			<div className="adminTools1">
				{letQSet? cpATIcoBtn_('labelDiagram/add', 'New', clickNew): ''}
				{letQSet? cpATIcoBtn_('general/edit', 'Edit', (cntTick === 1) &&  clickUrl(dispatch, _ATRoot + 'questionset/edit/'+ticks[0])): ''}
				{letClone? cpATIcoBtn_('general/copy', 'Duplicate', (cntTick === 1) &&  clickUrl(dispatch, _ATRoot + 'questionset/new/'+ticks[0])): ''}
				{letDelete? cpATIcoBtn_('general/delete', 'Delete', (cntTick) && clickDel): ''}
				{letPub? cpATIcoBtn_('general/publish', 'Publish', (cntTick) && clickPub): ''}
				{letUnpub? cpATIcoBtn_('general/unpublish', 'Unpublish', (cntTick) && clickUnpub): ''}
				{letQSet? cpATIcoBtn_('general/reload', 'Reload', clickReload): ''}
			</div>
			<div className="adminTools2" onClick={clickFilterQS} >
				{svgIcon2("general/filter","#2995cd")}Filter: ({aryLen(sortIds)} / {aryLen(objKeys(allQSets))} )
			</div>
		</div></div>
			<div className="metaItemRowHeader f12">
			<div className="quesSItem1">{UI.CheckBox0(tickAll, setTickAll, 'chkall')}All</div>
			<div className="quesSItem2">Question Set ID</div>
			<div className="quesSItem3">ESAS Serise Codes</div>
			<div className="quesSItem4">Description</div>
			<div className="quesSItem5">Languages</div>
			<div className="quesSItem6">No. of Questions</div>
			<div className="quesSItem7">Version</div>
			<div className="quesSItem8">Last Modified</div>
        </div>            
        <div className="metaListContainer">
			{sortIds.map( qsid => (QSetItem(qsid, QSets[qsid], err2d[qsid], ticks, setTick))) }         
        </div>		
	</div>
	</div>
	{visQSFilter? <LayerATQSetFilter {...{close:(e => setVisQSFilter(0)), qsFilterState }}/>: ''}		
	</div>
});
export default PageATQSets;

const QSetItem = (qsid, qset, errs, ticks, setTick ) => {
	const {draft:d, pub:p} = qset; 
	const dp = toObj(d? d: p);
	const on = ticks?.includes(qsid);
	const _key = qsid + '_' + (on? 'Y': 'N');
	return <label key={'qset_'+_key} style={{width:'100%'}}><div className="itemRowBlk">
		<div className="adminItemRow f12">
        <div className="quesSItem1">{UI.CheckBox0(on, setTick(qsid), 'chk'+_key)}</div>
		<div className="quesSItem2">{dp.QSetId}</div>
		<div className="quesSItem3">{dp.ESASStr}</div>
		<div className="quesSItem4">{dp.QSNameEn}<br/>{dp.QSNameCt}</div>

		<div className="quesSItem5">{UI.centerBox(d? <div >{langDisplay(d.QSLangEn, d.QSLangCt)}</div>: '',
			p? <div >{langDisplay(p.QSLangEn, p.QSLangCt)}</div>: '')}</div>
		<div className="quesSItem6">{UI.centerBox(d? d.QSQCnt: '',p? p.QSQCnt: '')}</div>
		<div className="quesSItem7">{UI.centerBox(d? DPDisplay(d): '',p? DPDisplay(p): '')}</div>
		<div className="quesSItem8"><div>{aTUITime(dp.dateMod)}</div><div>{dp.modLogin}</div></div>
		<div></div>
    </div>
	{ATErrsDiv(errs)}
	</div></label>;
};


const DPDisplay = q =>  (q ? <div>{QDPBtn(q, 'QSVer', UI.stopEvent, 0)}</div> : '');

const filterQSets = (allQSets, eFilter) => {
	const n = toStrLT(eFilter?.qsName);
	const e = toStrLT(eFilter?.qsESAS);
	const q1 = toInt(eFilter?.qMin);
	const q2 = toInt(eFilter?.qMax);
	const l = toStr(eFilter?.lang).trim();
	const p = toStrLT(eFilter?.pub);
	const sn = n? objVals(allQSets).filter(s => 
		[s.pub?.QSNameCt, s.pub?.QSNameEn, s.pub?.QSetId, 
		 s.draft?.QSNameCt, s.draft?.QSNameEn, s.draft?.QSetId ].some(s => strIncludes(s, n)) 
	): objVals(allQSets);

	const se = e? sn.filter(s => [s.pub?.ESASStr, s.draft?.ESASStr, ].some(s => strIncludes(s, e))): sn;

	const sq = se.filter(s => ((isIn(s.pub?.QSQCnt, q1, q2) ) 
		|| (isIn(s.draft?.QSQCnt, q1, q2) )));

	const sl = (l === '*')? sq.filter(s => ((s.pub?.QSLangEn && s.pub?.QSLangCt)) || ((s.draft?.QSLangEn && s.draft?.QSLangCt)))
		: (l === langCodeEn)? sq.filter(s => ((s.pub?.QSLangEn)) || ((s.draft?.QSLangEn)))
		: (l === langCodeCt)? sq.filter(s => ((s.pub?.QSLangCt)) || ((s.draft?.QSLangCt)))
		: sq;
	const sp = (p === 'y')? sl.filter(s => (s.pub?.drpu === QP_PP)):
		(p === 'n')? sl.filter(s => (s.pub?.drpu !== QP_PP)):
		sl;

	const ret = Object.fromEntries(sp.map(dp => [dp.draft?.QSetId || dp.pub?.QSetId, dp]));
console.log('filterExers', {allQSets, ret, sn, se, sq, sl, sp, F: {n, e, q1, q2, l, p}, eFilter });
	return ret;
};
