import React from "react";
import { useUILang } from "../../../AppExPf/utils/useUILang";



const CpChartIndicatorGuideline = (props) => { //poc ChartIndicatorGuideline
    //const [ sorts, setSorts, addSort, flipSort] = props.useSL;
    const {passed = true, failed = true, attempted = true, expired = true, avg = true, passing = true, master} = props
    return <CpChartIndicatorGuidelineX {...{passed, failed, attempted, expired, avg, passing, master}} />
}

export const CpChartIndicatorGuidelineX = (props) => { //poc ChartIndicatorGuideline
    const [t, uiEn, UILang, setUILang, ut, t2, t3] = useUILang();
    const {passed, failed, attempted, expired, avg, passing, master} = props;
    
    return <div className={"CpChartIndicatorGuidelineX d-flex justify-content-end gap-3 flex-wrap fs-8 semi-bold w-100 mb-3"}>
        {passed? <span className={"sample-line"}><span className={"sample-correct-cube"}></span>{t("report-status-passed")}</span>:''}
        {failed? <span className={"sample-line"}><span className={"sample-incorrect-cube"}></span>{t("report-status-failed")}</span>:''}
        {attempted? <span className={"sample-line"}><span className={"sample-attempted-cube"}></span>{t("report-status-completed")}</span>:''}
        {expired? <span className={"sample-line"}><span className={"sample-not-attempted-cube"}></span>{t("report-status-expired")}</span>:''}
        {(avg || passing || master)? <span className={"d-flex gap-2"}>
            {avg? <span className={"sample-line"}><span className={"sample-avg-line"}></span>{t("report-avg-score-percentage")}</span>:''}
            {passing? <span className={"sample-line"}><span className={"sample-passing-line"}></span>{t("report-passing-score-percentage")}</span>:''}
            {master? <span className={"sample-line"}><span className={"sample-passing-line"}></span>{t("report-mastery-score")}</span>:''}
        </span>:''}
    </div>;
};

export default CpChartIndicatorGuideline;