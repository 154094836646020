import React, { useEffect, useState, useRef } from 'react';
import * as UI from '../../libs/libUI';
import { toAry, toInt, toStr } from '../../libs/libType';
import CpATQtnDo from './CpATQtnDo';
import { deepCopy } from '../AppUtil';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import AnsKeyContent from '../components/CpAnsKeyContent';
import CpATQtnHeader from './CpATQtnHeader';
import { calculateScore } from '../../consts/ATQtnScore';
import { exerTitle } from '../ATExerEdit/ViewExCommon';
import { useUILang } from '../utils/useUILang';
import { Accordion } from 'react-bootstrap';
import CpFIBManualMarkWithText from '../../AppExPFUser/EPAsmWork/CpFIBManualMarkWithText';
import CpManualMarkMarking from '../../AppExPFUser/EPAsmWork/CpManualMarkMarking';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import { __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_LBD, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_POL, __SYSQSubType_OED } from "../../consts/ATSysQType";
import CpAssignStdFilter from '../../AppExPFUser/EPAsmWork/CpAssignStdFilter';
import CpMessageBox from '../../AppExPFUser/_components/CpMessageBox';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { iconButton } from '../components/CpATIcoBtn';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';
import CpPopoverComponent from '../components/CpPopoverComponent';
import IconGroup from '../../poc/screens/schema/components/custom/form/icon/IconGroup';
import DrawingCanvas from '../components/CpDrawingCanvas';
//import { previewRemainTime,  } from '../../consts/ATConsts';
import { newHintsPop, NoMaskHints, hasOverlapDraw } from '../../consts/ATSysQType';
//import DrawingLayerEditor from '../../poc/screens/schema/components/custom/editor/DrawingLayerEditor';
import { clearNoUseWiris } from '../components/ckeditor5/Ckeditor5Base';
import DrawComponentV5 from './CpQDoDrawToolsV5';
import { asyncGetDraw } from '../utils/useMediaCache';
import { getCVData, validDrawSize, byteToMB } from '../utils/utilQtnAns';
import { debugTrimDraw } from '../../consts/AValidateWork';
import { popAlert } from '../components/CpPopup';
import { xapiPost } from '../../AppExPFUser/EPLibrary/PageLibrary';
import { asmIsReme } from '../../consts/EpAssigmentErr';
import { debugMode } from '../../saga/ReduxState';

const CpATQtnSingle = (props) => {
    const { dispatch, asm, doWhat, Q, fullQ, jsx, PVMode, idx=0, ddList=[], displayIdx, dummyHeight=0,
        mediaDLs, getMediaDLs, showQNo=1, totalQtn=1, aHints, vis=0, setVis=()=>{},
        close, back, gotoQ, nextQ, prevQ, submitQ, isThr = 0, isStt = 0, hideHeader=0, 
        CstudentResp, CteacherResp, titleStr, debugInfo, noSave, modified, endStr,
        collectStudentResp, collectTeacherResp, collectScore, parentTime, timeUsed=0, remainTime=0,
        shiftMark, SWMs, swmIdx, showQMark=0, saved=0, aPub=0, marked=0, dummyHeader=0,
        scrollRef, canVis=0, bottomMargin=0, setBottomMargin=0, noContent=0, 
        //setOverlayDraw=0, overlayDraw=-1,
        setOpenState, openState, //={overlayDraw:-1, hints:-1, ansKeys:-1, comment:-1},
        setOverBarReady = 0, onListScroll=0, isReme, needDrawDeb=0, answerVis, showCorr=0
    } = props;
    
    const [ t ] = useUILang();

    const [visAnsKeys, setVisAnsKeys] = useState(false);
    const [visHints, setVisHints] = useState(false);
    const [visComment, setVisComment] = useState(false);
    const [visDrawLayer, _setVisDrawLayer] = useState(false);

    //const asm = {};
    const setVisDrawLayer = d => {
        if(d && isStt && asm) xapiPost(dispatch, "xDrawLayer", {isReme: asmIsReme(asm), asmId: asm.assignId, QId:fullQ.QId});
        _setVisDrawLayer(d);
    };

    const [localSR, setLocalSR] = useState({}); // if no parent student resp, need a local copy for preview submit
    const [localTR, setLocalTR] = useState({});

    const preview = doWhat === 'preview';
    const listView = doWhat === 'listView';
    const asmAll = (doWhat === 'asmView' || doWhat === 'asmEdit');
    const studentResp = CstudentResp || localSR;
    //const teacherResp = preview ? localTR : (CteacherResp || localTR);
    const teacherResp = CteacherResp || localTR;
    const setStudentResp = (valOrFunc) => { if (!CstudentResp) setLocalSR(valOrFunc); };
 
    const SQType = fullQ?.SQType;
    const isAutoMark = fullQ?.autoMark;
    const notPolling = SQType !== __SYSQSubType_POL;
    const correctness = fullQ?.correctness || 0;
    const isAutoMarkQtn = [__SYSQSubType_MCS,__SYSQSubType_MCT,
        __SYSQSubType_LBT,__SYSQSubType_LBD].includes(SQType) || (isAutoMark) || (SQType === __SYSQSubType_FIB && preview);
    const isManualMarkQtn = ( SQType === __SYSQSubType_FIB && !isAutoMark) || 
        ([__SYSQSubType_OED,  __SYSQSubType_OEG, __SYSQSubType_OEE].includes(SQType) && correctness);
    const [showResult, setShowResult] = useState(isThr?isAutoMarkQtn:props?.showResult);
    //console.log("info "+idx, {CteacherResp, localTR, preview, showCorr, showResult});    
    const [canRender, setCanRender] = useState(true);
    const [getScore, setGetScore] = useState(0);
    const [myBottomMargin, setMyBottomMargin] = useState(0);
    const [contentCKSize, setContentCKSize] = useState(null);

    const [overCanvas, setOverCanvas] = useState(null);
    const [doDrawReset, setDoDrawReset] = useState(0);
    const [doDrawDisable, setDoDrawDisable] = useState(0);
    const drawOverlayExtra = useRef(null);
    const btnBarRef = useRef(null);
    const contentRef = useRef(null);
    const popOverRef = useRef(null);
    const drawOverlayRef = useRef(null);
    const [overlayDrawData, setOverlayDrawData] = useState(studentResp?.overlay?.qresp);
    const [drawResponse, setDrawResponse] = useState(null);

    const overlayDrawID = toStr(studentResp?.overlay?.qrespS3Id);
    const waitOverlayDraw = studentResp?.overlay?.qrespS3Id && !overlayDrawData;
    const drawInit = useRef(waitOverlayDraw);
    const [OID, setOID] = useState(0);
    const [afterInit, setAfterInit] = useState(0);
    //const [showDrawOverlay, setShowDrawOverlay] = useState(1);
    //const [timerCount, currentTimeDisplay, startTimer, stopTimer] = useQtnTimer(0);
    //const [myTimer, setMyTimer] = useState(null);
    //console.log('setBottomMargin?', setBottomMargin);
    const _setBottomMargin = setBottomMargin?setBottomMargin:setMyBottomMargin;

    const edProps = {mediaDLs, getMediaDLs};
    const AKText = toAry(Q?.AKText);
    const AKFile = toAry(Q?.AKFile);
    const AKLink = toAry(Q?.AKLink);

    const totalScore = (fullQ?.score)||0;
    const _setStudentResp = (obj) => {
        collectStudentResp && collectStudentResp(idx, obj);
        setStudentResp(obj);
    };
    const _setTeacherResp = (obj) => {
        collectTeacherResp && collectTeacherResp(idx, obj);
        //setTeacherResp(obj);
    };

    const showQ = Q?1:0;
    const doEx = (PVMode===0 || PVMode===1);
    const singleMode = (PVMode !== 1) && (PVMode !== 2);

    const mmQtn = [__SYSQSubType_OEG,__SYSQSubType_OEE,__SYSQSubType_OED].includes(SQType) ;
    //const scoreMethod = fullQ?.scoreMethod || FIBC.__FIBS_PBK;

    const showScore = SQType !== __SYSQSubType_POL;
    const myKey = fullQ?.QId + idx;

    //console.log("CpATQtnSingle aHints:", aHints);
    const _stShowHints = ((!isThr) && (aHints || aHints === undefined)); // if aHints not set, show   
    const showMarked = notPolling && [__SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_OED].includes(SQType) && (!correctness);
    const showCorrectTag = notPolling && (isAutoMarkQtn || (teacherResp?.allMarked && isManualMarkQtn));
    const showAttempt = notPolling && mmQtn && (!correctness) && (teacherResp?.allMarked);
    const showNotMarkedMsg = (isManualMarkQtn || mmQtn)
        && notPolling && isThr && (!preview) && !(teacherResp?.allMarked);
//    const showMarked = [__SYSQSubType_OEE, __SYSQSubType_OEG,__SYSQSubType_MCS,__SYSQSubType_MCT,
//        __SYSQSubType_LBT,__SYSQSubType_LBD].includes(SQType) || isAutoMark;
    const QNo = toInt(displayIdx);
    const gotScore = toInt(teacherResp?.aScore) + toInt(teacherResp?.mScore);
    const exStyle = singleMode?{padding:'0 10px 0px 10px',overflow:'auto',height:'100%'}:{padding:'0 0 40px 0',overflow:'visible'};
    //const exStyle2 = singleMode?{padding:'2px',overflow:'visible'}:{padding:'2px',overflow:'visible'};

    const showTeMarkBox = isThr && correctness;
    //const showHintBtn = (!isThr && showQ && (Q.showAnsHints) && _stShowHints);
    const showHintBtn = showQ && (Q.showAnsHints) && (isThr || _stShowHints);
    const _stShowKeyBtn = (!isThr && showResult && (!isReme) && showQ && Q.ansKeys);
    const showKeyBtn = (isThr && showQ && Q.ansKeys) || _stShowKeyBtn;
    const showCommentBtn = !isThr && showResult && (!isReme) && teacherResp?.comments;
    const showDrawLayerBtn = ((!isThr && showQ) || preview ) && (!showResult) && doEx && (doWhat !== 'asmView');
    //const showVerticalLine = (isThr || showScore) && (showTeMarkBox || showHintBtn || showKeyBtn || showCommentBtn);
    const showVerticalLine = (showDrawLayerBtn && showScore) || ((isThr || showScore) && (showTeMarkBox || showHintBtn || showKeyBtn || showCommentBtn));
    const showQtnScore = (showScore && ((( !showResult && (preview || (asmAll && !showCorr)) || listView) && !isThr) || showQMark));
    const showTeacherMark = (isThr && mmQtn);
    const showResultScore = showScore && ((showResult || showCorr) || (isThr && !mmQtn));
    const remeView = isReme && !showResult;
    const isOverDrawReady = 1;

    const setDirtyInfo = (flag) => {
        setOID(flag);
    };

    useEffect(()=>{ setAfterInit(1); return (()=>{ if (singleMode && preview && !doEx) { clearNoUseWiris(); };}); },[]);

    useEffect(()=>{ if (showResult || showCorr) calculateScore(Q, fullQ, (asmAll || preview), studentResp, localTR, collectScore, setGetScore); }, [showResult]);
    useEffect(()=>{ 
        if (openState) {
            if (openState.overlayDraw !== idx && visDrawLayer) setVisDrawLayer(0);
            if (openState.hints !== idx && visHints) setVisHints(0); 
            if (openState.ansKeys !== idx && visAnsKeys) setVisAnsKeys(0); 
            if (openState.comment !== idx && visComment) setVisComment(0); 
        };
    },[openState]);

    const loadDrawById = async () => { 
        //if (!visDrawLayer || !overlayDrawID) return; 
        //console.log({overlayDrawID,isStt,showResult,preview});
        if (!overlayDrawID || (!isStt) || showResult || preview || overlayDrawData) return; 
        const fgDraw = await asyncGetDraw(dispatch, getMediaDLs, overlayDrawID); // ddata is  
        //console.log('effect single overlay loadDrawById() ', {fgDraw: debugTrimDraw(fgDraw)});
        drawInit.current = true;
        setOverlayDrawData(fgDraw);
    };
    useEffect(()=>{ loadDrawById(); },[overlayDrawID]);    
    //useEffect(()=>{ console.log('set overCanvas', overCanvas); },[overCanvas]);

    const setOverlayResponse = (v) => {
        const drawS3Id = { overlay: {qrespS3Id:"", qresp:v}};
        // xapi trigger write overlay draw data: v
        _setStudentResp({...studentResp, ...drawS3Id});
    };    
    useEffect( () => { 
        if (overlayDrawData) {
            if (!drawInit.current && afterInit) {
                setOverlayResponse(overlayDrawData);
            } else { 
                drawInit.current = false; 
            };
        }; 
    }, [overlayDrawData]);
    useEffect(()=>{ // need rollback one step, don't update draw data back directly
        if (drawResponse) {
            //console.log('overlay setDrawResponse()', overCanvas, {draw:debugTrimDraw(drawResponse)});
            if (visDrawLayer && drawResponse) {
                //const [isValid, drawSize] = (!preview && overCanvas)? validDrawSize(overCanvas, 'ov'): [1,0];
                const [isValid, drawSize] = validDrawSize(overCanvas, 'ov');
                if (isValid) { 
                    setOverlayDrawData(drawResponse);
                    setDoDrawReset(0);
                    //setDoDrawDisable(0);
                } else { 
                    setDoDrawReset(1);
                    //setDoDrawDisable(1);
                    const txt = "<div className='flexColStart'><span>" + t('warning.warning_drawing_layer_total_size') + "</span><ul><li>"
                        + t('warning.warning_current_size').replace("%s",byteToMB(drawSize)) + "</li><li>"
                        + t('warning.warning_size_limit') + "</li></ul></div>";
                    popAlert(dispatch, 1, txt);
                };    
            };
        }
    },[drawResponse]);
    //const _setDrawResponse = v => { if (v) setDrawResponse(v); };
    //useEffect(()=>{ console.log('single myBottomMargin', myBottomMargin); }, [myBottomMargin]);

    useEffect(()=>{
        // for op qtn
        if (!afterInit) return;
        const extraEle = drawOverlayExtra.current;
        const topExtra = extraEle?.clientHeight || 76;
        const space = 20;
        /*
        if (showDrawOverlay && drawOverlayRef.current && contentRef.current) {
            const hh = contentRef.current.style.height;
            const ww = contentRef.current.style.width;
            if (contentCKSize) {
                drawOverlayRef.current.style.height = contentCKSize?.height + space + 'px';
                drawOverlayRef.current.style.width = contentCKSize?.width + 'px';
            } else {
                console.log('here', hh, space, btnBarH);
                drawOverlayRef.current.style.height = hh + space - btnBarH + 'px';
            };
        };
        */
       //console.log('visDrawLayer', visDrawLayer);
        if (visDrawLayer && drawOverlayRef.current) {
            //console.log('show me', idx, overlayDraw);
            if (contentCKSize) {
                drawOverlayRef.current.style.height = contentCKSize?.height + space + topExtra + 'px';
                //drawOverlayRef.current.style.width = contentCKSize?.width + 'px';
            };           
            setOpenState && setOpenState({...openState, overlayDraw:idx});
            //setOverlayDraw && setOverlayDraw(idx);
        } else {
            //console.log('close me', idx, overlayDraw);
            /*
            if (OID && overCanvas) { 
                console.log("need to save !!");
                setDrawResponse(getCVData(overCanvas));
                setOID(0);
            };
            */            
            onListScroll && onListScroll();
            if (openState && openState.overlayDraw === idx) {
                setOpenState && setOpenState({...openState, overlayDraw:-1});
                //setOverlayDraw && setOverlayDraw(-1);
            };
        };
    }, [contentCKSize, visDrawLayer]);
    
    const initOpen = {hints:-1, ansKeys:-1, comment:-1};
    const updateLocalState = (state) => {
        if ((!state.hints) && visHints) setVisHints(0);
        if ((!state.ansKeys) && visAnsKeys) setVisAnsKeys(0);
        if ((!state.comment) && visComment) setVisComment(0);
    };

    useEffect(()=>{
        if (visAnsKeys) updateLocalState({hints:0,ansKeys:1,comment:0});
        if (setOpenState) {
            if (visAnsKeys) { 
                if (openState.ansKeys !== idx) { setOpenState({...openState, ...initOpen, ansKeys:idx}); };
            } else if (openState.ansKeys === idx) { setOpenState({...openState, ansKeys:-1}); };
        };
    },[visAnsKeys]);
    useEffect(()=>{
        if (visHints) updateLocalState({hints:1,ansKeys:0,comment:0});
        if (setOpenState) {
            if (visHints) { 
                if (openState.hints !== idx) { setOpenState({...openState, ...initOpen, hints:idx}); }; 
            } else if (openState.hints === idx) { setOpenState({...openState, hints:-1}); };
        };
    },[visHints]);
    useEffect(()=>{
        if (visComment) updateLocalState({hints:0,ansKeys:0,comment:1});
        if (setOpenState) {
            if (visComment) {
                if (openState.comment !== idx) { setOpenState({...openState, ...initOpen, comment:idx}); };
            } else if (openState.comment === idx) { setOpenState({...openState, comment:-1}); };
        };
    },[visComment]);        

    const demoMsgObj26 = {
        mode: "warning",
        style: "icon",
        content: { title: t("warning.warning_manual_mark_msg") },
        iconPath: IconList.general.alert,
        styleClass: "manual-mark-msg"
    };

    const goBack = (e) => {
        if ((asmAll || preview) && close) close();
        (PVMode !==3) && back && back(e);
    };
   
    const _gotoQ = (e, ii) => gotoQ(e, ii);

    const doSubmit = (e) => { UI.stopEvent(e);
        if (asmAll && submitQ) { submitQ(); return; };
        if ((doEx || preview) && !showResult) { 
            setShowResult(true);
            setCanRender(false);
            submitQ && submitQ(); // call parent submitQ
            setTimeout(() => { setCanRender(true); }, 10); 
            return;
        };
    };
    
    const goNext = (e) => {if (nextQ) nextQ(e)};
    const goPrev = (e) => {if (prevQ) prevQ(e)};

    const showDrawLayer = (e) => { UI.stopEvent(e); setVisDrawLayer(visDrawLayer? 0: 1); }; // force use 1 or 0
    const showHints = (e) => {UI.stopEvent(e);setVisHints(visHints?0:1)};
    const showAnsKeys = (e) => {UI.stopEvent(e);setVisAnsKeys(visAnsKeys?0:1)};
    const showComments = (e) => {UI.stopEvent(e);setVisComment(visComment?0:1)};
    const closeAllVis = (e) => {UI.stopEvent(e);setVisHints(0);setVisAnsKeys(0);setVisComment(0);}

    const thrCorrClick = (e,cc) => {
        UI.stopEvent(e);
        teacherResp.mCorrect = cc;
        _setTeacherResp({...teacherResp});
    };

    const setThrMScore = (mm) => {
        teacherResp.mMark = mm;
        _setTeacherResp({...teacherResp});
    };

    const setThrComments = (cc) => {
        teacherResp.comments = cc;
        _setTeacherResp({...teacherResp});
    };

    const resizeCB = (data) => { setContentCKSize(data); };
    const btnBarHeight = (btnBarRef.current ? btnBarRef.current.clientHeight : 60) + 5;
    //console.log("btnBarHeight", btnBarHeight);
//    {(showResult && showMarked && isCorrSet(teacherResp))?qtnStatusBlk(teacherResp.isRight?'correct':'incorrect',t):''}                     
//    {(showResult && showMarked && ((isStt && isAutoMarkQtn) || (preview || (isThr && isAutoMark) || (isThrMarked(teacherResp) && !isCorrSet(teacherResp)))))?qtnStatusBlk('attempted',t):<div></div>}
//{(showResult && showMarked)?qtnStatusBlk('attempted',t):''}
//{(showResult && showCorrectTag)?qtnStatusBlk(teacherResp.isRight?'correct':'incorrect',t):''}

    //console.log("show mark?", (showScore && (((preview || (asmAll && !showResult && !showCorr) || listView) && !isThr) || showQMark)));
    //console.log({idx, teacherResp});
    const bottomDelta = PVMode===1?0:((!visAnsKeys && !visComment && !visHints) ? 0:((setBottomMargin?bottomMargin:myBottomMargin)-((bottomMargin || myBottomMargin)>0?60:0)));
    //console.log({singleMode, PVMode, doEx, preview, asmAll});
    return <div id="exSingleCont" key={myKey} className={singleMode?(PVMode===3?"ATQtnSingleContainer3":"ATQtnSingleContainer"):"ATQtnSingleContainer2" + (PVMode===2?' unclickable':'')}
        style={{paddingTop:(singleMode?(vis?(dummyHeight+60):60):0)+'px', paddingBottom: bottomDelta +'px'}}>
        {singleMode?<CpATQtnHeader {...{ idx, ddList, goBack, doSubmit, modified, hideHeader, endStr,
            PVMode, preview, showResult, getScore, noSave, saved, vis, setVis, answerVis,
            titleStr, showScore, isThr, debugInfo, isReme, showCorr,
            parentTime:doEx?parentTime:0, timeUsed:doEx?timeUsed:0, 
            remainTime:doEx?(preview?-1:remainTime):-1, gotoQ:_gotoQ,
        }}/>:''}
        <div ref={contentRef} className="ATQtnSingleContent " style={exStyle}>
        <div className="ATQtnSingleContent2 " style={hasOverlapDraw?{}:{border:'none'}}>
        {showQ?<><div className='flexColCenter f16' style={{height:'auto'}}>
            {showNotMarkedMsg?<div className='ATQtnSingleInfoCont' style={{margin:'8px 0 -5px 0', height:'auto'}}>
                <CpMessageBox item={demoMsgObj26}/></div>:''}
            <div ref={btnBarRef} className="ATQtnSingleInfoCont">
                <div className='flexRowBetweenFit' style={{flexGrow:1}}>
                    <div className='flexRowStartFit' style={{gap:'6px'}}>
                    {(PVMode !== 0 && QNo > 0 && showQNo )?<div className='flexRowStart text-nowrap semi-bold'>
                    {exerTitle(t('question')+' '+QNo)}</div>:''}
                    {(showResult && notPolling && showMarked) || ((showResult || isThr) && showAttempt)?qtnStatusBlk('attempted',t):''}
                    {(((showResult || showCorr) && showCorrectTag && teacherResp) || (isThr && showCorrectTag))?
                        qtnStatusBlk(teacherResp.isRight?'correct':'incorrect',t):''}
                    </div>
                    {showTeMarkBox?<CpFIBManualMarkWithText isCorrect={teacherResp?.mCorrect} setCorrect={thrCorrClick} />:''}
                </div>
                
                <div className="ATQtnSingleInfo f16 flex-wrap gap-3 ps-3" style={{marginLeft: "auto"}}>
                    {(showDrawLayerBtn && hasOverlapDraw && !showCorr)?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.drawingLayer}/>} onClick={showDrawLayer}/>:''}
                    {showHintBtn?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.hints}/>} onClick={showHints} />:''}
                    {showKeyBtn?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.answerKey}/>} onClick={showAnsKeys} />:''}
                    {showCommentBtn?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.feedback}/>} onClick={showComments} />:''}
                    {showVerticalLine?<div style={{backgroundColor:'rgba(178,179,188,1)', width:"1px", height:"34px"}}></div>:''}
                    {showTeacherMark?<CpManualMarkMarking score={teacherResp?.mMark} maxScore={fullQ.score} setScore={setThrMScore} />:''}
                    {showQtnScore?<div className='ATQtnSingleMarks semi-bold cus-btn-sm'>{totalScore + ' '+t(totalScore>1?'small-marks':'small-mark')}</div>:''}
                    {showResultScore?<div className='ATQtnSingleMarks semi-bold cus-btn-sm'>{ gotScore + ' / '+ totalScore}</div>:''}
                </div>
            </div></div>    
            {(hasOverlapDraw && visDrawLayer && !showResult)?<div ref={drawOverlayExtra} className='drawOverlayExtra'></div>:''}
            {(canRender && !noContent)?<CpATQtnDo {...props} Q={deepCopy(props.Q)} showResult={showResult} doWhat={doWhat}
                setStudentResp={_setStudentResp} setTeacherResp={_setTeacherResp} VV={fullQ.QId} idx={idx}
                studentResp={studentResp} teacherResp={teacherResp} PVMode={PVMode} remeView={remeView}
                scrollRef={singleMode?contentRef:scrollRef} canVis={canVis} resizeCB={resizeCB}
                />:'under contruction'}
            {debugMode()?<>{'Dirty: '+OID}</>:''}
            {(isThr && teacherResp)?<TeacherComment data={teacherResp?.comments} setData={setThrComments} />:''}
            {(aPub && teacherResp)?<TeacherComment data={teacherResp?.comments} readOnly={1} />:''}
            {canVis?<div className='fabricTest'>{testFabric('fabric'+myKey)}</div>:''}
            </>
        :jsx}
        {(visDrawLayer && hasOverlapDraw && showDrawLayerBtn)?<div ref={drawOverlayRef} id={'drawOverlayCont'+idx} className='drawOverlay'
            style={{height:"calc(100% - " + btnBarHeight + "px)", marginTop: btnBarHeight + "px"}}>
            {isOverDrawReady?<DrawComponentV5 {...{dispatch, doEdit: (isStt || preview || !showResult), QNo,
                isTeacher:isThr, showResult, preview, idx, setResponse: setDrawResponse, PVMode, gotoQ:_gotoQ,
                drawData: overlayDrawData, bgData:null, doReset:doDrawReset, setDoReset:setDoDrawReset, doDisable:doDrawDisable, setDoDisable:setDoDrawDisable,
                setParentCanvas: setOverCanvas, isOverLay:1, setOverBarReady, setVisDrawLayer, needDrawDeb,
                overLayExHeight:drawOverlayExtra.current?drawOverlayExtra.current.clientHeight:120, setDirtyInfo,
                }}/>:'loading'}
        </div>:''}
        </div>
        </div>
        {(singleMode)?<div className='ATQtnSingleBottomRow'>
            {idx>0?<CpActionButtonComponent variant={"preview-secondary"}
                icon={<CpIco src={IconList.general.previous}/>} animation={true} onClick={goPrev}/>:<span/>}
            {isThr?<CpAssignStdFilter {...{shiftMark, SWMs, swmIdx, isReme, marked}} />:''}
            {idx<(totalQtn-1)?<CpActionButtonComponent variant={"preview-secondary"}
                icon={<CpIco src={IconList.general.next}/>} animation={true} onClick={goNext}/>:<span/>}
        </div>:''}
        {/*(id, icon, title, desc, jsx, style, clickClose=()=>{}, setBottomMargin) => {*/}
        {(showQ && PVMode !==2 && !newHintsPop)?<>
            {Q?.showAnsHints? <BottomSlidePage id={'anshints'+idx} icon={IconList.general.hints} 
                title={t("hints")} desc={t('question')+' '+QNo} idx={idx}
                jsx={<QEditorReadOnly {...props} {...edProps} data={Q?.ansHints} />}
                style={{bottom:(visHints?'0':'-100%')}} clickClose={()=>closeAllVis()}
                setBottomMargin={_setBottomMargin} vis={visHints} gotoQ={_gotoQ} />:''}
            {(showResult && Q.ansKeys)? <BottomSlidePage id={'ansKeys'+idx} icon={IconList.general.answerKey} 
                title={t("answer-key")} desc={t('question')+' '+QNo} idx={idx}
                jsx={<AnsKeyContent {...{...props, ...edProps, AKText, AKFile, AKLink}}/>}
                style={{bottom:(visAnsKeys?'0':'-100%')}} clickClose={()=>closeAllVis()}
                setBottomMargin={_setBottomMargin} vis={visAnsKeys} gotoQ={_gotoQ}/>:''}
            {(showResult && teacherResp?.comments)? <BottomSlidePage id={'ansComment'+idx} icon={IconList.general.feedback} 
                title={t("comments")} desc={t('question')+' '+QNo} idx={idx}
                jsx={<QEditorReadOnly {...props} {...edProps} data={teacherResp?.comments} />}
                style={{bottom:(visComment?'0':'-100%')}} clickClose={()=>closeAllVis()}
                setBottomMargin={_setBottomMargin} vis={visComment} gotoQ={_gotoQ}/>:''}
        </>:''}

        {(showQ && PVMode !==2 && newHintsPop)?<>
            {(Q?.showAnsHints && visHints)?bottomSlidePage2(popOverRef,IconList.general.hints,t("hints"),t('question')+' '+QNo,
                <QEditorReadOnly {...props} {...edProps} data={Q?.ansHints}/>,
                visHints, setVisHints, _setBottomMargin, _gotoQ, idx):''}
            {visAnsKeys?bottomSlidePage2(popOverRef,IconList.general.answerKey,t("answer-key"),t('question')+' '+QNo,
                <AnsKeyContent {...{...props, ...edProps, AKText, AKFile, AKLink}}/>,
                visAnsKeys, setVisAnsKeys, _setBottomMargin, _gotoQ, idx):''}
            {visComment?bottomSlidePage2(popOverRef,IconList.general.feedback,t("comments"),t('question')+' '+QNo,
                <QEditorReadOnly {...props} {...edProps} data={teacherResp?.comments} />,
                visComment, setVisComment, _setBottomMargin, _gotoQ, idx):''}
        </>:''}
        {(!newHintsPop && !NoMaskHints && (visAnsKeys || visHints || visComment)) && <div className='bottomOverLay' onClick={()=>closeAllVis()}/>}

        {dummyHeader?dummyHeader:''}
    </div>;
};
/*
            <div ref={btnBarRef} className='ATQtnSingleInfoCont'>
                <div className='flexRowStartFit' style={{gap:'6px'}}>
                {(PVMode !== 0 && QNo > 0 && showQNo )?<div className='flexRowStart text-nowrap semi-bold'>
                    {exerTitle(t('question')+' '+QNo)}</div>:''}
                    {(showResult && notPolling && showMarked) || ((showResult || isThr) && showAttempt)?qtnStatusBlk('attempted',t):''}
                    {(((showResult || showCorr) && showCorrectTag && teacherResp) || (isThr && showCorrectTag))?
                        qtnStatusBlk(teacherResp.isRight?'correct':'incorrect',t):''}
                </div>
                <div className="ATQtnSingleInfo f16 flex-wrap gap-3">
                    {showTeMarkBox?<CpFIBManualMarkWithText isCorrect={teacherResp?.mCorrect} setCorrect={thrCorrClick} />:''}
                    {(showDrawLayerBtn && hasOverlapDraw && !showCorr)?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.drawingLayer}/>} onClick={showDrawLayer}/>:''}
                    {showHintBtn?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.hints}/>} onClick={showHints} />:''}
                    {showKeyBtn?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.answerKey}/>} onClick={showAnsKeys} />:''}
                    {showCommentBtn?<CpActionButtonComponent variant={"color-dark-preview-primary"} size={SIZE.SM}
                        icon={<CpIco src={IconList.general.feedback}/>} onClick={showComments} />:''}
                    {showVerticalLine?<div style={{backgroundColor:'rgba(178,179,188,1)', width:"1px", height:"34px"}}></div>:''}
                    {showTeacherMark?<CpManualMarkMarking score={teacherResp?.mMark} maxScore={fullQ.score} setScore={setThrMScore} />:''}
                    {showQtnScore?<div className='ATQtnSingleMarks semi-bold cus-btn-sm'>{totalScore + ' '+t(totalScore>1?'small-marks':'small-mark')}</div>:''}
                    {showResultScore?<div className='ATQtnSingleMarks semi-bold cus-btn-sm'>{ gotScore + ' / '+ totalScore}</div>:''}
                </div>
            </div></div> 
*/
export default CpATQtnSingle;
/*
    {showHintBtn?iconButton('','general/hints','#31279d','#c1c0e1',showHints,true,{transform:'scale(1.2)'},{width:'45px',height:'32px',}):''}
    {showKeyBtn?iconButton('','general/answerKey','#31279d','#c1c0e1',showAnsKeys,true,{transform:'scale(1.2)'},{width:'45px',height:'32px',}):''}
    {showCommentBtn?iconButton('','general/feedback','#31279d','#c1c0e1',showComments,true,{transform:'scale(1.2)'},{width:'45px',height:'32px'}):''}

    <div style={{border:'1px solid lightgrey', width:"1px", height:"40px"}}></div>:''}

    {idx>0?iconButton('','general/previous','white','#ec8008',goPrev,true,{transform:'scale(1.5)'},{width:'50px',height:'40px'}):<span/>}
    {isThr?<CpAssignStdFilter {...{shiftMark, SWMs, swmIdx, marked}} />:''}
    {idx<(totalQtn-1)?iconButton('','general/next','white','#ec8008',goNext,true,{transform:'scale(1.5)'},{width:'50px',height:'40px'}):<span/>}
*/
const BottomSlidePage = (props) => {
    //(id, icon, title, desc, jsx, style, clickClose=()=>{}, setBottomMargin) => {
    const {id, icon, title, desc, jsx, style, clickClose=()=>{}, setBottomMargin, vis, gotoQ, idx } = props;
    const _setMargin = () => {
        const ele = document.getElementById(id);
        if (ele) {
            const hh = ele.getBoundingClientRect().height;// + Math.floor(Math.random() * 10);                    
            setBottomMargin && setBottomMargin(hh);
        };
    };
    useEffect(()=>{
        let resize_ob;
        if (vis) {
            const ele = document.getElementById(id);
            if (ele) {            
                resize_ob = new ResizeObserver(()=>{ _setMargin(); });
                resize_ob.observe(ele);
            };
        };
        _setMargin();
        return () => {
            setBottomMargin && setBottomMargin(0);
            resize_ob && resize_ob.disconnect();
        };        
    }, [vis]);
    return <div id={id} className='bottomSlideBar' style={style}>
    <div className='bottomSlideBarHeader'>
        <div className="d-flex justfy-content-start align-items-center gap-2 clickable" onClick={(e)=>{ gotoQ && gotoQ(e,idx); }}>
            <CpIco src={icon} className=""/>
            <IconGroup title={title} />
            <div className="vr"></div>
            <IconGroup title={desc} />
        </div>        
        {iconButton('','general/close','#FFFFFF','transparent',clickClose,true,{transform:"scale(2)"},{padding:0})}
    </div>
    <div className='bottomSlideBarContent'>{jsx}</div>
    </div>;
};

/*
{singleMode?<CpATQtnHeader idx={idx} ddList={ddList} goBack={goBack} gotoQ={_gotoQ} doSubmit={doSubmit} modified={modified} hideHeader={hideHeader} endStr={endStr}
            close={close} preview={preview} PVMode={PVMode} showResult={showResult} getScore={getScore} noSave={noSave} saved={saved} vis={vis} setVis={setVis} answerVis={answerVis}
            titleStr={titleStr} parentTime={doEx?parentTime:0} timeUsed={doEx?timeUsed:0} remainTime={doEx?(preview?-1:remainTime):-1} 
            showScore={showScore} isThr={isThr} debugInfo={debugInfo} isReme={isReme} showCorr={showCorr} />:''}

const bottomSlidePage_org = (id, caption, jsx, style, clickClose=()=>{}) => {
    return <div id={id} className='bottomSlideBar' style={style}>
    <div className='bottomSlideBarHeader'><span>{caption}</span>
        {iconButton('','general/close','#FFFFFF','transparent',clickClose,true,{transform:"scale(2)"},{padding:0})}
    </div>
    <div className='bottomSlideBarContent'>{jsx}</div>
    </div>;
};
*/

const bottomSlidePage2 = (popOverRef, icon, title, desc, content, vis=0, setVis=()=>{}, setBottomMargin, gotoQ, idx) => {
    const head = <div className="d-flex justfy-content-start align-items-center gap-2 clickable " onClick={(e)=>{ gotoQ && gotoQ(e,idx); }}>
            <CpIco src={icon} className=""/>
            <IconGroup title={title} />
            <div className="vr"></div>
            <IconGroup title={desc} />
        </div>    
    return <CpPopoverComponent ref={popOverRef} title={head} content={content} 
        vis={vis} setVis={setVis} fixedPosition={true} scroll={true} backdrop={!NoMaskHints} 
        exClass={"modalZ1700 bottomPop"+idx} setBottomMargin={setBottomMargin} autoFocus={false}
        enforceFocus={false} restoreFocus={false}
    />;
};

//
// base on poc QuestionToolbarContainer.tsx
const getStatusIcon = resultStatus => {
    return ( resultStatus === "correct")? <CpIco src={IconList.status.correct}/>
        :( resultStatus === "incorrect")? <CpIco src={IconList.status.incorrect}/>
        :( ["attempted", "marked"].includes(resultStatus))? <CpIco src={IconList.status.attempted}/>
        :''
};

// base on poc QuestionToolbarContainer.tsx
const qtnStatusBlk = (resultStatus, t) => {
    return <div className={"question-marks"} style={{marginRight:'10px'}}>
        <CpActionButtonComponent size={SIZE.SM} className={"position-relative"} icon={getStatusIcon(resultStatus)}
            variant={`outline-preview-primary pe-none manual-mark-indicator ${resultStatus} semi-bold`}
            title={`${t(`manual-mark.${resultStatus === "attempted" ? "marked" : resultStatus}`)}`} />
    </div>;
};

const TeacherComment = (props) => {
    const {data, setData, readOnly=0} = props;
    const [t] = useUILang();
    return <div className={"flexColCenter"} style={{padding:'5px 0',height:'auto', width:'100%', maxWidth:'100%', backgroundcolor:'grey'}}>
        <Accordion defaultActiveKey={['0']} alwaysOpen className={"inline-comments w100"} style={{maxWidth:'1024px'}}>
            <Accordion.Item eventKey="0">
                <Accordion.Header className={"border-bottom border-at-secondary-dim semi-bold p-0"}>
                    <h5 className={"m-0"}>{t("comments")}</h5>
                </Accordion.Header>
                <Accordion.Body className={"p-0"}>
                    {readOnly?<QEditorReadOnly data={data}/>
                    :<Ckeditor5Base editorType={"inputKey"} needDebounce={true}
                    data={data} setData={setData} enable={true} debug={false} showStatus={false}/>                          
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>         
    </div>
};

const testFabric = kk => <DrawingCanvas id={kk} isTest={1} setCanvas={false} updateResp={()=>{}} mouseUp={()=>{}}/>;
/*
export const useResize = (ele=null, cb=0) => {
    const [data, setData] = useState(null);
    let resize_ob;
    useEffect( ()=>{
        if (ele) {
            resize_ob = new ResizeObserver(()=>{
                if (ele) {
                    const dd = {width: ele.getBoundingClientRect().width, height: ele.getBoundingClientRect().height};
                    setData(dd);
                    cb && cb(dd);
                };
            });
            resize_ob.observe(ele);            
        };

        return () => { resize_ob && resize_ob.disconnect(); };
    },[ele]);
    return data;
};
*/