import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";

//import ActionButtonComponent from "../../../../../schema/components/custom/form/button/ActionButtonComponent";
//import {ExerciseActionKind, ExerciseContext} from "../../../../../../state/ExerciseProvider";
//import {ExerciseContentType, getContentType} from "../../../../../../type/ContentType";
//import {PresentationMode} from "../../../../../../type/PresentationMode";

import ActionButtonComponent from "./CpActionButtonComponent";
import CpIco from "./CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import {ReactSortable, Sortable, Store} from "react-sortablejs";
import {
    _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn,
    _ExCtType_Txt, _ExCtType_Video
} from '../../consts/ATValidateEcnts';
import { PresentationMode } from "./CpExPFConst";
import { toInt, toStr } from "../../libs/libType";

const CustomComponent = forwardRef((props, ref) => {
    return <div ref={ref}
        className={"d-flex gap-2 gap-md-3 flex-wrap justify-content-start justify-content-sm-start"}>{props.children}</div>;
});

//    <ExerciseBubble handleSelectedContentId={handleSelectedContentId} enableActive={presentationMode === PresentationMode.SLIDE_MODE}/> poc get data from context
const CpExerciseBubble = (props) => { // base on poc ExerciseBubble.tsx
    const {handleSelectedContentId, enableActive, mode, editContent, selectedContentId, onDragEnd} = props;

    const {content = [], presentationMode, setContent} = editContent;
    //const [currentSelectedContentId, setCurrentSelectedContentId] = useState(0);
    //const [currentSelectedContentId, setCurrentSelectedContentId] = useState(selectedContentId);
    const [afterInit, setAfterInit] = useState(0);

    useEffect(()=>{ setAfterInit(1); },[]);

    // content = {id:'', type:'', questionNum:0}
    const renderIndicator = (content) => {
        const {type = "", questionNum = 0} = content;
        if(type === _ExCtType_Qtn){ return toStr(toInt(questionNum));
        }else if(type === _ExCtType_Txt){ return <CpIco src={IconList.contentType.rubric}/>
        }else if(type === _ExCtType_CK){ return <CpIco src={IconList.contentType.text}/>
        }else if(type === _ExCtType_Img){ return <CpIco src={IconList.contentType.image}/>
        }else if(type === _ExCtType_Mp3){ return <CpIco src={IconList.contentType.audio}/>
        }else if(type === _ExCtType_Video){ return <CpIco src={IconList.contentType.video}/>
        }else if(type === _ExCtType_Lnk){ return <CpIco src={IconList.contentType.link}/> 
        }
        return "";
    };

    const _setContent = c => { if (setContent) setContent(c); };
    
    useEffect(() => {
        if (selectedContentId >= 0 && afterInit) {
            if (presentationMode !== PresentationMode.SLIDE_MODE) jumpToEctn(selectedContentId);
            //setCurrentSelectedContentId(selectedContentId);
        };
    }, [selectedContentId]);

    const bubbleClick = (id) => {
        if (presentationMode !== PresentationMode.SLIDE_MODE) jumpToEctn(id);
        handleSelectedContentId && handleSelectedContentId(id);
    };

    return <div id="question-selector-container" className={"doing mb-2"}>
        <ReactSortable key={mode} tag={CustomComponent} list={content} setList={_setContent} animation={200} onEnd={onDragEnd}
            dragClass="sortableDrag" easing="ease-out" disabled={mode !== "edit"}
            delay={2} // time in milliseconds to define when the sorting should start
            //delayOnTouchOnly={true} // only delay if user is using touch
            //touchStartThreshold={2} // px, how many pixels the point should move before cancelling a delayed drag event            
            //delayOnTouchStart={true}
            //distance={1}
            >
            {content.map((content, idx) => <span className={"position-relative"} key={idx}>
                <ActionButtonComponent variant={`${"mcq-dim"} ${enableActive ? ((selectedContentId === content.id) ? "current" : "") : ""}`}
                    className={`number-indicator  ${mode === "edit" ? "draggable" : ""}`}
                    title={renderIndicator(content)} onClick={() => bubbleClick(content.id)} truncate={false}/>
                </span>
            )}
        </ReactSortable>
    </div>;
};
export default CpExerciseBubble;

export const jumpToEctn = (id) => {
    // comment for enable jump to question in edit mode too
    //if (mode === "edit") return false;
    let elementTop = 0;
    let navbarHeight = 0;
    let exerciseEditTop = 0;

    const element = document.querySelector(`#content-id-${id}`);
    if (element) {
        elementTop = element.getBoundingClientRect().top;
    };

    let exerciseEditScrollContainer = document.getElementsByClassName(`exercise-edit-scroll-container`);
    if (exerciseEditScrollContainer[0]) {
        exerciseEditTop = exerciseEditScrollContainer[0]?.getBoundingClientRect().top;
    };

    let navbar = document.getElementsByClassName(`navbar`) //(want more spaces, not stick to the top element)
    if (navbar[0]) {
        navbarHeight = navbar[0]?.getBoundingClientRect().height
    };
    if (exerciseEditScrollContainer && element && navbar) {
        exerciseEditScrollContainer = exerciseEditScrollContainer[0];
        navbar = navbar[0];
        const topPos = elementTop - exerciseEditTop - navbarHeight;
        exerciseEditScrollContainer.scrollTo({
            top: topPos, // scroll so that the element is at the top of the view
            behavior: 'smooth' // smooth scroll
        });
    };
};