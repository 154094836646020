import React, {useMemo, useState, useEffect} from "react";
import CpStickyNavTab from "../_components/CpStickyNavTab";
import ScrollContainer from "../_components/CpScrollContainer";

import TabStudentAssignmentReportNextSteps from "../EPReport/TabStudentAssignmentReportNextSteps";
import TabStudentAssignmentReportResult from "../EPReport/TabStudentAssignmentReportResult";
import { BtnPopDev, preJS } from "../../AppExPf/AppUtil";
import { aryLen, toAry, toInt, toObj, toStr } from "../../libs/libType";
import { _ARS_CLOSE_RESUME, _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { exerHasMark } from "./Tags/TagMarkingTag";
import { asmPassMark } from "../EPReports/data/dataUtil";
import PageAsmWork from "../EPAsmWork/PageAsmWork";
import { loadAssignment } from "./PageAssignment";
import TabStudentAssigmentReme from "./TabStudentAssigmentReme";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { stuPassAsm, stuPassValue } from "./CardStudentAssignmentCard";

export const TabIdReport = 'report';
export const TabIdNext = 'next';
export const TabIdReme = 'followup';

const PageAssignmentStudent = (props) => { // base on poc AssignmentReportPage.tsx
    const { dispatch } = props;
    const { asm, Exer, work, mark, act } = props;

    const aPend = (asm?.AState === _AST_PENDING);
    const aPub = (asm?.AState === _AST_PUBLISHED);
    const assignId = toStr(asm?.assignId);
    const wsub = (work?.WState === _WST_SUBMIT);
    
    const myNexts = useMemo(() => filterMyNexts(asm, Exer, work, mark), [asm, Exer, work, mark]);
    const myRemes = useMemo(() => toAry(props.remeAsms), [props.remeAsms]);
    const hasReme = aryLen(myRemes);
    const myFailRemes = toAry(myRemes).filter(r => !stuPassAsm(r, r.mark));
    const cntFailReme = hasReme && aryLen(myFailRemes); 

    const StuRepTBI = useMemo(() => [
        wsub && {id: TabIdReport, title: "report-results", },
        {id: TabIdNext, title: "report-next-steps", badge:{txt: myNexts.length}},
        hasReme && {
            id: TabIdReme, 
            title: "report-follow-up-exercises", 
            badge: {
                txt: toInt(cntFailReme) || undefined, 
                css: myFailRemes? 'bg-tag-info': 'bg-tag-success',
                ico: hasReme && (!cntFailReme) && IconList.general.confirm},
            }
    ].filter(t => t), [wsub, myNexts, myRemes]);

    const [_tab, setTab] = useState(act);
    const tab = useMemo(() => toAry(StuRepTBI).some(i => i.id === _tab)? _tab: StuRepTBI?.[0]?.id, [_tab, StuRepTBI]);

    const [doRAsm, setDoRAsm] = useState(0);
    const loadRemeAsmDo = async rAsmId => {
        const [asm, Exer, qtns, work, mark, ws, ms, ras, answerVis] = await loadAssignment(dispatch, rAsmId, 1);        
        const useCacheQtns = qids => qtns;
        const backToRemeList = () => {setDoRAsm(0); props.reload(); }
        const backToRemeIndexPage = () => { loadRemeAsmDo(rAsmId); }
        const clickBack = () => { console.log('loadRemeAsmDo-clickBack()'); backToRemeList(); } 
        const backToRpt = () => console.log(' loadRemeAsmDo-backToRpt()'); 
        const reload = () => { console.log('loadRemeAsmDo-reload()'); backToRemeList(); }  //EX-1694,
        //const reload = () => { console.log('loadRemeAsmDo-reload()); backToRemeIndexPage(); } // EX-1694, 
        //usePageAssignments() = {...props, isThr, isStt, uRole, asmId, works, marks, remeAsms, report, loading};
        setDoRAsm({ asm, Exer, qtns, work, mark, useCacheQtns, clickBack, backToRpt, reload, answerVis }); 
    }; 

    const cssPageVis = doRAsm? {display:'none'}: {};

    //console.log('PageAssignmentStudent', {act, tab, aip, wsub, cssPageVis});
    if(aPend) return 'Assignment Pending';
    if((!aPub)||(!act)) return <PageAsmWork key={assignId} {...props} />
    return <>
        {doRAsm? <PageAsmWork {...{...doRAsm}} />: '' }
        <ScrollContainer style={cssPageVis} className={`PageAssignmentStudent exercise-edit-scroll-container h-100 d-flex flex-column w-100 overflow-auto scroll-padding`}>
            <div className={"container-fluid mt-2"}><div className={"row"}><div className={"d-flex flex-column gap-3"}>
                <div className={"d-flex justify-content-end gap-2"}><div className={"d-flex flex-wrap justify-content-between w-100"}>
                    <span className={"d-flex gap-2 semi-bold fs-6 align-items-center"} style={{overflow:'visible'}}>
                        <CpStickyNavTab items={StuRepTBI} active={tab} handleActive={setTab} className={"exercise-report-theme"}/>
                        <BtnPopDev txt="asm">{preJS(props.asm, 3)}</BtnPopDev>
                        </span>
                </div></div>
            <div>
    <BtnPopDev txt='work'>{preJS(props.work, 3)}</BtnPopDev>
    <BtnPopDev txt='mark'>{preJS(props.mark, 3)}</BtnPopDev>
    {preJS({wsub})}
            </div>
            </div></div></div>
            {(wsub && (tab===TabIdReport))? <TabStudentAssignmentReportResult {...{...props, doRAsm, loadRemeAsmDo}} /> :
            (tab === TabIdNext)? <TabStudentAssignmentReportNextSteps {...{...props, myNexts}} />:
            (tab === TabIdReme)? <TabStudentAssigmentReme {...{...props, myRemes, myFailRemes, doRAsm, loadRemeAsmDo}} />:
             ''
            }
        </ScrollContainer>
        </>
};
export default PageAssignmentStudent;



//asm , Exer, work, mark
export const myNextPara = (asm, Exer, work, mark) => {
    const APass = asm.APass;
    const wSub = (work.WState === _WST_SUBMIT);
    const myMark = toInt(mark?.MStat?.stScore);

    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);
    const passValue = stuPassValue(asm, mark?.userId);
    const pass = stuPassAsm(asm, mark); //myMark >= pMark; 
    
    const EScore = aScore + mScore;
    const pMark = asmPassMark(asm.passUnit, passValue, EScore); 
    const Stat = toObj(asm.Stat);
    const subCnt = toInt(Stat.submit);
    const sumMark = toInt(Stat.sumMark);
    const avgMark = sumMark / subCnt; 
    const aboveAvg = myMark >= avgMark;
    const belowAvg = myMark <= avgMark;

    return {wSub, APass, pass, belowAvg, aboveAvg, myMark, pMark, avgMark, sumMark, EScore, subCnt};
}

const filterMyNexts =  (asm, Exer, work, mark) => { 
    const {wSub, APass, pass, belowAvg, aboveAvg} = myNextPara(asm, Exer, work, mark);

    return toAry(Exer.ENexts).filter(n => {
        if(n.att === 'n'){
            if(wSub) return false; // check no att
        }else if(n.att === 'y'){
            if(!wSub) return false; // check attempt
            if(APass && n.passt){
                if(n.pass === 'a' && !pass) return false; //check above pass
                if(n.pass === 'b' && pass) return false; //check below pass;
            }
            if (!APass && n.passt) return false;
            if(n.avgt){
                if(n.avg === 'b' && !belowAvg) return false; // check below avg
                if(n.avg === 'a' && !aboveAvg) return false; // check above avg
            }
        } 
        return true; //check nothing
    });
};

