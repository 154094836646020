import React, { useEffect, useState, useRef } from 'react';

import * as UI from '../../libs/libUI';

import { aTErrDiv1, aTErrTxt1, ATUICheckBox0, aTUIMust, BtnPopDev, clickUrl, PopA, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import CpATSubjectTickList from '../components/CpATSubjectTickList';
import { ATUIText, ATUILabel } from '../AppUtil';
import { toAry, toObj, toStr } from '../../libs/libType';
import { expf2 } from '../../consts/ATSysQType';

const TabQSetGeneral = (props => {
    const {fields, setField, setTick, setTickSj, lock} = props;
    const opts = toObj(props.opts);
    const ATSubjects = toAry(opts.ATSubjects);
    const mySjIds = toAry(opts.mySjIds);
    const fieldErrs = toObj(props.fieldErrs);

    const qs = toObj(props.qset); 
    const reme = qs.QSReme? 1: 0;
    const setReme = setField('QSReme');

    return <>
    <div>
        <div className="w50 f14">
            <div className='flexRowStart'>{ATUILabel('Question Set ID','qsi',true,120)}
            {ATUIText(qs.QSetId, setField('QSetId'), 'tqs', lock||(!(fields.isNew)),'Please enter Set ID here','fxGrow',{})} 
            <div style={{width:'40px'}}></div>
            </div>
            {aTErrDiv1(fieldErrs.QSetId)}
        </div>
        <div className="w50 f14">
            <div className='flexRowStart'>{ATUILabel('ESAS Series Codes','esc',true,140)}
            {ATUIText(qs.ESASStr, setField('ESASStr'), 'tes', lock,'Please enter ESAS Series Code here (use "," as separator)','fxGrow',{})} 
            <div style={{width:'40px'}}></div>
            </div>
            {aTErrDiv1(fieldErrs.ESASStr)}
        </div>
    </div>
    <hr className='hrat'/>
    <div className="atsecthaed">Support Languages {aTUIMust}
        <label className="w m4">{ATUICheckBox0(qs.QSLangEn, setTick('QSLangEn'), 'chkE', lock)}En</label>
        <label className="w m4">{ATUICheckBox0(qs.QSLangCt, setTick('QSLangCt'), 'chkC', lock)}繁中</label>
        {aTErrTxt1(fieldErrs.QSLang)}
    </div>
    <hr className='hrat'/>
    <div className='flexRowStart f14'>{ATUILabel('Set Name (En)','sne',true,120)}{ATUIText(qs.QSNameEn, setField('QSNameEn'), 'tne', lock, 'Please enter Question Set Name here')} </div>
    {aTErrDiv1(fieldErrs.QSNameEn)}
    <div className='flexRowStart f14'>{ATUILabel('Set Name (繁中)','snc',true,120)}{ATUIText(qs.QSNameCt, setField('QSNameCt'), 'tnc', lock, 'Please enter Question Set Name here')} </div>
    {aTErrDiv1(fieldErrs.QSNameCt)}
    <hr className='hrat'/>
    <div className='f14'>{ATUILabel('Enable for Subject(s) in AT','efsa',true,200)}</div>
    <div className='subjectItemContainer f14'>
        {CpATSubjectTickList( ATSubjects, mySjIds, toAry(qs.QSSubjIds), setTickSj, lock) }
        {aTErrTxt1(fieldErrs.QSSubjIds)}
    </div>
    {expf2?<>
        <div className='f14'>{ATUILabel('Enable for Follow-up Exercise', 'efrm', true, 200)}</div>
        <div className='subjectItemContainer f14'>
            <div className='subjectItemContainer'>
                {atRadioItem(0, v => { setReme(0); }, reme, 'For question searching', lock)}
                {atRadioItem(1, v => { setReme(1); }, reme, 'For follow-up exercises', lock)}
                {aTErrTxt1(fieldErrs.QSReme)}
            </div>
        </div>
    </>:''}
    <hr className='hrat'/>
    <div className='flexRowStart f14'>{ATUILabel('Keyword(s) (En)','kse',false,120)}{ATUIText(qs.QSKwEn, setField('QSKwEn'), 'tke', lock, 'use "," as separator')}</div>
    <div className='flexRowStart f14'>{ATUILabel('Keyword(s) (繁中)','ksc',false,120)}{ATUIText(qs.QSKwCt, setField('QSKwCt'), 'tke', lock, 'use "," as separator')}</div>
    </>;
});
export default TabQSetGeneral; 

export const ATRadioItem = ({val, setter, sel, txt, lock}) => {
    const on = (val === sel);
    const cls = (lock? '': 'clickable ')+'subjectItem'+(on? 'Sel': '');
    const click = ((!lock) && setter)? (e => { UI.stopEvent(e); setter(val); }) : undefined;
    return <div className={cls} onClick={click} ><label>{UI.radio0(val, click, sel, 'chk'+txt, lock)} {txt} </label></div>;
}; 
export const atRadioItem = (val, setter, sel, txt, lock) => <ATRadioItem {...{val, setter, sel, txt, lock}} />;

