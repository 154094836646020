export const IconList = {
    menu: {
        home: 'menu/home.svg',
        library: 'menu/library.svg',
        myExercises: 'menu/myExercises.svg',
        assignment: 'menu/assignment.svg',
        report: 'menu/report.svg',
        metadata: 'menu/metadata.svg',
        question: 'menu/question.svg',
        admin: 'menu/admin.svg',
        logout: 'menu/logout.svg',
        help: 'menu/help.svg',
        cookie: 'menu/cookie.svg',
    },
    general: {
        scrollLeft: 'general/scrollLeft.svg',
        scrollRight: 'general/scrollRight.svg',
        collapse: 'general/collapse.svg',
        expand: 'general/expand.svg',
        dropdown: 'general/dropdown.svg',
        previous: 'general/previous.svg',
        next: 'general/next.svg',
        edit: 'general/edit.svg',
        delete: 'general/delete.svg',
        trash: 'general/trash.svg',
        remove: 'general/remove.svg',
        copy: 'general/copy.svg',
        moreHorizontal: 'general/moreHorizontal.svg',
        moreVertical: 'general/moreVertical.svg',
        save: 'general/save.svg',
        cancel: 'general/cancel.svg',
        close: 'general/close.svg',
        back: 'general/back.svg',
        exit: 'general/exit.svg',
        exitdoor: 'general/exitdoor.svg',
        preview: 'general/preview.svg',
        previewFill: 'general/preview-fill.svg',
        zoomIn: 'general/zoomIn.svg',
        zoomOut: 'general/zoomOut.svg',
        calendar: 'general/calendar.svg',
        publish: 'general/publish.svg',
        unpublish: 'general/unpublish.svg',
        submit: 'general/submit.svg',
        upload: 'general/upload.svg',
        request: 'general/request.svg',
        filter: 'general/filter.svg',
        reload: 'general/reload.svg',
        notification: 'general/notification.svg',
        open: 'general/open.svg',
        assign: 'general/assign.svg',
        reorderVertical: 'general/reorderVertical.svg',
        bookmark: 'general/bookmark.svg',
        favourite: 'general/favourite.svg',
        hints: 'general/hints.svg',
        answerKey: 'general/answerKey.svg',
        feedback: 'general/feedback.svg',
        add: 'general/add.svg',
        minus: 'general/minus.svg',
        alignmentLeft: 'general/alignmentLeft.svg',
        alignmentRight: 'general/alignmentRight.svg',
        alignmentCenter: 'general/alignmentCenter.svg',
        redo: 'general/redo.svg',
        dragVertical: 'general/dragVertical.svg',
        dragHorizontal: 'general/dragHorizontal.svg',
        dragCusVertical: 'general/dragCusVertical.svg',
        dragCusHorizontal: 'general/dragCusHorizontal.svg',
        confirm: 'general/confirm.svg',
        discard: 'general/discard.svg',
        correct: 'general/correct.svg',
        incorrect: 'general/incorrect.svg',
        setting: 'general/setting.svg',
        fullscreen: 'general/fullscreen.svg',
        fullscreenExit: 'general/fullscreenExit.svg',
        select: 'general/select.svg',
        download: 'general/download.svg',
        arrowRight: 'general/arrowRight.svg',
        alert: 'general/exclamation-triangle.svg',
        menu: 'general/menu.svg',
        avatar: 'general/person-circle.svg',
        collection: 'general/collection.svg',
        previousPage: 'general/caret-left-fill.svg',
        nextPage: 'general/caret-right-fill.svg',
        filterAdvance: 'general/sliders.svg',
        like: 'general/like.svg',
        likeFill: 'general/like-fill.svg',
        openNode: 'general/open-node.svg',
        closeNode: 'general/close-node.svg',
        previewOff: 'general/preview-off.svg',
        search: 'general/search.svg',
        scrollTop: 'general/scrollTop.svg',
        scrollBottom: 'general/scrollBottom.svg',
        cancelFull: 'general/cancel-full.svg',
        confirmFull: 'general/confirm-full.svg',
        time: 'general/time.svg',
        previousCalendar: 'general/previousCalendar.svg',
        nextCalendar: 'general/nextCalendar.svg',
        arrowUp: 'general/arrowUp.svg',
        arrowDown: 'general/arrowDown.svg',
        loading: 'general/loading.svg',
        copyText: 'general/copyText.svg',
        nextStepRight: 'general/next-step-right.svg',
        nextStepBottom: 'general/next-step-bottom.svg',
        circleCheck: 'general/circle-check.svg',
        orderUp: 'general/order-up.svg',
        orderDown: 'general/order-down.svg',
        asc: 'general/asc.svg',
        desc: 'general/desc.svg',
        tips: 'general/tips.svg',
        saved: 'general/saved.svg',
        viewExercise: 'general/viewExercise.svg',
        drawingLayer: 'general/drawingLayer.svg',
        markingNote: 'general/markingNote.svg',
        quit: 'general/quit.svg',
        addExercise: 'general/addExercise.svg',
        addRule: 'general/addRule.svg'
    },
    result: {
        bgP: 'result/bg-passed.svg',
        bgF: 'result/bg-failed.svg',
        bgA: 'result/bg-attempted.svg',
        bgN: 'result/bg-not-published.svg',
        en: {
            handP: 'result/hand-passed-en.svg',
            handF: 'result/hand-failed-en.svg',
            handA: 'result/hand-attempted-en.svg',
            handN: 'result/hand-not-published.svg',
        },
        zh: {
            handP: 'result/hand-passed-zh.svg',
            handF: 'result/hand-failed-zh.svg',
            handA: 'result/hand-attempted-zh.svg',
            handN: 'result/hand-not-published.svg',
        }
    },
    tagging: {
        new: 'tagging/new.svg',
        tag: 'tagging/tag.svg',
        newTag: 'tagging/new-tag.svg',
    },
    language: {
        en: 'language/en.svg',
        can: 'language/can.svg',
        pth: 'language/pth.svg',
        tc: 'language/tc.svg',
        chi: 'language/chi.svg',
        zh: 'language/chi.svg'
    },
    draw: {
        pen: 'draw/pen.svg',
        rectangle: 'draw/rectangle.svg',
        eraser: 'draw/eraser.svg',
        text: 'draw/text.svg',
        pencil: 'draw/pencil.svg',
        image: 'draw/image.svg',
        fullscreen: 'draw/fullscreen.svg',
        fullscreenExit: 'draw/fullscreenExit.svg',
        select: 'draw/select.svg',
        trash: 'draw/trash.svg',
        tiny: 'draw/tiny.svg',
        small: 'draw/small.svg',
        medium: 'draw/medium.svg',
        big: 'draw/big.svg',
        huge: 'draw/huge.svg',
        undo: 'draw/undo.svg',
        redo: 'draw/redo.svg',
        download: 'draw/download.svg',
        arrow: 'draw/arrow.svg',
        dashLine: 'draw/dashLine.svg',
        straightLine: 'draw/straightLine.svg',
        highlight: 'draw/highlight.svg',
        circle: 'draw/circle.svg',
        polygon: 'draw/polygon.svg',
        quit: 'draw/quit.svg',
        question: 'draw/question.svg',
        group:{
            pencilDesktop: 'draw/GroupButton/pencilDesktop.svg',
            highlightDesktop: 'draw/GroupButton/highlightDesktop.svg',
            dashLineDesktop: 'draw/GroupButton/dashLineDesktop.svg',
            straightLineDesktop: 'draw/GroupButton/straightLineDesktop.svg',
            arrowDesktop: 'draw/GroupButton/arrowDesktop.svg',
            circleDesktop: 'draw/GroupButton/circleDesktop.svg',
            polygonDesktop: 'draw/GroupButton/polygonDesktop.svg',
            imageDesktop: 'draw/GroupButton/imageDesktop.svg',
            pencilMobile: 'draw/GroupButton/pencilMobile.svg',
            highlightMobile: 'draw/GroupButton/highlightMobile.svg',
            straightLineMobile: 'draw/GroupButton/straightLineMobile.svg',
            dashLineMobile: 'draw/GroupButton/dashLineMobile.svg',
            arrowMobile: 'draw/GroupButton/arrowMobile.svg',
            circleMobile: 'draw/GroupButton/circleMobile.svg',
            polygonMobile: 'draw/GroupButton/polygonMobile.svg',
            imageMobile: 'draw/GroupButton/imageMobile.svg',
        }
    },
    canvasDrawing: {
        image: 'canvasDrawing/image.svg',
        fitWidth: 'canvasDrawing/fit-width.svg',
        fitHeight: 'canvasDrawing/fit-height.svg',
        link: 'canvasDrawing/link.svg',
        trash: 'canvasDrawing/trash.svg',
        actualSize: 'canvasDrawing/actual-size.svg'
    },
    labelDiagram: {
        image: 'labelDiagram/image.svg',
        add: 'labelDiagram/add.svg',
        zoomIn: 'labelDiagram/zoomIn.svg',
        zoomOut: 'labelDiagram/zoomOut.svg',
        fitWidth: 'labelDiagram/fit-width.svg',
        fitHeight: 'labelDiagram/fit-height.svg',
        actualSize: 'labelDiagram/actual-size.svg'
    },
    presentation: {
        assessmentMode: 'presentation/assessmentMode.svg',
        practiceMode: 'presentation/practiceMode.svg',
        gridMode: 'presentation/gridMode.svg',
        listMode: 'presentation/listMode.svg',
        labelMode: 'presentation/label.svg',
        tableMode: 'presentation/tableMode.svg',
        timelineMode: 'presentation/timelineMode.svg',
        priorityMode: 'presentation/priorityMode.svg',
        restrictPlayerMode: 'presentation/restrictPlayerMode.svg',
        fullControlPlayerMode: 'presentation/fullControlPlayerMode.svg',
    },
    background: {
        uploadImage: 'background/uploadImage.svg',
        takeImage: 'background/takeImage.svg',
        uploadFile: 'background/uploadFile.svg'
    },
    inputType: {
        generalEditor: 'inputType/generalEditor.svg',
        equationEditor: 'inputType/equationEditor.svg',
        imageEditor: 'inputType/imageEditor.svg',
        plainTextEditor: 'inputType/plainTextEditor.svg',
        numberEditor: 'inputType/numberEditor.svg',
        maxEditor: 'inputType/maxEditor.svg',
        uploadFileEditor: 'inputType/uploadFileEditor.svg',
        uploadVideoEditor: 'inputType/uploadVideoEditor.svg',
        drawingEditor: 'inputType/drawingEditor.svg',
        hyperlinkEditor: 'inputType/hyperlinkEditor.svg'
    },
    contentType: {
        image: 'contentType/image.svg',
        box: 'contentType/box.svg',
        text: 'contentType/text.svg',
        audio: 'contentType/audio.svg',
        link: 'contentType/link.svg',
        video: 'contentType/video.svg',
        rubric: 'contentType/rubric.svg',
        passage: 'contentType/passage.svg',
        draw: 'contentType/draw.svg',
        file: 'contentType/file.svg'
    },
    questionType: {
        multipleChoice: 'questionType/multipleChoice.svg',
        labelTheDiagram: 'questionType/labelTheDiagram.svg',
        polling: 'questionType/polling.svg',
        fillInTheBlanks: 'questionType/fillInTheBlanks.svg',
        openEnded: 'questionType/openEnded.svg',
        thumbnail: {
            en: {
                mcqSingleAnswer: 'questionType/thumbnail/en/mcqSingleAnswer.svg',
                mcqMultipleAnswer: 'questionType/thumbnail/en/mcqMultipleAnswer.svg',
                matchTableSingleAnswer: 'questionType/thumbnail/en/matchTableSingleAnswer.svg',
                matchTableMultipleAnswer: 'questionType/thumbnail/en/matchTableMultipleAnswer.svg',
                matchTableSingleAnswer2: 'questionType/thumbnail/en/matchTableSingleAnswer2.svg',
                matchTableMultipleAnswer2: 'questionType/thumbnail/en/matchTableMultipleAnswer2.svg',
                fibAutoMark: 'questionType/thumbnail/en/fibAutoMark.svg',
                fibManualMark: 'questionType/thumbnail/en/fibManualMark.svg',
                labTheDiagramTextField: 'questionType/thumbnail/en/labTheDiagramTextField.svg',
                labTheDiagramDropDown: 'questionType/thumbnail/en/labTheDiagramDropDown.svg',
                openEndedGeneral: 'questionType/thumbnail/en/openEndedGeneral.svg',
                openEndedShortAnswer: 'questionType/thumbnail/en/openEndedShortAnswer.svg',
                openEndedEssay: 'questionType/thumbnail/en/openEndedEssay.svg',
                openEndedCanvasDrawing: 'questionType/thumbnail/en/openEndedCanvasDrawing.svg',
                pollingSingle: 'questionType/thumbnail/en/pollingSingle.svg',
                pollingMultiple: 'questionType/thumbnail/en/pollingMultiple.svg'
            },
            zh: {
                mcqSingleAnswer: 'questionType/thumbnail/zh/mcqSingleAnswer.svg',
                mcqMultipleAnswer: 'questionType/thumbnail/zh/mcqMultipleAnswer.svg',
                matchTableSingleAnswer: 'questionType/thumbnail/zh/matchTableSingleAnswer.svg',
                matchTableMultipleAnswer: 'questionType/thumbnail/zh/matchTableMultipleAnswer.svg',
                matchTableSingleAnswer2: 'questionType/thumbnail/zh/matchTableSingleAnswer2.svg',
                matchTableMultipleAnswer2: 'questionType/thumbnail/zh/matchTableMultipleAnswer2.svg',
                fibAutoMark: 'questionType/thumbnail/zh/fibAutoMark.svg',
                fibManualMark: 'questionType/thumbnail/zh/fibManualMark.svg',
                labTheDiagramTextField: 'questionType/thumbnail/zh/labTheDiagramTextField.svg',
                labTheDiagramDropDown: 'questionType/thumbnail/zh/labTheDiagramDropDown.svg',
                openEndedGeneral: 'questionType/thumbnail/zh/openEndedGeneral.svg',
                openEndedShortAnswer: 'questionType/thumbnail/zh/openEndedShortAnswer.svg',
                openEndedEssay: 'questionType/thumbnail/zh/openEndedEssay.svg',
                openEndedCanvasDrawing: 'questionType/thumbnail/zh/openEndedCanvasDrawing.svg',
                pollingSingle: 'questionType/thumbnail/zh/pollingSingle.svg',
                pollingMultiple: 'questionType/thumbnail/zh/pollingMultiple.svg'
            }
        }
    },
    status: {
        correct: 'status/correct.svg',
        incorrect: 'status/incorrect.svg',
        attempted: 'status/attempted.svg',
        finish: 'status/finish.svg',
        cancel: 'status/cancel.svg'
    },
    file: {
        extension: 'file/extension.svg',
        link: 'file/link.svg',
        excel: 'file/excel.svg'
    },
    player: {
        start: 'player/start.svg',
        pause: 'player/pause.svg',
        stop: 'player/stop.svg',
        volume: 'player/volume.svg',
        volumemute: 'player/volume-mute.svg',
        // speed: 'player/speed.svg',
        arrowup: 'player/arrowup.svg',
        arrowdown: 'player/arrowdown.svg',
    },
    sourceType: {
        upload: 'sourceType/upload.svg',
        youtube: 'sourceType/youtube.svg',
        brightcove: 'sourceType/brightcove.svg',
        externalLink: 'sourceType/link.svg'
    },
    brand: {
        oupc: 'brand/oupc.svg',
        oupTab: 'brand/oup-tab.svg'
    },
    assignment: {
        preview: 'assignment/preview.svg',
        reload: 'assignment/reload.svg',
        changeDeadline: 'assignment/changeDeadline.svg',
        changeSettings: 'assignment/changeSettings.svg',
        delete: 'assignment/delete.svg',
        details: 'assignment/details.svg',
        endAssignment: 'assignment/endAssignment.svg',
        publishResults: 'assignment/publishResults.svg',
        reports: 'assignment/reports.svg',
        resume: 'assignment/resume.svg',
        retry: 'assignment/retry.svg',
        share: 'assignment/share.svg',
        start: 'assignment/start.svg',
        open: 'assignment/open.svg',
        view: 'assignment/view.svg',
        followUpExercise: 'assignment/followUpExercise.svg'
    }
}