import React, {useMemo} from "react";
import {
    DONUT_ATTEMPTED_COLOR,
    DONUT_AVG_LINE_COLOR,
    DONUT_BODY_COLOR,
    DONUT_CORRECT_COLOR,
    DONUT_DEFAULT_SEGMENT_COLOR,
    DONUT_INCORRECT_COLOR,
    DONUT_NO_ATTEMTED_COLOR,
    DONUT_TEXT_COLOR
} from "../EPReport/ChartTypeConst";

import ChartRadialSlider from "./ChartRadialSlider";
import ChartRadial from "./ChartRadial";
import ChartDonut from "../EPReport/ChartDonut";
import { useUILang } from "../../AppExPf/utils/useUILang";

import {groupData} from "../../poc/helper/groupByNumberArray";
import { aryLen, toAry, toFix1 } from "../../libs/libType";

const ChartSubjectOverview = (props) => { // base on poc SubjectOverviewChart
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();

    const chartColor = status => 
        status === 'p'? DONUT_CORRECT_COLOR:
        status === 'f'? DONUT_INCORRECT_COLOR:
        status === 'a'? DONUT_ATTEMPTED_COLOR:
        /*status === 'n'?*/ DONUT_NO_ATTEMTED_COLOR;
    const chartTitle = status => 
        status === 'p'? t("report-status-passed"):
        status === 'f'? t("report-status-failed"):
        status === 'a'? t("report-status-completed"):
        /*status === 'n'?*/ t("report-status-expired");
    const chartName = status =>
        status === 'p'? "correct":
        status === 'f'? "incorrect":
        status === 'a'? "attempted":
        /*status === 'n'?*/ "not-attempted";

    const {teacher, student} = props;
    const {overallScore: teacherOverallScore, data} = teacher || {};
    const {attempted, overallScore: studentOverallScore, averageScore} = student || {};

    const groupedData = useMemo(() => groupData(toAry(data)), [data]);

    const attemptedValue = useMemo(() => toAry(attempted).reduce((accumulator, currentValue) => {
        const {record, status = "n"} = currentValue
        return accumulator + (["p", "f", "a"].includes(status) ? (record?.value || 0) : 0);
    }, 0), [attempted]);

    const attemptedTotal = useMemo(() => toAry(attempted).reduce((accumulator, currentValue) => {
        const {record, status = "n"} = currentValue;
        return accumulator + (record?.value || 0);
    }, 0), [attempted]);

    const attemptedData = useMemo(() => {
        const ret = {
            title: {
                color: DONUT_BODY_COLOR,
                value: <tspan textAnchor="middle">
                    <tspan fontSize={10}>{attemptedValue}</tspan>
                    <tspan fontSize={6} fill={DONUT_TEXT_COLOR}>/{attemptedTotal}</tspan>
                </tspan>
            },
            subTitle: {
                color: DONUT_TEXT_COLOR,
                //value: <tspan x="50%" y={"24.5"} textAnchor="middle">{t("report-attempted")}</tspan>
                value: <tspan x="50%" y={"24.5"} textAnchor="middle">{t("report-result-published")}</tspan>
            },
            segments: toAry(attempted).map(attempt => {
                return { 
                    color: chartColor(attempt.status), 
                    value: attempt.record.percentage || 0 
            }; })
        };
        return ret;
    }, [attempted, isUIEn]);

    const average = averageScore?.percentage || 0;
    const overall = studentOverallScore?.percentage || 0;
//console.log('average', average);
    const classAverageScoreData = useMemo(() => {
        const avgp1 = toFix1(average);
        const ret = {
            line: [{
                color: DONUT_AVG_LINE_COLOR,
                value: avgp1,
                title: `${t("report-class-avg-score")}(${avgp1}%)`,
                type: "avg"
            }],
            title: {
                color: DONUT_DEFAULT_SEGMENT_COLOR,
                value: <>{overall}%</>
            },
            subTitle: {
                color: DONUT_TEXT_COLOR,
                value: <tspan x="50%" y={"24.5"} textAnchor="middle">{t("report-overall-score-percentage")}</tspan>
            },
            segments: [{
                color: DONUT_DEFAULT_SEGMENT_COLOR,
                value: overall,
            }]
        };
//console.log('new average', average);
        return ret;
    }, [average, overall, isUIEn]);


    if (!student) { 
//console.log('watch out for Dount!!!', {groupedData}, groupedData[0]);
        return <>{/*<div className={"radial-chart-container d-flex align-items-center max-size-chart-500 max-width m-auto"}>*/}
        <div className={"ChartSubjectOverview_classsOrGrade radial-chart-container d-flex align-items-center max-size-chart-400 max-width m-auto"}>
            <ChartRadialSlider dataLength={aryLen(groupedData)}>
                {groupedData.map((gData, idx) => <section key={idx}><ChartRadial
                    label={t("report-overall-average-score-percentage")} overallScore={teacherOverallScore}
                    data={gData}/>
                </section>)}
                {aryLen(groupedData)? '': <section><ChartRadial label={t("report-overall-average-score-percentage")}/></section>}
            </ChartRadialSlider>
        </div></>
    };

    return <div className={"ChartSubjectOverview_student subject-overview-container"}>
        <div className={"col d-flex flex-column justify-content-center align-items-center gap-3"}>
            <div className={"d-flex px-3"}><ChartDonut id='ClsAsCnt' {...attemptedData}/></div>
            <div className="d-flex subject-overview-status flex-column flex-xl-row gap-3 flex-wrap justify-content-center align-items-start semi-bold fs-6">
                {attempted.map((attempt, idx) => {
                    return <span className={"sample-line"} key={idx}>
                        <span className={`sample-${chartName(attempt.status)}-cube`}></span>
                        {chartTitle(attempt.status)} ({attempt?.record?.value})
                    </span>
                })}
            </div>
        </div>
        <div className={"col d-flex flex-column justify-content-center align-items-center gap-3"}>
            <div className={"d-flex px-3"}><ChartDonut id='clsAsmSvgP' {...classAverageScoreData}/></div>
            <div className="d-flex subject-overview-status flex-column gap-3 flex-wrap justify-content-center align-items-start semi-bold fs-6">
                <span className={"sample-line"}>
                    <span className={`sample-avg-line`}></span>
                    <span className={"text-wrap"}>
                        <span>{t("report-class-avg-score")}</span> <span>({average}%)</span>
                    </span>
                </span>
            </div>
        </div>
    </div>
};

export default ChartSubjectOverview;