import React, { useEffect, useMemo, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import QEditorReadOnly from './CpQEditorReadOnly';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import { Bold, Italic, Strikethrough, Underline, Subscript, Superscript } from '@ckeditor/ckeditor5-basic-styles';
import { Alignment } from '@ckeditor/ckeditor5-alignment';

import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import List from '@ckeditor/ckeditor5-list/src/list';
import { FontColor, FontSize, FontFamily } from '@ckeditor/ckeditor5-font';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';

import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';
import MathType from '@wiris/mathtype-ckeditor5/src/plugin';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import { ImageInsert, ImageResizeButtons, ImageResizeEditing, ImageResizeHandles } from '@ckeditor/ckeditor5-image';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';

import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import Link from '@ckeditor/ckeditor5-link/src/link.js';

import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';
import { TableCaption, TableCellProperties, TableProperties } from '@ckeditor/ckeditor5-table';

import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';

import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';

import RestrictedEditingMode from '@ckeditor/ckeditor5-restricted-editing/src/restrictededitingmode';

import { COLOR_PALETTE } from './includes/color_palette';
import { PreventDragImage } from './includes/custom_paste_plugin';
import { PreventTyping } from './includes/custom_typing_plugin';
import cpCkAnswer from './CpCkAnswer';
import CpCkInsertImage from './CpCkInsertImage';
import CpCkInsertMedia from './CpCkInsertMedia';
import { customMediaConfig } from './includes/custom_media_config';
import { useUILang } from '../../utils/useUILang';
import mathConfig from './includes/mathConfig';
import { getMathTagStrOnly } from '../../../libs/libAnsCompare';
import { autoId } from '../../AppUtil';
import { ReduxBind, debugMode } from '../../../saga/ReduxState';
import { toObj, isAry } from '../../../libs/libType';
import { ckDebounce } from '../../utils/useAutoSave';
import { hasFontFamily } from '../../../consts/ATSysQType';

import zhTrans from 'ckeditor5/translations/zh.js';
import enTrans from 'ckeditor5/translations/en.js';

import 'ckeditor5/ckeditor5.css';
import '../../../css/ckeditor.css';

const tableConfig = {
    table: {
        contentToolbar: [
            'toggleTableCaption', '|', 'tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'
        ],
        tableProperties: {
            borderColors: COLOR_PALETTE,
            backgroundColors: COLOR_PALETTE
        },
        tableCellProperties: {
            borderColors: COLOR_PALETTE,
            backgroundColors: COLOR_PALETTE
        }
    },
};

const imageConfig = {
    image: {
        resizeUnit: '%',
        resizeOptions: [
            {  name: 'resizeImage:original', value: null, icon: 'original'  },
            {  name: 'resizeImage:50',  value: '25', icon: 'small' },
            {  name: 'resizeImage:50',  value: '50', icon: 'medium'},
            {  name: 'resizeImage:75',  value: '75', icon: 'large' },
            {  name: 'resizeImage:100', value: '100', icon: 'x-large' }
        ],
        toolbar: [ 'toggleImageCaption', 'imageTextAlternative', '|',
            'imageStyle:inline', '|', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
            'resizeImage', '|', 'linkImage'
        ],
    },
};

const generalHtmlSupportConfig = {
    htmlSupport: { 
        allow: [ {
            name: 'video',
            styles: true,
            classes: true,
            attributes: {
                style: true,
                'oupid': true,
                'data-id': true,
                width: true,
                controls: true
            }
        }, {
            name: 'audio',
            styles: true,
            classes: true,
            attributes: {
                style: true,
                'oupid': true,
                'data-id': true,
                width: true,
                controls: true
            }
        },
            {
                name: 'iframe',
                styles: true,
                classes: true,
                attributes: {
                    style: true,
                    'oupid': true,
                    'data-id': true,
                    src: true,
                    width: true,
                    height: true
                }
            } ],
        disallow: [ /* HTML features to disallow */ ]
    },
};

const mediaEmbedConfig = {
    mediaEmbed:{
        previewsInData: true,
        providers: customMediaConfig,
        extraProviders: [
            {
                name: 'Brightcove',
                // The following RegExp matches https://www.example.com/media/{media id},
                // (either with "http(s)://" and "www" or without), so the valid URLs are:
                //
                // * https://www.example.com/media/{media id},
                // * http://www.example.com/media/{media id},
                // * www.example.com/media/{media id},
                // * example.com/media/{media id}

                url: /^players.brightcove.net\/(\w+)\/(\w+)_default\/index\.html\?videoId=(\w+)/,

                // The rendering function of the provider.
                // Used to represent the media when editing the content (i.e. in the view)
                // and also in the data output of the editor if semantic data output is disabled.
                html: match => {
                    const isThemed = match[ 2 ] !== 'default';

                    return `<div class="ratio-container">
                        <div class="ratio-frame ${ isThemed ? 'themed' : '' }"><iframe src="https://players.brightcove.net/${ match[ 1 ] }/${ match[ 2 ] }_default/index.html?videoId=${ match[ 3 ] }&playsinline" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div></div>`;
                }
            },           
        ],
        toolbar: [ 'comment' ]
    }
};

const equationConfig = {
    restrictedEditing: {
        allowedCommands: [ 'MathType' ],//, 'ChemType' ],
        allowedAttributes: [ 'MathType'],//, 'ChemType' ]
    },
    //licenseKey: process.env.LICENSEKEY,
};

const mySetting = {
    default: {
        plugins: [Essentials, Paragraph, Heading, List, Bold, Italic, Indent, IndentBlock,
            Table, TableToolbar,TableProperties, TableCellProperties, TableColumnResize,
            MediaEmbed, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage,
            ImageInsert, Base64UploadAdapter, SimpleUploadAdapter,
            FontColor, FontBackgroundColor, WordCount],
        toolbar: ['heading', 'bold', 'italic', 'fontColor', 'numberedList', 'bulletedList', 'outdent', 'indent', 'insertTable',
                '|', 'linkImage', 'mediaEmbed', 'insertImage', 'imageStyle:block','imageStyle:inline','imageStyle:side', 'toggleImageCaption',
        ],
    },
 
    // editor A
    question: {
        plugins: [ Essentials,
            Paragraph, PasteFromOffice, RemoveFormat, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            FontSize, FontColor, FontFamily, Link,
            Table, TableToolbar, TableCaption, TableProperties, TableCellProperties,
            Image, ImageCaption, ImageStyle, ImageToolbar, ImageInsert, ImageResizeEditing, ImageResizeButtons, ImageResizeHandles, LinkImage, MediaEmbed,
            List, Alignment, WordCount, CpCkInsertImage, MathType, GeneralHtmlSupport, CpCkInsertMedia
        ],
        toolbar: {
            items:[
            'undo', 'redo', '|', 'fontFamily', 'removeFormat', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
            '|', 'fontSize', 'fontColor', '|', 'link', 'insertTable',
            '|', 'CpCkInsertMedia', 'CpCkInsertImage','mediaEmbed', '|', 'bulletedList', 'numberedList',
            '|', 'alignment', '|', 'MathType', //'ChemType'
            ],
            shouldNotGroupWhenFull: true,
        }, 
    },
    // editor A
    questionFIB: {
        plugins:  [ Essentials,
            Paragraph, PasteFromOffice, RemoveFormat, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            FontSize, FontColor, FontFamily, Link, Table, TableToolbar, TableCaption, TableProperties, TableCellProperties,
            Image, ImageCaption, ImageStyle, ImageToolbar, ImageInsert, ImageResizeEditing, ImageResizeButtons, ImageResizeHandles, LinkImage, MediaEmbed,
            List, Alignment, WordCount, MathType, cpCkAnswer, CpCkInsertImage, FontBackgroundColor, GeneralHtmlSupport, CpCkInsertMedia
        ],
        toolbar: {
            items:[
            'undo', 'redo', '|','fontFamily', 'removeFormat',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
            '|', 'fontSize', 'fontColor', '|', 'link', 'insertTable',
            '|', 'CpCkInsertMedia', 'CpCkInsertImage', 'mediaEmbed',
            '|', 'bulletedList', 'numberedList',
            '|', 'alignment',
            '|', 'MathType', '|', 'cpCkAnswer',
            ],
            shouldNotGroupWhenFull: true,
        },
    },
    // editor B    
    inputResponse: {
        plugins: [ Essentials,
            Paragraph, PasteFromOffice, RemoveFormat, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            FontSize, FontColor, FontFamily, Link,
            Table, TableToolbar, TableCaption, TableProperties, TableCellProperties,
            Image, ImageCaption, ImageStyle, ImageToolbar, ImageInsert, ImageResizeEditing, ImageResizeButtons, ImageResizeHandles, LinkImage, MediaEmbed,
            //ImageUpload, CustomUploadAdapterPlugin, PreventDragImage,
            // UploadAdapter, SimpleUploadAdapter,
            List, Alignment, WordCount, CpCkInsertImage, MathType, GeneralHtmlSupport,
        ],
        toolbar: {
            items:[
            'undo', 'redo', '|','fontFamily', 'removeFormat', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
            '|', 'fontSize', 'fontColor', '|', 'link', 'insertTable',
            '|', 'CpCkInsertImage', 'mediaEmbed', '|', 'bulletedList', 'numberedList',
            '|', 'alignment', '|', 'MathType', //'ChemType'
            ],
            shouldNotGroupWhenFull: true,
        }, 
    },    
    simpleCK: {
        plugins: [ Essentials,
            Paragraph, PasteFromOffice, RemoveFormat, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            FontSize, FontColor, FontFamily, Link,
            Table, TableToolbar, TableCaption, TableProperties, TableCellProperties,
            Image, ImageCaption, ImageStyle, ImageToolbar, ImageInsert, ImageResizeEditing, ImageResizeButtons, ImageResizeHandles, LinkImage, MediaEmbed,
            List, Alignment, WordCount, CpCkInsertImage, GeneralHtmlSupport,
        ],
        toolbar: {
            items:[
            'undo', 'redo', '|','fontFamily', 'removeFormat', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
            '|', 'fontSize', 'fontColor', '|', 'link', 'insertTable',
            '|', 'CpCkInsertImage', 'mediaEmbed', '|', 'bulletedList', 'numberedList',
            '|', 'alignment',
            ],
            shouldNotGroupWhenFull: true,
        }, 
    },     
    // editor B 
    inputAnswer: {
        plugins: [Essentials, Paragraph, Bold, Italic, Strikethrough, Subscript, Superscript, Alignment,
            Underline, Image, ImageCaption, ImageStyle, ImageToolbar, ImageInsert, ImageResizeEditing, ImageResizeButtons, ImageResizeHandles, LinkImage,
            FontBackgroundColor, WordCount, CpCkInsertImage, MathType],
        toolbar: {
            items:['bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
                '|', 'CpCkInsertImage',
                '|', 'alignment', '|','MathType'],
            shouldNotGroupWhenFull: true,
        },
    },
    inputAnswerAdv: {
        plugins: [Essentials, Paragraph, PreventDragImage,
            WordCount, MathType ],
        toolbar: {
            items:['MathType' ],
            shouldNotGroupWhenFull: true,
        },
    },
    inputCKTest: {
        plugins: [Essentials, Paragraph, PreventDragImage,
            WordCount, MathType ],
        toolbar: {
            items:['MathType' ],
            shouldNotGroupWhenFull: true,
        },
    },    
    // editor D
    inputKey: {
        plugins: [Essentials, Paragraph, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            Alignment, FontSize, FontColor, FontFamily, FontBackgroundColor, WordCount, MathType],
        toolbar: {
            items:['fontFamily', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript','|',
            'fontSize','fontColor','|','alignment','|','MathType'],
        },
    },     
    // editor D inline
    inputKeyInline: {
        plugins: [Essentials, Paragraph, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            Alignment, FontSize, FontColor, FontFamily, FontBackgroundColor, WordCount, MathType],
        toolbar: {
            items:['fontFamily', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript','|',
            'fontSize','fontColor','|','alignment','|','MathType'],
        },
    },    
    equation: {
        plugins: [Essentials, Paragraph, PreventDragImage, PreventTyping,
            RestrictedEditingMode, MathType
        ],
        toolbar: {
            items:['MathType'],
            shouldNotGroupWhenFull: true,
        },
    },
    image: {
        plugins: [Essentials, Paragraph,
            Image, ImageCaption, ImageStyle, ImageToolbar, ImageInsert, ImageResizeEditing, ImageResizeButtons, ImageResizeHandles, LinkImage,
            CpCkInsertImage,
        ],
        toolbar: {
            items: ['CpCkInsertImage'],
            shouldNotGroupWhenFull: true,
        },
    },
    // editor D
    testlag: {
        plugins: [Essentials, Paragraph, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            Alignment, FontSize, FontColor, FontFamily, FontBackgroundColor, MathType],
        toolbar: {
            items:['fontFamily', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript','|',
            'fontSize','fontColor','|','alignment','|','MathType'],
        },
    },     
};
/*
    testlag: {
        plugins: [Essentials, Paragraph, Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            Alignment, FontSize, FontColor, FontBackgroundColor, WordCount, MathType],
        toolbar: {
            items:['bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript','|',
            'fontSize','fontColor','|','alignment','|','MathType'],
        },
    },   
*/

//oupOneLineEditor
const classType = {default:"",simpleCK:"",question:"oupQuesEditor",inputResponse:"oupQuesEditor",
    equation:"oupEquaEditor",inputCKTest:"oupQuesEditor",
    questionFIB:"oupQuesEditor",inputAnswer:"oupAnswerEditor",inputAnswerAdv:"oupAnswerAdvEditor",
    image:"oupEquaEditor", inputKey:"oupAnswerEditor", inputKeyInline:"oupAnswerEditor",
    testklag:"oupAnswerEditor"};

const Ckeditor5Base = ReduxBind(props => {
    const {ck_key} = toObj(props.settings());
    

    const {data, setData, editorType, showStatus=false, enable, setEditor, setAnsEditor, 
        debug=false, containerStyle, mediaDLs, getMediaDLs, needDebounce=false, setUpFlag=0,
        setCharCount, setWordCount, setCountInfo, isAT} = props;

    const CKID = useMemo(() => {
        const id = autoId(); 
        
        return id;
    }, []);
    
    const [blur, _setBlur] = useState(1);
    const setBlur = b => {
        _setBlur(ob => {
            
            return b;
        });
    };

    const [t, showEn, UIlang, setLang, ut, t2, t3] = useUILang();
    const [myEditor, setMyEditor] = useState(null);
    const [statsCount, setStatsCount] = useState({chars:0, words:0});

    const [dataTimer, setDataTimer] = useState();
    const dirtyChange = useRef(0);
    
    const newTimer = nt => setDataTimer(ot => {
        if(ot){ clearTimeout(ot); } 
        return nt;
    });
    const getCkData = (editor, id, caller)=>{ 
        if (dirtyChange.current && setData && editor?.getData) {
            setData(editor.getData()); 
            dirtyChange.current = 0;
        };
    };

    const debounce = (editor, id) => newTimer(setTimeout( () => {
        getCkData(editor, id, 'debounce');
    }, ckDebounce )); 

    useEffect(() => { 
        return () => { newTimer(); };
    }, []); // clear Timer, 


    useEffect(()=>{
        return ()=>{
            //Close wiris
            const wiris = document.querySelectorAll(".wrs_modal_close_button");// as HTMLLinkElement;
            if (wiris && wiris.length>0) {               
                for (let ii=(wiris.length-1); ii >= 0; ii--) {
                    wiris[ii].click();
                };
            };
        };
    },[]);

    useEffect(()=>{
        
        if (myEditor && mediaDLs && Object.keys(mediaDLs).length > 0) {
            if (myEditor.plugins.has('CpCkInsertImage')) {
                const ckImage = myEditor.plugins.get('CpCkInsertImage');
                if (ckImage) { 
                    
                    ckImage.updateImages(mediaDLs);
                };
            };
            
            if (myEditor.plugins.has('CpCkInsertMedia')) {
                const ckMedia = myEditor.plugins.get('CpCkInsertMedia');
                if (ckMedia) { 
                    
                    ckMedia.updateMedia(mediaDLs);
                }; 
            };
        };
    },[mediaDLs]);

    if (!enable) return <QEditorReadOnly key={props.key} {...{data, mediaDLs, getMediaDLs}}/>;

    const myConfig = {
        licenseKey: ck_key,
        //licenseKey: 'bGlQMjlMZVNIRFlpWUpxZWViek0rLzVZbXR6T2lsZlFIdmZWcWdMWW9HTkplc05vSmhpNzNLTGF3MlRCd1Mxcy1NakF5TXpFd01EYz0=',
        //prod licenseKey: 'N1pSdDF5K1h5WlhzbER1cHBRZ0JhbVNYMXNFZXpNQ05DZmNiOS91eTVLdkJJbkwzeVhDMUZjeGVVNE9lZk9XOC1NakF5TXpFd01EYz0=',
        language: UIlang,
        fontColor: { colors: COLOR_PALETTE, columns: 6 },
        fontSize: { options: ['tiny','small','default','big','huge'] },         
        //...mySetting[editorType?editorType:"default"],
        plugins: mySetting[editorType?editorType:"default"].plugins.filter((pp)=>hasFontFamily?1:(pp !== FontFamily)),
        ...{toolbar: {...mySetting[editorType?editorType:"default"].toolbar,
            items: mySetting[editorType?editorType:"default"].toolbar.items.filter((tt)=>hasFontFamily?1:(tt !== 'fontFamily'))}},
        ...tableConfig,
        ...imageConfig,
        ...mediaEmbedConfig,
        ...(editorType==="equation"?equationConfig:{}),
        ...generalHtmlSupportConfig,
        wordCount: {
            onUpdate: stats => {
                // Prints the current content statistics.
                
                setStatsCount({chars:stats.characters, words:stats.words});
                setCharCount && setCharCount(stats.characters);
                setWordCount && setWordCount(stats.words);
                setCountInfo && setCountInfo({chars:stats.characters, words:stats.words});
            }
        },
        link : {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
			decorators: {
				isExternal: {
					mode: 'automatic',
					callback: url => (url.startsWith('http://') || url.startsWith('https://')),
					attributes: {
						target: '_blank',
						rel: 'noopener noreferrer',
					},
			    },            
            },
        },
        ...(hasFontFamily) && {fontFamily: {
            options: [ 'default', 'Times New Roman, Times, serif' ],
            supportAllValues: true
        }},        
        mathTypeParameters: {
            editorParameters: {...mathConfig.mathConfig, checkSyntax: 'true',},
            /*
            editorParameters: {
                //language: "en", //zh
                //metrics:false,
                //centerbaseline: false,
                toolbar: `<toolbar ref='quizzes' removeLinks='true'></toolbar>`                
            },            
            editorParameters: { language: UIlang, checkSyntax: 'true',cleancachegui:'true' }
            */
            //editorParameters: { language: "en", checkSyntax: 'true',cleancachegui:'true' }
        },
        translations: [(UIlang==='zh'? zhTrans:enTrans)]
        //removePlugins: isAT?[]:['CpCkInsertMedia']
    };

    //console.log('my config', myConfig);
    if (editorType==="questionFIB") {
        const {ckAnswerConfig} = props;
        if (ckAnswerConfig) myConfig["cpCkAnswer"] = {...ckAnswerConfig, uit:t, showEn:showEn};
    };

    const {ckImageConfig} = props;
    if (ckImageConfig) {
        myConfig["CpCkInsertImage"] = {...ckImageConfig, uit:t, showEn:showEn};
        myConfig["CpCkInsertMedia"] = {...ckImageConfig, uit:t, showEn:showEn};
    };

    const _enableEditor = (editor, flag) => {
        flag ? editor.disableReadOnlyMode("myID"):editor.enableReadOnlyMode("myID");
    };

    const isInline = (editorType==="inputAnswer"||editorType==="equation" || editorType==="image"
        ||editorType==="inputAnswerAdv"||editorType==="inputKeyInline");

    return <div style={{ width: "100%", ...(containerStyle?containerStyle:{})}}>
        <CKEditor
            editor={isInline?InlineEditor:ClassicEditor}
            config={myConfig}
            data={data}
            onReady={editor => {
                if (editor) {
                    /*
                    const toolbarElement = editor.ui.view.toolbar.element;
                    editor.on( 'change:isReadOnly', ( evt, propertyName, isReadOnly ) => {
                        if ( isReadOnly ) { toolbarElement.style.display = 'none'; }
                        else { toolbarElement.style.display = 'flex'; }
                    });

                    if (editorType==="equation") { 
                        editor.enableReadOnlyMode("myID");
                    };
                    */
                    
                    // add class to control style, e.g. height         
                    editor.editing.view.change((writer) => {
                        writer.addClass(classType[editorType], editor.editing.view.document.getRoot() );
                    });
                    
                    // force math type display eng
                    const toolbar = editor.config.get( 'toolbar' );
                    //console.log('toolbar', toolbar);
                    const mathTypeButtonIndex = toolbar.items.indexOf( 'MathType' );
                    const mathTypeButton = editor.ui.view.toolbar.items.get( mathTypeButtonIndex );                        
                    if (mathTypeButton) mathTypeButton.tooltip = 'MathType';
                    
                    // override MediaEmbed tooltip, icon ?
                    const MediaEmbedDDIndex = toolbar.items.indexOf( 'mediaEmbed' );
                    const MediaEmbedDD = editor.ui.view.toolbar.items.get( MediaEmbedDDIndex ); 
                    //console.log('MediaEmbedDD', MediaEmbedDD);
                    if (MediaEmbedDD) {       
                        //const MediaEmbedButton = MediaEmbedDD.buttonView;
                        const MediaEmbedButton = MediaEmbedDD.labelView;
                        const MediaEmbedIcon = MediaEmbedDD.iconView;
                        MediaEmbedDD.tooltip = showEn?'Insert online video/audio':'新增網上影片/音訊';
                        if (MediaEmbedButton) {
                            //MediaEmbedButton.label = showEn?'Insert online video/audio':'新增網上影片/音訊';
                            //MediaEmbedButton.Symbol.text = showEn?'Insert online video/audio':'新增網上影片/音訊';
                        };
                        if (MediaEmbedIcon) {
                            MediaEmbedIcon.element.innerHTML = mediaIcon;
                        };
                    };
                    
                    if (hasFontFamily) {
                        const useIcon = ['inputKey', 'inputKeyInline'].includes(editorType);
                        const fontFamilyIndex = toolbar.items.indexOf( 'fontFamily' );
                        const fontFamilyButtonDD = editor.ui.view.toolbar.items.get( fontFamilyIndex ); 
                        //console.log('fontFamilyButtonDD',fontFamilyButtonDD);
                        if (!useIcon && fontFamilyButtonDD) {
                            const fontFamilyButton = fontFamilyButtonDD.buttonView;
                            const fontFamilyIcon = fontFamilyButtonDD.buttonView.iconView;
                            if (fontFamilyButton) {
                                fontFamilyButton.set( 'icon', false );
                                fontFamilyButton.set( 'withText', true );
                                const ffCommand = editor.commands.get( 'fontFamily' );
                                fontFamilyButton.bind( 'label' ).to( ffCommand, 'value', value => {
                                    return value ? (value+'').split(',')[0].replaceAll("'","") : t( 'Default' );
                                } );          
                                //fontFamilyButton.element.parentElement.style.border = '1px solid lightgrey';
                                //fontFamilyButton.element.children[1].style.width = 'fit-content';
                            };          
                            if (fontFamilyIcon) {
                                fontFamilyIcon.element.style.maxWidth = 0;
                                fontFamilyIcon.element.innerHTML = '';
                            };
                        };
                    };

                    // disable soft keyboard
                    if (editorType === 'equation') {
                        editor.model.change( writer => {
                            const root = editor.editing.view.document.getRoot();
                            writer.setAttribute( 'inputmode', 'none', root);
                            } );                            
                    };

                    // debug use
                    if (debug) CKEditorInspector.attach(editor);

                    // store editor for local use
                    props.onEditorReady && props.onEditorReady(editor);
                    setMyEditor(editor);                    

                    // pass editor to parent
                    if (setEditor) setEditor.current = editor;
                    if (setAnsEditor) setAnsEditor(editor);
                    
                    //_enableEditor(editor, enable);
                    
                };
            }}
            onChange={(event, editor) => {
                if (editor) {
                    //console.log('ck change !');
                    dirtyChange.current = 1;
                    //const ret = editorType === 'equation' ?                   
                    //if (debugMode() && (!blur)) { debounce(editor, CKID); } else { getCkData(editor, CKID, 'change'); }; 
                    if (!blur && needDebounce) { debounce(editor, CKID); }
                    if ( setUpFlag ) setUpFlag(1);
                    //getCkData(editor, CKID, 'change');
                };
            }}
            onBlur={(event, editor) => {
                setBlur(1);
                //console.log('ck blur !');
                if (dirtyChange.current) {
                    getCkData(editor, CKID, 'blur');
                    //dirtyChange.current = 0;
                };
                //if (debugMode()) { getCkData(editor, CKID, 'blur'); }                
            }}
            onFocus={(event, editor) => { setBlur(0); }}
        />
        {showStatus && <div className="ckEditorStatusBar f12">
            <span style={{marginRight:"10px"}}>{t3("ck-words")}: {statsCount.words}</span>
            <span>{t3("ck-characters")}: {statsCount.chars}</span>
        </div>}
    </div>;
});

export default Ckeditor5Base;

const mediaIcon = '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 27.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve"><g>	<path d="M13.5,0.9c-0.3,0-0.4,0.2-0.5,0.4c-0.1,0.3,0.2,0.4,0.4,0.5c2.8-0.1,5.3,2.2,5.4,5c0,0.3,0.2,0.4,0.4,0.5		c0.3,0,0.4-0.2,0.5-0.4C19.6,3.5,16.8,0.9,13.5,0.9z"/>	<path d="M13.6,2.7c-0.3,0-0.4,0.2-0.5,0.4s0.2,0.4,0.4,0.5C15.5,3.5,17,5,17,6.9c0,0.3,0.2,0.4,0.4,0.5c0.3,0,0.5-0.3,0.4-0.6		C17.9,4.5,15.9,2.7,13.6,2.7z"/>	<path d="M13.5,4.5c-0.3,0-0.4,0.2-0.5,0.4c0,0.3,0.2,0.4,0.4,0.5c0.9,0,1.6,0.7,1.7,1.6c0,0.3,0.2,0.4,0.4,0.5		c0.3,0,0.4-0.2,0.5-0.4C16.1,5.6,14.9,4.5,13.5,4.5z"/>	<path d="M15.3,10.3C15,7.5,12.5,5.2,9.6,5.2c-1.4,0-2.7,0.6-3.9,1.5C4.9,7.5,4.3,8.4,4,9.3c-1.7,0.5-3.2,2.1-3.2,4.1		c0,2.4,1.9,4.2,4.2,4.2h9.7c2,0,3.6-1.7,3.6-3.6C18.3,12.1,17,10.6,15.3,10.3z M12.2,12.5c0,0,0,0.1-0.1,0.1l-3.7,2.2		c-0.3,0.1-0.7,0-0.7-0.4V10c0-0.4,0.4-0.6,0.7-0.4l3.7,2.2C12.4,11.9,12.4,12.2,12.2,12.5z"/></g></svg>';

export const clearNoUseWiris = () => {
    /*
    const wiris = [];
    wiris[0] = document.querySelectorAll(".wrs_modal_offline");
    wiris[1] = document.querySelectorAll(".wrs_modal_dialogContainer");
    wiris[2] = document.querySelectorAll(".wrs_modal_overlay");
    if (wiris.length>0) {               
        wiris.forEach((ww)=>{
            if (ww) { for (let ii=0; ii<ww.length; ii++) ww[ii].remove(); };
        });      
    };
    */    
};