import React, { useEffect, useState, useRef } from 'react';

import { apiCallLoad_, asyncApiCallLoad_ } from '../../libs/awsFuncs';
import { errMsg } from '../../libs/libFormat';
import { isObj, isStr, isAry, toAry, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';
import { ATErrsDiv, aTUIMust, BtnPopDev, clickConfirm, clickUrl, mergePubsDrafts, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';
import { QP_P, QP_D, langCodeEn } from '../../consts/ATConsts'
import { urlPush_Replace } from '../../saga/urlPush.saga';
import { popAlert, popConfirm } from '../components/CpPopup';
//import TabQMC from './TabQMC';
import { cpATIcoBtn_ } from '../components/CpATIcoBtn';
import CpTabHead from '../components/CpTabHead';
import { ATDo_ESet, _ATCanDo } from '../../consts/ATRoleRights';
import CpDraftPubBtns, { PubDraftStates } from '../components/CpDraftPubBtns';
import { detectIsNew, hasErr, normalizeEdit, useEditDraftPub, useLangView } from '../utils/useEditDraftPub';
import { mediaAryToObj, mediaUpFile, useMediaCache } from '../utils/useMediaCache';
import TabESetSetting from './TabESetSetting';
import TabESetMeta from './TabESetMeta';
import TabESetExers from './TabESetExers';
import TabESetThumb from './TabESetThumb';
import { normalizedEditESet, validEditESet } from '../../consts/ATValidateESet';
import { useDropImgState } from '../components/CpDropImg';
import { arrayForEachAsync } from '../../libs/libArray';
import { loading_add } from '../../saga/loading.saga';
import { mSets2Rows } from '../components/CpMetaTickList';
import { dataURI2File } from '../../libs/libEncode';

const backPath = _ATRoot + 'exercisesets';


const PageATESetEdit = ReduxBind( props => {
  const { dispatch } = props;
  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
  const canESet =_ATCanDo(role, ATDo_ESet); 

  const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub] = useEditDraftPub();
  const [isNew, ESetId] = detectIsNew(props.match?.params, pub, draft, 'ESetId');
  
  useEffect(() => { ESetId && dispatch(urlPush_Replace( _ATRoot + 'exerciseset/edit/'+ESetId)) }, [ESetId]);

  const imgStates = { e0: useDropImgState(), e1: useDropImgState(), e2: useDropImgState(), c0: useDropImgState(), c1: useDropImgState(), c2: useDropImgState(), };

  const setESubjId = setTickAry('ESSubjIds');
  const setFilter = setTickAry('ESMetaFilters');

  const [tab, setTab] = useState(0);

  // then Choose Pub/Draft 
  const [view, setView] = useState(QP_P);
  const {hasDraft, saved, r2p, pubed, unpubed, showPub, lock } = PubDraftStates(view, draft, pub, 'ESVer'); 
  const eset = showPub ? pub : fields;
  
  const [lang, setLang, clickLang, showEn, hasEn, hasCt, safeLang] = useLangView(eset?.ESLangEn, eset?.ESLangCt, langCodeEn);

  const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 1);

  const safeSetView = v => UI.stopEventThen(e => setView((!pub)? QP_D: (!hasDraft)? QP_P: v));

  const onLoad = (res, err) => {
    if (err) popAlert(dispatch, 0, errMsg(err));
    const { draft, pub, fieldErrs, ATSubjects, mySjIds, draftQMs, pubQMs, draftMetaSets, pubMetaSets, medias, EPubs, EDrafts  } = toObj(res);
    setPub(pub)
    setDraft(draft);
    setFields(draft);
    setFieldErrs(fieldErrs);
    const [draftMSetRows, pubMSetRows] = [mSets2Rows(draftMetaSets), mSets2Rows(pubMetaSets)];
    setOpts(o => ( {...toObj(o), ATSubjects, mySjIds, Es: {...toObj(o?.Es), ...mergePubsDrafts(EPubs, EDrafts, 'EId')}, 
      draftQMs, pubQMs, draftMSetRows, pubMSetRows, medias: mediaAryToObj(medias), } ));
    setView(v => ((!pub)? QP_D: (!draft)? QP_P: v));
    const eset = pub || draft; 
  };
  const onLoadDraft = (res, err) => {
    if (err) popAlert(dispatch, 0, errMsg(err));
    if (res) {
      const [d] = [res.draft];
      setDraft(d);
      setFields(toObj(d));
      setFieldErrs({});
      setView(d? QP_D: QP_P);
    }
  };
  const back = () => dispatch(urlPush_Replace(backPath));
  const onDelete = (res, err) => { back(); }
  
  const reload = () => apiCallLoad_(dispatch, '/getATESet', onLoad, { ESetId, isNew });
  useEffect(() => { canESet? reload(): dispatch(urlPush_Replace(_ATRoot)); }, [] );

  const normalize = () => normalizeEdit(fields, opts, [], normalizedEditESet, validEditESet, setFields, setFieldErrs);
  const clickSaveATESet = doPub => async e => { UI.stopEvent(e);
    dispatch(loading_add('saveESET', 1));
    const SR = await SaveESet(dispatch, normalize, imgStates);
    const [ESetId, fes, res, err] = SR;
    if((!res) || (res.state === 'retry')){
      fes && setFieldErrs(fes);
    }else if ((res?.state) === 'ok') {
        doPub
          ?confirmPub(ESetId)
          :back();//onLoad(res, err);
    }else{
      back();
    }
    dispatch(loading_add('saveESET', -1));
  };

  const clickPub = e => { UI.stopEvent(e); confirmPub(ESetId) };
  const confirmPub = (ESetId) =>  popConfirm(dispatch, 0, 'Confirm Publish', () => apiCallLoad_(dispatch, '/putATESetsPublish', onLoad, { ESetId }));

  const letClone = canESet; 
  const letPub = canESet;
	const letUnpub = canESet; 
  const letDelete = canESet; 
  
  const clickEndESetEd = clickUrl(dispatch, backPath);
  const clickClone = letClone && pub && UI.stopEventThen(e => apiCallLoad_(dispatch, '/getATESetDraft', onLoadDraft, { ESetId }));
  const clickPublish = letPub && unpubed && clickPub;
  const clickSavePublish = letPub && hasDraft && clickSaveATESet(1);
  const clickUnpublish = letUnpub && pubed && clickConfirm(dispatch, 'Confirm Unpublish', () => apiCallLoad_(dispatch, '/putATESetsUnpublish', onLoad, { ESetId }));
  const clickDelete = letDelete && hasDraft && clickConfirm(dispatch, 'Confirm Delete', () => isNew? back(): apiCallLoad_(dispatch, '/deleteESets', onDelete, { ESetId }));

  const tapTab = i => UI.stopEventThen(e => setTab(i));
  const tabProps = {eset, draft, pub, fieldErrs, fields, setFields, setField, setTick, setESubjId, setFilter, imgStates, opts, setOpts, 
    mediaDLs, getMediaDLs, clickLang, showPub, lock, showEn, hasEn, hasCt};

  return <div className="adminQuesTop" >
    <div className="f16">Exercise / Exercise Sets / {isNew ? 'New' : 'Edit'}</div>
    {ATErrsDiv(fieldErrs)}
    {preJS({showPub, lock, showEn, hasEn, hasCt, hasDraft, isNew, saved, pubed, })}
    
    <div className="adminToolsContainer"><div style={{display:"flex"}}>
      <div className="adminTools1">
      {showPub ? <>
        {letPub && cpATIcoBtn_('general/publish', 'Publish', (!hasDraft) && clickPublish)}
        {letUnpub && cpATIcoBtn_('general/unpublish', 'Unpublish', clickUnpublish)}
        {letClone && cpATIcoBtn_('general/copy', 'Duplicate as Draft', clickClone)}
      </> : <>
        {cpATIcoBtn_('general/save', 'Save', clickSaveATESet(0))}
        {cpATIcoBtn_('general/cancel', 'Cancel', clickEndESetEd)}
        {letDelete && cpATIcoBtn_('general/delete', 'Delete', clickDelete)}
        {letPub && cpATIcoBtn_('general/publish', 'Save & Publish', clickSavePublish)}
      </>}          
      </div>
      <div className="adminTools2"><CpDraftPubBtns {...{saved, draft, showPub, pub, click:safeSetView, verKey:'ESVer' }} /></div>
    </div></div>
    {CpTabHead(tab, tapTab, [<>Settings {aTUIMust}</>, <>Exercises {aTUIMust}</>, <>Metadata {aTUIMust}</>, <>Thumbnails {aTUIMust}</>], <>
      <BtnPopDev txt='eset'>{preJS(eset,3)}</BtnPopDev>
      <BtnPopDev txt='fields'>{preJS(fields,3)}</BtnPopDev>
      <BtnPopDev txt='opts'>{preJS(opts,3)}</BtnPopDev>
    </>)}
    { chooseTab(tab, (showPub?'P': 'D')+(showEn?'E':'C'), tabProps, [ TabESetSetting, TabESetExers, TabESetMeta, TabESetThumb ]) }
  </div>;
});
export default PageATESetEdit;

export const chooseTab = (tabIdx, key, tabProps, TabComps, ) => {
  const Comp = TabComps[tabIdx];
  return Comp? <Comp key={key} {...tabProps} />: '';
}; 

const SaveESet = async (dispatch, normalizeEset, imgStates) => {
  try{
      const [fields, fieldErrs] = normalizeEset();
      const ESetId = fields.ESetId;
      if (hasErr(fieldErrs))
        return [ESetId, fieldErrs];
      
      const [res, err]  = await asyncApiCallLoad_(dispatch, '/putATESetDraft', { fields });
      const ok = (res?.state) === 'ok';
    
      const ESThumbs = ok && await saveEsetThumbs(dispatch, fields, imgStates);
      
      const newThumbs = ESThumbs && (!deepEqual(ESThumbs, fields.ESThumbs));
      
      if(newThumbs){
        const [sr, se] = await asyncApiCallLoad_(dispatch, '/putATESetThumbs', {ESetId, ESThumbs} ); 
        
      }
      return [ESetId, res?.fieldErrs || fieldErrs, res, err];
  }catch(e){
    console.error(e);
  }
};

const saveEsetThumbs = async (dispatch, eset, imgStates) => {
  const thumbIds = ['e0', 'e1', 'e2', 'c0', 'c1', 'c2'];
  const ests = toObj(eset.ESThumbs);
  const [mtype, ESetId] = ['esetthumb', eset.ESetId];

  const ESThumbs = {};
  await arrayForEachAsync(thumbIds, async thumbId => {
    const st = toObj(imgStates[thumbId]);
    const { file, data, err } = st;
    const { name, type, size } = file||{};
    const save = data && name && size && type && (!err);
    const testId = '';//['EST', ESetId, thumbId].join('_');
    const fields = { name, size, testId, mtype, ESetId, thumbId };
    const upid = save  && await mediaUpDataURI(dispatch, fields, data, name, type);
    
    ESThumbs[thumbId] = toStr(upid || ests[thumbId]);
  });
  return ESThumbs;
};

const mediaUpDataURI = async (dispatch, getFields, dataURI, name, type) => mediaUpFile(dispatch, getFields, await dataURI2File(dataURI, name, type)); 

const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);