import React, { useEffect, useRef, useState } from 'react';
import * as UI from '../../libs/libUI';
import * as SQT from '../../consts/ATSysQType';
import { popConfirm } from './CpPopup';
import { useUILang } from '../utils/useUILang';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';

const oldType = 'Choose other question type';
export const CpQtnTypeMenu = (props) => {
    const { sel, setSel, right, clearQAnswers, setShowEditor, pub, dispatch, isAT} = props;
    const [show, setShow] = useState(false);
    const [ t, uiEn, UILang, setUILang, ut, t2, t3 ] = useUILang();

    const prevSQType = sel;
    const lock = isAT && pub;

    const QMappedName = {
        [SQT.__SYSQSubType_MCS]: t(SQT.__UISYSQTypes[SQT.__SYSQTypesMapping[SQT.__SYSQSubType_MCS]])+' - '+t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_MCS]),
        [SQT.__SYSQSubType_MCT]: t(SQT.__UISYSQTypes[SQT.__SYSQTypesMapping[SQT.__SYSQSubType_MCT]])+' - '+t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_MCT]),
    
        [SQT.__SYSQSubType_FIB]: t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_FIB]),
    
        [SQT.__SYSQSubType_LBT]: t(SQT.__UISYSQTypes[SQT.__SYSQTypesMapping[SQT.__SYSQSubType_LBT]])+' - '+t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_LBT]),
        [SQT.__SYSQSubType_LBD]: t(SQT.__UISYSQTypes[SQT.__SYSQTypesMapping[SQT.__SYSQSubType_LBD]])+' - '+t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_LBD]),
    
        [SQT.__SYSQSubType_OED]: t(SQT.__UISYSQTypes[SQT.__SYSQTypesMapping[SQT.__SYSQSubType_OED]])+' - '+t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_OED]),
        [SQT.__SYSQSubType_OEG]: t(SQT.__UISYSQTypes[SQT.__SYSQTypesMapping[SQT.__SYSQSubType_OEG]])+' - '+t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_OEG]),
        [SQT.__SYSQSubType_OEE]: t(SQT.__UISYSQTypes[SQT.__SYSQTypesMapping[SQT.__SYSQSubType_OEE]])+' - '+t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_OEE]),
    
        [SQT.__SYSQSubType_POL]: t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_POL]),
    };

    const opts_ = [
        { id: SQT.__SYSQType_MCQ, jsx: () => <div>{t(SQT.__UISYSQTypes[SQT.__SYSQType_MCQ])}</div>,
        allowC:0,head:()=><div>{oldType}</div>},
        { id: SQT.__SYSQSubType_MCS, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_MCS])}</div>,
        allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_MCS]}</div>},
        { id: SQT.__SYSQSubType_MCT, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_MCT])}</div>,
        allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_MCT]}</div>},

        { id: SQT.__SYSQType_FIB, jsx: () => <div>{t(SQT.__UISYSQTypes[SQT.__SYSQType_FIB])}</div>
        ,allowC:0,head:()=><div>{oldType}</div>},
        { id: SQT.__SYSQSubType_FIB, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_FIB])}</div>
        ,allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_FIB]}</div>},

        { id: SQT.__SYSQType_LBD, jsx: () => <div>{t(SQT.__UISYSQTypes[SQT.__SYSQType_LBD])}</div>
        ,allowC:0,head:()=><div>{oldType}</div>},
        { id: SQT.__SYSQSubType_LBT, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_LBT])}</div>
        ,allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_LBT]}</div>},
        { id: SQT.__SYSQSubType_LBD, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_LBD])}</div>
        ,allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_LBD]}</div>},

        { id: SQT.__SYSQType_OPE, jsx: () => <div>{t(SQT.__UISYSQTypes[SQT.__SYSQType_OPE])}</div>
        ,allowC:0,head:()=><div>{oldType}</div>},
        { id: SQT.__SYSQSubType_OEG, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_OEG])}</div>
        ,allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_OEG]}</div>},
        { id: SQT.__SYSQSubType_OEE, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_OEE])}</div>
        ,allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_OEE]}</div>},
        { id: SQT.__SYSQSubType_OED, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_OED])}</div>
        ,allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_OED]}</div>},        

        { id: SQT.__SYSQType_POL, jsx: () => <div>{t(SQT.__UISYSQTypes[SQT.__SYSQType_POL])}</div>
        ,allowC:0,head:()=><div>{oldType}</div>},
        { id: SQT.__SYSQSubType_POL, jsx: () => <div>{t(SQT.__UISYSQSTypes[SQT.__SYSQSubType_POL])}</div>
        ,allowC:1,head:()=><div>{QMappedName[SQT.__SYSQSubType_POL]}</div>},
    ];    
    const opts = (SQT.showDrawQOpt) ? opts_: opts_.filter(o => o.id !== SQT.__SYSQSubType_OED);

    const getHead = () => {
        const xx = opts.find((opt, ii) => {return opt.id === sel});
        return xx ? xx.head() : opts[1].head();
    };

    const onBlur = e => { UI.stopEvent(e);
        setShow(false);
    };

    const optClick = (e, QType) => { UI.stopEvent(e);
        setShow(false);
        (prevSQType !== QType) && (!lock) && popConfirm(dispatch, 0, t('warning.warning_pls_confirm_clear_question_and_type'), () => {
            setShowEditor(0);
            clearQAnswers(QType, true, prevSQType);
            setSel(QType);
            //setShowEditor(1);
        },()=>{},t);
    };

    const clickShow = lock? undefined: (e => { UI.stopEvent(e); setShow(!show); });

    return <>
        <div className='ATPDMenu' tabIndex={0} onBlur={onBlur} style={{width:'100%', justifyContent: "flex-end"}}>
            <div className={'ATPDHeader gap-1 '+ (show?' show ':'') + (lock?'':'clickable')} onClick={clickShow}
                 style={{ padding:"8px", width: "100%", justifyContent: "center" }}>
                {getHead()}<CpIco src={IconList.general.dropdown} width="0.6em" />
            </div>
            {show && (opts && opts.length > 0) && <div className={'ATPDContainer'+(right?"Right ":"Left ")}
                    style={{ padding:"4px 8px 4px 8px", width: "100%", top:"45px" }}>
                {opts.map((opt, ii) => {
                    const cstyle= {margin:"4px 8px 4px 8px", padding:0, color:(opt.allowC?'black':'grey'),
                        paddingLeft:(opt.allowC?'20px':'0')}
                    const cclass=opt.allowC?'ATPDrow clickable':'ATPDrowLabel unclickable';
                    return <div key={"opt" + opt.id + ii} className={cclass}
                    onClick={(e)=>optClick(e, opt.id)} style={cstyle}>{opt.jsx()}</div>
                })}
            </div>}
        </div>
    </>;
};

export default CpQtnTypeMenu;

//{svgIcon2("general/dropdown","#2995cd",{paddingLeft:"5px",width:"15px"})}