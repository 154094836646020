import React, {useEffect, useState, useMemo} from "react";
import { ReduxBind } from "../../saga/ReduxState";
import PageEXPFLayout from "../PageEXPFLayout";
import { IconList } from '../../consts/ATIconListToUsePoc';
import { clickUrl } from "../../AppExPf/AppUtil";
import CpDashboardFilterCTA from "./CpDashboardFilterCTA";
import CpHeaderTitle from "../_components/CpHeaderTitle";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpDashboardFilter from "./CpDashboardFilter";
import { toAry, toObj, toStr, toInt, isObj, toUniAry } from "../../libs/libType";
import { useFields } from "../../AppExPf/utils/useEditDraftPub";
import { asyncApiCall_, asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { expfRoles } from "../../saga/auth.saga";
import { sortAsms, filterAsms, mkAStateItems, mkASortItem } from "../EPAssign/CpAssignmentListFilter";
import { objKeys } from "../../libs/libType";
import { fixAsms, pickAsmsByMSubj, useMemoSubjItems } from "../EPAssign/PageAssignments";
import { useYearClass } from "../EPAssign/TabAssignmentSettingClass";
import { usePaging } from "../../AppExPf/utils/ATPaging";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _pathAssign } from "../../consts/ATConstReact";

import LayerAssignmentChange, { useAsmDead, useAssignmentDead } from "../EPAssign/LayerAssignmentDeadline";
import LayerAssignmentDelete, { useAssignmentDel } from "../EPAssign/LayerAssignmentDelete";
import LayerAssignmentEnd, { useAssignmentEnd } from "../EPAssign/LayerAssignmentEnd";
import LayerAssignmentPublish, { useAssignmentPub } from "../EPAssign/LayerAssignmentPublish";
import LayerAssignmentShare, { CpShareCTA, useAssignmentShare } from "../EPAssign/LayerAssignmentShare";
import { CpAssignOffCanvas, useAssignmentEdit } from "../EPAssign/CpExerciseEditAssignCTA";

import CpDashboardStudentBody from "./CpDashboardStudentBody";
import CpDashboardTeacherBody from "./CpDashboardTeacherBody";
import { unpackMark, unpackWork } from "../../consts/wirisConfig";

import { popAlertExtra } from "../../AppExPf/components/CpPopup";
//import PageDashboardSt from "./PageDashboardSt";
//import PageDashboardTe from "./PageDashboardTe";

const PageDashboards = ReduxBind(props => {
    const { dispatch, _saga } = props;
    const [isThr, isStt, uRole ] = expfRoles(props);
    const params = toObj(props.match?.params);
    const {asmId, act} = toObj(params);

    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const [opts, setOpts] = useState({});
    const loadOpts = async () => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignmentOpts', {withSubj:1});
        setOpts(os => ( {...toObj(os), ...toObj(res?.opts) } ));
    };
    useEffect(() => { loadOpts(); }, []);

    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs] = useFields(); 
    const {yearItems, year, classItems, classId, yearClassDDI, lastCls, subjItems, AMSubjId} = useDashOpts(fields, opts, uiEn, ut, t, isThr);

    const [asms, setAsms] = useState([]);
    const [dueAsms, setDueAsms] = useState([]);
    const [pubAsms, setPubAsms] = useState([]);

    const putReadNoticeTodos = async (todoAsms, skipDays) => {
        const [res, err] = await asyncApiCall_(dispatch, '/putReadNotice', {notice: 'todo', todoAsms, skipDays});
    };

    const popupNoticeTodos = (todoAsms) => {
        const cntToDoAsm = toUniAry(todoAsms?.toDoAsmIds);
        const cntToDoReme = toUniAry([...toAry(todoAsms?.remeIdToDos), ...toAry(todoAsms?.remeIdFails)]);
        if(!(cntToDoAsm.length || cntToDoReme.length)) return;
//console.log('popupNoticeTodos() ', {cntToDoAsm, cntToDoReme} );
        const remInfo = {checked: false};
        const extra = {icon:IconList.menu.assignment, title:"warning.warning_exercise_reminder"};
        const _setRemChk = e => { remInfo.checked = !remInfo.checked; };
        const clickOk = e => putReadNoticeTodos(todoAsms, remInfo.checked);
        const clickX = e => putReadNoticeTodos(todoAsms, 0); //EX-1667
        const onUnpop = e => {/* unpoped by click X, not ok not cancle */ /*putReadNoticeTodos(todoAsms, remInfo.checked)*/ }; 
        const msgHTML = <div>{t('warning.warning_exercise_reminder_content.you_have')}<br /><ul>
            <li><span className='text-danger text-decoration'>{cntToDoAsm.length}</span> {t('warning.warning_exercise_reminder_content.content_1')}</li>
            <li><span className='text-danger text-decoration'>{cntToDoReme.length}</span> {t('warning.warning_exercise_reminder_content.content_2')}</li>
            </ul>
            <input type="checkbox" defaultChecked={remInfo.checked} onChange={_setRemChk} />
            <span className='fs-8'> {t('warning.warning_exercise_reminder_content.content_3')}</span>
        </div>;

        //const msgHTML = t("warning.warning_exercise_reminder_content").replace("%s",cntToDoAsm.length).replace("%s",cntToDoReme.length);
        popAlertExtra(dispatch, 1, msgHTML, clickOk, 0, clickX, onUnpop, extra);
    };

    const reload = async (asmId) => {
        if(!classId || !AMSubjId)  return;
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignments', {AFilter:{classId, AMSubjId, isHome:1, noticeClsId: lastCls?.[0]}, asmId}); //EX-1667
        popupNoticeTodos(toObj(res.todoAsms)); //putReadNoticeTodos(toObj(res.todoAsms));
        const upworks = toAry(res?.works).map(unpackWork);
        const upMarks = toAry(res?.marks).map(unpackMark);
        setAsms( fixAsms(res?.asms, upworks, upMarks) );
        setDueAsms( fixAsms(res?.dueAsms, upworks, upMarks) );
        setPubAsms( fixAsms(res?.pubAsms, upworks, upMarks) );
    };
    useEffect(() => { if(!asmId) reload(); }, [classId, AMSubjId, asmId]);

    const clickExitAsm = clickUrl(dispatch, _pathAssign('',''));

    const [listView, setListView] = useState(1);

    const {useAsmEnd, useAsmDead, useAsmEdit, useAsmPub, useAsmDel, useAsmShare, asmDo } = useAsmDo(dispatch);

    const subProps = { ...props, isThr, isStt, 
        t, uiEn, UILang, setUILang, ut,
        yearClassDDI, yearItems, year, classItems, classId, subjItems, AMSubjId, 
        opts, clickBack: clickExitAsm, reload,
        listView, setListView, fields, setFields, setField, useAsmDead, useAsmEdit, useAsmEnd, useAsmDel, useAsmPub, useAsmShare, asmDo, // tobon 3 1 add here to pass to sub elements
        asms, dueAsms, pubAsms, asmId, act}; 

    return <PageEXPFLayout sideIdx='home' 
        menuTitle={<CpHeaderTitle title={t("sidemenu-home")} iconPath={IconList.menu.home} animation={true}/>} 
        menuActionBar={<CpDashboardFilterCTA {...subProps} />} 
        header='' 
        subBody='' 
        footer=''>
        <div className={"d-none d-lg-flex flex-column flex-lg-row flex-wrap gap-3 pt-3 px-3"}>
            {<CpDashboardFilter {...subProps} />}
        </div>         
        <hr className={"d-none d-lg-block mt-2 mb-3 mx-3"}/>
        {isThr?<CpDashboardTeacherBody {...subProps}/>
        :isStt?<CpDashboardStudentBody {...subProps}/>
        :''}
        {isThr?<>
            <LayerAssignmentChange {...{useAsmDead}} />
            <LayerAssignmentEnd {...{useAsmEnd}} />
            <CpAssignOffCanvas {...{useAsmEdit}} />{ /* Change Settings */}
            <LayerAssignmentDelete {...{useAsmDel}} />
            <LayerAssignmentPublish {...{useAsmPub}} />
            <LayerAssignmentShare {...{useAsmShare}} />
        </>:''}

    </PageEXPFLayout>
});
export default PageDashboards;



export const useAsmDo = (dispatch) =>{
    const useAsmEnd = useAssignmentEnd();
    const useAsmDead = useAssignmentDead();
    const useAsmEdit = useAssignmentEdit();
    const useAsmPub = useAssignmentPub();
    const useAsmDel = useAssignmentDel();
    const useAsmShare = useAssignmentShare();
    const asmDo = (asm, act, reload, onRmAsm) => {
        
        if(act ==='endAsm' ) useAsmEnd.setEndAsm({asm, reload}); 
        if(act ==='editAsm' ) useAsmEdit.setEditAsm(/*call is different!!!*/ asm, reload); 
        if(act ==='deadAsm' ) useAsmDead.setDeadAsm({asm, reload});
        if(act ==='pubAsm' ) useAsmPub.setPubAsm({asm, reload});
        if(act ==='rptAsm' ) dispatch(urlPush_Replace(_pathAssign(asm.assignId, 'report')) );
        if(act ==='rmAsm' ) useAsmDel.setDelAsm({asm, onRmAsm}); // make page pop dele
        if(act ==='shareAsm' ) useAsmShare.setShareAsm({asm, reload});
    };
    return {useAsmEnd, useAsmDead, useAsmEdit, useAsmPub, useAsmDel, useAsmShare, asmDo};
};

export const useDashOpts = (fields, opts, uiEn, ut, t, isThr) => {
    const {year:y, classId:c, AMSubjId:j} = toObj(fields);

    const [yearItems, year, classItems, classId, yearClassDDI, lastCls] = useYearClass(opts?.yearClass2d, y, c, uiEn, ut, isThr);

    //const AStateItems = mkAStateItems(isStt, t);
    //const AState = AStateItems[st]? st: objKeys(AStateItems)[0];
    //const ASortItems = mkASortItem(t);
    //const ASort = ASortItems[so]? so: objKeys(ASortItems)[0];

    const subjItems = useMemoSubjItems(opts?.subjMetas, 0, uiEn, ut, t);
    const AMSubjId = subjItems[j]? j:  objKeys(subjItems)[0];

    return {yearItems, year, classItems, classId, yearClassDDI, lastCls, subjItems, AMSubjId};
};
