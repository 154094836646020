import {forwardRef, useEffect, useImperativeHandle, useRef} from "react";

import {
    DONUT_BODY_COLOR,
    DONUT_TEXT_DIM_COLOR,
    getMaxValue,
    LineBarType,
    LineBarValueType,
    schoolYearByMonth,
} from "../../EPReport/ChartTypeConst";

import { getUUID } from "../../_components/CpGetUUID";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import { getPolyLine } from "./ChartLineBarPolyLine";
import { getBar } from "./ChartLineBarBar";
import { aryLen, maxVal, objKeys, toAry, toNum, toObj } from "../../../libs/libType";

const svgns = "http://www.w3.org/2000/svg";
const strokeColorIndicator = "#ffffff", strokeBGColorIndicator = "#ffffff";

const rptYearsByClsInfs = (KSYears) => {
    return toAry(KSYears).map(year => {
        return {id:year, title:year, value:year};
    })
};

const ChartLineBar = (props, ref) => { // base on poc LineBarChart
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    
    const { title, lineBarType, lineBarValueType } = props;
    const { line: lineData = [], bar: barData = [] } = toObj(props.data);

    const { isKeyStage, rptKeyStage } = props;
	const {KSYears, KSClss, KSStus} = toObj(rptKeyStage);

    const svgRef = useRef(null);

    const xItems = isKeyStage? rptYearsByClsInfs(KSYears): schoolYearByMonth(t).slice(0, 12);
    const months = aryLen(xItems);
    const totalPosY = months; //12;

    const opacity = 0.5;
    const indicatorNum = 10;
    const { maxValue, stepValue, valueRange } = getMaxValue(lineBarType === LineBarType.LINE? lineData: barData, indicatorNum, lineBarValueType === LineBarValueType.PERCENTAGE)

    const axisStrokeColor = DONUT_BODY_COLOR, axisStrokeWidth = 2;

    const xAxisMargin = Math.floor(70 * 12 / maxVal(1, months));
    const barWidth = 35;//xAxisMargin / 2;

    const lineBaseXLeft = 80;
    const yAxisMargin = 35, lineBaseYTop = 50;

    const lineBaseXRight = lineBaseXLeft + months * xAxisMargin;
    const lineBaseYBottom = lineBaseYTop + (valueRange.length - 1) * yAxisMargin;

    const titleSize = 20;
    const xTitleGap = lineBaseYBottom / 2 * -1;
    const yTitleGap = lineBaseXLeft - 60;
    const xIndicatorLineGap = 25, yIndicatorLineGap = 10;

    const yIndicatorShiftDown = 6; // = indicator font half height

    const xValueIndicator = 10, yValueIndicator = 5;
    const roundIndicator = 5;
    const strokeWidthIndicator = 1;
    const indicatorSize = 1;
    const indicatorGap = 14;

    //Data
    const dataPosY = 18;

    //Bar
    const barStartingY = 0 + axisStrokeWidth / 2;

    //Value Range Size
    const valueRangeSize = lineBaseYBottom - lineBaseYTop;

    const dotStartingX = lineBaseXLeft + xAxisMargin / 2;

    const xIndicator = [
        ['y', `${lineBaseYBottom + xIndicatorLineGap}`],
        ['text-anchor', `middle`],
        ['font-weight', `500`],
        ['font-size', `${titleSize}px`],
        ['fill', `${axisStrokeColor}`]
    ];

    const yIndicator = [
        ['x', `${lineBaseXLeft - yIndicatorLineGap}`],
        ['text-anchor', `end`],
        ['font-weight', `500`],
        ['font-size', `${titleSize}px`],
        ['fill', `${axisStrokeColor}`]
    ];

    const convertedLineData = getPolyLine({
        startingPoint: dotStartingX,
        xAxisMargin,
        valueRangeSize,
        xItems,
        maxValue,
        data: lineData
    });
    const convertedBarData = getBar({
        startingPoint: dotStartingX - (barWidth / 2),
        barStartingY,
        xAxisMargin,
        barWidth,
        valueRangeSize,
        xItems,
        maxValue,
        data: barData
    });
    const dataStyle = {
        ...convertedLineData,
        ...convertedBarData
    };
    const emptyContent = () => { if (svgRef.current) svgRef.current.innerHTML = ""; };

    const grid = [
        ['x1', toNum(lineBaseXLeft)],
        ['x2', toNum(lineBaseXLeft) + toNum(months) * toNum(xAxisMargin)],
        ['stroke', DONUT_TEXT_DIM_COLOR],
        // ['opacity', `${opacity}`],
        ['stroke-width', `1`]
    ];
//console.log({grid}, {lineBaseXLeft, months, xAxisMargin});
    const renderGrid = () => {
        if (svgRef.current) {
            const g = document.createElementNS(svgns, 'g');
            g.setAttribute('transform', `scale(1,-1) translate(0,-${lineBaseYBottom})`);
            for (let idx = 0; idx < valueRange.length; idx++) {
                // if (idx === valueRange.length - 1) {
                //     //0 bottom no need to draw
                //     continue;
                // }
                const line = document.createElementNS(svgns, 'line');
                grid.forEach(style => { line.setAttribute( style[0], style[1]); });
                const gridLineY = valueRange[idx] * (valueRangeSize) / maxValue;
                // lineBaseYTop + (yAxisMargin * idx) - axisStrokeWidth
                line.setAttribute("y1", `${gridLineY}`);
                line.setAttribute("y2", `${gridLineY}`);
                g.appendChild(line);
            };
            svgRef.current.appendChild(g);
        };
    };

    const renderReportType = () => {
        if (svgRef.current) {
            const text = document.createElementNS(svgns, 'text');
            text.setAttribute("x", `${xTitleGap}`);
            text.setAttribute("y", `${yTitleGap}`);
            text.setAttribute('transform', 'rotate(270)');
            text.setAttribute('text-anchor', "middle");
            text.setAttribute('font-weight', `bold`);
            text.setAttribute('font-size', `${titleSize}`);
            text.innerHTML = `${title}`;
            svgRef.current.appendChild(text);
        };
    };

    
    const xLine = [ //asix
        ['x1', `${lineBaseXLeft}`],
        ['y1', `${lineBaseYBottom}`],
        ['x2', `${lineBaseXLeft + months * xAxisMargin}`],
        ['y2', `${lineBaseYBottom}`],
        ['stroke', `${axisStrokeColor}`],
        ['opacity', `${opacity}`],
        ['stroke-width', `${axisStrokeWidth}`]
    ];
    const renderXAxis = () => {
        if (svgRef.current) {
            const line = document.createElementNS(svgns, 'line');
            xLine.forEach(style => { line.setAttribute(style[0], style[1]); })
            svgRef.current.appendChild(line);
            const text = document.createElementNS(svgns, 'text');
            xItems.forEach((xItem, idx) => {
                const tspan = document.createElementNS(svgns, 'tspan');
                tspan.setAttribute('x', `${lineBaseXLeft + (xAxisMargin * idx) + xAxisMargin / 2}`);
                xIndicator.forEach(style => { tspan.setAttribute(style[0], style[1]); })
                tspan.innerHTML = xItem.title;
                text.appendChild(tspan);
            });
            svgRef.current.appendChild(text);
        };
    };

    const yLine = [ //asix
        ["x1", `${lineBaseXLeft}`],
        ["y1", `0`],
        ["x2", `${lineBaseXLeft}`],
        ["y2", `${lineBaseYBottom + axisStrokeWidth / 2}`],
        ["stroke", `${axisStrokeColor}`],
        ["opacity", `${opacity}`],
        ["stroke-width", `${axisStrokeWidth}`]
    ];
    const renderYAxis = () => {
        if (svgRef.current) {
            const line = document.createElementNS(svgns, 'line');
            yLine.forEach(style => { line.setAttribute(style[0], style[1]); });
            svgRef.current.appendChild(line);
            const text = document.createElementNS(svgns, 'text');
            for (let idx = 0; idx < valueRange.length; idx++) {
                const vr = valueRange[idx]
                const tspan = document.createElementNS(svgns, 'tspan');
                const indicatorY = lineBaseYTop + (yAxisMargin * idx) + yIndicatorShiftDown
                tspan.setAttribute('y', `${indicatorY}`);
                yIndicator.forEach(style => {
                    tspan.setAttribute(style[0], style[1]);
                })
                tspan.innerHTML = `${vr}`;
                text.appendChild(tspan)
            };
            svgRef.current.appendChild(text)
        };
    };

    const renderLineData = () => {
        if (svgRef.current) {
            const g = document.createElementNS(svgns, 'g');
            g.setAttribute('transform', `scale(1,-1) translate(0,-${lineBaseYBottom})`);
            dataStyle.line.forEach((line) => {
                const polyline = document.createElementNS(svgns, 'polyline');
                line.forEach(style => { polyline.setAttribute(style[0], style[1]); })
                g.appendChild(polyline)
            });

            xItems.forEach((xItem, idx) => {
                dataStyle.dot.forEach(dot => {
                    const circle = document.createElementNS(svgns, 'circle');
                    dot.forEach(style => { circle.setAttribute(style[0], style[1]); });
                    g.appendChild(circle);
                    //Value indicator
                    const text = document.createElementNS(svgns, 'text');
                    text.setAttribute("x", circle.getAttribute('cx'))
                    text.setAttribute("y", (-1 * parseFloat(circle.getAttribute('cy')) - indicatorGap).toString());
                    text.setAttribute('fill', `${circle.getAttribute('fill')}`)
                    text.setAttribute('font-size', `${indicatorSize}rem`)//use rem , don't resize by svg
                    text.setAttribute('text-anchor', "middle")
                    text.setAttribute('transform', `scale(1,-1)`)
                    text.setAttribute('class', `indicator-text`)
                    const filter = circle.getAttribute('group-item-data')
                    text.setAttribute("group-item-data", `${filter}`);
                    text.innerHTML = `${circle.getAttribute('value')}%`
                    g.appendChild(text);
                });
            });
            svgRef.current.appendChild(g);
        };
        makeValueIndicatorContainer();
    };

    const makeValueIndicatorContainer = () => {
        if (svgRef.current) {
            var texts = svgRef.current.querySelectorAll(".indicator-text")
            for (let i = 0; i < texts.length; i++) {
                const elem = texts[i];
                let rect = document.createElementNS(svgns, "rect");
                const bounds = elem.getBBox();
                const {x, y, width, height} = bounds;
                const fill = elem.getAttribute('fill');
                rect.setAttribute('class', `indicator-container`);
                rect.setAttribute("x", `${x - xValueIndicator / 2}`);
                rect.setAttribute("y", `${y - yValueIndicator / 2}`);
                rect.setAttribute("rx", `${roundIndicator}`);
                rect.setAttribute("ry", `${roundIndicator}`);
                rect.setAttribute('stroke', `${fill}`);
                rect.setAttribute('stroke-width', `${strokeWidthIndicator}`);
                rect.setAttribute('border', `1px solid ${strokeColorIndicator}`);
                rect.setAttribute('fill', `${strokeBGColorIndicator}`);

                rect.setAttribute('text-anchor', "middle");
                rect.setAttribute('transform', `scale(1,-1)`);

                rect.setAttribute("width", `${width + xValueIndicator}`);
                rect.setAttribute("height", `${height + yValueIndicator}`);

                const filter = elem.getAttribute('group-item-data');
                rect.setAttribute("group-item-data", `${filter}`);
                elem.parentNode.insertBefore(rect, elem);
            };
        };
    };

    const clearTotalIndicatorContainer = () => {
        if (svgRef.current) { //Clear all
            const removeItems = svgRef.current.querySelectorAll(".indicator-total-text");
            removeItems.forEach((element) => { element.remove(); });
        };
    };

    const makeTotalIndicatorContainer = () => {
        if (svgRef.current) {
            clearTotalIndicatorContainer();
            const elements = svgRef.current.querySelectorAll('[group-x-data]');
            const result = [];

            elements.forEach((element) => {
                const groupXData = element.getAttribute('group-x-data');
                const hasHideAttr = element.getAttribute('isHide') === "1";
                const sum = parseInt(element.getAttribute('sum') || '0', 10);
                const existingData = result.find((data) => data.group === groupXData);
                //const isHidden = element.style.display === 'none';
                const isHidden = element.style.display === 'none' || hasHideAttr;
                if (!isHidden) {
                    if (!existingData || sum > existingData.sum) {
                        result.push({
                            x: parseFloat(element.getAttribute('x') || '0'),
                            y: parseFloat(element.getAttribute('height') || '0'),
                            sum,
                            group: element.getAttribute("group-x-data") || ""
                        });
                    };
                };
            });

            const g = document.createElementNS(svgns, 'g');
            for (let i = 0; i < result.length; i++) {
                const {x, y, sum} = result[i];
                g.setAttribute('transform', `scale(1,-1) translate(0,-${lineBaseYBottom})`);
                //Total Value indicator
                const text = document.createElementNS(svgns, 'text');
                text.setAttribute("x", `${x + barWidth / 2}`);
                text.setAttribute("y", (-1 * (y + totalPosY)).toString());

                // text.setAttribute('fill', `${circle.getAttribute('fill')}`)
                text.setAttribute('font-size', `${indicatorSize}rem`);//use rem , don't resize by svg
                text.setAttribute('text-anchor', "middle");
                text.setAttribute('transform', `scale(1,-1)`);
                text.setAttribute('class', `indicator-total-text`);

                text.innerHTML = `${sum}`;
                g.appendChild(text);
            };
            svgRef.current.appendChild(g);
        };
    };

    const renderBarData = () => {
        if (svgRef.current) {
            const g = document.createElementNS(svgns, 'g');
            g.setAttribute('transform', `scale(1,-1) translate(0,-${lineBaseYBottom})`);

            dataStyle.bar.forEach(bar => {
                const uuid = getUUID();
                // const clipPath = document.createElementNS(svgns, 'clipPath')
                // clipPath.setAttribute('id', `${uuid}`)
                // const rectTextContainer = document.createElementNS(svgns, 'rect');
                const rect = document.createElementNS(svgns, 'rect');
                let isHide = 0;
                bar.forEach(style => {
                    rect.setAttribute(style[0], style[1]);
                    // rectTextContainer.setAttribute(style[0], style[1]);
                    if (style[0] === "isHide") isHide = (style[1] === "1");
                })
                // clipPath.appendChild(rectTextContainer)
                // g.appendChild(clipPath)
                g.appendChild(rect);
                const _hh = rect.getAttribute("height");
                const hh = _hh ? (parseFloat(rect.getAttribute("height")) - dataPosY) : 0;
                //data
                const text = document.createElementNS(svgns, 'text');
                text.setAttribute('transform', `scale(1,-1)`);
                // text.setAttribute('clip-path', `url(#1)`)
                text.setAttribute("x", (parseFloat(rect.getAttribute('x')) + barWidth / 2).toString());
                //text.setAttribute("y", `-${parseFloat(rect.getAttribute("height")) - dataPosY}`);
                text.setAttribute("y", `${-hh}`);
                text.setAttribute("text-anchor", "middle");
                text.setAttribute("group-item-data", `${rect.getAttribute("group-item-data")}`);
                text.setAttribute("font-size", `${titleSize}`);
                // text.setAttribute("alignment-baseline", "middle");
                text.setAttribute("fill", "white");
                text.innerHTML = rect.getAttribute('value') || "";
                if (isHide) text.style.display = "none";
                g.appendChild(text);
            })
            svgRef.current.appendChild(g)
        }
        makeTotalIndicatorContainer();
    };

/*    
    const testAxisY = (value) => {
        if (svgRef.current) {
            const g = document.createElementNS(svgns, 'g');
            g.setAttribute('transform', `scale(1,-1) translate(0,-${lineBaseYBottom})`);
            const line = document.createElementNS(svgns, 'line');
            grid.map(style => { if (style[0] !== "stroke") { line.setAttribute(style[0], style[1]); } })
            const position = value / maxValue * valueRangeSize
            line.setAttribute("y1", `${position}`);
            line.setAttribute("y2", `${position}`);
            line.setAttribute("stroke", `red`);
            g.appendChild(line);
            svgRef.current.appendChild(g);
        }
    };
*/

    const handleShowData = (action) => {
        clearTotalIndicatorContainer();
        const {key = "group-item-data", value = []} = action;
        if (svgRef.current) {
            const elements = svgRef.current.querySelectorAll(`[${key}]`);
            elements.forEach(element => {
                if (value.length) {
                    const eleAttr = element.getAttribute(key);
                    if (eleAttr && value.includes(eleAttr)) {
                        element.style.display = '';
                    } else {
                        element.style.display = 'none';
                    }
                } else {
                    // element.style.display = '';
                    element.style.display = 'none';
                }
            });
        }
        makeTotalIndicatorContainer();
    };

    useImperativeHandle(ref, () => ({ handleShowData }));

    useEffect(() => {
        //console.log("ChartLineBar redraw");
        emptyContent();
        renderGrid();
        renderReportType();
        renderXAxis();
        renderYAxis();

        lineBarType === LineBarType.LINE && renderLineData();
        lineBarType === LineBarType.BAR && renderBarData();

        // testAxisY(3)
    }, [UILang, svgRef.current]);

    return <svg viewBox={`0,0,${lineBaseXRight},${lineBaseYBottom + yAxisMargin * 1.5}`} ref={svgRef}/>
};

export default forwardRef(ChartLineBar);
//export default ChartLineBar;