import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import priorities from '@ckeditor/ckeditor5-utils/src/priorities';
import CpCkAnswerCommand from './CpCkAnswerCommand';
import * as FIBC from "../../../consts/ATQtnAnsTypeFIB";
import { deepCopy } from '../../AppUtil';

const colorText = '#0C8CE9';
const colorDD = 'green';
export default class CpCkAnswer extends Plugin {
    static get pluginName() { return 'cpCkAnswer'; };

    init() {
        
        this.config = this.editor.config.get('cpCkAnswer') || {};
        this._setFunc();        
        this._addCommands();
        this._addViews();
        this._addSchema();
        this._addConverters();
        this._addObserver();
        this._setInitData();
    };

    _setInitData() {
        this.ansArr = [...this.getAnsArr()];
        this.keyIndex = this.getKeyIndex();

        this.redoFlag = false;
        this.undoFlag = false;

        this.firstInit = true;
        this.hasDrop = false;

        this.upcastFlag = false;
    };

    _setFunc() {
        if (typeof this.config.onClick === 'function') {
            this.onClick = this.config.onClick;
        } else this.onClick = () => console.error("onClick function not presented");

        if (typeof this.config.newAnswer === 'function') {
            this.newAnswer = this.config.newAnswer;
        } else this.newAnswer = () => console.error("newAnswer function not presented");
        
        if (typeof this.config.getAnsArr === 'function') {
            this.getAnsArr = this.config.getAnsArr;
        } else this.getAnsArr = () => console.error("getAnsArr function not presented");   
        
        if (typeof this.config.allDone === 'function') {
            this.allDone = this.config.allDone;
        } else this.allDone = () => console.error("allDone function not presented");   
                
        if (typeof this.config.getKeyIndex === 'function') {
            this.getKeyIndex = this.config.getKeyIndex;
        } else this.getKeyIndex = () => console.error("getKeyIndex function not presented");          

        if (typeof this.config.updateQAnswers === 'function') {
            this.updateQAnswers = this.config.updateQAnswers;
        } else this.updateQAnswers = () => console.error("updateQAnswers function not presented");          

    };

    _addCommands() {
        const { editor } = this;
        editor.commands.add('exec_cpCkAnswer', new CpCkAnswerCommand(editor));
    };

    _addViews() {
        const { editor } = this;
        //const t = editor.t;
        const uit = this.config.uit;        

        const answerNames = [ 'Text', 'Dropdown'];

        editor.ui.componentFactory.add( 'cpCkAnswer', locale => {
            const dropdownView = createDropdown( locale );

            addListToDropdown( dropdownView, this.getDropdownItemsDefinitions( answerNames ) );

            dropdownView.buttonView.set( {
                label: '+ ' + uit('fib-editor.add-a-input'),//,t( '+ Add a blank' ),
                //icon: oupIcon,
                tooltip: true,
                withText: true
            } );

            const command = editor.commands.get( 'exec_cpCkAnswer' );
            dropdownView.bind( 'isEnabled' ).to( command );

            this.listenTo( dropdownView, 'execute', evt => {
                editor.execute( 'exec_cpCkAnswer', {
                    command: 'insert',
                    value: evt.source.commandParam,
                    createAnswer: this._createAnswer,
                });
                editor.editing.view.focus();
            } );

            return dropdownView;
        } );
    };

    getDropdownItemsDefinitions( answerNames ) {
        const itemDefinitions = new Collection();
        const uit = this.config.uit;
    
        for ( const name of answerNames ) {
            const definition = {
                type: 'button',
                model: new Model( {
                    commandParam: name,
                    label: uit(name==='Text'?'fib-editor.text.blank':'fib-editor.text.dropdown'),//name,
                    withText: true
                } )
            };
    
            itemDefinitions.add( definition );
        }
    
        return itemDefinitions;
    }

    _createAnswer(writer,  qtype = FIBC.__FIBT_TXT, oupansid = -1, qvalue = "new value", qindex = 0) {
        const answer = writer.createElement('oupanswer', { oupansid: oupansid, qtype: qtype, qvalue: qvalue, qindex: qindex });
        writer.appendText(' 0 ', { fontColor: 'white', fontBackgroundColor: (qtype===FIBC.__FIBT_TXT)?colorText:colorDD}, answer);
        writer.appendText(' ' + qvalue + ' ', { fontColor: 'black' }, answer);
        return answer;
    };

    _addSchema() {
        const { schema } = this.editor.model;

        schema.register('oupanswer', {
            allowWhere: '$text',
            isObject: true,
            isInline: true,
            allowAttributes: ['oupansid', 'qtype', 'qvalue', 'qindex'],
        });
    };

    _addConverters() {
        const conversion = this.editor.conversion;
        conversion.for('editingDowncast').elementToElement({
            model: {
                name: 'oupanswer',
            },
            view: (modelElement, { writer }) => {
                const oupansid = modelElement.getAttribute('oupansid');
                const qtype = modelElement.getAttribute('qtype');
                const qvalue = modelElement.getAttribute('qvalue');
                const qindex = modelElement.getAttribute('qindex');    
                
                const ans = writer.createContainerElement(
                    'span', {
                        class: 'oupanswer',
                        oupansid: oupansid,
                        qtype: qtype,
                        qvalue: qvalue,
                        qindex: qindex,
                    },
                );      
                return toWidget(ans, writer);
            },
        });

        // Add a converter for editing downcast pipeline.
        conversion.for( 'editingDowncast' ).add( dispatcher => {
            dispatcher.on( 'attribute:qindex:oupanswer', ( evt, data, conversionApi ) => {
                if ( !data.attributeOldValue || !data.attributeNewValue ) {
                    return;
                }
                
                const vv = conversionApi.mapper.toViewElement(data.item);
                if (vv) {
                    
                    conversionApi.writer.setAttribute('oupansid',data.item.getAttribute("oupansid"),vv);
                    conversionApi.writer.setAttribute('qindex',data.item.getAttribute("qindex"),vv);                    
                    conversionApi.writer.setAttribute('qtype',data.item.getAttribute("qtype"),vv);                    
                };
            } );
        } );        


        conversion.for('dataDowncast').elementToElement({
            model: {
                name: 'oupanswer',
            },
            view: (modelElement, { writer }) => {
                const oupansid = modelElement.getAttribute('oupansid');
                const qtype = modelElement.getAttribute('qtype');
                const qvalue = modelElement.getAttribute('qvalue');
                const qindex = modelElement.getAttribute('qindex');  
                
                const ans = writer.createContainerElement(
                    'span', {
                        class: 'oupanswer',
                        oupansid: oupansid,
                        qtype: qtype,
                        qvalue: qvalue,
                        qindex: qindex,
                    },      
                );      
                return ans;
            },
        });
        
        conversion.for('upcast').elementToElement({
            view: {
                name: 'span',
                classes: ['oupanswer'],
            },
            model: (viewElement, { writer }) => {
                let oupansid = -1, qtype = "", qvalue = "", qindex=0;
                if (viewElement.childCount > 0) {
                    oupansid = viewElement.getAttribute('oupansid');
                    qtype = viewElement.getAttribute('qtype');
                    qvalue = viewElement.getAttribute('qvalue');
                    qindex = viewElement.getAttribute('qindex');
                    
                };
                //this.upcastFlag = true;
                
                return this._createAnswer(writer, qtype, oupansid, qvalue, qindex);
            }
        });
    };

    isDrop() {
        return this.hasDrop;
    };

    _addObserver() {
        const editor = this.editor;
        editor.on('ready', event => {
        });

        editor.model.document.on( 'change:data', event => {
            this.onChange();}
            , { priority: 'high' });

        editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(editor.model, 
                (viewElement) => viewElement.hasClass('oupanswer')),
        );
      
        this.listenTo(editor.editing.view.document, 'click', (evt, data) => {
            if (data.domEvent.detail === 1 || data.domEvent.detail === 2) {
                if (editor.isReadOnly) return;
                const result = this.checkifoupanswer(data);
                if (result.pID) {
                    
                    const modelElement = editor.editing.mapper.toModelElement(result.targetEle);
                    this.onClick(editor,parseInt(result.pID),modelElement.getAttribute('qindex'));
                    evt.stop();
                };
            };
        }, { priority: priorities.get('highest')+10 });    
        
        editor.editing.view.document.on('drop', ( evt, data ) => {
            
            
            this.hasDrop = true;
            /*
            const result = this.checkifDrop(data);
            if (result) {
                this.onChange(0,0,1);
                evt.stop();
            };
            */
        }, { priority: 'high' } );        
    };

    checkifoupanswer(data) {
        let pID = undefined, targetEle = undefined;
        if (data.target.getAttribute('oupansid')) {
            // widget
            pID = data.target.getAttribute('oupansid');
            targetEle = data.target;
        } else if (data.target.parent && data.target.parent.getAttribute('oupansid')) {
            // embedded text
            pID = data.target.parent.getAttribute('oupansid');
            targetEle = data.target.parent;
        } else if (data.target.name === 'img' && data.target.parent && data.target.parent.parent &&
            data.target.parent.parent.getAttribute('oupansid')) {
            // embedded math equation
            pID = data.target.parent.parent.getAttribute('oupansid')
            targetEle = data.target.parent.parent;
        };
        return {pID, targetEle};
    };

    // call by outside
    // ansData = {oupansid, qtype, qvalue}
    updateAnswer(ans=undefined, deleteID=undefined) {
        
        this.onChange(ans, deleteID);
    };
    
    doBeforeClose() {
        this.editor.model.change(writer => {
            const root = this.editor.model.document.getRoot();
            const range = writer.createRangeIn(root);
            
            let pEle = [];

            for (const value of range.getWalker({ ignoreElementEnd: true })) {
                if (value.item.name === 'oupanswer' && value.type === 'elementStart') {
                    pEle.push(value.item);
                };
            };

            this.ansArr = this.ansArr.filter((item)=>{
                return (pEle.findIndex((kk)=>{
                    return (parseInt(kk.getAttribute("oupansid")) === item.oupansid)}) >= 0)
            });
            this.updateQAnswers(this.ansArr);
            pEle = [];
        });
    };

    getAnsVal(oupansid) {
        if (oupansid >= 0) {
            //const index = this.ansArr.findIndex((obj)=>{return obj.oupansid === oupansid});
            //const correctIndex = data.qvalue.findIndex((item)=>{return (item.correct?(item.correct):false)});
        };
        return '';
    };

    onChange(ans=undefined, deleteID=undefined) {
        this.editor.model.change(writer => {
            const root = this.editor.model.document.getRoot();
            const range = writer.createRangeIn(root);
            let pEle = [];

            for (const value of range.getWalker({ ignoreElementEnd: true })) {
                if (value.item.name === 'oupanswer' && value.type === 'elementStart') {
                    pEle.push(value.item);
                };
            };

            if (deleteID) {
                let index = this.ansArr.findIndex((obj)=>{return obj.oupansid === deleteID});
                
                //if (index >=0) this.ansArr[index].deleted=true;
                index = pEle.findIndex((obj)=>{return parseInt(obj.getAttribute("oupansid")) === deleteID});
                
                if (index >=0) writer.remove(pEle[index]);
                return;
            };

            if (ans) {
                const index = this.ansArr.findIndex((obj)=>{return obj.oupansid === ans.oupansid});
                if (index >=0) {
                    this.ansArr[index]=deepCopy(ans);
                };
            };
          
            let liveCnt = 0;
            this.ansArr.forEach((item)=>{
                if (!item.deleted) liveCnt++;
                item.deleted = (pEle.findIndex((kk)=>{
                    return (parseInt(kk.getAttribute("oupansid")) === item.oupansid)}) < 0) ? true:false;
            });

            /*
            let liveCnt = 0;
            this.ansArr.forEach((item)=>{
                if (pEle.findIndex((kk)=>{
                    
                    return (parseInt(kk.getAttribute("oupansid")) === item.oupansid)}) < 0) {
                    item.deleted = true }
                else {item.deleted = false; liveCnt++;}
            });
            */
            
            let occupiedID = "";
            //console.log({ele:pEle.length, liveCnt, ansArr:this.ansArr});
            //if (this.hasDrop || pEle.length !== this.ansArr.length || deleteID || ans || this.firstInit) {
            if (this.upcastFlag || this.hasDrop || pEle.length !== liveCnt || deleteID || ans || this.firstInit) {
                pEle.forEach((item, ii)=>{
                    let id = parseInt(item.getAttribute("oupansid"));
                    let qt = item.getAttribute("qtype");
                    let data = undefined;
                    
                    
                    if (id === -1) { 
                        data = this.newAnswer(++this.keyIndex,qt==='Text'?FIBC.__FIBT_TXT:FIBC.__FIBT_DRD);
                        this.ansArr.push(data);
                    } else data = this.ansArr.find((obj)=>{return obj.oupansid === id});

                    if (data !== undefined) {
                        if (occupiedID.includes("."+data.oupansid+".")) { 
                            
                            
                            
                            data = {...data, oupansid: ++this.keyIndex, qtype:data.qtype,
                                qvalue:data.qvalue.map((obj)=>{return {...obj}})
                            };
                            this.ansArr.push(data);
                        } else {
                            occupiedID = occupiedID + "."+data.oupansid+".";
                        };

                        
                        writer.setAttribute('oupansid',data.oupansid, item);
                        writer.setAttribute('qtype',data.qtype, item);
                        writer.setAttribute('qvalue',"", item);
                        
                        writer.setAttribute('qindex',ii+1, item);

                        if (item.childCount > 0) {
                            for (let zz=item.childCount-1;zz>=0;zz--) {
                                const child = item.getChild(zz);
                                
                                writer.remove(child);
                            };                        
                        };

                        writer.appendText(' '+(ii+1)+' ', {fontColor: 'white',
                            fontBackgroundColor:data.qtype===FIBC.__FIBT_TXT?colorText:colorDD}, item);
                        
                        
                        const correctIndex = data.qvalue.findIndex((item)=>{return (item.correct?(item.correct):false)});
                        const ansStr = (correctIndex<0)?removePTag(data.qvalue[0].data):removePTag(data.qvalue[correctIndex].data);
                        if (ansStr !== "") {
                            const vf = this.editor.data.processor.toView(ansStr);
                            
                            const mf = this.editor.data.toModel( vf );
                            
                            writer.append(mf, item);
                            
                            
                            if (item.getChild(1)) writer.unwrap(item.getChild(1));
                        };
                    } else console.error("ans id not found: ", id);
                }); 
            };
            
            this.allDone(this.firstInit?0:this.ansArr, this.keyIndex);
            this.firstInit = false;
            this.hasDrop = false;
            this.upcastFlag = false;
            pEle = [];
        });
    };    
};

const removePTag = (str) => {return str.replace(/<\/?p[^>]*>/g, "")};

