import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Button} from "react-bootstrap";

import { useUILang, useCtnLang, isEn2Lang } from "../../AppExPf/utils/useUILang";
import { CpExerciseCard } from "../EPExercise/CpExerciseCard";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpPagination from "../_components/CpPagination";

import ExerciseEditContentDemoQuestion from "../../poc/screens/pages/included/exercise/tab/ExerciseEditContentDemoQuestion";
import { Button0, stopEvent } from "../../libs/libUI";
import { ReduxBind } from "../../saga/ReduxState";
import { LayerExerFilterQ, clearFindQFields } from "./CpSearchQuestionContainer";
import { isAry, objEntries, toAry, toObj, toStr } from "../../libs/libType";
import { toUniIdAry } from "../../consts/ATValidate";
import { usePaging } from "../../AppExPf/utils/ATPaging";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { useQtnCache } from "../../AppExPf/utils/useQtnCache";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { ViewExCtn } from "../../AppExPf/ATExerEdit/TabExerContent";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { preJS } from "../../AppExPf/AppUtil";
import { exerQIds, exerUniQIds } from "../../consts/ATValidateExer";

export const CpSearchQuestionContentContainer = ReduxBind(props => { //base on POC SearchQuestionContentContainer
    const {dispatch, poolSchool, poolMy, poolOup, exlikes, useFs, addedQIds, onAddQ, clickFindQ, showEn } = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts] = useFs;

    const [qtns, useCacheQtns] = useQtnCache(dispatch, 0);
    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    const [listExs, setListExs] = useState();

    const [cur, setCur] = useState();

    const listQ = poolOup;
    const listEx = poolSchool || poolMy;

    const o = toObj(opts);

    const pickEx = idx => setCur(idx);
    const ex = toAry(listExs)[cur];

    const Ids = listQ? o.QIds: o.EIds;
    const IdCount = toAry(Ids).length;
    const paging = usePaging(IdCount, 50, 1); 
    const { page, rpp, totalRow, totalPage, res1, res2, setPaging } = paging;
    useEffect(() => { setPaging({page:1}); }, [Ids]);
    
    useEffect(() => {
        if(!listQ) loadEx();
    }, [listQ, Ids, res1, res2]);


    const QIds = useMemo(() => toAry(o.QIds).slice(res1-1, res2), [res1, res2, o.QIds]);
    const Qs = useCacheQtns(QIds); 
    const listQs = useMemo(() => listQ && QIds.filter(i => Qs[i]), [listQ, QIds, qtns])

    const eqids = exerUniQIds(ex);
    useCacheQtns(eqids); 

    const loadSortEs = async () => {
        const EIds = toAry(o.EIds).slice(res1-1, res2);
        if(!EIds.length) 
            return [];
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getExers', { EIds, caller: 'FindQ' });
        const EMap = Object.fromEntries(toAry(res?.exers).filter(e => e && e.EId).map(e => [e.EId, e]));
        return EIds.map(i => EMap[i]).filter(e => e);
    }
    const loadEx = async () => setListExs(await loadSortEs());

    const dataExers = toAry(listExs); //t("dummy.exercise.card", {returnObjects: true}) || [];
    
    const clickClearFindQOpts = e => { stopEvent(e); clearFindQFields(setFields, fields); };
    
    const listRef = useRef(null);
    const handleRoll = (e, px) => { stopEvent(e);
        if (listRef.current) {
            listRef.current.scroll({ left: listRef.current.scrollLeft + px, behavior: "smooth" });
        };
    };
    const handleLeft = e => handleRoll(e, -200);
    const handleRight = e => handleRoll(e, 200);

    const cardProps = {qtns, useCacheQtns, tagMSets, useTagMSets, mini:1, cur, pickEx, ...exlikes};

    const leftExFilter = () => (//renderLeftContainer_DEFAULT_QUESTION_POOL 
    <div className={"d-flex flex-column justify-content-between h-100 overflow-hidden"}>
        <div className={"overflow-auto h-100 d-none d-md-block"}> <LayerExerFilterQ {...{...props}} /></div>
        <div className={"d-none d-md-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100 pt-3"}>
            <Button variant="gray-body-color w-100 border" onClick={clickClearFindQOpts}>{t("clear")}</Button>
            <Button variant={`exercise-third-btn-hover-light text-exercise-third-btn btn-block w-100`} onClick={clickFindQ}>{t("search")}</Button>
        </div>
    </div>);

    const leftExList = () => ( //renderLeftContainer__SCHOOL_SHARED_EXERCISES__Pool_MY_EXERCISES = () => (
    <span className={"d-flex flex-md-column gap-2 gap-md-0 w-100"}>
        <span role={"button"} onClick={handleLeft} className={"d-flex d-md-none fs-6 align-items-center"}><CpIco src={IconList.general.scrollLeft}/></span>
        <span className={"d-flex flex-md-column gap-2 gap-md-2 w-100 flex-grow-1 overflow-hidden pb-lg-3"} ref={listRef}>
            {dataExers.map((ex, idx) =>
                <CpExerciseCard key={idx} {...{...cardProps, ex, idx}} />)}
        </span>
        <span role="button" onClick={handleRight} className={"d-flex d-md-none fs-6 align-items-center"}><CpIco src={IconList.general.scrollRight}/></span>
    </span>);

    const poolInfo = {poolSchool, poolMy, poolOup};
    const pvProps = {...props, ex, dispatch, useCacheQtns, addedQIds, qFilters:fields, onAddQ, 
        showEn, hasEn:showEn, hasCt:!showEn, isAT:0, mini:1, lock: 1, 
        qtns, useCacheQtns, mediaDLs, getMediaDLs, tagMSets, useTagMSets,
        PVMode:2, showQNo:0,
        insertBlock:noop, replaceContent:noop, clickDelI:noop, clickMoveI:noop, clickInsI:noop};    
    
    const renderPreviewEx = () => {
        const ECtns = ex?.ECtns || [];
        let QIndex = 0;
        const result = [];
        {ECtns.forEach((ECtn, idx) => {
            if (ECtn.type !== _ExCtType_Qtn) return;
            const eq = qtns[toUniIdAry(toStr(ECtn?.QIds).split(','))];
            if(!(eq && eq.QId)) return;
            ++QIndex;
            result.push(
            <div key={QIndex} className='flexRowCenter' id={'content-id-'+idx}>
                <ViewExCtn key={'vec'+QIndex} isAT={0} {...{...pvProps, idx, displayIdx:QIndex, ECtn}} />
            </div>);
        })};
        return <div className='flexColStartFit p4'>{result}</div>;
    };

    const renderPreviewQ = () => {
        return <div className='flexColStartFit p4'>{ toAry(listQs).map( (QId, idx) => {
            return <div key={idx} className='flexRowCenter' id={'content-id-'+idx}>
                <ViewExCtn key={'vec'+QId} {...{...pvProps, idx, displayIdx:idx+1, ECtn: {type: _ExCtType_Qtn, QIds: QId} }} />
            </div>;
        })}</div>;
    };

    const renderRightContainer =  <div key={cur} className="table-preview-container d-flex flex-column h100">
        <div className="fxGrow border border-2 rounded mx-3 ms-lg-0 me-lg-3 overflow-auto border-exercise-third-btn">
            {listEx? renderPreviewEx(): renderPreviewQ()}
            <div className="clearboth"></div>
        </div>
        {poolOup && <div className={"bg-dim-100"}><CpPagination key='oupPage' {...{...poolInfo, miniLayout:true, paging}} /></div>}
        {listEx && <div className={"bg-dim-100 mt-3"}><CpPagination key='schoolPage' {...{...poolInfo, miniLayout:true, paging}} /> </div>}
    </div>;

    const render1MiniCard = <CpExerciseCard item={dataExers[0]}  {...{...cardProps, ex, nano:1, idx:1,  }} />
    return <><div className={`table-data-container d-flex flex-column flex-md-row gap-2 ${(listEx)? "mt-3": "px-3 my-3"}  flex-grow-1 overflow-hidden`}>
        <div className={`table-list-container ${(listEx)? "search-list-container px-3": ""} d-flex flex-column gap-2 gap-md-3`}>
            {listEx? leftExList(): leftExFilter()}
            {ex? <span className={`${(listEx)? "d-lg-none": "d-none"} `}>{render1MiniCard}</span>: ''}
        </div>
        {renderRightContainer}
    </div></>;
});
export default CpSearchQuestionContentContainer;

const noop = ()=>{};
const PreviewExercise = (props) => {
    const dummyContents = [];
    return <><div className={"d-flex flex-column h-100 flex-grow-1 border-top position-relative overflow-auto p-3"}>
        {dummyContents.map(item => <ExerciseEditContentDemoQuestion content={item}
            key={item.id} type={"addQuestion"} filterContentType={["question"]}/>)}
    </div></>
};
