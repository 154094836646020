import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpActionButton from "../_components/CpActionButton";
import { ReduxBind } from "../../saga/ReduxState";
import { LoadIco } from "../../AppExPf/AppUtil";


const CpTeacherDownloadExcelCTA = (props => { // base on poc TeacherDownloadExcelCTA.tsx
    const { clickDL } = props;
    const [ t ] = useUILang();
    
    return <><CpActionButton title={t("download-excel")} iconPath={IconList.file.excel} hiddenText={true}
            className={`CpTeacherDownloadExcelCTA btn excel-btn text-nowrap gap-2 rounded-light semi-bold user-select-none`}
            onClick={clickDL}>
        </CpActionButton>
        <Modal show={false} className={"justify-content-center"} backdrop="static"
               centered dialogClassName="loading-modal">
            <div className={"loading-animate bg-white rounded-light border shadow"}>{LoadIco('60px')}</div>
        </Modal>
    </>
});

export default CpTeacherDownloadExcelCTA;