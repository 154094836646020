import {useState} from "react";
import { getChartColor } from "../../EPReport/ChartTypeConst";

const Indicator = (props) => {
    const {xItem: {id, title}, index, onClick, ...btnProps} = props;
    const [active, setActive] = useState(true);

    const color = getChartColor(index);
    const handleActive = (e) => {
        setActive(prev => !prev);
        if (onClick) { onClick(e); }
    };

    return <span role={"button"} className={`indicator px-3 py-1 rounded-pill user-select-none ${active ? "" : "border"}`}
        style={{border: (active ? `1px ${color} solid` : "")}}
        group-item-data={`${id}`} onClick={handleActive} {...btnProps} >
        <span className={"ind-color"} style={{backgroundColor: color}}></span>
        <span className={"ind-text semi-bold"}>{title}</span>
    </span>
};

const CpLineBarIndicator = (props) => { // base on poc LineBarIndicator
    const {xItems, ...btnProps} = props;
    //return <div className={`progress-by-month-indicator-container ${xItems.length ? "pt-3 pt-md-0 " : "pt-4"}`}></div>
    return <div className={`CpLineBarIndicator progress-by-month-indicator-container ${xItems.length ? "py-3 py-md-0 " : "py-3"}`}>
        {xItems.map((xItem, idx) => <Indicator key={xItem.id} index={idx} xItem={xItem} {...btnProps} />)}
    </div>
};

export default CpLineBarIndicator;