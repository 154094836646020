import React, {useMemo} from "react";
import {Button} from "react-bootstrap";
import CpIco from "../../_components/CpIco";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import { IconList } from '../../../consts/ATIconListToUsePoc';
import ChartDividedBar from "./ChartDividedBar";
import CpTips from "./CpTips";
import { CpSortingIcon } from "../../EPReport/TabPerformanceDetailChart";
import { EPUITime3 } from "../../_components/CpEpTabHead";
import { preJS } from "../../../AppExPf/AppUtil";
import { objEntries, toAry, toFloat } from "../../../libs/libType";
import { strCmpTrimLow } from "../../EPAssign/TabTeacherAssignmentStatus";
import { ReduxBind } from "../../../saga/ReduxState";
import { stopEvent } from "../../../libs/libUI";
import { urlPush_Replace } from "../../../saga/urlPush.saga";
import { _pathAssign } from "../../../consts/ATConstReact";
import { expfRoles } from "../../../saga/auth.saga";
import { getTransMap } from "../../../libs/libTransMap";

const ChartAssignmentProgress = ReduxBind(props => { //poc AssignmentProgressChart
    const [isThr, isStt, uRole ] = expfRoles(props);
    const myUId = props.userId();

    const {dispatch } = props; 
    const [t, uiEn, UILang, setUILang, ut, t2, t3] = useUILang();
    const [ sorts, setSorts, addSort, flipSort] = props.useSL;
    const {type} = props;

    const allC = type === "a"; //targetType === ReportTargetType.ALL_CLASSES;
    const selC = type === "sc"; //targetType === ReportTargetType.SELECTED_CLASS;
    const setStu = type === "s" ; //targetType === ReportTargetType.SELECTED_STUDENT;
    const _data = props.data;
    const data = useMemo(() => sortData(_data, sorts, allC, selC, setStu ), [_data, sorts, type]);
    
    if (!(allC || selC || setStu))  return <></>;
    
    const AsmProgRows = () => { return <>{data.map((d, idx) => {
        const {
            id, language, title, assignedBy, className, teacherId, 
            resultPublish, submitted,
            scoreRange, averageScore, passingScore,
            APass, passP, submit, avgMp, 
        } = d;

        //const highestStatus = countMainState(scoreRange);
        const highestStatus = submit? (APass? ((avgMp >= passP)? 'p': 'f') :'a'): '';

        const myA = ((!isThr) || (myUId === teacherId)); 

        const clickAsmRpt = myA && (e => {stopEvent(e); dispatch(urlPush_Replace(_pathAssign(id, 'report'))); });
        return <tr key={idx} className="AsmProgRow">
            <td><span className={"d-flex gap-2 align-items-start"}>
                <span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn"}
                    style={{width: "1.5rem", height: "1.5rem"}}>
                    <CpIco src={IconList.language[language]}/>
                </span>
                <span>{title}</span>
            </span></td>
            <td>{assignedBy}</td>                    
            {(allC) && <td>{className}</td>}
            {(allC || selC) && <td className={"text-nowrap"}>{
                EPUITime3(resultPublish) //convertFromTimeStamp(resultPublish, "YYYY/MM/DD hh:mm")
            }</td>}
            {(allC || selC) && <td>{submitted?.value} / {submitted?.total}</td>}
            <td><ChartDividedBar segments={scoreRange} averageScore={averageScore} passingScore={passingScore}
                indicatorOutside={true} /></td>
            {(setStu) && <td className={`text-status-${scoreRange[0].status}`}>{scoreRange[0].maxPercentage}%</td>}
            {(allC || selC) && <td className={`text-status-${highestStatus}`}>{averageScore.percentage}%</td>}
            <td>{myA? <Button variant="gray-body-color fs-6" onClick={clickAsmRpt}><CpIco src={IconList.general.search}/></Button> :''}</td>
        </tr>
    })}</>};

    const CpTableHeaderSortingButton = (props) => { //TableHeaderSortingButton 
        const { sortKey } = props;
        const { title, type, tips} = props
        const onClick = sortKey? flipSort(sortKey): undefined;
        return <span onClick={onClick} role={sortKey? "button": "none"} 
            className="CpTableHeaderSortingButton d-flex justify-content-center align-items-center gap-2 user-select-none" >
            {title}
            {tips? <CpTips>{tips}</CpTips>: ''}
            {(sortKey)? <CpSortingIcon {...{sorts, addSort, flip:flipSort, sortKey}} />: ''}
        </span>
    };

    //{preJS(data, 3)}   
    return <div className={"ChartAssignmentProgress flow-container scroll-padding"}>
        {false && preJS({...sorts, type})}
        <table className={"report report-table-progress-chart semi-bold"}><thead><tr>
            <th className={"col-assignment"}>
                <span><CpTableHeaderSortingButton {...{title:t(getTransMap("report-assignment",isStt)), type:"report-assignment", sortKey:'title'}} /></span>
            </th>
            <th className={"col-assigned-by"}>
                <span><CpTableHeaderSortingButton {...{title:t(getTransMap("report-assigned-by",isStt)), type:"report-assigned-by", sortKey:'assignedBy'}} /></span>
            </th>
            {(allC) && <th className={"col-class"}>
                <CpTableHeaderSortingButton {...{title:t("report-class"), type:"report-class", sortKey:'className'}} />
            </th>}
            {(allC || selC) && <th className={"col-result-publish"}>
                <CpTableHeaderSortingButton {...{title:t("report-result-publish"), type:"report-result-publish", sortKey:'resultPublish'}} />
            </th>}
            {(allC || selC) && <th className={"col-submitted"}>
                <CpTableHeaderSortingButton {...{title:t("report-submitted"), type:"report-submitted", tips:'', sortKey:'' }} />
            </th>}
            {setStu 
                ?<th className={"col-score-percentage"} colSpan={2} >
                    <CpTableHeaderSortingButton {...{title:t("report-score-percentage"), type:"report-score-percentage", sortKey:'avgMp'}} />
                </th>
                :<th className={"col-score-percentage"}>
                    <CpTableHeaderSortingButton {...{title:t("report-score-percentage"), type:"report-score-percentage", sortKey:'' }} />
                </th>
            }
            {(allC || selC) && <th className={"col-avg-score-percentage"}>
                <CpTableHeaderSortingButton {...{title:t("report-avg-score-percentage"), type:"report-avg-score-percentage", sortKey:'avgMp' }} />
            </th>}
            <th className={"col-action"}>{t("report-action")}</th>
        </tr></thead>
            <tbody>{AsmProgRows()}</tbody>
        </table>
    </div>;
});
export default ChartAssignmentProgress;

const countMainState = (scoreRange) => {
    // Group the objects by status and sum their values
    const sumsByStatus = scoreRange.reduce((acc, curr) => {
        acc[curr.status] = (acc[curr.status] || 0) + curr.value;
        return acc;
    }, {});
    // Get the highest status by value
    const highestStatus = Object.entries(sumsByStatus).reduce((acc, curr) => {
        return curr[1] > acc.value ? {status: curr[0], value: curr[1]} : acc;
    }, {status: null, value: -Infinity}).status;
    return highestStatus;
};

const sort1 = (a, b, kv) => {
    if(!kv) return;
    const [key, so] = kv;
    const isAsc = so !== 'desc';
    
    //title, className resultPublish submitted maxMp avgMp
    if(['title','className','assignedBy'].includes(key)) return isAsc? strCmpTrimLow(a[key], b[key]): strCmpTrimLow(b[key], a[key]);
    if('avgMp' === key) return (isAsc? 1: -1) * (toFloat(a?.averageScore?.percentage) - toFloat(b?.averageScore?.percentage));
    return (isAsc? 1: -1) * (toFloat(a[key]) - toFloat(b[key]));
};

const sortData = (_data, sorts, allC, selC, setStu ) => {
    const ss = objEntries(sorts); 
    const ret = [...toAry(_data)];
    ret.sort( (a, b) => 
        sort1(a, b, ss[0]) || sort1(a, b, ss[1]) || sort1(a, b, ss[2]) || 
        sort1(a, b, ss[3]) || sort1(a, b, ss[4]) || sort1(a, b, ss[5])
    );
    
            /*ret.push({
            id: "TestID",
            language: "en",
            title: "Test",
            EName: "TEST MY Science Exercise 004",
            className: "1T",
            resultPublish: 1687316356472,
            datePub: 1687316356472,
            submit: 1,
            submitted: { value: 1, total: 3 },
            scoreRange: [{
                value: 1,
                maxPercentage: 52,
                minPercentage: 50,
                status: "f"
            }],
            maxP: 54.5,
            avgMp: 54.5,
            averageScore: { percentage: 54 },
            passingScore: { percentage: 60 }
        });*/

    return ret;
};
