import {Container} from "react-bootstrap";
import React, {useContext, useMemo, useState, useEffect} from "react";
import {
    DONUT_ATTEMPTED_COLOR,
    DONUT_AVG_LINE_COLOR,
    DONUT_CORRECT_COLOR,
    DONUT_DEFAULT_SEGMENT_COLOR,
    DONUT_INCORRECT_COLOR,
    DONUT_NO_ATTEMTED_COLOR,
    DONUT_PASSING_LINE_COLOR,
    DONUT_TEXT_COLOR, 
} from "./ChartTypeConst";
import { isEn2Lang, useCtnLang, useUILang, validLang } from "../../AppExPf/utils/useUILang";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpDropdown, { ent2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";
import { secondToHHMMSS, timeStampNowGMT } from "../../libs/libTime";
import { EPUITime3 } from "../_components/CpEpTabHead";
import { getT2bSrt, getT2bEnd } from "../EPAssign/CpAssignmentList";

import ChartDonut from "./ChartDonut";
import CpQuestionCorrectnesAndScores from "./CpQuestionCorrectnesAndScores";

import ChartPerformanceMetadataSets from "./ChartPerformanceMetadataSets";
import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT, _WST_SUBMIT,
    _QST_SUB_WRONG, _QST_SUB_CORR, _QST_SUB_ATT, _QST_SUB_NOTMARK, _QST_SUB_NOTATT } from "../../consts/ATConstsAssignment";
import { useMemoCurr, useMSetEntsByJ, useTypeByMSetEmts, useRptMSet, useMemoMSetEnts } from "./TabTeacherAssignmentReport";
import { aryLen, objVals, toAry, toInt, toObj, toStr, toUniAry } from "../../libs/libType";
import { ReduxBind } from "../../saga/ReduxState";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { BtnDev, preJS } from "../../AppExPf/AppUtil";
import { _ATMetaTransByCode, _ATMetaTypeByCode } from "../../consts/ATMetaTypes";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { getTeacherName } from "../../AppExPf/ATExerEdit/TabExerContent";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import { qResps } from "../EPAsmWork/utilAsmWork";
import { TagStuProgressTag } from "../EPAssign/Tags/TagStuProgressTag";
import TagWorkSubmitTag from "../EPAssign/Tags/TagWorkSubmitTag";
import { TagCountdownTag } from "../EPAssign/Tags/TagCountdownTag";
import { TagAsmMarkingTag } from "../EPAssign/Tags/TagMarkingTag";
import { ansState } from "./TabPerformanceDetailChart";
import { toRptFix } from "../EPReports/PageReports";
import { rptMName } from "../EPReports/data/dataUtil";
import { xapiPost } from "../EPLibrary/PageLibrary";
import { myNextPara } from "../EPAssign/PageAssignmentStudent";
import { expfRoles } from "../../saga/auth.saga";
import { getTransMap } from "../../libs/libTransMap";
import { expf2 } from "../../consts/ATSysQType";
import TabStuAsmRptReme from "./TabStuAsmRptReme";
import { toUniIdAry } from "../../consts/ATValidate";
import { TagStuRemePass } from "../../AppExPf/ATExerEdit/TagRemeLv";
import { stuPassAsm } from "../EPAssign/CardStudentAssignmentCard";

const TabStudentAssignmentReportResult = ReduxBind(props => { // base on poc StudentAssignmentReportResult.tsx
    const { qtns, dispatch } = props;
    const [isThr, isStt, uRole ] = expfRoles(props);
    const myUId = props.userId();

    const {asm:A, Exer:E, work:W, mark:M} = props;
    const [asm, Exer, work, mark] = [toObj(A), toObj(E), toObj(W), toObj(M)];

    const {AState, assignId} = asm;
    const APub = (AState === _AST_PUBLISHED);
    const myRemes = props.remeAsms;
    const myFailRemes = toAry(myRemes).filter(r => !stuPassAsm(r, r.mark));
    const hasReme = expf2 && APub && toAry(myRemes).length; 
    const hasFailReme = hasReme && aryLen(myFailRemes); 
    
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(asm.ALang);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);

    const [ cache2d, useGetCache ] = useCaches(props);

    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 
    const subjMetas = useGetCache('subjMetas', '*');
    const [ QIds, SMIds, rptMSetEnts, rptJEnts ] = useMemoCurr(Exer, qtns, tagMSets, useTagMSets, subjMetas);

    const rType = 'student';

    const rptDDI = useMemo(() => {
        const pfx = '';//t("teacher-report-performance") + ' - ';
        return ent2DropdownItem([
            ...rptJEnts.map(([i, j]) => [i, pfx + ut(j.nameEn, j.nameCt)])  //curr: `${t("teacher-report-performance")} - ${t("curriculum")}`,
        ]);
    }, [rptJEnts, uiEn]);

    const [j, setRptJ] = useState();
    const rptJs = useMemo(() => Object.fromEntries(rptJEnts), [rptJEnts]); 
    const rptJ = rptJs[j]? j: toStr(rptJEnts[0]?.[0]);

    const JMSetEnts = useMSetEntsByJ(rptJ, rptMSetEnts); //
    const [rptMType, setRptMType, typeMSets, MSTypeDDI, hasMSTypeDDI] = useTypeByMSetEmts(JMSetEnts, uiEn, t);
    const [remeMSId, setRptMSet, typeMSetEnts, MSetDDI, hasMSetDDI] = useRptMSet(rptMType, JMSetEnts, uiEn, ut);
    
    const myMarks = myNextPara(asm, Exer, work, mark);
    const {wSub, APass, pass, belowAvg, aboveAvg, myMark, avgMark, pMark} = myMarks; 
    const eScore = aScore + mScore;
    const myMarkPC = toPercent(myMark, eScore); 
    const passPC = toPercent( pMark, eScore);
    const avgPC = toPercent(avgMark, eScore);

    const donutData1 = useMemo(() => mkDonutData1(t, eScore, myMark, myMarkPC, avgPC, APass, pMark, passPC), [uiEn, eScore, myMark, myMarkPC, avgPC, APass, pMark, passPC]);

    const qStats = useMemo(() => statStuAsmRptQs(QIds, qtns, asm, Exer, work, mark), [qtns, A, E, W, M]);

    const data2 = useMemo(() => mapData2(qStats, qtns, asm, mark, wSub, t), [qStats, A, E, W, M]);
    const donutData2 = useMemo(() => mkDonutData2(t, data2), [uiEn, data2]);

    const thrNames = useGetCache('teachers', '*'); 
    const assignedBy = getTeacherName(asm.userId, thrNames, ut, t);

    const ansTime = secondToHHMMSS(Math.floor((work.msUsed)/1000));
    const timeLimit = (asm?.limitMin)?(asm?.minsToGo):'--';
    const maxAttempt = asm.attempt? toInt(asm.attTimes): (hasM? 1: t('unlimited'));  
    const myAttempt = toInt(work._attemp - (wSub? 0: 1)) || 0; 

    const summaryData = [
    {title: t("assignment-info-start"), value: getT2bSrt(asm)},
    {title: t("assignment-info-end"), value: getT2bEnd(asm)},
    {title: t("assignment-time-limit"), value: timeLimit},
    {title: t(getTransMap("assignment-assigned-by",isStt)), value: <span className={"d-flex align-items-center gap-2"}><CpIco src={IconList.general.avatar}/>
        <span>{assignedBy}</span></span>},
    {title: t("assignment-last-submission"), value: EPUITime3(work?.dateSub)},
    {title: t("assignment-no-of-attempts"), value: (APub)? myAttempt: ((myAttempt || "-")+' / ' + maxAttempt) },
    {title: t("assignment-time-used"), value: ansTime}];
    
    const mdProps = useMemo(() => {
        const mset1 = toObj(typeMSets?.[rptMType]?.[remeMSId]);
        const MSets = mset1? { [remeMSId]: mset1 }: {};
        return {t, ct, qStats, qtns, MSets};
    }, [typeMSets, rptMType, remeMSId, qStats, qtns, uiEn, showEn]);

    const {data, metadata, mdQIdxs} = useMemo(() => {
        const data = qStats.map(q => sumMetaQs('*QID:'+q.qIdx, qStats, qtns, [q.qIdx]));
        const output = { data };
        const {metadata, mdQIdxs} = mapCurrMSets(output, mdProps); //rptMSets, typeMSets 
        const unCatQIdx = qStats.map(q => q.qIdx).filter(i => !mdQIdxs.includes(i));
        metadata.push(metadata1(output, mdProps, '**uncat', t2('report-performance-uncategorized', showEn), unCatQIdx, unCatQIdx));
        return {data, metadata, mdQIdxs};
    }, [mdProps]);

    const perQ  = {data: mapQStatItem(qStats, qtns, asm, mark, wSub) };
    //const workSign = getWorkSign(ALang, APass, pass, APub, wSub);
    const workSign = getWorkSign(UILang, APass, pass, APub, wSub);

    useEffect(() => {
        assignId && rptJ && xapiPost(dispatch, 'xStuAsmRpt', {assignId, rType, rptJ, rptMType, remeMSId});
    }, [assignId, rptJ, rptMType, remeMSId] );

    return <>
        <Container fluid={true} className={"TabStudentAssignmentReportResult fade-in-container mt-3 mb-5"}>
<BtnDev txt='all My Remes'>{preJS({myRemes}, 3)}</BtnDev>
<BtnDev txt='all My Fails'>{preJS({hasFailReme}, 3)}</BtnDev>

            <div className="TabStudentAssignmentReportResult report-result d-flex felx-wrap flex-column flex-lg-row align-items-stretch gap-3">
                <div className="report-result-col result-col-left result-col-top">
                    <div className={"border rounded-light p-3 overflow-hidden h-100"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-start"}>
                            <h4 className={"m-0"}>{t("report-summary")}</h4>
                            <div className="d-flex flex-wrap justify-content-center w-100 gap-3 mt-3">
                                <div className="flex-fill d-flex justify-content-center min-wrap-300">
                                    <div className={"result-assignment"}>
                                        <span className={"result-bg"}><CpIco src={workSign.bg}/></span>
                                        <span className={"result-hand"} ><CpIco src={workSign.hand}/></span>
                                    </div>
                                </div>
{preJS(workSign)}
{preJS({hasReme, hasFailReme})}
                                <div className="flex-fill d-flex align-items-start flex-column min-wrap-300 mt-3 order-1">
                                <div className={`d-flex flex-wrap gap-2`}>
                                        <TagStuProgressTag AState={asm.AState} WState={work.WState} hasM={hasM}/>
                                        <TagWorkSubmitTag {...{asm, work}}/>       
                                        <TagCountdownTag {...{asm}}/>
                                        <TagAsmMarkingTag {...{asm}}/>      
                                        {hasReme? <TagStuRemePass {...{t, fill:1, pass:!hasFailReme}} />:''}
                                    </div>
                                    <div className={"d-flex gap-2 mt-3"}>
                                        <table className={"result-summary-table fs-8"}><tbody>
                                            {summaryData.map((summary, idx) => <tr key={idx} className={"align-top"}>
                                                <td className={"semi-bold pe-3 text-nowrap"}>{summary.title}:</td>
                                                <td className={"ps-3"}>{summary.value}</td>
                                            </tr>)}
                                        </tbody></table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-result-col result-col-right result-col-top">
                    <div className={"border rounded-light h-100 p-3"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center"}>
                            <h4 className={"m-0"}>{t(getTransMap("report-assignment-overview",isStt))}</h4>
                        </div>
                        <div className={"d-flex justify-content-evenly h-100  align-items-center gap-2"}>
                            <div className="d-flex flex-wrap justify-content-center  align-items-start align-items-lg-center w-100 h-100 gap-3">
                                <div className="flex-fill d-flex align-items-center flex-column min-width-wrap-300">
                                    <div className="d-flex flex-row flex-md-column flex-lg-row flex-wrap justify-content-center gap-3">
                                        <div className="flex-fill d-flex justify-content-center wrap-250"><ChartDonut {...donutData1} /></div>
                                        <div className="flex-fill d-flex width-wrap-220 justify-content-center align-items-center order-1">
                                            <div className={"semi-bold fs-8"}>
                                                {donutData1.line.map((line, idx) => <span className={"sample-line"} key={idx}>
                                                <span className={`sample-${line.type}-line`}></span>{line.title}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex-fill d-flex align-items-center flex-column min-width-wrap-300 order-1">
                                    <div className="d-flex flex-row flex-md-column flex-lg-row flex-wrap justify-content-center gap-3">
                                        <div className="flex-fill d-flex justify-content-center wrap-250"><ChartDonut {...donutData2} /></div>
                                        <div className="flex-fill d-flex width-wrap-220 justify-content-center align-items-center order-1">
                                            <div className={"semi-bold fs-8"}>
                                                {donutData2.segments.map((segment, idx) => <span className={"sample-line"} key={idx}>
                                                    <span className={`sample-${segment.type}-cube`}></span>{segment.title+' '}({segment.number})</span>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="report-result d-flex flex-column flex-xl-row align-items-stretch gap-3 mt-3">
                <div className="report-result-col result-col-left result-col-bottom">
                    <div className={"border rounded-light h-100 p-3"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}>
                            <h4 className={"m-0"}>{t("report-question-correctness-and-scores")}</h4>
                        </div>
                        <div className={"d-flex"}><CpQuestionCorrectnesAndScores {...perQ}/></div>
                    </div>
                </div>
                <div className="report-result-col result-col-right result-col-bottom">
                    <div className={"border rounded-light h-100 p-3"}>
                        <div className={"d-flex flex-column gap-2 flex-wrap mb-3"}>
                            <h4 className={"m-0"}>{t("report-performance")}</h4>
                            <div className={"d-flex gap-2 flex-wrap"}>
                                <span style={{width: "280px"}} className={"d-flex w-sm-100"}>
                                    <CpDropdown className="w100" {...{items:rptDDI, idx:rptJ, cb:setRptJ}} /></span>
                                <span style={{width: "250px"}} className={"d-flex w-sm-100"}>
                                    <CpDropdown className="w100" {...{items:MSTypeDDI, idx:rptMType, cb:setRptMType }} /></span>
                                <span style={{width: "250px"}} className={"d-flex w-sm-100"}>
                                    {/* Dropdown items={metadataSetOptions} */}
                                    <CpDropdown className="w100" {...{items:MSetDDI, idx:remeMSId, cb:setRptMSet }} /></span>
                            </div>
                        </div>
                        <div className={"overflow-x mt-3"}>
                            <ChartPerformanceMetadataSets {...{metadata, data}} {...{miniLayout:true, miniTitle:toStr(MSTypeDDI?.[rptMType]?.t)}} />
                            {/* //as PerformanceMetadataSetsChartType} }
                            <PerformanceMetadataSetsChart {...{...PerformanceMetadataSetsData}} {...{miniLayout:true, miniTitle:t("dummy.dse-math")} } />
                            */}
                        </div>
                    </div>
                </div>
            </div>
{hasReme? <TabStuAsmRptReme {...{...props, myUId, qStats, tagMSets, useTagMSets}} />:''}
        </Container>
    </>
});
export default TabStudentAssignmentReportResult;

export const useMetaRAsmsByMSid = (remeAsms, remeMSId) => useMemo(() => Object.fromEntries(toAry(remeAsms)
    .filter(a => toStr(a.remeSMId).startsWith(remeMSId+','))
    .map(a => [a.remeSMId, a])), [remeAsms, remeMSId]);

const statStuAsmRptQs = (QIds, qtns, asm, Exer, work, mark) => {
    const mr = qResps(mark?.resp, asm.Exer); // filter qtn resp only
    return QIds.map((qid, qIdx) => {
        const q = qtns[qid];
        const qMetas = toUniIdAry(q.QDisplayMetas);
        const {aScore, mScore, opAttempt, isOP, isRight, allMarked} = mr[qIdx];
        return { qIdx, qid, qMetas, right:isRight, mark: toInt(mScore)+toInt(aScore)};
    });
};

const sumMetaQs = (id, qStats, qtns, qIdxs) => {
    const ret = {
        referenceId: id,
        numCorrectly: {
          //value: right?1:0, //total: 1
        },
        isCorrectness: true,
        //student: [{ id: 'dumCurStu', value: right? qMark: 0, "status": "p" }]
    };

    const ttlQ = qIdxs.length;
    const sum = {right:0, qmark:0, umark:0, avp:0};
    toAry(qIdxs).forEach(qIdx => {
        const { qid, right, mark } = toObj(qStats[qIdx]);
        
        const q = toObj(qtns[qid]);
        const umark = toInt(mark);
        const qscore = toInt(q.score);
        sum.right += toInt(right);
        sum.umark += umark;
        sum.qmark += qscore;
        sum.avp += (qscore?(umark / qscore):0);
    }); 
    
    ret.correctness = toPercent(sum.right, ttlQ);
    //ret.averageScore = toPercent(sum.avp, ttlQ);    
    ret.averageScore = toPercent(sum.umark, sum.qmark);
    
    return ret;
};

const mapQStatItem = (qStats, qtns, asm, mark, wSub) => {
    const mr = qResps(mark.resp, asm.Exer); // filter qtn resp only
    return qStats.map(s => {
        const {qIdx, qid, right} = s;
        const {aScore, mScore, opAttempt, isOP, isRight, allMarked, hasAnswered} = mr[qIdx];
        const q = toObj(qtns[qid]);
        const qMark = q.score;
        return {
            id: qIdx,//'qid',
            no: qIdx+1,
        
            marks: {
              value: toInt(aScore) + toInt(mScore),
              total: qMark,
            },
            status: ansState(q, wSub, hasAnswered, allMarked, isRight),
            //status: (right? 'a': 'f') //,'p'            
        };
    });
};
const mapData2 = (qStats, qtns, asm, mark, wSub) => {
    const mr = qResps(mark.resp, asm.Exer); // filter qtn resp only
    const totalQtn = toInt(mark?.MStat?.totalQtn);

    const data = { totalQtn, right:0, wrong:0, att:0, noAtt:0 };
    toAry(qStats).forEach((s, qIdx) =>{
        const { qid } = toObj(s);
        const q = toObj(qtns[qid]);
        const { hasAnswered, allMarked, isRight, } = mr[qIdx];
        const status = ansState(q, wSub, hasAnswered, allMarked, isRight);
        if (status === _QST_SUB_CORR) { data.right++;
        } else if (status === _QST_SUB_WRONG) { data.wrong++;
        } else if (status === _QST_SUB_ATT) { data.att++
        } else if (status === _QST_SUB_NOTATT) { data.noAtt++ };
    });
    return data;
};   

const mkDonutData2 = (t, data2) => {
    const {totalQtn, right, wrong, att, noAtt} = data2;
    return {
        title: { color: DONUT_TEXT_COLOR, value: totalQtn},
        subTitle: { color: DONUT_TEXT_COLOR, value: t("report-answered") },
        segments: [
            {color: DONUT_CORRECT_COLOR, title: t("report-correct"),type: "correct",
                value: parseFloat(toPercent(right, totalQtn)), number: right },
            {color: DONUT_INCORRECT_COLOR,title: t("report-incorrect"),type: "incorrect",
                value: parseFloat(toPercent(wrong, totalQtn)), number:  wrong },
            {color: DONUT_ATTEMPTED_COLOR, title: t("report-attempted"),type: "attempted",                 
                value: parseFloat(toPercent(att, totalQtn)), number: att },
            {color: DONUT_NO_ATTEMTED_COLOR, title: t("report-not-attempted"),type: "not-attempted",
                value: parseFloat(toPercent(noAtt, totalQtn)), number: noAtt }
        ]
    };
};

const mkDonutData1 = (t, eScore, myMark, myMarkPC, avgPC, APass, pMark, passPC) =>{
    return {
        line: [
            { type: "avg", color: DONUT_AVG_LINE_COLOR, value: avgPC, title: t("report-class-avg-score")+' ('+avgPC+`%)`, },
            APass && { type: "passing", color: DONUT_PASSING_LINE_COLOR, value: passPC, title: t("report-passing-score")+' ( '+pMark+' '+t("marks")+')', }
        ].filter(l => l),
        title: {
            color: DONUT_DEFAULT_SEGMENT_COLOR,
            value: <>
                <tspan textAnchor="middle">
                    <tspan y="18">{myMark} <tspan fill={DONUT_TEXT_COLOR}>/{eScore}</tspan></tspan>
                </tspan>
                <tspan x="50%" y={"23.5"} textAnchor="middle" className="donut-percent">({myMarkPC}%)</tspan>
            </>
        },
        subTitle: { color: DONUT_TEXT_COLOR, value: <tspan x="50%" y={"27.5"} textAnchor="middle">{t("report-score")}</tspan> },
        segments: [{ color: DONUT_DEFAULT_SEGMENT_COLOR, value: parseFloat(myMarkPC), },]
    };
};


const mapCurrMSets = (out, props) => {
    const {MSets} = props;
    const cm2d = objVals(MSets).map(MSet => mapCurrMeta(out, {...props, MSet}));
    const metadata = [].concat(...cm2d.map(x => x.ret));
    const mdQIdxs = toUniAry([].concat(...cm2d.map(x => x.qIdxs)));
    return {metadata, mdQIdxs};
};
const mapCurrMeta = (out, props) => {
    const {MSet} = props;
    const mRoot = toAry(MSet.mRoot);
    const MBranchs = toObj(MSet.MBranchs);
    const metas = toObj(MSet.metas);
    
    const chs = mRoot.map(metaId => mapCurrMetaBranch(out, {...props, metaId, metas, MBranchs} )).filter(b => b);
    const qIdxs = toUniAry([].concat(...chs.map(ch => ch.qIdxs)));
    const ret = chs.map(ch => ch.ret);
    return {qIdxs, ret};
};
const mapCurrMetaBranch = (out, props) => {
    const {ct, qStats, qtns, MSet, metaId, metas, MBranchs} = props;
    const m = metas[metaId];
    if(!m) return;
    const id = MSet.metaSetId+','+metaId;

    const childRows = toAry(MBranchs[metaId]).map(chid => mapCurrMetaBranch(out, {...props, metaId:chid} )).filter(b => b);

    const subQIdxs = toUniAry([].concat(...(childRows.map(c => c.qIdxs))));
    const _dirQIdxs = qidxsBySMId(qStats, qtns, id);
    const dirQIdxs = _dirQIdxs.filter(i => !subQIdxs.includes(i));
    const qIdxs = toUniAry([...dirQIdxs, ...subQIdxs]).sort();

    const title = rptMName(m, ct, id);
    const ret = metadata1(out, props, id, title, qIdxs, dirQIdxs);
    if(ret) ret.children = childRows.map(c => c.ret); 
    
    return ret && {qIdxs, ret};
};

const metadata1 = (out, props, id, title, qIdxs, dirQIdxs) =>{
    const {t, ct, qStats, qtns, MSet, metaId, metas, MBranchs} = props;
    const total = qIdxs.length;
    if(!total) return;
    
    qIdxs.sort();
    out.data.push(sumMetaQs(id, qStats, qtns, qIdxs, dirQIdxs));
    //const list = dirQIdxs.map(idx => ({id:'*QID:'+idx , no:idx+1})); 
    const list = dirQIdxs.map(idx => ({id:'*QID:'+idx , no:idx+1})).sort((a,b)=>{ return (toInt(a.no) - toInt(b.no))}); 
    return { id, title, question:{ total, list }};
}

const toPercent = (cnt, ttl) => ttl? toRptFix(100 * cnt / ttl): 0;

const getWorkSign = (lang, APass, pass, APub, wSub) => {   
    return APass?(
        pass? { hand: IconList.result[lang]["handP"], bg: IconList.result.bgP}
        :{ hand: IconList.result[lang]["handF"], bg: IconList.result.bgF}
    ):APub? (wSub ? {hand: IconList.result[lang]["handA"], bg: IconList.result.bgA}
        :{hand: IconList.result[lang]["handN"], bg: IconList.result.bgN})
        :{hand: IconList.result[lang]["handN"], bg: IconList.result.bgN};
};

/*
        if ( !hasAnswered ) { // student has answered any blank or all blanks of question
            data.noAtt++;
        } else if ( hasAnswered && !allMarked ) { // answered, but not all marked by teacher + auto
            data.att++;
        } else if ( isRight ) { 
            data.right++;
        } else { 
            data.wrong++;
        }

    return APass?(
        pass? { hand: IconList.result[lang]["handP"], bg: IconList.result.bgP}
        :{ hand: IconList.result[lang]["handF"], bg: IconList.result.bgF}
    ):APub? { hand: IconList.result[lang]["handN"], bg: IconList.result.bgN}
        :{hand: IconList.result[lang]["handA"], bg: IconList.result.bgA};
*/
export const qidxsBySMId = (qStats, qtns, SMId) => qStats.filter(s => toAry(qtns[s.qid]?.QDisplayMetas).includes(SMId)).map(s => s.qIdx);
//export const qidxsBySMId = (qStats, SMId) => qStats.filter(s => toAry(s.q?.QDisplayMetas).includes(SMId)).map(s => s.qIdx);
