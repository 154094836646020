import React, {useEffect, useState, useRef} from "react";

//import SIDEMENU_LOGO from '../../poc/static/img/frontend/sidemenu-logo.svg'
//import SIDEMENU_LOGO_TITLE from '../../poc/static/img/frontend/sidemenu-logo-title.svg'
//import SwitchLanguage from "../../poc/screens/shared/includes/sidemenu/SwitchLanguage";

import { _AssignmentRoot, _ExerciseRoot, _LibraryRoot, _WebRoot } from "../../consts/ATConstReact";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { appLogout } from "../../libs/awsFuncs";
import * as UI from '../../libs/libUI';
import { toObj, toStr } from "../../libs/libType";

import { ReduxBind } from "../../saga/ReduxState";
import { urlPush_Replace } from "../../saga/urlPush.saga";

import CpIco from "./CpIco";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { expfRoles } from "../../saga/auth.saga";
import { usePopExer } from "../EPLibrary/PageLibrary";
import { webAppVer } from "../../libs/libAppVer";

/*
const [isThr, isStt, uRole ] = expfRoles(props);
isTeacher (bool), isStudent (bool) , role (string)
for UI to display for according to role
*/
const SIDEMENU_LOGO = UI.imgSrc('img/frontend/sidemenu-logo.svg');
const SIDEMENU_LOGO_TITLE = UI.imgSrc('img/frontend/sidemenu-logo-title.svg');

const menu = [
    { title: "sidemenu-home", ico: IconList.menu.home, click:'home', roles: ["editor", "teacher", "student"] },
    { title: "sidemenu-library", ico: IconList.menu.library, click:_LibraryRoot, roles: ["teacher"] },
    { title: "sidemenu-my-exercises", ico: IconList.menu.myExercises, click:_ExerciseRoot, roles: ["editor", "teacher"] },
    { title: "sidemenu-question", ico: IconList.menu.question, click:'question', roles: ["editor"] },    
    { title: "sidemenu-assignments", ico: IconList.menu.assignment, click:_AssignmentRoot, roles: ["teacher"] },
    //{ title: "sidemenu-my-assignments", ico: IconList.menu.assignment, click:_AssignmentRoot, roles: ["student"] },
    { title: "sidemenu-my-exercises", ico: IconList.menu.assignment, click:_AssignmentRoot, roles: ["student"] },
    { title: "sidemenu-metadata", ico: IconList.menu.metadata, click:'metadata', roles: ["editor"] },
    { title: "sidemenu-reports", ico: IconList.menu.report, click:'report', roles: ["editor", "teacher"] },
    { title: "sidemenu-learning-reports", ico: IconList.menu.report, click:'report', roles:["student"]},
    { title: "sidemenu-admin", ico: IconList.menu.admin, click:'admin', roles: ["editor"] },
];

const CpSideBar = ReduxBind( props => {
    const {dispatch, position, sideIdx} = props
    const [t, uiEn, lang, setLang, ut] = useUILang();

    const U = toObj(props.user());
    const [isThr, isStt, uRole ] = expfRoles(props);

    const uname = toStr(ut(U.nameEn, U.name)).trim();

    const {popExer, setPopExer, goPopExer, exModified, setExModified} = usePopExer(props);

    const [show, setShow] = useState(false)
    const firstTimeRef = useRef(true);

    useEffect(() => {
        if (!firstTimeRef.current) {
            setShow(prev => !prev)
        }
        firstTimeRef.current = false;    
    }, [props.open]);

    const openHelp = () => {
        const url = uiEn?'https://digital.oupchina.com.hk/oep/en/help'
            :'https://digital.oupchina.com.hk/oep/zh/help';
        window.open(url, '_blank');
    };    

    const clickClose = e => { UI.stopEvent(e); setShow(false); };
    const click = b => e => { UI.stopEvent(e);
        setShow(false);
        setPopExer(0, () => {
            if(b === 'logout'){
                appLogout(props.dispatch, uiEn, props.settings())
            }else if([_LibraryRoot, _ExerciseRoot, _AssignmentRoot].includes(b)){
                dispatch(urlPush_Replace(b));
            }else if(['home', 'report'].includes(b)){
                dispatch(urlPush_Replace(_WebRoot+b));
            }else if (b==='help') {
                openHelp();
            }else if (b==='cookieman'){
                try{ window.OneTrust?.ToggleInfoDisplay(); }catch(e){ console.error(e); }
            }
        });
    };

    //EX-1691    
    return <div className={"sidenav"}>
        <a href="#" ></a>
        <div className={`sidenav-container ${position}-sidenav d-flex flex-column justify-content-start gap-2 ${show ? "active" : ""}`}>
            <div className={"d-flex flex-row  justify-content-between align-items-center gap-2 my-1 pt-3 mx-3"}>
                <div className={"d-flex flex-row gap-2"}>
                    <img src={SIDEMENU_LOGO} className={"logo"}/>
                    <div className={"logo-title"}><img src={SIDEMENU_LOGO_TITLE}/></div>
                </div>
                <div role={"button"} className={"d-block d-md-none fs-3 text-light"} onClick={clickClose}>
                    <CpIco src={IconList.general.close}/>
                </div>
            </div>
            <div className={"split-line mx-2"}/>
            <div className={"sidenav-list-small-screen-container d-flex flex-column justify-content-between flex-grow-1 h-100"}>
                <div className={"d-block d-md-flex flex-md-column flex-grow-1 justify-content-between position-relative py-2 sidenav-list-container"}>
                    <div className={"sidenav-list d-flex flex-column gap-2 h-100 pt-2"}>
                        {menu.map((i, key) => {
                            return (i.roles.includes(uRole))
                            ?<CpSideBarItem {...{key, ...i, onClick:click(i.click), active:(sideIdx === i.click)}} />
                            :''
                        })}
                    </div>
                </div>
                <div className={"version fs-8 text-light text-truncate mx-3 pb-1 justify-content-end"}>{webAppVer }</div>
                <div className={"sidenav-bottom-list d-grid d-md-flex flex-column gap-1 py-3"} onClick={clickClose}>
                    <CpAvatar {...{name:uname}} />
                    <CpSwitchLanguage />
                    <CpSideBarItem onClick={click('help')} ico={IconList.menu.help} title={"sidemenu-help"} active=''/>
{/*
                    <div className={`sidenav-icon d-flex flex-row justify-content-start align-items-center gap-2 mx-3 `}>
                        <span><CpIco src={IconList.menu.help}/></span>
                        <span className="title semi-bold text-truncate fs-8">
                            <a href="#" className="optanon-show-settings title">Cookie Preferences</a>
                        </span>
                    </div>
*/}
                    <CpSideBarItem onClick={click('cookieman')} ico={IconList.menu.cookie} title={"sidemenu-cookie"} active=''/>
                    <CpSideBarItem onClick={click('logout')} ico={IconList.menu.logout} title={"sidemenu-logout"} active=''/>
                </div>
            </div>
        </div>
    </div>;
});
export default CpSideBar;

const CpSideBarItem = props => {
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {ico, title, active, onClick} = props;
    return <div role="button" onClick={onClick} className={`sidenav-icon d-flex flex-row justify-content-start align-items-center gap-2 mx-3 ${active? "active": ""}`}>
        {ico? <span><CpIco src={ico}/></span>: ''}
        <span className="title semi-bold text-truncate fs-8">{t(title)}</span>
    </div>
}

export const CpAvatar = props => { //base on poc <Avator>
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {name = t("dummy.owner"), mx = 3} = props
    return <div role={"button"} className={`sidenav-icon d-flex flex-row justify-content-start align-items-center gap-2 mx-${mx}  text-truncate pe-none `}>
        <span className={"fs-6"}><CpIco src={(name === t("oupc-name"))? IconList.brand.oupc: IconList.general.avatar}/></span>
        <span className={"title semi-bold text-truncate fs-8"}>{name}</span>
    </div>
};

export const CpSwitchLanguage = props => {
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const changeLanguage = e => { setLang(uiEn? "zh" : "en"); };

    return <div className={`sidenav-icon side-nav-container-language d-flex flex-row justify-content-start align-items-center gap-2 mx-3`}
        role={"button"} onClick={changeLanguage}>
        <span className={`side-nav-language fs-8 ${uiEn? "": "zh"}`}>{t(uiEn? 'language-en': 'language-zh')}</span>
        <span className={"title semi-bold text-truncate fs-8"}>{t(uiEn? 'switch-to-chinese' : 'switch-to-english')}</span>
    </div>
};
