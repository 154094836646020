import { objEntries, objKeys, toAry, toFaction1, toFloat, toInt, toObj, toStr } from "../../../libs/libType";
import { strCmpTrimLow } from "../../EPAssign/TabTeacherAssignmentStatus";

export const toOverData2a = (overData2a_, clsNames) => {
  const {data, cSums} = toObj(overData2a_);
  const items = objKeys(cSums).map(id => ({id, title: toStr(clsNames[id]) }));
  items.sort((a,b )=> strCmpTrimLow(a.title, b.title));
  const overData2a = { type:'a', items, data:toAry(data), };  // ProgressByMonthByAllClassesTeacherData = {};
  return overData2a;
};

/*
export const toOverData2c = (rptOvers, rptOverStus, stu,) => { 
    const os = Object.fromEntries(toAry(rptOvers).map(o => [o.assignId, o]));
    const sumMs = {};
    toAry(rptOverStus).forEach(r => {
      const {AMetaDisplays, assignId, students} = toObj(r);
      const rs = toObj(students[stu]);
      
      if(rs){
        const o = toObj(os[assignId]);
        const m = ym2m(r.ym);
        sumMs[m] = addAsmSum(sumMs[m], o.APass, o.Exer?.eScore, rs.att, rs.pass, rs.mark);
      } 
    });
      
    const data = objEntries(sumMs).map(([m, s]) => {
      return {
        month: toStr(m),
        averageScore: { percentage: toFaction1(s.markp, s.ttl)},
        overview: [
          s.pass && { status: "p", value: s.pass },
          s.fail &&{ status: "f", value: s.fail },
          s.att && { status: "a", value: s.att },
          s.natt && { status: "n", value: s.natt },
        ].filter(s => s)
      }
    });
    const stuOverDataB = { type:'s', data }; //ProgressByMonthByStudentSharedData
    
    return stuOverDataB;
  };
  */
  