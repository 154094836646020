import React, {} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT, _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { timeStampNowGMT } from "../../libs/libTime";
import { toAry, } from "../../libs/libType";
import { expfRoles } from "../../saga/auth.saga";
import { ReduxBind } from "../../saga/ReduxState";
import CpDropdown, { ent2DropdownItem } from "../_components/CpDropdown";
import { strCmpTrimLow } from "./TabTeacherAssignmentStatus";

const CpAssignmentListFilter = ReduxBind(props => {
    const { opts, fields, setFields, setField, listView, setListView } = props;
    const {yearClassDDI, yearItems, year, classItems, classId, subjItems, AMSubjId, AStateItems, AState, ASortItems, ASort } = props;
    const [isThr, isStt, uRole ] = expfRoles(props);

    return <div className={"exercise-library-filter-container px-3 d-flex flex-column flex-xxl-row justify-content-xxl-between align-items-xxl-end gap-3 mt-3 w-100"}>
        <span className={"d-none d-lg-flex flex-wrap gap-3 align-items-center"}>
            {isStt? <AssignmentFilterYearClassDropdown {...{yearClassDDI, classId, cb:setField('classId') }} /> :''}
            {isThr? <CpAssignmentFilterYearDropdown {...{yearItems, year, cb:setField('year') }} /> :''}
            {isThr? <CpAssignmentFilterClassDropdown {...{classItems, classId, cb:setField('classId')}} /> :''}
            <CpAssignmentFilterSubjectDropdown {...{subjItems, AMSubjId, cb:setField('AMSubjId')}} />
        </span>
        <span className={"d-flex gap-2 align-items-center justify-content-between"}>
            <span className={"d-none d-lg-flex gap-3 align-items-center"}>
                {listView? <CpAssignmentFilterStatusDropdown {...{AStateItems, AState, cb:setField('AState')}} />: ''}
                {<CpAssignmentFilterSortingDropdown  {...{ASortItems, ASort, cb:setField('ASort')}} />}
            </span>
            {/*<span className={"d-flex gap-2 align-items-center"}>
                <span role={"button"} onClick={clickView(1)} 
                      className={`fs-4 ${listView? "text-exercise-third-btn": "text-dim-350"} uiAnimation`}>
                    <CpIco src={IconList.presentation.tableMode}/>PPP
                </span>
                <span role={"button"} onClick={e => {window.alert('todo: assignment column view ')}} 
                      className={`fs-4 ${listView? "text-dim-350": "text-exercise-third-btn"} uiAnimation`}>
                    <CpIco src={IconList.presentation.priorityMode}/>
                </span>
            </span>*/}
        </span>
    </div>
});
export default CpAssignmentListFilter;

export const AssignmentFilterYearClassDropdown = (props => {
    const {yearClassDDI, classId, cb} = props;
    const [t] = useUILang();
    return <span className={`d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
        <span className={"d-flex align-items-center semi-bold"}>{t("year-class")}:</span>
        <span className={`d-flex`} style={{width: "180px"}}><CpDropdown className="DDAsmYearCls w100" {...{items:yearClassDDI, idx:classId, cb}} /></span>
    </span>
});

export const CpAssignmentFilterYearDropdown = (props) => {
    const {yearItems, year, cb} = props;
    const [t] = useUILang();
    return <span className={`d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
        <span className={"d-flex align-items-center semi-bold"}>{t("year")}:</span>
        <span className={`d-flex`}><CpDropdown className="DDAsmYear w100" {...{items:yearItems, idx:year, cb}} /></span>
    </span>;
};

export const CpAssignmentFilterClassDropdown = props => {
    const { classItems, classId, cb } = props;
    const [t] = useUILang();
     return <span className={`CpAssignmentFilterClassDropdown d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
        <span className={"d-flex align-items-center semi-bold"}>{t("class")}:</span>
        <span className={`d-flex`}><CpDropdown className="DDAsmCls w100" {...{items:classItems, idx:classId, cb}} /></span>
    </span>;
};
export const CpAssignmentFilterSubjectDropdown = props => {
    const { subjItems, AMSubjId, cb} = props;
    const [t] = useUILang();
    return <span className={`CpAssignmentFilterSubjectDropdown d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
        <span className={"d-flex align-items-center semi-bold"}>{t("subject")}:</span>
        <span className={`d-flex`} style={{width: "280px"}}><CpDropdown className="DDAsmSubj w100" {...{items:subjItems, idx:AMSubjId, cb}} /></span>
    </span>;
};
export const CpAssignmentFilterStatusDropdown = props => {
    const { AStateItems, AState, cb} = props;
    const [t] = useUILang();
    return <span className={`CpAssignmentFilterStatusDropdown d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
        <span className={"d-flex align-items-center semi-bold"}>{t("status")}:</span>
        <span className={`d-flex`} style={{width: "150px"}}><CpDropdown className="DDAsmState w100" {...{items:AStateItems, idx:AState, cb}} /></span>
    </span>
};
export const CpAssignmentFilterSortingDropdown = props => {
    const {ASortItems, ASort, cb} = props;
    const [t] = useUILang();
    return <span className={`CpAssignmentFilterSortingDropdown d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
        <span className={"d-flex align-items-center semi-bold"}>{t("sort")}:</span>
        <span className={`d-flex`} style={{width: "300px"}}><CpDropdown className="DDAsmSort w100" {...{items:ASortItems, idx:ASort, cb}} /></span>
    </span>
};

export const mkAStateItems = (isStt, t) => {
    return ent2DropdownItem(isStt? [
    ['*', t("assignment-status-options-all")],
    ['sal_notsub', t("assignment-status-options-not-submitted")], //stu asm list
    ['sal_due', t("assignment-status-options-due-this-week")], //stu asm list
    ['sal_res', t("assignment-status-options-pending-results")], //stu asm list
    ['sal_pub', t("assignment-status-options-results-published")], //stu asm list
    ['sal_out', t("assignment-status-options-expired")], //stu asm list
    ['sal_has_reme', t("assignment-tag-follow-up-complete")], //stu asm list
    ['sal_has_fail_reme', t("assignment-tag-follow-up-uncompleted")], //stu asm list
]:[
    ['*',t("assignment-filter-options-all")],
    ['tal_pend',t("assignment-filter-options-not-yet-started")], //thr asm list
    ['tal_prog',t("assignment-filter-options-in-progress")], //thr asm list
    ['tal_end',t("assignment-filter-options-completed")], //thr asm list
])};

const AWSub = a => (a?.work?.WState) === _WST_SUBMIT;
const AWToSub = a => ((a?.work?.WState) !== _WST_SUBMIT) && (a.AState === _AST_IN_PROGRESS);
const AWNoAtt = a => ((a?.work?.WState) !== _WST_SUBMIT) && [_AST_PUBLISHED, _AST_WAIT_RESULT].includes(a.AState);

const passAllReme = SRStat => (SRStat?.hasReme) && (!(SRStat?.hasFailReme));   
const failSomeReme = SRStat => (SRStat?.hasFailReme);   

const filterAsmsStt = (f) => {
    const weekTS = timeStampNowGMT() + (1000 * 8 * 24 * 60 * 60);
    console.log("f", f);
    return ( 
    (f === 'sal_notsub')? a => AWToSub(a): //[_AST_PENDING, _AST_IN_PROGRESS].includes(a.AState):  //Not Sumbmited
    (f === 'sal_due')? a => AWToSub(a) && a.timeEnd && (a.time2beEnd < weekTS):  //Due this week  
    (f === 'sal_res')? a => AWSub(a) && (a.AState !== _AST_PUBLISHED): //Pending Results  
    (f === 'sal_pub')? a => AWSub(a) && (a.AState === _AST_PUBLISHED):  //Results Published  
    (f === 'sal_out')? a => AWNoAtt(a):  //Expired
    (f === 'sal_has_reme')? a => passAllReme(a?.SRStat):  //Results Published  
    (f === 'sal_has_fail_reme')? a => failSomeReme(a?.SRStat):  //Expired
    a => a);
};
const filterAsmsThr = (f) => {
    const weekTS = timeStampNowGMT() + (1000 * 8 * 24 * 60 * 60);
    return ( 
    (f === 'tal_pend')? a => a.AState === _AST_PENDING: //This week  
    (f === 'tal_prog')? a => a.AState === _AST_IN_PROGRESS: //This week  
    (f === 'tal_end')? a => ![_AST_PENDING, _AST_IN_PROGRESS].includes(a.AState):
    a => a);
};
export const filterAsms = (isStt, asms, filter) => {
    //console.log("toAry(asms)",toAry(asms));
     if((!filter) || filter==='*') return asms;
    return toAry(asms).filter(isStt? filterAsmsStt(filter): filterAsmsThr(filter));
}; 

export const mkASortItem = t => {
    return ent2DropdownItem([
//    ['nsmt', t("assignment-status-options-not-submitted")],
//    ['ctnew', t("sorting-options-create-new")],
//    ['ctold', t("sorting-options-create-old")],
    ['assnew', t("sorting-options-assigned-date-new")],
    ['assold', t("sorting-options-assigned-date-old")],
    ['strnew', t("sorting-options-start-date-new")],
    ['strold', t("sorting-options-start-date-old")],
    ['endnew', t("sorting-options-end-date-new")],
    ['endold', t("sorting-options-end-date-old")],
    ['titacd', t("sorting-options-stroke-up")],
    ['titdecd', t("sorting-options-stroke-down")],
//    ['like', t("sorting-options-favourite")],
//    ['assnew', t("sorting-options-assigned-new")],
//    ['assold', t("sorting-options-assigned-old")],
])};

const cmpAName = (a, b) => strCmpTrimLow((a.Exer.ENameEn||a.Exer.ENameCt), (b.Exer.ENameEn||b.Exer.ENameCt));
export const sortAsms = (_asms, sortKey) => {
    const asms = [...toAry(_asms)];
    if(sortKey==='assnew'){ asms.sort((b, a) => (a.dateAdd - b.dateAdd) || cmpAName(b,a));
    }else if(sortKey==='assold'){ asms.sort((b, a) => (b.dateAdd - a.dateAdd) || cmpAName(b,a));
    }else if(sortKey==='strnew'){ asms.sort((b, a) => (a.time2beStart - b.time2beStart) || cmpAName(a,b)); 
    }else if(sortKey==='strold'){ asms.sort((b, a) => (b.time2beStart - a.time2beStart) || cmpAName(a,b));
    }else if(sortKey==='endnew'){ asms.sort((b, a) => (T2bEnd(a) - T2bEnd(b)) || cmpAName(b,a)); 
    }else if(sortKey==='endold'){ asms.sort((b, a) => (T2bEnd(b) - T2bEnd(a)) || cmpAName(a,b));                
    }else if(sortKey==='titacd'){ asms.sort((b, a) => cmpAName(b,a) || (a.time2beStart - b.time2beStart));
    }else if(sortKey==='titdecd'){ asms.sort((b, a) => cmpAName(a,b) || (a.time2beStart - b.time2beStart));
/*    }else if(sortKey==='nsmt'){ asms.sort((a, b) => (a.progress - b.progress) || strCmp(a.progress, b.progress));
    }else if(sortKey==='ctnew'){ asms.sort((b, a) => (a.dateMod - b.dateMod) || strCmp(a.dateMod, b.dateMod));
    }else if(sortKey==='ctold'){ asms.sort((b, a) => (b.dateMod - a.dateMod) || strCmp(b.dateMod, a.dateMod));
    }else if(sortKey==='like'){ asms.sort((b, a) => (b.like - a.like) || strCmp(b.like, a.like)); 
*/    }    
    return asms;//
};

export const T2bEnd = (asm) =>{
    const t2e = asm?.time2beEnd;
    const AEndTime = (asm?.timeEnd)?t2e:1000000000000;
    
    return AEndTime;
};