import { toUniIdAry } from "../../../consts/ATValidate";
import { splitSetMetaIds2D, splitSMId } from "../../../consts/ATValidateMetaSet";
import { aryLen, maxVal, minVal, objEntries, objKeys, objVals, strCmp, toAry, toFloat, toInt, toObj, toPercentF, toStr } from "../../../libs/libType";

export const ym2m = ym => toInt(toStr(ym).slice(4,6));

export const rptisMonth = (rpt, m) => {
  return ((!m) || (m === '*')) || (toInt(ym2m(rpt.ym)) === toInt(toStr(m).split('_')[1]));
};
export const rptisJ = (rpt, j) => {
  return toAry(rpt.AMetaDisplays).includes(j);
};

export const addAsmSum = (_sum, APass, eScore, att, pass, mark) => {
  const sum = _sum || {ttl:0, att:0, pass:0, fail:0, natt:0, minp:100, maxp:0, markp:0 };
  if(att){
    if(APass){
      if(pass){
        sum.pass++;
      }else{
        sum.fail++;
      }
    }else{
      sum.att++;
    }
    sum.ttl++;
    const p = toPercentF(mark, eScore);
    sum.markp += p;
    sum.minp = minVal(p, sum.minp);
    sum.maxp = maxVal(p, sum.maxp);
  }else{
    sum.natt++;
  }
  
  return sum;
};

export const rptMetaFullTree = (msets, msid) => {
  const mset = msets[msid];
  const metas = toObj(mset?.metas);
  const mids = objKeys(metas);
  const [MRoot, MBranchs] = MSetFullTree(metas);
  return {mset, mids, metas, MRoot, MBranchs};
};
export const rptMetaSubTree = (msets, msid, mids) => {
  const mset = msets[msid];
  const metas = toObj(mset?.metas);
  const [MRoot, MBranchs] = MSetSubTree(metas, mids, 1);
  return {mset, mids, metas, MRoot, MBranchs};
};
export const MSetFullTree = (metas, withUnPub) => MSetSubTree(metas, objKeys(metas), withUnPub); 
export const MSetSubTree = (metas, mids, withUnPub) => {
//console.log('MSetSubTree start ', mids);
  const [_metas, ]  = [toObj(metas), ];

  const rset = new Set();
  const MBranchs = {};
  const addNode = mid => {
    const m = mid && _metas[mid];
//console.log('MSetSubTree addNode mid:'+ mid, m);
    if(!(m && (m.pub || withUnPub))) return;
    if(MBranchs[mid]) return;
    MBranchs[mid] = [];//[...mids];
    const pmid = m.pMeta;
//console.log('MSetSubTree addNode pmid:'+ pmid);
    if(!pmid){
      rset.has(mid) || rset.add(mid);
    }else{
      if(!MBranchs[pmid]) addNode(pmid);
      MBranchs[pmid].push(mid);
    }
  }; 
  toUniIdAry(mids).forEach(addNode);

  const sortM = (i, j) => (toInt(_metas[i]?.rnum) - toInt(_metas[j]?.rnum)) || strCmp(i, j);
  //const _sortM = (i, j) => { console.log('sortM', i, j); return _sortM(i, j); };
  
  const MRoot = Array.from(rset);
  MRoot.sort(sortM);
  objVals(MBranchs).forEach(b => b.sort(sortM));
//if(aryLen(mids)) console.log('MSetSubTree ret ', {mids, MRoot, MBranchs});
  return [ MRoot, MBranchs ];
  //return [ MRoot.sort(sortM), Object.fromEntries(objEntries(MBranchs).sort(e => [e[0], e[1].sort(sortM)])) ];
};

export const onlyEndMIds = (mset, mids, withUnPub) => {
  if(!(mset && mids)) return [];
  const msid = mset.metaSetId;
  const metas = toObj(mset.metas);
  const [mRoot, MBranchs] = MSetSubTree(metas, mids, withUnPub);
  const ret = mids.filter(m => {
    const mPub = metas[m]?.pub || withUnPub;
    const isEnd = !aryLen(MBranchs[m]);
    return mPub && isEnd? 1: 0;
  });
//console.log({mRoot, MBranchs, mids, ret});
//console.log(tree);
//console.log(metas);
  return ret;
};
export const onlyEndSMIds = (mset, mids, withUnPub) => {
  const msid = mset?.metaSetId;
  return msid? toUniIdAry(onlyEndMIds(mset, mids, withUnPub)).map(mid => msid+','+mid): [];
};
export const onlyEndSMIdEnts = (msets, smidPairs, withUnPub) => {
  const pairs = toAry(smidPairs);
  const msids = toUniIdAry(pairs.map(p => p[0]));
  const onlyEndSMId2d = msids.map(msid => {
    const mset = msets?.[msid];
    const mids = mset && toUniIdAry(pairs.map(p => p[0] === msid && p[1]));
    return [msid, onlyEndSMIds(mset, mids, withUnPub)];
  });
  return onlyEndSMId2d;
};
export const onlyPubSMIds = (msets, smids) => toUniIdAry(smids).filter(smid => {
  const [msid, mid] = splitSMId(smid);
  return msets?.[msid]?.metas?.[mid]?.pub;
});


/*
const onlyEndSMIdss = (tagMSets, SMIds) => {
  const SMId2d = splitSetMetaIds2D(toUniIdAry(SMIds));
  const ret = objEntries(SMId2d).map(([msid, midSet]) => {
      const mset = tagMSets?.[msid];
      if(!mset) return [];
      const mids = Array.from(midSet);
      const [mRoot, MBranchs] = MSetSubTree(mset.metas, mids, 1);
      const endMs = mids.filter(m => {
          if(MBranchs[m] && MBranchs[m].length) return 0;
          return 1; 
      });
//console.log({msid, mids, endMs, mRoot, MBranchs});
      return endMs.map(m => msid +',' + m);
  });
  //console.log({ret, SMIds, SMId2d,});
  return [].concat(...ret);
};
*/
/*
const InflateMetaIds = (smid2d) =>{
  const id2d = objEntries(smid2d).map(([msid, mids]) => 
      toUniIdAry(mids).map(mid => 
          msid+','+mid))
  return toUniIdAry([].concat(...id2d));
};
*/

export const rptMName = (m, ut, fallback) => (m && [toStr(m.LVNum).trim(), ut(m.nameEn, m.nameCt).trim()].join(' ')).trim() || toStr(fallback).trim();
//export const rptMName = (m, ut, fallback) => (m && (ut(m.nameEn, m.nameCt).trim() || toStr(m.LVNum).trim())) || toStr(fallback).trim();

export const sortRptMs = ms => toAry(ms).sort((a, b) => ( (a._rnum - b._rnum) || strCmp(a.id, b.id) ) );

export const asmPassRate = (pUnit, pasVal, fullMark) => (pUnit === 'p')? pasVal: (fullMark? ((pasVal * 100) /fullMark): 100);  
export const asmPassMark = (pUnit, pasVal, fullMark) => (pUnit === 'm')? pasVal: ((fullMark * pasVal) / 100);  
