import React, { useEffect, useMemo, useState } from 'react';
import * as UI from '../../libs/libUI';
import { svgIcon2 } from '../components/CpATIcoBtn';

import { _ATMetaTransByCode, _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB, _ATMetaTypeCodes, _ATMetaTypes } from '../../consts/ATMetaTypes';
import { ATUIRadio0, ATUIText, preJS } from '../AppUtil';
import { subState } from '../utils/useChain';
import CpDropdown, { ent2DropdownItem } from '../../AppExPFUser/_components/CpDropdown';


export const initMFtrOpt = {msName:'', msType:'*', pub:'*'};

const LayerATMetaFilter = (props => {
    const {close, MFilterState, } = props;
    
    const _initOpt = MFilterState[0] || initMFtrOpt;
    const [opts, setOpts] = useState(_initOpt);
    const setOpt = key => val => setOpts(subState(key, val));

    const tapGo = e => { UI.stopEvent(e); MFilterState[1](opts); close(); };
    const tapReset = e => { UI.stopEvent(e); setOpts(initMFtrOpt); };

    const MTDDIs = useMemo(() => ent2DropdownItem([
        ['*', 'All'], ...(_ATMetaTypes().map(o => [o.code, o.name]))
    ]), []); 

    return UI.ScreenMask(<>
        <div className="screenMask2"></div>
        <div className="vam w" style={{backgroundColor:'#fff'}}>
            <div className="filterRow1"><div></div><div className='f18'>Filter</div>
                <div className='clickable' onClick={close}>{svgIcon2("general/close", "white", { width: "30px" })}</div>
            </div>
            <div className='m8'>
                <div className="tal m8">
{
//preJS({opts}, 3)
}
                    <div className="m4 vam">Name / ID includes: </div>
                    <div className="m8 vam"> 
                    {ATUIText(opts?.msName, setOpt('msName'), 'tn', 0, '', 'w100', {})} 
                    </div>
                </div>
                <div className="tal m8">
                    <div className="m4 vam">Type: </div>
                    <div className="m8 vam "><CpDropdown {...{className:"w0", items:MTDDIs, idx:opts?.msType, cb:setOpt('msType')}} /></div>
                </div>
                <div className='tal m8'>
                    <div className="m4 vam">Publish State: </div>
                    <div className="w m8">{ATUIRadio0('*', setOpt('pub'), opts?.pub)} All</div>
                    <div className="w m8">{ATUIRadio0('Y', setOpt('pub'), opts?.pub)} Published</div>
                    <div className="w m8">{ATUIRadio0('N', setOpt('pub'), opts?.pub)} Unpublished</div>
                </div>        
                <center><div className='ATfilterLine' /></center> 
                <div className='tac'>
                    <div className="m4 w vam">
                        {UI.colorButton('resetBtn', 'Reset', tapReset, '#e3f5ff', '100px', { margin: '10px', color: '#2995cd' })}
                    </div>
                    <div className="m4 w vam">
                        {UI.colorButton('searchBtn', 'Search', tapGo, '#e3f5ff', '100px', { margin: '10px', color: '#2995cd' })}
                    </div>
                </div>
            </div>
        </div>
    </>);
});
export default LayerATMetaFilter;


