import { useEffect, useState } from 'react';
import * as UI from '../../libs/libUI';

import { aTErrDiv1, ATErrsDiv, ATErrsDiv2, BtnPopDev, clickConfirm, CpATBtn, deepCopy, preJS } from '../AppUtil';
import { iconButton } from '../components/CpATIcoBtn';
import { langCodeCt, langCodeEn } from '../../consts/ATConsts';
import {
    UIDisplayName, _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn,
    _ExCtType_Txt, _ExCtType_Video
} from '../../consts/ATValidateEcnts';
import { toAry, toObj, toStr } from '../../libs/libType';
import { ReduxBind } from '../../saga/ReduxState';
import { clsQLang } from '../ATQtnEdit/PageATQtnEdit';
import { useChain } from '../utils/useChain';
import ViewExCtnSetting from './ViewExCtnSetting';
import ViewExCtnAudio from './ViewExCtnAudio';
import ViewExCtnCK from './ViewExCtnCK';
import ViewExCtnImg from './ViewExCtnImg';
import ViewExCtnLnk from './ViewExCtnLnk';
import ViewExCtnQ from './ViewExCtnQ';
import ViewExCtnTxt from './ViewExCtnTxt';
import ViewExCtnVideo from './ViewExCtnVideo';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpDropdown, { ent2DropdownItem, } from '../../AppExPFUser/_components/CpDropdown';
import { useUILang } from '../utils/useUILang';
import CpExEditToolBar from '../../AppExPFUser/_components/CpExEditToolBar';
import CpExerciseEditPreviewCTA from '../../AppExPFUser/_components/CpExerciseEditPreviewCTA';

import CpQViewExt from '../components/CpQViewExt';
import { useCaches } from '../utils/useCaches';
import { CpAutoMarkTag, CpManMarkTag } from '../../AppExPFUser/EPAssign/Tags/TagMarkingTag';
import { toUniIdAry } from '../../consts/ATValidate';
import CpATQtnSingle from '../qtnDo/CpATQtnSingle';
import CpPromptButtonComponent from '../../AppExPFUser/_components/CpPromptButtonComponent';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';
import { useVisDetSub } from '../../AppExPFUser/EPExercise/PageExerciseEdit';

import { popAlertToast } from '../components/CpPopup';

export const getStudentName = (students, userId, ut) => {
    const s = toObj(students?.[userId]);
    return ut(s.nameEng, s.nameChi) || userId; 
}
export const getTeacherName = (userId, thrNames, ut, t) => {
    const utName = thr => (thr? (ut(thr.nameEng, thr.nameChi) || userId): '');  
    return userId? utName(thrNames?.[userId]): t("oupc-name");
};

export const CpECtnViews = {
    [_ExCtType_Qtn]: ViewExCtnQ, 
    [_ExCtType_Txt]: ViewExCtnTxt,
    [_ExCtType_Img]: ViewExCtnImg,
    [_ExCtType_CK]: ViewExCtnCK, 
    [_ExCtType_Video]: ViewExCtnVideo,
    [_ExCtType_Mp3]: ViewExCtnAudio,
    [_ExCtType_Lnk]: ViewExCtnLnk,
};

export const ContentBlock = (type, ex='') => {
    if(type === _ExCtType_Qtn) 
        return {type, QIds:ex};
    else if(type === _ExCtType_Txt)
        return {type, en:{align:'left'}, ct:{align:'left'}};
    else if(type === _ExCtType_Img)
        return {type, en:{displayTitle:1,enlarge:1,align:'center'}, ct:{displayTitle:1,enlarge:1,align:'center'}}
    else if(type === _ExCtType_Video)
        return {type, en:{videoType:'yt',displayTitle:1,align:'center'},ct:{videoType:'yt',displayTitle:1,align:'center'}}
    else if(type === _ExCtType_Mp3)
        return {type, en:{audioType:'yt',displayTitle:1,audioUI:'ply'},ct:{audioType:'yt',displayTitle:1,audioUI:'ply'}}
    else if(type === _ExCtType_Lnk)
        return {type, en:{displayTitle:1},ct:{displayTitle:1}}
    else return {type, en:{}, ct:{}};
};

const TabExerContent = ReduxBind(props => {
    const {ex, fieldErrs, setFieldErrs, setFields, opts, setOpts, showEn, hasEn, hasCt, clickLang, showPub, lock,
        mediaDLs, getMediaDLs, setOnAddMedia, addLocalMedias, qtns, useCacheQtns, tagMSets, useTagMSets} = props;
    const {dispatch} = props;
    const [ t ] = useUILang();

    const [PVMode, setPVMode] = useState(0);
    const [visExPvOpts, setVisExPvOpts] = useState(0);
    const [visExPv, setVisExPv] = useState(0);
    const [visEd, setVisEd] = useState(0);
    const [ed, setEd] = useState();
    const [newC, setNewC] = useState({});

    const ECtns = toAry(ex.ECtns); 

    const [addEContent, replaceContent, deleteContent, moveBlock, insertBlock] = useChain(setFields, 'ECtns'); 
    const clickDelI = idx => e => {
        UI.stopEvent(e);
        //console.log("ECtns[idx]"+idx, ECtns[idx]?.type);
        const mm = ECtns[idx]?.type===_ExCtType_Qtn?"delQ":"delM";
        deleteContent(idx);
        //new msg
        popAlertToast(dispatch,0,mm);
    };

    const newClose = save => {
        if (save) {
            const currentUser = props.userId();
            
            if (currentUser) ed.modUid = currentUser;
            if (ed.type === _ExCtType_Qtn) {
                const QArr = ed.QIds.split(',');
                if (QArr.length > 1) { // change to insert all questions
                    // for AT exe multi question id qtn add
                    QArr.forEach(qid => { addEContent(ContentBlock(_ExCtType_Qtn, qid)); });
                    //QArr.reverse().forEach(qid => { addEContent(ContentBlock(_ExCtType_Qtn, qid)); });
                } else addEContent(ed); // change to insert
            } else addEContent(ed); // change to insert
        } else {setNewC({})};
        setVisEd(0);
    };

    const edProps = {...props, replaceECtn:setEd, ECtn:ed, vis:visEd, close:newClose};
    const clickAdd = ctnType => e => {
        UI.stopEvent(e);
        setEd(ContentBlock(ctnType));
        setNewC({View:CpECtnViews[ctnType], type:ctnType});
        setVisEd(1);
    };

    if(visEd) return <LayerECtnEdit {...edProps} {...newC}/>;

    const clickInsI = idx => e => {
        UI.stopEvent(e);
        insertBlock(idx, deepCopy(ECtns[idx]));
        //new msg
        //console.log("ECtns[idx]"+idx, ECtns[idx]?.type);
        const mm = ECtns[idx]?.type===_ExCtType_Qtn?"copyQ":"copyM";
        popAlertToast(dispatch,0,mm);
    };
    //const clickMoveI0 = (idx, newIdx) => e => {UI.stopEvent(e); moveBlock(idx, newIdx); };
    const clickMoveI = (idx, newIdx) => e => {UI.stopEvent(e);
        let tmp = newIdx;
        if (tmp < 0) { tmp = ECtns.length - 1; };
        if (tmp >= ECtns.length) { tmp = 0 };
        //moveBlock(idx, newIdx);
        moveBlock(idx, tmp);
    };
    /*
    const clickPV = e => { UI.stopEvent(e); 
        const [fields, fieldErrs] = props.normalizePV(ex);
        if (!hasErr(fieldErrs)){ setVisExPvOpts(1); }
    };
    */
    const ctProps = {ex, showPub, showEn, hasEn, hasCt, lock, insertBlock, replaceContent, clickDelI, clickMoveI, clickInsI, opts, setOpts, 
        qtns, useCacheQtns, tagMSets, useTagMSets, mediaDLs, getMediaDLs, setOnAddMedia, addLocalMedias, fieldErrs, setFieldErrs };

    let QIndex = 0;
    return <>
        {aTErrDiv1(fieldErrs?.ECtns, t)}
        <div className='TabExerContent flexRowBetween f14'>
            <div className='flexRowStartFit'>
                {hasEn? <div className={'w '+clsQLang(showEn)} onClick={clickLang(langCodeEn)} >En</div>: ''}
                {hasCt? <div className={'w '+clsQLang(!showEn)} onClick={clickLang(langCodeCt)} >繁中</div>: ''}
            </div>
            <div className="m8 flexRowEndFit"><CpExerciseEditPreviewCTA {...{...ctProps, setVisExPv}} /></div>
        </div>
        {(!visExPv) && ECtns.map((ECtn, idx) => {
            if (ECtn.type === _ExCtType_Qtn) ++QIndex;
            return <ViewExCtn key={idx} {...{...ctProps, idx, ECtn, dispatch, isAT:1, displayIdx:QIndex}} />
        })}
        {false && (visExPvOpts? UI.ScreenMask(<PopSelPV {...{PVMode, setPVMode, setVisExPvOpts, setVisExPv}}/>): '')}
        {(lock || visExPv)? '': <CpExEditToolBar isAT={1} clickAdd={clickAdd} bgColor={'#0c8ce9'} 
            btnClass={' ATExEditToolBarBtn '} barClass={' ATExEditToolBar '}/>}
    </>;
});
export default TabExerContent; 

const copyTypes = new Set([_ExCtType_Txt, _ExCtType_Img, _ExCtType_CK, _ExCtType_Lnk]);
export const ViewExCtn = ReduxBind(props => {
    const { dispatch, lock, showEn, hasEn, hasCt, idx, ECtn, qtns, insertBlock, isAT, editQtn, addedQIds, qFilters, onAddQ,
        replaceContent, clickDelI, clickMoveI, clickInsI, tagMSets, useTagMSets, displayIdx,
        allDVis, asm, reload, isLastECtn, setLastLoaded} = props;
    const myUId = props.userId();

    const [ cache2d, useGetCache ] = useCaches(props);

    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const [visEd, setVisEd] = useState(0);
    const [ed, setEd] = useState();

    const [openMore, setOpenMore] = useVisDetSub(allDVis);
    const handleMore = e => { UI.stopEvent(e); setOpenMore(!openMore); };

    useEffect(()=>{
        if (isLastECtn && setLastLoaded) { if (isLastECtn(idx)) setLastLoaded(idx); };
    },[]);

    const type = ECtn?.type;
    const isQu = (type === _ExCtType_Qtn);
    const QID = toUniIdAry(toStr(ECtn?.QIds).split(','))[0];
    
    const fullQ = QID && qtns[QID];
    const isAutoMark = fullQ?.autoMark;
    const {isCopy:qCP, isEdited:qED, isNew:qNew} = toObj(fullQ);

    const quid = fullQ?.userId; //&& myUId; //hack (all non oup qtn show ex owner name);
    const CtnUid = quid || ECtn?.modUid;

    const isOupCtn = isAT? 1: (CtnUid? 0: 1);
    const thrNames = useGetCache('teachers', '*'); 
    const qOwner = (isOupCtn? t("oupc-name"): getTeacherName(CtnUid, thrNames, ut, t));

    const thrEdit = (!lock) && (isAT || (isOupCtn && fullQ?.allowTeaCopyEdit) || (!isOupCtn) || (!isQu) );

    const clickEditQ = e => { UI.stopEvent(e); 
        if (type === _ExCtType_Qtn && (!isAT)) {
          editQtn && editQtn(idx, ECtn);
          return;
        };
        setEd(ECtn); setVisEd(1);    
    };
    const editClose = save => {
        if (save) {
            // modified by user
            const currentUser = props.userId();
            if (currentUser) ed.modUid = currentUser;
            
            if (ed.type === _ExCtType_Qtn) {
                const QArr = ed.QIds.split(',');
                
                if (QArr.length >= 1) {
                    replaceContent(idx, {...ed, QIds:QArr[0]}); // replace the original question block qid
                    if (QArr.length > 1) {
                        QArr.splice(0,1); // first id already updated, remove it
                        QArr.reverse().forEach(qid => { // auto insert extra new question id,  +1 to index append
                            insertBlock(idx+1, ContentBlock(_ExCtType_Qtn, qid));
                        });
                    };
                } else replaceContent(idx, ed);
            } else replaceContent(idx, ed);
        };
        setVisEd(0);
    };

    const View = CpECtnViews[type];
    const edProps = {...props, View, type, ECtn:ed, replaceECtn:setEd, vis:visEd, close:editClose};
    if(visEd) return <LayerECtnEdit {...edProps} />;

    const canCopy = isAT && (!lock) && (showEn? hasCt: hasEn) && copyTypes.has(type);
    const copyText = 'Copy to '+ (showEn? 'Chinese': 'English');
    const clickCopy = clickConfirm(dispatch, 'Please Confirm '+ copyText, () => { 
        const en = showEn? ECtn.en: deepCopy(ECtn.ct);
        const ct = showEn? deepCopy(ECtn.en): ECtn.ct;
        replaceContent(idx, {...ECtn, en, ct}) 
    });

    const editOptions = ent2DropdownItem( [
        thrEdit && ['cp', t("copy")], 
        ['rm', t("delete")], 
        ['up', t("move-up")], 
        ['down', t("move-down")]
    ].filter(kv => kv));

    const handDDClick = flag => {
        if (flag==='cp'){ clickInsI(idx)();
        }else if (flag==='rm'){ clickDelI(idx)();
        }else if (flag==='up'){ clickMoveI(idx, idx-1)();
        }else if (flag==='down'){ clickMoveI(idx, idx+1)();
        }
    };
    //<div className={"rounded border border-1 border-dim-350 shadow-200 overflow-hidden"}>
    return <div id={'Ctn-'+idx} key={idx} className={"ViewExCtn mb-3 sequenceListAnimation exercise-preview-card w100"}>
        <div id={isQu? 'QID-'+QID: 'Ctn-'+idx} className={"rounded border border-1 border-dim-350 shadow-200 "}>
            <div className={"d-flex justify-content-between align-items-center gap-2 card-header"}>
                <span className={"d-flex gap-2"}>
                    <span className={`d-flex flex-column justify-content-center align-contents-center semi-bold px-3 py-2 ${isQu ? "border-end border-dim-300" : ""}`}>
                        {isQu? <span>{displayIdx}</span>: <span className={"text-capitalize"}>{t(UIDisplayName[type])}</span>}
                    </span>
<BtnPopDev txt={isQu? 'fullQ': 'ECtn'} >{preJS(isQu? fullQ: ECtn, 3)}</BtnPopDev>
                        { isQu? <span className={"d-flex align-items-center gap-1"}>
                            {isAutoMark? CpAutoMarkTag(): CpManMarkTag()}
                        </span>:''}    
                </span>
                {lock? '': <span className={"d-flex gap-2 fs-6 align-items-center"}>
                    {canCopy? <span className={"d-flex align-items-center gap-2 pe-2"}>{CpATBtn(copyText, 'btnDLTmp', clickCopy )}</span> :'' }
                    <span className={"d-none d-md-flex align-items-center gap-1 pe-2"}>
                        <span role={"button"} className={"btn action-btn"} onClick={clickMoveI(idx, idx-1)}><CpIco src={IconList.general.orderUp}/></span>
                        <span role={"button"} className={"btn action-btn"} onClick={clickMoveI(idx, idx+1)}><CpIco src={IconList.general.orderDown}/></span>
                    </span>
                    <span className={"d-none d-md-flex vr"}/>
                    <span className={"d-flex align-items-center gap-2 pe-2"}>
                        {thrEdit? <span role={"button"} className={"btn action-btn"} onClick={clickEditQ}><CpIco src={IconList.general.edit}/></span>: ''}
                        {thrEdit? <span role={"button"} className={"btn action-btn d-none d-md-block"} onClick={clickInsI(idx)}><CpIco src={IconList.general.copy}/></span>: ''}
                        <span role={"button"} className={"btn action-btn d-none d-md-block"} onClick={clickDelI(idx)}><CpIco src={IconList.general.trash}/></span>
                        <CpDropdown items={editOptions} iconPath={IconList.general.moreVertical} className={"d-block d-md-none more-dropdown"} cb={handDDClick}/>                 
                    </span>
                </span>}
                {onAddQ && <span className={"d-flex gap-3 fs-8"}>
                    <span role={"button"} className={`btn add-question-btn ${addedQIds.includes(QID) ? "add-again" : ""} semi-bold rounded d-flex gap-2 align-items-center px-2 py-1 me-2`}
                        onClick={() => onAddQ(QID, qFilters)}><CpIco src={IconList.general.add}/>
                    <span className={"fs-8"}>{t(addedQIds.includes(QID)? "add-again": "add-question")}</span></span>
                </span>}
            </div>
            <div className={`position-relative bg-preview-bg-color pe-none user-select-none`}>
                {ATErrsDiv2(ECtn.errs, 'tal p4', t)}
                {View && <View {...{...props, lock:1}} />}
            </div>    

            <div className={"px-3 py-2 card-footer"}>
            {false && preJS({qCP, qED, qNew})}
                <div className={`d-flex justify-content-between align-items-center ${isQu ? "" : "py-1"}`}>
                    <span className={"d-flex gap-2 semi-bold text-truncate align-items-center"}>
                        <span className={"fs-8"}>{t("exercise-edit-by")}:</span>
                        <span className={"d-flex gap-2 align-items-center text-truncate"}>
                            <span className={"fs-8"}>
                                <CpIco src={isOupCtn? IconList.brand.oupc: IconList.general.avatar}/>
                            </span>
                            <span className={"fs-8 text-truncate"}>{qOwner}</span>
                        </span>
                    </span>
                    {isQu && <span role={"button"} className={`btn action-btn`} onClick={handleMore}>
                            <CpIco src={IconList.general.moreHorizontal}/>
                    </span>}
                </div>
                {(isQu && fullQ && openMore)? <CpQViewExt {...{fullQ, asm, reload, showEn, tagMSets, useTagMSets}} />: ''}
            </div>                    
        </div>
    </div>;        
});

export const LayerECtnEdit = props => {
    const {dispatch, View, type, vis, close, lock, hasEn, hasCt, isAT, ECtn, setQtnModified } = props;
    const [showEn, setShowEn] = useState(props.showEn);
    const [canRender, setCanRender] = useState(true);
    const [visOpt, setVisOpt] = useState(0);
    const [ectnModified, setEctnModified] = useState(0);
    const [pv1Q, setPv1Q] = useState(0);
    const [ t ] = useUILang();
    
    const replaceECtn = (v) => {
        
        setEctnModified(1);
        const _f = props.replaceECtn;
        _f && _f(v);
    };

    const clickClose = save => e => { 
        
        UI.stopEvent(e); setEctnModified(0); close(save);};
    const clickEn = en => e => {
        UI.stopEvent(e);
        setCanRender(false);
        setShowEn(en);
        setTimeout(() => {
            setCanRender(true);
        }, 10);
    };
    const clickPV = (e) => {UI.stopEvent(e); setPv1Q(1);};
    if(!vis) return '';

    const closePV = () => {setPv1Q(0);};
    const jsxProps = {...props, PVMode:0, showEn, lock:1, preview:1};
    if (pv1Q) return <CpATQtnSingle key={'pvECtn'} jsx={<View {...jsxProps}/>} close={closePV} doWhat={'preview'}/>;

    const langBtn = (icon, bgColor, cb) => {
        return iconButton('',icon,'white',bgColor,cb,true,{transform:'scale(1.8)'},{width:'40px', marginLeft:'5px'});
    };
    const viewProps = {...props, replaceECtn: replaceECtn, showEn, setEctnModified};
    const baseColor = '#2995cd';
    const dimColor = '#749ab3';
    const hasSetting = (type !== _ExCtType_Qtn && type !== _ExCtType_CK);  
    return UI.ScreenMask(<>
        <div className="CPExEditContainer f16">
            <div className="CPExEditHeader">
                <div className="flexRowStartFit semi-bold">
                    {t('at-setting.exercise.media.title')+ ' - ' +t(UIDisplayName[type])}
                    {(isAT && hasEn)?langBtn('language/en',showEn?baseColor:dimColor,clickEn(1)):''}
                    {(isAT && hasCt)?langBtn('language/tc',showEn?dimColor:baseColor,clickEn(0)):''}
                </div>
                <div className="flexRowStartFit f14 gap-2">
                    <CpPromptButtonComponent variant={"at-primary-dim semi-bold hidden-action-btn-text-md"}
                        title={t('cancel')} icon={<CpIco src={IconList.general.delete}/>}
                        truncate={false}
                        cb={clickClose(0)} promptTitle={t("warning.warning_tips")}
                        promptDesc={t("warning.warning_confirm_without_saving")}
                        promptCancel={t("cancel")}
                        promptConfirm={t("confirm")} size={SIZE.MD} dispatch={dispatch}/>
                    {((ECtn?.type) !== _ExCtType_Qtn)?<CpActionButtonComponent variant={"outline-at-primary semi-bold hidden-action-btn-text-md"} title={t('preview')}
                        truncate={false} icon={<CpIco src={IconList.general.preview}/>}
                        size={SIZE.MD} onClick={clickPV} />:''}
                    <CpActionButtonComponent variant={"at-primary semi-bold hidden-action-btn-text-md " 
                        + (ectnModified?"":" disableBtn disable-option ") }
                        title={t('save')} truncate={false} icon={<CpIco src={IconList.general.save}/>}
                        size={SIZE.MD} onClick={clickClose(1)} />                    
                </div>
            </div>
            {false && <div className="w">{preJS({showEn, lock})}</div>}
            {canRender?<div className='LayerECtnEditTop'>
                <div className='flexColStart'>
                    <div className='ExOptionsBtn'>                       
                        {hasSetting?<CpActionButtonComponent variant={"borderless-at-primary-dim"} title={t('setting')}
                            icon={<CpIco src={IconList.general.setting}/>}
                            onClick={()=>setVisOpt(!visOpt)}/>:''}
                    </div>
                    <View {...viewProps}/>
                </div>
                <ViewExCtnSetting whoami={type} {...viewProps} vis={visOpt} setVis={setVisOpt}/>
            </div>:''}
        </div>
    </>,{overflow:'unset'});
};
/*
                iconButton(t('setting'),'general/setting','white','#749ab3',()=>setVisOpt(!visOpt),true,{width:'20px',height:'20px', marginRight:'5px'})
                    <CpPromptButtonComponent variant={"at-primary-dim semi-bold hidden-action-btn-text-md"}
                        title={t('cancel')} icon={<CpIco src={IconList.general.delete}/>}
                        truncate={false}
                        cb={clickClose(0)} promptTitle={t("prompt.leave.title")}
                        promptDesc={t("prompt.leave.desc")}
                        promptCancel={t("prompt.leave.cancel")}
                        promptConfirm={t("prompt.leave.confirm")} size={SIZE.MD}/>
*/
const preBtn = (txt, cb, color) =>  UI.colorButton('id'+txt,txt,cb,color,'30%', {color:'#fff', margin:'2px'});

const PopSelPV = props => {
    const {PVMode, setPVMode, setVisExPvOpts, setVisExPv} = props;
    const clickMode = s => e => { UI.stopEvent(0); setPVMode(s); }; 
    const clickClose = p => e => { UI.stopEvent(0); setVisExPv(p); setVisExPvOpts(0); };

    const prevSelBtn = (icon, cb, choose) => iconButton('', icon, choose? '#ec8008': '#b4b4b4', '#fff', cb, true, {height: '100px', width: '100px'},
        {borderRadius:'8px', border:'4px solid '+ (choose? '#ec8008': '#b4b4b4'), height:'120px', width:'150px', marginRight:'5px'});

    return <>
        <div className="screenMask2"></div>
        <div className='cssAlert'>
            <div className='ATExerSelLayer'>
                <div className='flexRowStart m5 f20'>Preview Mode</div>
                <div className='flexRowStart m5'>Please select exercise mode to preview:</div>
                <div className='flexRowCenterFit'>
                    {prevSelBtn('presentation/practiceMode', clickMode(0), !PVMode)}
                    {prevSelBtn('presentation/assessmentMode', clickMode(1), PVMode)}
                </div>
                <div>Selected: {PVMode? 'scroll': 'slide'} Mode</div>
                <div className='flexRowCenter'>
                    {preBtn('Cancel',clickClose(0), '#b4b4b4')}
                    {preBtn('Confirm',clickClose(1), '#ec8008')}
                </div>
            </div>
        </div>;
    </>;
};



