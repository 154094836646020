import { mapTagAtt } from "../libs/libHtml";
import { aryLen, isAry, isObj, toAry, toInt, toObj, toStr } from "../libs/libType";
import { _MaxLenIds, _MaxLenQId, _MaxLenTodo, _MinLenQId, _MaxExName} from "./ATConsts";
import { _REME_METATYPES } from "./ATMetaTypes";
import { toIdAry, toIdStr, toUniIdAry, toUniIdsStr, validFields, validIdCharsMinMax, validMinMax } from "./ATValidate";
import { _ExCtType_Qtn, normalizeECtn, SplitECtnsQs, validEditECnts } from "./ATValidateEcnts";
import { normalizeRemeRule } from "./ATValidateReme";


const validMDisplays = (canShow, metas) => canShow? metas.filter(m => canShow.includes(m)): metas;

export const normalizedEditExer = (fields, opts, trace) => {
    //const track = o => trace && trace.push(o);
    //track('-- normalizedEditATQSet --');

    const fs = toObj(fields);
    
    const hasE = fs.ELangEn?1:0;
    const hasC = fs.ELangCt?1:0;

    const EMSubjId = toIdStr(fs.EMSubjId);
    const EMetaDisplays = toUniIdAry([EMSubjId, ...toAry(fs.EMetaDisplays)]);
    return {
        //Settings
        EId: toStr(fs.EId).trim(),

        ELangEn: hasE,
        ENameEn: hasE? toStr(fs.ENameEn).trim(): '',
        EKwEn: hasE? toUniIdsStr(fs.EKwEn): '',

        ELangCt: hasC,
        ENameCt: hasC? toStr(fs.ENameCt).trim(): '',
        EKwCt: hasC? toUniIdsStr(fs.EKwCt): '',

        ESubjIds: toUniIdAry(fs.ESubjIds),

        EModeSlide: fs.EModeSlide?1:0,
        EModeScroll: fs.EModeScroll?1:0,

        EMetaIDStr: toUniIdsStr(fs.EMetaIDStr),
        EMetaDisplays: validMDisplays(opts.draftMetaCanShows, EMetaDisplays),
    
        //Content Blocks ----- ----- ----- -----
        ECtns: SplitECtnsQs(fs.ECtns).map(I => { const { errs, ...i } = I; return normalizeECtn(i, hasE, hasC); } ), 
        //Next Step ----- ----- ----- -----
        ENexts: toAry(fs.ENexts).map(I => { const {errs, ...i } = I; return i; } ),
        ERemes: toAry(fs.ERemes).map(I => normalizeRemeRule(I)),

        //School Exere only       
        EMSubjId,
        EShare: fs.EShare?1:0,

        isNew : fs.isNew?1:0,
        isCopy: fs.isCopy?1:0,
        isAT: fs.isAT?1:0,

    };
};
export const normalizeEReme = _r => {
    const {err, ...r} = toObj(_r);
    return {
        ...r, 
        ruleNameCt: toStr(r.ruleNameCt).trim(),
        ruleNameEn: toStr(r.ruleNameEn).trim(),
        modUid: toIdStr(r.modUid),
        MSetId: toStr(r.MSetId),
        rmLv: toStr(r.rmLv),
        rmMark: toInt(r.rmMark),
        rmAB: toStr(r.rmAB),
        rmPass: toInt(r.rmPass),
    };
};
export const validEditExer = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validEditExer --');

    const v = validFields(fs, {});
    const {isNew, ELangEn, ELangCt} = fs;

    const isAT = fs.isAT || (opts?.isAT);

    //Settings
    if (isAT) {
        //error.error_title_120_limit
        v.setIf('EId', (validIdCharsMinMax(fs.EId, _MinLenQId , _MaxLenQId, 'Exercise ID')?
            'error.error_exercise_id_50_limit':''));
        v.setIf('ESubjIds', (!(fs.ESubjIds?.length)) && 'error.error_no_subject_selected'); 
    } else {
        toIdStr(fs.EMSubjId) || v.set('EMSubjId', 'error.error_no_subject_selected');
    };

    v.setIf('ELang', (!(fs.ELangCt || fs.ELangEn)) && 'error.error_no_language');

    v.setIf('ENameEn', fs.ELangEn && (validMinMax(fs.ENameEn, 1, _MaxExName, 'Name')?
        ['(%s) %s','languages.en','error.error_title_120_limit']:''));
    v.setIf('ENameCt', fs.ELangCt && (validMinMax(fs.ENameCt, 1, _MaxExName, 'Name')?
        ['(%s) %s','languages.zh','error.error_title_120_limit']:''));

    //EMetaIDStr: toUniIdsStr(fs.EMetaIDStr), 
    v.setIf('EMetaDisplays', (!isAry(fs.EMetaDisplays)) && 'Missing Filters');
    v.setIf('EMetaDisplays', (!fs.EMSubjId) && 'Missing Subject');

    v.setIf('EMode', (!(fs.EModeSlide || fs.EModeScroll)) && 'error.error_no_exercise_mode'); 

    v.setIf('EKwEn', (validMinMax(fs.EKwEn, 0 , _MaxLenIds, 'Keywords')?
        'error.error_keywords_1000_limit':''));
    v.setIf('EKwCt', (validMinMax(fs.EKwCt, 0 , _MaxLenIds, 'Keywords')?
        'error.error_keywords_1000_limit':''));

    //Next Step ----- ----- ----- -----
    v.setIf('ENexts', (!isAry(fs.ENexts)) && 'error.error_no_nextstep');
    
    //const rs = toAry(fs.ERemes);
    //v.setIf('ERemes', rs.some((b, i) => rs.some((a, j) => (j !== i) && isDupReme(a, b))) && 'error.error_at_repeated_question_scope');

    const nextErrs = [].concat(...toAry(fs.ENexts).map(n => {
        const {ruleNameEn, nameEn, mediaEn,  ruleNameCt, nameCt, mediaCt } = toObj(n);
        const isF = eNextIsFile(n);
        return [
            ELangEn && (!ruleNameEn) && ['(%s) %s','languages.en','error.error_no_rule_name'],
            ELangEn && (!ruleNameCt) && ['(%s) %s','languages.zh','error.error_no_rule_name'],
            isF && ELangEn && (!mediaEn) && ['(%s) %s','languages.en','error.error_no_file'],
            isF && ELangEn && (!nameEn) && ['(%s) %s','languages.en','error.error_no_filename'],
            isF && ELangCt && (!mediaCt) && ['(%s) %s','languages.zh','error.error_no_file'],
            isF && ELangCt && (!nameCt) && ['(%s) %s','languages.zh','error.error_no_filename'],
        ];
        /*
            ELangEn && (!ruleNameEn) && 'Missing Next Step Rule Name (En)',
            ELangEn && (!ruleNameCt) && 'Missing Next Step Rule Name (Ct)',
            isF && ELangEn && (!mediaEn) && 'Missing Next Step File (En)',
            isF && ELangEn && (!nameEn) && 'Missing Next Step Filename (En)',
            isF && ELangCt && (!mediaCt) && 'Missing Next Step File (Ct)',
            isF && ELangCt && (!nameCt) && 'Missing Next Step Filename (Ct)',
        */
    })).filter(e => e);
    if(nextErrs.length) v.set('ENexts', nextErrs);


    track('-- validEditExer end --');
    return v.fieldErrs;
};

export const clearRemes = (msets, ERemes, setERemes) => {
    const _msets = toObj(msets);
    const _ERemes = toAry(ERemes).filter(r => {
        const mset = _msets[r.MSetId];
        return (!mset) || _REME_METATYPES.includes(mset.MSType);
    });
    if(aryLen(_ERemes) < aryLen(ERemes)) setERemes(_ERemes);
};

export const isDupReme = (rem1, rem2) => {
    if(rem1 === rem2) return 1;
    const [a, b] = [toObj(rem1), toObj(rem2)];   
    if(a.rmLv !== b.rmLv) return 0;
    if(a.MSetId !== b.MSetId) return 0;
    return 1;
};

export const fixECtns = (ECtns, qids) => {
    return toAry(ECtns).filter(c => {
        if(!c.type) return;
        if(c.type !== _ExCtType_Qtn) return 1; 
        const i = toIdStr(c.QIds);
        return qids.includes(i);
    });
};

export const validPubExer = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validPubExer --');
    const editErrs = validEditExer(fs, opts, trace);
    const v = validFields(fs, editErrs);

    const {isAT, isNew} = fs;
    if(isAT || (!isNew)){
        //Content ----- ----- ----- -----
        if(isAry(fs.ECtns) && (fs.ECtns.length > 0)){
            validEditECnts(fs, opts, track, v);
        }else{
            v.set('ECtns', 'error.error_exercise_no_content');
        };
    }

    track('-- validPubExer end --');
    console.log('validPubExer', {fs, opts, trace, editErrs, v});
    return v.fieldErrs;
};

export const eNextIsFile = ENx => (ENx.dl !== 'l');

export const ECtnQIds = ECtns => [].concat(...toAry(ECtns).map(n => toIdAry(toStr(n.QIds).split(','))));
export const ECtnUniQIds = ECtns => toUniIdAry(ECtnQIds(ECtns));

export const exerQIds = ex => ECtnQIds(ex?.ECtns);
export const exerUniQIds = ex => toUniIdAry(exerQIds(ex));

export const exerMediaIds = ex =>  exerMapMedias(ex, {});

const mkMapM = (mSrcs, mMap) => src => { 
    src && mSrcs.push(src); 
    return (src && mMap[src]) || src;
};
export const exerMapMedias = (ex, mMap) => {
    const mSrcs = [];
    const mapM = mkMapM(mSrcs, mMap);
    const mapCK = ckhtml => {
        const [ckmedias, chhtmlOut] = mapTagAtt(ckhtml, 'oupid', mMap);
        mSrcs.push(...ckmedias);
        return chhtmlOut;
    };
    const [hasE, hasC] = [ex.ELangEn, ex.ELangCt];

    ex.ECtns = toAry(ex.ECtns).map((c, idx) => {
        //const type = c.type;
        if(hasE){
            if(isObj(c.en?.img)) c.en.img.oupid = mapM(c.en.img.oupid);   
            if((c.en?.ckData)) c.en.ckData = mapCK(c.en.ckData);   
            if((c.en?.mediaId)) c.en.mediaId = mapM(c.en.mediaId);
        }else{
            c.en = '';
        };
        if(hasC){
            if(isObj(c.ct?.img)) c.ct.img.oupid = mapM(c.ct.img.oupid);   
            if((c.ct?.ckData)) c.ct.ckData = mapCK(c.ct.ckData);   
            //if((c.ct?.mediaId)) c.ct.mediaId = mapM(c.ct.mediaId);
            if((c.ct?.ca?.mediaId)) c.ct.ca.mediaId = mapM(c.ct.ca.mediaId);
            if((c.ct?.pt?.mediaId)) c.ct.pt.mediaId = mapM(c.ct.pt.mediaId);
        }else{
            c.ct = '';
        };
        
        return c;
    });
    const nextMM = exerMapNextMedia(ex.ENexts, mMap);
/*
    ex.ENexts = toAry(ex.ENexts).map(n => {
        const isF = eNextIsFile(n);
        n.mediaEn = isF? mapM(n.mediaEn): '';
        n.mediaCt = isF? mapM(n.mediaCt): '';
        return n;            
    }); 
*/
    const mapIds = toUniIdAry([...mSrcs, ...nextMM]);
    return mapIds;
};
export const exerMapNextMedia = (ENexts, mMap) => {
    if(!isAry(ENexts)) return [];
    const mSrcs = [];
    const mapM = mkMapM(mSrcs, mMap);
    ENexts.forEach(n => {
        const isF = eNextIsFile(n);
        n.mediaEn = isF? mapM(n.mediaEn): '';
        n.mediaCt = isF? mapM(n.mediaCt): '';
    });
    return toUniIdAry(mSrcs);
};
