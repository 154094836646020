import React, {useState, useMemo} from "react";
import CpIco from "../_components/CpIco";
import { langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import { _ARS_CLOSE_RESET, _ARS_CLOSE_RESUME, _ARS_CLOSE_SUBMIT, _AST_IN_PROGRESS, _AST_PENDING } from "../../consts/ATConstsAssignment";
import { exerHasMark } from "./Tags/TagMarkingTag";
import { formatedDateTime } from "../_components/CpDate";
import { stopEvent } from "../../libs/libUI";
import { useEditDraftPub } from "../../AppExPf/utils/useEditDraftPub";
import { CpSettingModalText } from "../_components/CpSettingModalTabCps";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { PresentationMode } from "../../poc/type/PresentationMode";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { objKeys, toAry, toObj, toStr, toInt } from "../../libs/libType";
import { preJS, deepCopy } from "../../AppExPf/AppUtil";
import { CpAssignOffCanvas, useAssignmentEdit } from "./CpExerciseEditAssignCTA";
import CpMessageBox from "../_components/CpMessageBox";
import { CpSettingModalSection, CpSettingModalTabContainer, CpSettingModalTitle } from "../_components/CpSettingModalTabCps";
import CpActionButton from "../_components/CpActionButton";
import { useMemoClsName } from "../_components/CpAssignToTag";
import { ReduxBind } from "../../saga/ReduxState";
import { getT2bEnd, getT2bSrt } from "./CpAssignmentList";


const TabTeacherAssignmentSettings = ReduxBind(props => {
    const {dispatch, reload} = props; 
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();

    const _asm = toObj(props.asm);
    const {Exer, ...asm} = _asm;
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);

    const [ cache2d, useGetCache ] = useCaches(props);
    const yearClass2d = useGetCache('yearClass2d', '*');
    const classId = toStr(asm.classId);
    const clsName = useMemoClsName(yearClass2d, toStr(classId), ut, uiEn); // Bon useMemoe, because Bon know it should
    const studentIds = toAry(asm.studentIds);
    const clsStudents = useGetCache('classStudents', classId);
    const wholeCls = useMemo(() => {
        const sset = new Set(studentIds);
        return objKeys(clsStudents).some(i => !sset.has(i))? 0: 1;
    }, [asm, clsStudents]);

    const showEn = langIsEn(asm.ALang); 
    const isSlide = (asm.AMode === PresentationMode.SLIDE_MODE);

    const [started, ended] = [ asmStatStarted(asm), asmStateEnded(asm) ]; 
    const [lockCls, lockTime, lockSet] = [started, started, started];

    const modeTxt = t(isSlide? "assignment-settings-slide-mode": "assignment-settings-scroll-mode")
    const modeIco = isSlide? IconList.presentation.practiceMode: IconList.presentation.assessmentMode;

    const useAsmEdit = useAssignmentEdit();
    const {editAsm, setEditAsm} = useAsmEdit; 
    const clickEdit = tab => e => {stopEvent(e); setEditAsm(deepCopy(_asm), reload, tab); };

    const AStartTime = getT2bSrt(asm);
    const AEndTime = getT2bEnd(asm);

    const debASM = (asm) => { const {
        code, EId, Exer, EQIds, dateMod, modUid, dateAdd, addUid, 
        AState, assignId, AHints, ALang, AMode, ARand, 
        studentIds, Stat, userId, year, classId, schoolId, 
        mark, ttl, submit, sumMark, 
        ...x} = toObj(asm); return x; }
    
    const attTimes = (asm?.attempt)?toInt(asm?.attTimes):(hasM?1:t('unlimited'));

    return <CpSettingModalTabContainer className='TabTeacherAssignmentSettings' paddingX={0}>
        <CpSettingModalSection paddingX={0}>
            <div className={"d-flex justify-content-between w-100"}>
                <CpSettingModalTitle>{t("assignment-edit-tab-settings-class-student")}:</CpSettingModalTitle>
                {lockCls? '': <CpActionButton title={t("edit")} iconPath={IconList.general.edit}
                    className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"}
                    hiddenText={false} onClick={clickEdit('cls')}/>}
            </div>
            <div className={"d-flex flex-column flex-md-row ms-3 ms-md-5 gap-3 gap-md-5"}>
                <span className={"d-flex gap-2"}>
                    <span className={"semi-bold"}>{t("assignment-settings-year")}</span><span>{toStr(asm.year)}</span>
                </span>
                <span className={"d-flex gap-2"}>
                    <span className={"semi-bold"}>{t("assignment-settings-class")}</span>
                    <span>{clsName} ( {wholeCls?t('whole-class'): (studentIds.length)} )</span>
                </span>
            </div>
        </CpSettingModalSection>
        <CpSettingModalSection paddingX={0}>
            <div className={"d-flex justify-content-between w-100"}>
                <CpSettingModalTitle>{t("assignment-edit-tab-settings-duration")}:</CpSettingModalTitle>
                {lockTime? '':<CpActionButton title={t("edit")} iconPath={IconList.general.edit}
                    className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"}
                    hiddenText={false} onClick={clickEdit('time')}/>}
            </div>
            <div className={"d-flex flex-column flex-md-row ms-3 ms-md-5 gap-3 gap-md-5"}>
                <span className={"d-flex gap-2"}>
                    <span className={"semi-bold"}>{t("assignment-settings-from")}:</span>
                    <span>{AStartTime}</span>
                </span>
                <span className={"d-flex gap-2"}>
                    <span className={"semi-bold"}>{t("assignment-settings-to")}:</span>
                    <span>{AEndTime}</span>
                </span>
            </div>
        </CpSettingModalSection>
        <CpSettingModalSection paddingX={0}>
            <div className={"d-flex justify-content-between w-100"}>
                <CpSettingModalTitle>{t("assignment-edit-tab-settings-behaviour")}:</CpSettingModalTitle>
                {lockSet? '': <CpActionButton title={t("edit")} iconPath={IconList.general.edit}
                    className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"}
                    hiddenText={false} onClick={clickEdit('set')}/>}
            </div>
            {hasA? <div className={"w-100"}><div className={"d-flex mw-100 ps-0 ps-md-5"}><CpWarnBoxAsmMark {...{hasM}} /></div></div> :''}
            <table className={"table-fit-column mx-3 mx-md-5"}><tbody className={"align-top"}>
                {rowGap( t("assignment-settings-exercise-behaviour") )}
                {row(t("assignment-settings-exercise-language"), 
                    t(showEn? "assignment-settings-english": "assignment-settings-chinese"))}
                {row(t("assignment-settings-exercise-mode"), 
                    <span className={`d-flex gap-2 justify-content-start align-items-center`}>
                        <span className={"fs-2"}><CpIco src={modeIco}/></span>
                        <span className={""}><CpSettingModalText>{modeTxt}</CpSettingModalText></span>
                    </span>
                )}
                {row(t("assignment-settings-no-of-attempt"), attTimes + ' ' + t('assignment-settings-times'))}
                {row(t("assignment-settings-display-scores-and-answers"), t(asmShowScoreTxt(asm.showScores)))}
                {/*row(t("assignment-settings-display-answers-and-answer-keys"), t("assignment-settings-options-all-result-published"))*/}    
                {row(t("assignment-settings-publish-all-results"), t(asmResultTxt(asm.pubResults, asm.showResults)))}
                {row(t("assignment-settings-time-limit"), asm.limitMin? toStr(asm.minsToGo) +' '+ t("assignment-settings-minutes"): t('assignment-settings-unlimited'))}
                {row(t("assignment-settings-hints"), t(asm.AHints? "assignment-settings-show": "assignment-settings-hidden"))}
                {rowGap( t("assignment-settings-anti-cheating") )}
                {row(t("assignment-settings-shuffle-choices"), t(asm.ARand? "assignment-settings-random": "assignment-settings-not-random"))}
                {row(t("assignment-settings-student-close-the-app-without-submission"), t(asmResumeTxt(asm.AResume)))}    
            </tbody></table>
        {preJS(debASM(asm), 3)}
        </CpSettingModalSection>
        {editAsm? <CpAssignOffCanvas {...{useAsmEdit}} />: ''}
    </CpSettingModalTabContainer>;
});
export default TabTeacherAssignmentSettings;

const rowGap = group => <tr><td colSpan={2} className={"pb-3 semi-bold"}>{group}:</td></tr>;
const row = (title, value) => {
    return <tr>
        <td className="d-inline-block w-100 d-md-table-cell semi-bold p-0 pb-0 pb-md-4 ps-3 ps-md-5" valign={"top"} style={{maxWidth: "500px"}}>
            <span className={"text-wrap"}>{title}</span>
        </td>
        <td className="d-inline-block w-100 d-md-table-cell p-0 pb-4 pb-md-4 ps-3 ps-md-5 px-md-0" valign={"top"} >
            <span className={"text-nowrap"}>{value}</span>
        </td>
    </tr>;
};

export const AStatStarted = s => s && (s !== _AST_PENDING);   
export const asmStatStarted = a => AStatStarted(a?.AState); 

export const AStateEnded = s => (s) && (s !== _AST_IN_PROGRESS) && (s !== _AST_PENDING);   
export const asmStateEnded = a => AStateEnded(a?.AState);

export const asmEndTime = asm => {
    const { timeEnd, time2beEnd } = toObj(asm);
    return (timeEnd || asmStateEnded(asm)) ?time2beEnd: 0;
};

export const asmStartTime = asm => getT2bSrt(asm); 

export const CpWarnBoxAsmMark = (props) => {
    const [t] = useUILang();
    const title = t(props.hasM? "exercise-contains-two-marking": "assignment-settings-the-exercise-contain-auto-marked");   
    return <CpMessageBox item={{mode: "warning", style: "dot", content: {title, list: []} }}/>;
};
    
const asmShowScoreTxt = ss => (ss ==='pub'? "assignment-settings-options-all-result-published":
    ss === 'mark'? 'assignment-settings-options-each-assignment-manually-marked':
    ss === 'sub'? 'assignment-settings-options-student-submission': 
    ss === 'att'? "assignment-settings-options-all-attempts-completed":
    ss ==='dead'? "assignment-settings-options-deadline":
    ss
);

const asmResultTxt = (pr, sr) => (pr?(
    sr === 'dead'? "assignment-settings-options-deadline":
    sr === 'sub'? "assignment-settings-options-all-student-submitted":
    '('+pr+','+sr+')'
    ):'assignment-settings-manually'
);

const asmResumeTxt = (re) => (
    re === _ARS_CLOSE_RESUME? "assignment-settings-allow-resume":
    re === _ARS_CLOSE_SUBMIT? "assignment-settings-count-as-submitted":
    re === _ARS_CLOSE_RESET? 'assignment-settings-reset':
    "--"
);

