import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { asyncApiCallLoad_, asyncApiCall_ } from '../../libs/awsFuncs';
import { fileExt, humanFileSize, StrShorten } from '../../libs/libFormat';
import { objEntries, objKeys, objVals, toAry, toInt, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';
import { aTErrTxt1, ATUICheckBox0, aTUIMust, CpATBtn, Loader, preJS, autoId, readUploadText } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import { ATDo_QImp, _ATCanDo } from '../../consts/ATRoleRights';
import { __SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL, hasDrawQ } from '../../consts/ATSysQType';
import { validEditATQtnV2 } from '../../consts/ATValidateQ';
import { arrayAddOrDelete, arrayForEachAsync } from '../../libs/libArray';
import { downloadUrl } from '../../libs/libDownload';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import CpATSubjectTickList from '../components/CpATSubjectTickList';
import { subState } from '../utils/useChain';
import { hasErr, useEditDraftPub } from '../utils/useEditDraftPub';
import { mediaUpFile } from '../utils/useMediaCache';
import { _maprow, chkRepeatHead, ckImgsSetQ, maphead, mkMustHaveChecker, mkflat, pushIf } from './utilQImport';

import 'bootstrap/dist/css/bootstrap.min.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toIdStr, toUniIdAry, toUniIdsStr } from '../../consts/ATValidate';
import { CpFileCard, CpSavingMask, checkQIDs, fileToItem, useKeyList } from './PageATQImport';
import { csvToRows } from '../ATMetaEdit/PageATMetaEdit';


const [maxCsv] = [1, 1000];
const [maxFSize] = [5 * 1024 * 1024];

const PageATQMetas = ReduxBind((props) => {
  const { dispatch } = props;
  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
  const canQImp = _ATCanDo(role, ATDo_QImp);

  const [impRet, setImpRet] = useState(); 
  const [saving, _setSaving] = useState();
  const setSaving = (msg, ttlQ, doneQ, ttlM, doneM) => _setSaving({msg, ttlQ, doneQ, ttlM, doneM});
  const unsetSaving = () => _setSaving();
  
  const [mLnks, setMLnks] = useKeyList();

  const [ csvErrs, setCsvErrs ] = useState([]);
  const [ csvs, setCsvs, addCsv, delCsv, csvKeys, csvItems ] = useKeyList();
  const [ errs, setErrs, addErr, delErr, errKeys, errItems, ] = useKeyList();

  const [fields, setFields, setField,  setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub] = useEditDraftPub();
  
  const csv1 = objVals(csvs)[0];
  const lock = 0;

  const onDrop = addFiles => {
    addFiles.forEach( addFile => {
        const item = fileToItem(addFile);
        const err = '';//validUpdateItem(item);
        if(err){
            addErr(err);
        }else{
            if('csv' === fileExt(item.name)){
              setCsvs(ms => ( { ...toObj(ms), csv1: item } ) );  
            }
        }
    });
  };
  
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  const dropProps = getRootProps();
  const DropCfg = lock? {...dropProps, onClick:UI.stopEvent} : dropProps;

  const unUp = (deleter, k) => lock? undefined: (e => { UI.stopEvent(e); deleter(k); });

  const clickUp = async e => { UI.stopEvent(e); clickUpC(); }; 
  const clickUpC = async () => {
    console.log('clickUpC');
    const [fs, fes] = [{}, {}];
    do{
      if(!csv1) fes.csv1 = 'Please Upload CSV';
      setFields(fs);
      setFieldErrs(fes);
      
      if(hasErr(fes)) break;

      const csvRes = await loadCheckQMetaCSV(csv1);
      const {hc, headErrs, qrows, qs, errqs, csvErrs, rowErrs} = csvRes;

      if(!hasErr(headErrs) ){
        await checkQIDs(dispatch, csvRes, 0);
      }
      const mixerrs =  [...csvErrs, ...objEntries(rowErrs).map( ([r, e]) => ('Row '+r.split('_')[1]+': '+e ) ) ];
      setCsvErrs( mixerrs );

      if((!qs.length) || (mixerrs.length))
        break;
     
      const updateQ = (ttlq, doneQ) => { 
        setSaving('Updating Question', ttlq, doneQ, 0, 0); 
      };
      const ir = await asyncUpdateQs(dispatch, qs, updateQ);
      setImpRet(ir);
    }while(0);

    unsetSaving();
  };


  if(impRet){ 
    const clickReset = e => { UI.stopEvent(e);  setCsvErrs([]); setCsvs({}); setErrs({}); setFieldErrs({}); setImpRet(0); };
    const qok = impRet.filter(ir => (ir.res?.state === 'ok'));
    const qerr = impRet.filter(ir => (ir.res?.state !== 'ok'));
    const mapId = s => s.length? 
    s.map(s => {
      const id = s.q.QId;
      return <div key={id} className="w m4 f12">{id}</div>
    }):
    <div className="m4 f12">(no question)</div>;
    return <div className="atpage">
      <div className="atpagehead f16">Questions / Batch Update Metadata</div>
      <div className="atrow"><div className="fieldhead f14" ><b>Question(s) Updated:</b> </div>{mapId(qok)}</div>
      <div className="atrow"><div className="fieldhead f14" ><b>Question(s) Rejected:</b> </div>{mapId(qerr)}</div>
      {CpATBtn('Update More', 'be', clickReset, lock)}
    </div>;
  }; 

  const clickDL = e => { UI.stopEvent(e); downloadUrl('/ATTemplates/template_updatemetadata.csv'); };
  return <div className="atpage atrow" >
    <div className="atpagehead f16">Questions / Batch Update Metadata</div>
    <div className="trow">
        <div><div className="atfieldhead">CSV File {aTUIMust}: {CpATBtn('Download Template', 'btt', clickDL, lock,) }</div></div>
        {aTErrTxt1(fieldErrs.csv1)}
      </div>
      <div className="trow tal p8 box1 br8" {...DropCfg}>
      <div className="atrow">{
          lock? <i><b>Not Allowed to add more files to Closed Update Lot</b></i>:
          isDragActive ? "Drop files here to upload...": "Drag & drop, or click to add files"
        }</div>
      <div className="atrow"><b>CSV Files:</b> <small>( max: <b>{maxCsv}</b> files, max <b>{maxFSize}</b> byte per file )</small></div>
        <div className="atrow" >{ (csvKeys.length < 1)?'(csv file)'
            :objEntries(csvs).map( ([k,v]) => <CpFileCard key={k} file={v} onClickX={unUp(delCsv, k)} /> )
        }</div>
        <input {...getInputProps()} />
      </div>
      {toAry(csvErrs).map((e, i) => <div key={i} >{aTErrTxt1(e)}</div> ) }
      <div className="trow">{CpATBtn('Verify CSV and Upload', 'bu', clickUp, lock) }</div>
      <CpSavingMask {...{saving}} />
  </div>;
});

export default PageATQMetas;

const loadCheckQMetaCSV = async (csv1) => {
  const [csvTxt, err] = await readUploadText(csv1.input);
  const [qrows, headErrs, hc] = await QMetaFixCSV(csvTxt); 
  const csvErrs = [...headErrs];

  const [qs, errqs, rowErrs] = [[], [], {}];
  toAry(qrows).forEach( (qrow, idx) => {
    const [rownum, q, qerr] = [idx+2, ...qrow, ];
    const verrs = {}; //validEditATQtnV2(q, opts, []);
    const rowerrs = [...objVals(qerr), ...objVals(verrs)];
    const rowerrStr = rowerrs.join('; ').trim();
    q.imprownum = (idx+2);
    if(rowerrStr){ 
      rowErrs['ImpRow_'+rownum] = rowerrStr;
      errqs.push(q);
    }else{
      qs.push(q);
    } 
  });
  console.log({qrows, headErrs, hc, csvErrs, qs, errqs, rowErrs});
  return {qrows, headErrs, hc, csvErrs, qs, errqs, rowErrs};
};

const QMetaFixCSV = async (csvStr) => {
  const rows = csvToRows(toStr(csvStr).trim());
  //const rows = dummy_CSV();
  const headRow = rows.splice(0,1)[0];
  const [hc, headErrs] = loadHeadRow(headRow);
  const he = Object.keys(headErrs).length;
  const qrows = he? []: loadQMetaRows(rows, hc);    
  return [qrows, headErrs, hc]; 
};

const loadHeadRow = (headRow) => {
  const hc = { ...headcfg(), };
  const hcLowCase = mkflat({}, [hc]);
  maphead(hcLowCase, headRow);
  
  const headErrs = [];
  pushIf(headErrs, chkRepeatHead(hc));

  const checker = checkQHead(hc);
  pushIf(headErrs, checker.errStr());

//console.log({hc, hcLowCase, checker});
  return [hc, headErrs];
};

const loadQMetaRows = (rows, hc) => {
  const qrows = rows.map((row, rowidx) => {
      const q = _maprow(row, hc);
      const errs = {};
      const _Q = {
          QId: toIdStr(q.QId),
  
          QMetaIDStr: toUniIdsStr(q.QMetaIDStr), 
          QMetaPreIDStr: toUniIdsStr(q.QMetaPreIDStr),  
          QMetaProIDStr: toUniIdsStr(q.QMetaProIDStr),  
              
          isNew : 0,
          isAT: 0,
      };
      //hc.layout && (_Q.ansChoice = toAnsCoice(q.layout, 'layout', errs, hc.layout.key)); //mc, pol
      const Q = {..._Q};
      return [Q, errs];
  });
  
  return qrows;
};

const checkQHead = (hc) => {
  const checker = mkMustHaveChecker();
  checker.checkList(hc);
  return checker;
};

const asyncUpdateQs = async (dispatch, qs, update) => {
  const impRet = [];
  await arrayForEachAsync(qs, async (q, idx) => {
    update(qs.length, idx+1);
    const fields = {...q};
    console.log('putATQtnUpdate', {fields});
    const [res, err] = await asyncApiCall_(dispatch, '/putATQtnUpdate', { fields, batch:1 });
    impRet.push({err, res, q});
  });
  return impRet;
};

const m = key => ({key, col:[], });
const headcfg = () => {
    return {
        QId: m('QID'),
        QMetaIDStr: m('Metadata'),
        QMetaPreIDStr: m('Metadata (Prior)'), 
        QMetaProIDStr: m('Metadata (Advanced)'),
    };
};

const dummy_CSV = () => [
  ['QID','Metadata','Metadata (Prior)', 'Metadata (Advanced)'],
  ['dddd','  //ms1, PRI_KS1_JunPri_P1;ms2, NCMATH2E_4B_7_7.2_A;',
  'ms2, NCMATH2E_4B_7_7.2_A','ms1, PRI_KS1_JunPri_P1', ],
/*
  ['ex2BIOMC001','  //ms1, PRI_KS1_JunPri_P1;ms2, NCMATH2E_4B_7_7.2_A;',
  'ms2, NCMATH2E_4B_7_7.2_A','ms1, PRI_KS1_JunPri_P1', ],
['2403a','  //NCMATH2E_4B_7_7.2_A;',
  'ms1, PRI_KS1_JunPri_P1;ms2, ms2, NCMATH2E_4B_7_7.2_A','ms2, NCMATH2E_4B_7_7.2_A', ],
['SMATH_MC_4B08_10','  //NCMATH2E_4B_7_7.2_A;',
  'ms1, PRI_KS1_JunPri_P1;ms2, ms2, NCMATH2E_4B_7_7.2_A','ms2, NCMATH2E_4B_7_7.2_A', ],
*/
];