import React, {useEffect, useState} from "react";

import { ATErrsDiv, ATErrsDiv2, BtnPopDev, deepCopy, preJS, preJSSort, } from "../../AppExPf/AppUtil";
import { isEn2Lang, useUILang } from "../../AppExPf/utils/useUILang";
import CpActionButton from "../_components/CpActionButton";
import ScrollContainer from '../_components/CpScrollContainer';
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState"
import CpIco from "../_components/CpIco";
import TabExerEdContent from "./TabExerEdContent";
import TabExerEdDetail from "./TabExerEdDetail";
import { stopEvent } from "../../libs/libUI";
import CpExerciseEditPreviewCTA from "../_components/CpExerciseEditPreviewCTA";
import CpExerciseEditAssignCTA from "../EPAssign/CpExerciseEditAssignCTA";
import { hasErr, normalizeEdit, useSetFields } from "../../AppExPf/utils/useEditDraftPub";
import { CpEpTabHead, EPUITime3 } from "../_components/CpEpTabHead";
import CpDropdown, { ent2DropdownItem } from "../_components/CpDropdown";
import { asyncApiCallLoad_ , asyncApiCall_ } from "../../libs/awsFuncs";
import LayerExNew from "./LayerExNew";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { LayerLocalMedia, } from "../../AppExPf/ATMediaPool/LayerLocalMedia";
import { objKeys, toAry, toObj, toStr, toInt, objVals, toUniAry } from "../../libs/libType";
//import { isAry, objEntries, objKeys, objVals, to01, toAry, toInt, toObj, toStr, toUniAry } from "../../libs/libType";
import { subState } from "../../AppExPf/utils/useChain";
import { exerMapMedias, exerMediaIds, fixECtns, normalizedEditExer, validPubExer } from "../../consts/ATValidateExer";
import { exTeacherName } from "./CpExerciseCard";
import { useCaches } from "../../AppExPf/utils/useCaches";

import { useQtnCache } from "../../AppExPf/utils/useQtnCache";
import LayerUpMedia, { mapExQtnsMedias, upLocalMedias } from "./LayerEMediaUp";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { toIdStr, toUniIdAry } from "../../consts/ATValidate";
import { normalizedEditATQtnV2, validEditATQtnV2 } from "../../consts/ATValidateQ";
import { __MCAT_ATG, __MCAT_ATI } from "../../consts/ATQtnAnsTypeMCQ";
import {TabExerNextStep} from "../../AppExPf/ATExerEdit/TabExerNextStep";
import { rmQtnMediaSrcs } from "../../consts/qtnMedias";
import { AlertExEdQuit } from "../EPLibrary/PageLibrary";
import { packQtn, unpackQtn } from "../../consts/wirisConfig";


const PageExerciseEdit = ReduxBind(props => { //ExerciseEditPage
    const userId = props.userId();// 'teacher'; //searchParams.get("role") || "teacher"

    const {dispatch, subjMetas, pub, fields, assign, setPopExer, goPopExer, 
        setParaDel, reload, back, exModified, setExModified} = props; 

    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();

    const [ cache2d, useGetCache ] = useCaches(props);

    const thrNames = useGetCache('teachers', '*'); 

    const [qtns, useCacheQtns, addNewQtn, ] = useQtnCache(dispatch, 0);
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);
    const [onAddMedia, setOnAddMedia] = useState();

    const allDVis = useVisDetAll(0);
    const [ClickVisAllD, visAllDCmd, setVisAllD, visAllD, scanSub] = allDVis;

    const [upMsg, setUpMsg] = useState(''); 
    const setPub = p => setPopExer(subState('pub', p));
    const setFields = f => setPopExer(subState('fields', f));
    const [setField, setTick, setTickAry] = useSetFields(setFields);

    const [fieldErrs, setFieldErrs] = useState({});

    const [opts, setOpts] = useState({});

    const edit = fields?1:0;

    const startEdit = () => { setFields(fields? fields: deepCopy(pub)); setExModified(0);}
    const stopEdit = () => { setFields(0); setExModified(0); if(!pub) back(); };

    const [newSrc, setNewSrc] = useState();

    const [tab, setTab] = useState(0);
    const [vis, setVis] = useState(0);
    const lock = edit? 0: 1;
    const ex = edit? fields: pub;

    useEffect(()=>{ if (ex) getMediaDLs(exerMediaIds(ex)); },[]);

    if(!ex) return '';
    const EId = ex.EId;
    const moder = exTeacherName(ex, thrNames, ut, t);
    const isOupEx = ex.schoolId?0:1;
    const isMyEx = (ex.modUid === userId)? 1: 0;

    const clickCancel = async e => { 
        stopEvent(e);  
        if (exModified) { setVis(1); 
        } else { stopEdit(); };
    }; 
    const clickConfirm = e => { stopEvent(e); stopEdit(); setVis(0); };
    const clickEndExEd = e => { stopEvent(e); setVis(0); };
    const clickEditEx = e => { stopEvent(e); startEdit();}; //dispatch({type: ExerciseActionKind.INIT_EXERCISE, payload: "edit"})
    const handleCopyEdit = e => { stopEvent(e); setNewSrc(ex); };

    const clickSaveEx = async e => { stopEvent(e); 
        const qids = toUniIdAry(objVals(qtns).map(q => q && q.QId));
        fields.ECtns = fixECtns(fields.ECtns, qids);
        const [fs, fes] = normalizeEdit(fields, {...opts, qtns}, [], normalizedEditExer, validPubExer, setFields, setFieldErrs);
        if (hasErr(fes)) return;
        const mediaMap = await upExMedias(dispatch, fs, qtns, getMediaDLs, setUpMsg, addLocalMedias);
        const [upQEx, Qerrs] = await upExQtns(dispatch, fs, mediaMap, qtns, addNewQtn, getMediaDLs, setUpMsg, addLocalMedias);
        exerMapMedias(upQEx, mediaMap);

        if(upQEx) setFields(upQEx);
        if(hasErr(Qerrs)){
            setFieldErrs(Qerrs);
            return;
        };
        
        const [_res, err] = await asyncApiCallLoad_(dispatch, '/putExer', {fields:upQEx});

        if(err) console.error('clickSave', err);      
        const res = toObj(_res);
        if(res.state === 'retry'){
            setFieldErrs(toObj(res.fieldErrs));
            //setFields(toObj(res.fields));
        }else if(res.state === 'ok') {
            setPub(res.pub);
            stopEdit(); 
            reload(); 
        }
    };

    const canEdit = isMyEx && lock;

    const editOpts = ent2DropdownItem([
        canEdit && ['c', t("copy-edit")],
        canEdit && ['d', t("delete")], 
        ['show', t(visAllD? "hide-details": "show-details")]].filter(t => t));

        const editOptsCb = idx => {
        if(idx === 'c'){ setNewSrc(ex); 
        }else if(idx === 'd'){ setParaDel({EId, onDel:()=>{reload(); back();}});
        }else if(idx === 'show'){ ClickVisAllD(); }
    };

    const {EShare, EMSubjId, isCopy, isNew, ELangEn, ELangCt} = ex;
    const isAT = 0;
    const showEn = (ELangEn && ELangCt)? props.showEn: ELangEn? 1: 0;

    const tabProps = {...props, ex, lock, showEn, isAT, isOupEx, isMyEx,
        fields, setFields, setField, setTickAry, fieldErrs, setFieldErrs, 
        pub, opts, setOpts, moder, addLocalMedias,
        allDVis, exModified,
        qtns, useCacheQtns, addNewQtn, mediaDLs, getMediaDLs, onAddMedia, setOnAddMedia};
    
    const ExEditBtns = lock? <>{canEdit
        ?<CpActionButton title={t("edit")} iconPath={IconList.general.edit} hiddenText={false}
            onClick={clickEditEx} className={"btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-1"} />
        :<CpActionButton title={t("copy-edit")} iconPath={IconList.general.copy} hiddenText={false} 
            onClick={handleCopyEdit} className={"btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-1"} />}
    </>:<>
        <CpActionButton title={t("save")} iconPath={IconList.general.save} hiddenText={false} 
            onClick={clickSaveEx} className={"btn btn-borderless-exercise-third-btn gap-2 rounded semi-bold user-select-none py-1 "
            + (exModified?"":" disableBtn disable-option ")} />
        <CpActionButton title={t("cancel")} iconPath={IconList.general.cancelFull} hiddenText={false} iconSize={"fs-8"} 
            onClick={clickCancel} className={"btn btn-borderless-exercise-third-btn gap-2 rounded semi-bold user-select-none py-1"} />
    </>;

    return <ScrollContainer className={"exercise-edit-scroll-container h-100 d-flex flex-column w-100 overflow-auto scroll-padding"}>
        <div className={"container-fluid"}>
{preJS('{visAllDCmd, visAllD, scanSub}')}
            {'' && preJS({lock, showEn, edit, pub:pub? 1: 0, fields:(fields?1:0) } ) }
            {'' && preJS({isOupEx, isMyEx, EShare, EMSubjId, isCopy, isNew})}
            {ATErrsDiv2(fieldErrs, '', t)}
            <div className={"row"}><div className={"col-12"}>
                <div className={"py-3 d-flex flex-row flex-nowrap gap-1 gap-md-3  align-items-start justify-content-between fs-6 w-100"}>
                    <span className={"d-flex flex-wrap gap-3 flex-column flex-md-row overflow-hidden mt-1"}>
                        <span className={"d-flex gap-2 align-items-center semi-bold text-truncate"}>
                            <span className={"fs-8 animation-fade-up"}><CpIco src={isOupEx? IconList.brand.oupc: IconList.general.avatar} /></span>
                            <span className={"animation-fade-up text-truncate"}>{moder}</span>
                        </span>
                        <span className={"d-flex gap-2 align-items-center semi-bold"}>
                            <span className={"fs-8 animation-fade-up"}><CpIco src={IconList.general.time}/></span>
                            <span className={"animation-fade-up text-break"}>{EPUITime3(ex.dateMod)}</span>
                        </span>
                    </span>                        
                    <span className={"d-flex gap-2 flex-column"}>
                        <span className={"d-flex gap-2 justify-content-end"}>
                            <CpExerciseEditPreviewCTA {...tabProps} />
                            <CpExerciseEditAssignCTA {...{...tabProps, assign, lock:(edit || !exHasQtn(ex))}} />
                        </span>
                        <span className={"d-flex d-md-none gap-2 justify-content-end align-items-center"}>
                            {ExEditBtns}
                            <CpDropdown className="w100" {...{items:editOpts, cb:editOptsCb, iconPath:IconList.general.moreVertical, className:"more-dropdown d-block d-md-none"}} /> 
                        </span>                            
                    </span>
                </div>
            </div>
            <div className={"d-flex flex-column gap-3"}>
                <div className={"d-flex justify-content-end gap-2"}>
                <div className={"d-flex flex-wrap justify-content-between w-100"}>
                    {/*<StickyNavTab items={items} active={tab} handleActive={handleActive} className={"exercise-content-tab-theme"}/>*/}
                    <span className={"d-flex gap-2 semi-bold fs-6 align-items-center"} style={{overflow:"visible"}} >
                        <CpEpTabHead {...{tab, setTab, tabs:[t("exercise-edit-tab-content"), t("exercise-edit-tab-details"), t("exercise-edit-tab-next-steps")]}} />
                        <BtnPopDev txt='fields.ECnts'>{preJSSort(ex.ECtns,3)}</BtnPopDev>
                        <BtnPopDev txt='fields.ENexts'>{preJSSort(ex.ENexts,3)}</BtnPopDev>
                        <BtnPopDev txt='fields'>{preJSSort(fields,3)}</BtnPopDev>
                        <BtnPopDev txt='pub'>{preJSSort(pub,3)}</BtnPopDev>
                    </span>
                    <span className={"align-items-center gap-2 d-none d-md-flex"}>
                        {ExEditBtns}
                        <CpDropdown className="w100" {...{items:editOpts, cb:editOptsCb, iconPath:IconList.general.moreVertical, className:"more-dropdown"}} />
                    </span>
                </div>
                </div>
            </div>
        </div></div>
        <div className={"container-fluid container-md mt-3"}>
            <div className={"row"}><div className={"col-12"}>{
                (tab === 1)? <TabExerEdDetail {...tabProps} />: //<ExerciseEditDetailsPage />
                (tab === 2)? <TabExerNextStep {...tabProps} />: //<ExerciseNextStepsPage />
                <TabExerEdContent {...tabProps} /> //<ExerciseEditContentPage />
           }</div></div>
        </div>
        {newSrc? <LayerExNew {...{goPopExer, en:showEn, newSrc, setNewSrc, isCopy:1, subjMetas }} />: '' }       
        <LayerLocalMedia clickClose={()=>setOnAddMedia(0)} {...{...onAddMedia, setOnAddMedia, mediaDLs, getMediaDLs}} />
        <LayerUpMedia {...{head:'Saving Exercise', upMsg}} />
        {vis?<AlertExEdQuit confirmTxt={'confirm'} cancelTxt={'back'} clickExit={clickConfirm} clickClose={clickEndExEd}
            bodyTxt={'warning.warning_confirm_cancel'} />:''}
    </ScrollContainer>;
});
export default PageExerciseEdit; 

export const exHasQtn = (ex) => { return (toInt(ex?.mQCnt) + toInt(ex?.aQCnt)) > 0; };

const asyncUpQ = async (dispatch, q, EId, ELang, setUpMsg) => {
    const opts = {};
    const[qFields, qErrs] = normalizeEdit(q, opts, [], normalizedEditATQtnV2, validEditATQtnV2);
    if(hasErr(qErrs)) console.error(qErrs);

    const [res, err] = await asyncApiCall_(dispatch, '/putQuestion', {fields: packQtn(q), EId, ELang});
    //const res = {qtn: rmQtnMediaSrcs(q) };
    const retQ = unpackQtn(res?.qtn);
    return retQ || q;
};

const upExQtns = async(dispatch, ex, mediaMap, qtns, addNewQtn, getMediaDLs, setUpMsg, addLocalMedias) => {
    const QqMid2d = mapExQtnsMedias(ex, qtns, mediaMap);

    const QMap = {}; 
    const Qs2Up = QqMid2d.filter(([q, qMedIds, drawBlobs]) => {
        const {isEdited, isNew, isCopy, save, QId} = q;
        return isEdited || isNew || isCopy || QId.startsWith('*NewQ_'); 
    });
console.log('upExQtns', QqMid2d, Qs2Up);
    const qDrawBlobs = Object.assign([], ...QqMid2d.map(([q, qMedIds, drawBlobs]) => toObj(drawBlobs)));
    addLocalMedias(qDrawBlobs);

    const upQCnt = Qs2Up.length; 
    const upup = i => setUpMsg({msg:'Uploading Question...', ttl:upQCnt, idx:i});
    //const upup = i => setUpMsg('');

    const EId = toIdStr(ex.EId);
    const ELang = isEn2Lang(ex.ELangEn); 
    const upQ = async ([q, medias]) => {
        const {QId} = q;
        upup(objKeys(QMap).length+1);
        const retq = await asyncUpQ(dispatch, rmQtnMediaSrcs(q), EId, ELang, setUpMsg);
        upup(objKeys(QMap).length+1);
        addNewQtn(retq.QId, retq);
        QMap[QId] = toStr(retq.QId);
    };

    await Promise.all(Qs2Up.map(upQ));
    setUpMsg('');
    
    const mapCtnQs = ectn => {
        const {type, QIds} = toObj(ectn);
        if (type !== _ExCtType_Qtn) return ectn;
        const qids = toUniIdAry(toStr(QIds).split(','));
        const mapQids = qids.map(qid => (QMap[qid] || qid)).filter(i => i).join(',');
        return {...ectn, QIds: mapQids};
    };
    const retEx = {...ex, ECtns: toAry(ex.ECtns).map(mapCtnQs)};
    const upErrs = { 
        //forceErr: 'stop save for Dev' 
    };
    return [retEx, upErrs];
};


// ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####

export const useVisDetAll = (initVisAll, id) => {
    const [ visAllD, setVisAllD ] = useState(initVisAll); //to display state
    const [ visAllDCmd, setVisAllDCmd ] = useState(0); //to child cmd
    const [ scanSub, setscanSub ] = useState(0); // start lookchk
    const [ onP, setOnP ] = useState(0); // vis after loop
    
    const ClickVisAllD = () => {
        if(setVisAllD) setVisAllD(visAllD? 0: 1); 
        setVisAllDCmd(visAllDCmd? 0: 1); 
    };
    const rescan = () => {
        setOnP(1); 
        setscanSub(1); //Start A Loop Check Turn On 
    };
    useEffect(() => { 
        if(scanSub) setscanSub(0); //Turn Of Loop Check after triggered
        if(!scanSub && onP){ setOnP(0); setVisAllD(1); }//condition turn On and reset After Loop Check
    }, [scanSub]); //hacky reset check
    
    return [ClickVisAllD, visAllDCmd, setVisAllD, visAllD, scanSub, setscanSub, setOnP, rescan];
};

export const useVisDetSub = (allDVis, ignore) => {
    const [ClickVisAllD, visAllDCmd, setVisAllD, visAllD, scanSub, setscanSub, setOnP, rescan] = toAry(allDVis);
    const [openMore, _setOpenMore] = useState(visAllD);

    useEffect(() => { _setOpenMore(visAllD); }, [visAllDCmd]); //follow on Command
    useEffect(() => { if(scanSub && (!ignore) && (!openMore)){ setOnP(0); }}, [scanSub]) //Sub Loop Check

    const setOpenMore = v => {
        _setOpenMore(v); //Self Swith
        if(v){
            if(rescan) rescan();
        }else{
            if(setVisAllD) setVisAllD(0); //Turn Off No Loop
        }
    };
    return [openMore, setOpenMore];
};

// ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
const upExMedias = async (dispatch, ex, qtns, getMediaDLs, setUpMsg, addLocalMedias) => {
    const eMids = exerMapMedias(ex, {});
    const QqMid2d = mapExQtnsMedias(ex, qtns, {});
    const qMids = toUniAry([].concat(...QqMid2d.map(([q, ms]) => ms)));
    const qDrawBlobs = Object.assign([], ...QqMid2d.map(([q, mids, drawBlobs]) => toObj(drawBlobs)));
    addLocalMedias(qDrawBlobs);
    const mids = toUniAry([...toAry(eMids), ...toAry(qMids)]);
    return upLocalMedias(dispatch, mids, getMediaDLs, setUpMsg, qDrawBlobs);
};
