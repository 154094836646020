import React, {useEffect, useMemo, useState} from "react";
import {useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";

import { CpStateTxtBox } from "./CpValueStatusBox";
import ChartMiniBar from "./CpChartMinibar";

import { _WST_SUBMIT, _QST_SUB_WRONG, _QST_SUB_CORR, _QST_SUB_ATT, _QST_SUB_NOTMARK, _QST_SUB_NOTATT } from "../../consts/ATConstsAssignment";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { BtnDev, BtnPopDev, preJS } from "../../AppExPf/AppUtil";
import { _ATMetaTypeByCode, _ATMetaType_SU } from "../../consts/ATMetaTypes";

import CpMJX from "../_components/CpMJX";
import { ExpandBtn, sortRptSWMs, statThrAsmRptQs, } from "./TabPerformanceMetadataSetsChart";
import { aryLen, objEntries, objKeys, objVals, strCmp, toAry, toInt, toObj, toPercent1, toPercentF, toStr, toUniAry, } from "../../libs/libType";
import { RemeLvSame } from "../../consts/ATValidateReme";
import { toIdAry, toUniIdAry } from "../../consts/ATValidate";
import { MSetFullTree, onlyEndSMIds, } from "../EPReports/data/dataUtil";
import { strCmpTrimLow } from "../EPAssign/TabTeacherAssignmentStatus";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { useMetaRAsmsByMSid } from "./TabStudentAssignmentReportResult";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from '../../consts/ATIconListToUsePoc';
import { mkEQSumFake } from "../../AppExPf/ATExerEdit/TabExerSetting";
import { MIdsByMSId, splitSMId } from "../../consts/ATValidateMetaSet";
import { MTreeForRemes } from "./TabStuAsmRptReme";

const ChartThrAsmReme = ReduxBind(props => { //PerformanceMetadataSetsChart
    const { showExPV, loadPVRemeAsm, markReme, loadMarkReme } = props;
    const [t, uiEn, lang, setUILang, ut, t2] = useUILang();
 
    const { rptJ, rptMType, rptRLv, rptMSId, QIds, typeMSets } = props;
    //rptJ: "mssu1,KIN_ECE_K1", rptMType: "tb", rptMSId: "ms1", rptRLv: RemeLvSame, 
    const lvSame = (rptRLv === RemeLvSame);

    const {asm, Exer, qtns, works, marks} = props;
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(asm?.ALang);

    const lvRemeAsms = useMemo(() => toAry(props.remeAsms).filter(r => toAry(r.remeLvs).includes(rptRLv)), [props.remeAsms, rptRLv]);
    const msetLvRAsms = useMetaRAsmsByMSid(lvRemeAsms, rptMSId); 

//Reme Metas By Qs == ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
    const EQSum = useMemo(() => {
        if(!props.EQSum) console.warn('TabExerNextStepBase cannot get EQSum from parent CP');
        return mkEQSumFake(Exer, qtns);
      }, [Exer, qtns, props.EQSum]);
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres, EQMetaPros, minQs, ] = toAry(EQSum);

    const mset = typeMSets?.[rptMType]?.[rptMSId];
    const EQMetaSames = useMemo(() => onlyEndSMIds(mset, MIdsByMSId(EQMetas, rptMSId)), [EQMetas, mset]); 

    const rRuleSMIds = useMemo(() => lvSame? EQMetaSames: objKeys(msetLvRAsms), [lvSame, EQMetaSames, msetLvRAsms]); 
    const rTreeMIds = useMemo(() => toUniIdAry(rRuleSMIds.map(i => splitSMId(i)[1])), [rRuleSMIds]);
    const rptMTree = useMemo(() => MTreeForRemes(mset, rTreeMIds, ut), [mset, rTreeMIds, uiEn]);

//Student and Work Info (form base Asm) ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
    const rptSWMs = useMemo(() => sortRptSWMs(asm?.studentIds, props.students, works, marks, ct), [asm, props.students, works, marks, showEn]); 
    const ttlWSub = useMemo(() => toAry(rptSWMs).filter(w => w.submitted).length, [rptSWMs]);
    
    const qStats = useMemo(() => statThrAsmRptQs(QIds, qtns, asm, rptSWMs), [QIds, qtns, asm, rptSWMs]);
    //const cmpStats = useMemo(() => mkCmpStat(mset, qStats, rptSWMs, ), [mset, qStats, rptSWMs]);
    const [cmpMetas, cmpTree] = useMemo(() => mkCmpStat(mset, qStats, rptSWMs), [mset, qStats, rptSWMs]);

    const StuInfs = useMemo(() => sortStuRemes(asm?.studentIds, props.students, msetLvRAsms, ct), [asm, props.students, msetLvRAsms, showEn]); 

//Debug ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
    const dbgQMetas = useMemo(() => ( { RemeLvSame: EQMetas.sort().join('; '), RemeLvPre: EQMetaPres.sort().join('; '), RemeLvPro: EQMetaPros.sort().join('; '), } ), [EQSum]) ;    
    const dbgAsms = useMemo(() => toAry(props.remeAsms).map(r => {
        const {remeLvs, remeSMId} = r;
        return {remeSMId: toStr(remeSMId), remeLvs: toUniAry(remeLvs).sort().join(', '), };
    }).sort((a, b) => strCmpTrimLow(a.remeSMId, b.remeSMId)), [props.remeAsms]);

    const clickRemePV = rAsmId => e => {stopEvent(e); loadPVRemeAsm(rAsmId);};
    const clickRemeMark = (rAsmId, stuId, rptRLv) => e => { stopEvent(e); loadMarkReme(rAsmId, stuId, rptRLv); };

    return <>
<BtnDev {...{txt:'ChartThrAsmReme'}}>
        {preJS({rptRLv, rptMSId})}
        {preJS({rRuleSMIds})}
        {preJS({rTreeMIds})}
        {preJS({EQMetas})}
        {preJS({EQMetaPres})}
        {preJS({EQMetaPros})}
        {preJS({EQMetaSames})}
        {preJS({cmpMetas},3)}
</BtnDev>
            <table className={'ChartThrAsmReme report performance-metadata-sets-chart'}>
            <thead><tr>
                <th className={`text-rotate question-no-th ${lang} sticky-header header-qno`}>
                    {/*<div className={"ms-auto me-0"}><span>{t("delete-items.exercise")}</span></div>*/}
                    <div className={"ms-auto me-0"}><span>{""}</span></div>
                {/*</th><th colSpan={2} className={`correctly-th`}>*/}
                </th><th colSpan={2} className={`ps-3 wider no-of-studnet-follow-up-th ${lang}`}>
                    <div><span>{t("report-follow-up-no-of-student")}</span></div>
                {/*</th><th colSpan={2} className={`correctness-th ${lang}`}>*/}
                </th><th colSpan={2} className={`ps-3 wider no-of-studnet-follow-up-th ${lang}`}>
                    <div><span>{t("report-follow-up-no-of-mastered-student")}</span></div>
                </th>
                {lvSame && <th className={`text-rotate text-center ${lang}`}>
                    <div><span>{t("report-follow-up-average-score-assignment")}</span></div>
                </th>}
                <th className={`text-rotate text-center ${lang}`}>
                    <div><span>{t("report-follow-up-average-score-exercise")}</span></div>
                </th>

                {StuInfs.map((s, i) => <th key={i} className={`text-rotate avatar-th`} >
                    <div><span className={s.remeCnt? '': 'text-dim-350'}>{s.no} {s.name}</span></div>
                </th>)}
                <th className={"w-100"}></th>
            </tr></thead>
            <tbody>{rptMTree.map((rptMNode, key) => <CpThrRptRemeRow {...{key, 
                StuInfs, ttlWSub, metaAsms: msetLvRAsms, cmpMetas, qStats, rptSWMs,
                rptMNode, t, rptRLv, lvSame, EQMetaSames, rRuleSMIds, clickRemePV, clickRemeMark}}/> )}</tbody>
        </table>
    </>;
});

export default ChartThrAsmReme;

const CpThrRptRemeRow = props => {
    const indent = toInt(props.indent); 
    const { t, StuInfs, ttlWSub, qStats, rptSWMs, rptMNode, } = props; 
    const { metaAsms, cmpMetas, rptRLv, lvSame, EQMetaSames, rRuleSMIds,} = props;
    const { clickRemePV, clickRemeMark } = props;
    const { id, SMId, title, children } = toObj(rptMNode);

    const [open, setOpen] = useState(true);
    const handleOpen = () => { setOpen(prev => !prev)};

    const stuCnt = toAry(StuInfs).length;
    const rAsm = metaAsms[SMId];
    const rAsmId = toStr(rAsm?.assignId);
    const {Exer, remeLvStus, sumWMs} = toObj(rAsm);
    const passValue = toInt(rAsm?.passValue);
    const remeLvPasss = rAsm?.remeLvPasss || {RemeLvSame:passValue, RemeLvPre:passValue, RemeLvPro:passValue }
    const rStuIds = toUniIdAry(remeLvStus?.[rptRLv]);

    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = useMemo(() => exerHasMark(Exer), [Exer]);
    const eMark = toInt(aScore) + toInt(mScore);

    const stuPassMarks = useMemo(() => Object.fromEntries(objVals(sumWMs).map(w => {
        const sps = objEntries(remeLvStus).filter(e => toAry(e[1]).includes(w.stuId)).map(e => remeLvPasss[e[0]]);
        const spv = toInt(aryLen(sps)? Math.max(...sps): passValue) 
//console.log('stuPassMarks', w.stuId, {sps, spv});
        return [w.stuId, eMark * spv * 0.01];
    })), [sumWMs, remeLvPasss]);

    const rmSumWMs = useMemo(() => Object.fromEntries(objEntries(sumWMs).map(e => rStuIds.includes(e[0]) && e).filter(e => e)), [sumWMs, rStuIds]);
    
    const lvStus = useMemo(() => toUniIdAry(rAsm?.remeLvStus?.[rptRLv]), [rAsm?.remeLvStus, rptRLv]);

    const {workCnt, subCnt, passCnt, sumUMark} = useMemo(() => {
        const r = {workCnt: 0, subCnt: 0, passCnt: 0, sumUMark: 0}
        objEntries(rmSumWMs)
        .filter(([si, w]) => lvStus.includes(si))
        .forEach(([si, w]) => {
            const {idx, marked, submitted, uMark} = w;
            r.workCnt++;
            if(submitted) r.subCnt++;
            if(submitted /*&& marked*/) r.sumUMark += uMark;
            if(submitted /*&& marked*/ && (stuPassMarks[si] <= uMark)) r.passCnt++;
        });
        return r; 
    }, [rAsm, lvStus]); 

    const cmp = cmpMetas[SMId]; 
    const cmpAvgMarkP = useMemo(() => {
        const qs = toAry(cmp?.qIdxs).map(i => qStats[i]);
//console.log({cmp, SMId, qs, qStats, ttlWSub});
        const sum = {sumQMark: 0, sumUMark: 0};
        qs.forEach(q => {
            const {qMark, sumMark, sumSubmit} = toObj(q);
//console.log({qMark, sumMark, sumSubmit});
            sum.sumQMark += toInt(qMark);
            sum.sumUMark += toInt(sumMark);
        });
//console.log({sum});
        return toPercent1(sum.sumUMark, sum.sumQMark * ttlWSub);
    }, [cmp, qStats, ttlWSub]);
 
//console.log('CpRptRow', {workCnt, subCnt, passCnt, sumUMark, eMark, passMark, }, rAsm); 
    const hasR = rAsm || ''; //for simple display: hasR || drawUI
    const hasRule = rRuleSMIds.includes(SMId); // lvSame && EQMetaSames.includes(SMId);

    useEffect(() => {
        if(hasRule){
//            const stuLvs = objVals(sumWMs).map(w => objEntries(remeLvStus).filter(e => toAry(e[1]).includes(w.stuId)).map(e => e[0]));
//            console.log({SMId, rptRLv, eMark, rAsm}); 
//            console.log({remeLvPasss, remeLvStus, });
//            console.log({lvStus, stuLvs, stuPassMarks, rmSumWMs, sumWMs})
        }
    }, [SMId, rptRLv, rAsm, stuPassMarks, rmSumWMs, lvStus]);

    return <><tr id={SMId} className='CpThrRptRemeRow'>
        <td className={`CTRRR_0 sticky-header header-qno`}>
{rAsm ? <BtnPopDev txt='...'>{preJS({
rptRLv, remeLvStus, rAsm,
}, 3)}</BtnPopDev>:''}
            <div id={toStr('m:'+SMId+',a:'+rAsmId)} className={"CpThrRptRemeRow d-flex justify-content-between gap-3 semi-bold"} style={{paddingLeft: `${indent}rem`}} >
                <div className={"overflow-auto py-2"}>
                    <span className={`d-flex gap-2 ${children.length === 0 ? "ps-4" : ""}`}>
                        {children.length > 0 && <ExpandBtn open={open} onClick={() => handleOpen()}/>}
                        <CpMJX dynamic={true}><span>{title}</span></CpMJX>
                    </span>
                    <div className={"d-flex gap-0 ps-4"}></div>
                </div>
                <div><div id={'reme-'+rAsmId} className={"field d-flex justify-content-end align-items-center px-3 text-nowrap"}>
                    {hasR? <CpActionButton title={t("assignment-cta-preview")} iconPath={IconList.assignment.preview}
                        className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none text-nowrap`}
                        onClick={clickRemePV(rAsmId)} hiddenText={true} />: 
                    hasRule? "No Available Exercises" 
                    :''}
                </div></div>
            </div>
        </td>

        <td className='CTRRR_1' style={{paddingRight:0}}>{hasR? <CpCorrectnessBar {...{val: toPercent1(workCnt, stuCnt), vis:hasR}} />: ''}</td>
        <td className='CTRRR_2' style={{paddingLeft:0}}>{hasR? <CpFactionNum {...{val: workCnt, ttl: stuCnt, type: "metadata"}} />: ''}</td>
        <td className='CTRRR_3' style={{paddingRight:0}}>{hasR? <CpAverageBar {...{val: toPercent1(passCnt, workCnt), vis:hasR}} />: ''}</td>
        <td className='CTRRR_4' style={{paddingLeft:0}}>{hasR? <CpFactionNum {...{val: passCnt, ttl: workCnt, type: "metadata"}} />: ''}</td>

        {lvSame? <td className='CTRRR_5'>{hasR || hasRule? <CpTxtBox {...{txt: cmpAvgMarkP+'%'}} />:''}</td>: ''}
        <td className='CTRRR_6'>{hasR? <CpTxtBox {...{txt:toPercent1(sumUMark, subCnt * eMark)+'%'}} />:''}</td>

        {StuInfs.map((stu, key) => <td className={"CTRRR_7 px-0"} {...{key}} >
            {hasR? <CpStudentBox {...{wm: rmSumWMs[stu.id], eMark, passMark: stuPassMarks[stu.id], type:"metadata", onClick:clickRemeMark(rAsmId, stu.id, rptRLv) }} />: ''}
        </td>)}
        <td className={"CTRRR_8 w-100"}></td>
    </tr>
    {open && children.map((rptMNode, key) => <CpThrRptRemeRow {...{...props, key, rptMNode, indent:indent + 1}}/> )}
</>};

const CpStudentBox = props => { // { id: string, stuId: string, type: "metadata" | "question" }) => {
    const {eMark, passMark, wm} = props;

    const {idx, marked, submitted, uMark} = toObj(wm);
    const allMarked = submitted && (marked || 1);
    const APass = 1;
    const pass = allMarked && (uMark >= passMark);

    const status = (!wm)? '': submitted? ((marked||1)? (APass? (pass? 'p': 'f' ): 'a') : 'm') :'n';
    const txt = marked? toPercent1(uMark, eMark)+'%': '';
    const onClick = (submitted && props.onClick) || undefined; 
    return <div {...{className: 'CpStudentBox'+(onClick? ' clickable ': ''), onClick}} ><CpStateTxtBox {...{status, txt}} /></div>;
};

const CpTxtBox = props => {// { id: string, type: "metadata" | "question" }) => {
    const {txt} = props;
    return <div className={"field d-flex justify-content-center align-items-center semi-bold"}>{toStr(txt)}</div>
    //return <div className={"field"}>{toStr(txt)}</div>
};

const CpFactionNum = props => {// { id: string, type: "metadata" | "question" }) => {
    const {txt, val, ttl} = props;//['-', 1, 3];
    if(ttl) return <div className={"field d-flex justify-content-center align-items-center semi-bold ms-0"}>{val}/{ttl}</div>
    return <div className={"field"}>{toStr(txt)}</div>
};

const CpAverageBar = props => {
    const {vis, val} = props;
    return vis
        ? <div className="field me-0"><ChartMiniBar type="average" marks={{percentage:val}} /></div>
        : <div className="field"></div>;
};

const CpCorrectnessBar = props => {
    const {vis, val} = props;
    return vis
        ?<div className="field me-0"><ChartMiniBar type="correctness" marks={{percentage:val}} /></div>
        :<div className="field"></div>;
};

const sortStuRemes = (stuIds, stus, remes, ut) => {
    const rvs = objVals(remes);
    const ret = toAry(stuIds).map(i => {
        const s = stus?.[i];
        return s && {
            id: i,
            no: s.classNo,
            name: ut(s.nameEng, s.nameChi),
            remeCnt: rvs.filter(r => toIdAry(r.studentIds).includes(i)).length,
            _name: s.nameEng,
        }
    }).filter(s => s);
    ret.sort((a, b) => strCmpTrimLow(a.no, b.no) || strCmpTrimLow(a._name, b._name) || strCmp(a.id, b.id));
    return ret;
};


const mkCmpStat = (mset, qStats, SWMs) => {
//console.log({qStats, SWMs});

    const msid = toStr(mset?.metaSetId);
    const metas = toObj(mset?.metas);
    const [ MRoot, MBranchs] = MSetFullTree(metas); 
//console.log({MRoot, MBranchs});

    const cmpMetas = {};
	const mkNode = mid => {
        const smid = msid+','+mid;
        const children = mkNodes(MBranchs[mid]);
        const subQIdxs = toUniAry([].concat(...children.map(c => c.qIdxs)));
        const dirQIdxs = qStats.filter(q => q && (!subQIdxs.includes(q.qIdx)) && q.qMetas.includes(smid)).map(q => q.qIdx);
        const total = dirQIdxs.length +  subQIdxs.length;
        const ret = total && {
            smid,           
            children,
            dirQIdxs,            
            qIdxs: toUniAry([...subQIdxs, ...dirQIdxs]),
        };
        if(ret) cmpMetas[smid] = ret;
        return ret;
    };
    const mkNodes = ids => toUniIdAry(ids).map(mkNode).filter(n => n);
    const cmpTree = mkNodes(MRoot);

//console.log({cmpMetas});
    return [cmpMetas, cmpTree, ];
};

export const fakeAsmRptRemeData = {
    "student": [
        {id: "s-001", no: "01", name: "Eric Yau", submitted: true},
        {id: "s-002", no: "02", name: "Wing Tang",submitted: false},
        {id: "s-003", no: "03", name: "Anson Bean",submitted: true}
    ],
    "metadata": [
        {
        id: "m-001", title: "Compulsory Part",
        question: { total: 10 },
        children: [{
            id: "m-001-001", title: "Number and Algebra Strand",
            question: { total: 10, list: [{id: "q-001", no: 1}] },
            children: [{
                id: "m-001-001-001", title: "1. Quadratic equations in one unknown",
                question: { total: 5, list: [
                    { id: "q-002", no: 2 },
                    { id: "q-003", no: 3 }
                ]},
                children: [ {
                    id: "m-001-001-001-001", title: "1.3 solve the equation ax2 + bx + c = 0 by plotting the graph of the parabola y = ax2 + bx + c and reading the x-intercepts",
                    question: { total: 2, list: [
                        {id: "q-004", no: 4},
                        {id: "q-005", no: 5}
                    ]}
                    },{
                    "id": "m-001-001-001-002",
                    "title": "1.6 solve problems involving quadratic equations",
                    "question": {
                        "total": 1,
                        "list": [
                        {
                            "id": "q-006",
                            "no": 6
                        }
                        ]
                    }
                    }
                ]
                }
            ]
            }
        ]
        },
        {
        "id": "m-002",
        "title": "report-performance-uncategorized",
        "question": {
            "total": 2,
            "list": [
            {
                "id": "q-007",
                "no": 7
            },
            {
                "id": "q-011",
                "no": 11
            },
            {
                "id": "q-012",
                "no": 12
            }
            ]
        }
        }
    ],
    "data": [
    {
        referenceId: "m-001",
        numCorrectly: { value: 0, total: 0},
        isCorrectness: true,
        correctness: 63.8,
        averageScore: 54.5,
        student: []
    },{
        referenceId: "m-001-001",
        numCorrectly: { value: 0, total: 0 },
        "isCorrectness": true,
        "correctness": 60.0,
        "averageScore": 52.5,
        "student": []},
    {
        referenceId: "q-001",
        numCorrectly: { value: 8, total: 10 },
        isCorrectness: true,
        correctness: 80.0,
        averageScore: 60.0,
        student: [
            { id: "s-001", value: 5, status: "p" },
            { id: "s-002", value: 0, status: "n" },
            { id: "s-003", value: 5, status: "p" }
        ]
    },{
        referenceId: "m-001-001-001",
        numCorrectly: { value: 0, total: 0 },
        isCorrectness: true,
        correctness: 56.0,
        averageScore: 51.0,
        student: [] },
        {
        "referenceId": "q-002",
        "numCorrectly": {
            "value": 4,
            "total": 10
        },
        "isCorrectness": true,
        "correctness": 40.0,
        "averageScore": 30.0,
        "student": [
            {
            "id": "s-001",
            "value": 5,
            "status": "p"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 5,
            "status": "p"
            }
        ]
        },
        {
        "referenceId": "q-003",
        "numCorrectly": {
            "value": 6,
            "total": 10
        },
        "isCorrectness": true,
        "correctness": 60.0,
        "averageScore": 70.0,
        "student": [
            {
            "id": "s-001",
            "value": 2,
            "status": "p"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 5,
            "status": "p"
            }
        ]
        },
        {
        "referenceId": "m-001-001-001-001",
        "numCorrectly": {
            "value": 0,
            "total": 0
        },
        "isCorrectness": true,
        "correctness": 50.0,
        "averageScore": 40.0,
        "student": [
        ]
        },
        {
        "referenceId": "m-001-001-001-002",
        "numCorrectly": {
            "value": 0,
            "total": 0
        },
        "isCorrectness": true,
        "correctness": 50.0,
        "averageScore": 40.0,
        "student": [
        ]
        },
        {
        "referenceId": "q-004",
        "numCorrectly": {
            "value": 4,
            "total": 10
        },
        "isCorrectness": true,
        "correctness": 40,
        "averageScore": 30,
        "student": [
            {
            "id": "s-001",
            "value": 0,
            "status": "f"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 5,
            "status": "p"
            }
        ]
        },
        {
        "referenceId": "q-005",
        "numCorrectly": {
            "value": 6,
            "total": 10
        },
        "isCorrectness": true,
        "correctness": 60,
        "averageScore": 50,
        "student": [
            {
            "id": "s-001",
            "value": 5,
            "status": "p"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 5,
            "status": "p"
            }
        ]
        },
        {
        "referenceId": "q-006",
        "numCorrectly": {
            "value": 8,
            "total": 10
        },
        "isCorrectness": true,
        "correctness": 80.0,
        "averageScore": 75.0,
        "student": [
            {
            "id": "s-001",
            "value": 2,
            "status": "p"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 2,
            "status": "p"
            }
        ]
        },
        {
        "referenceId": "m-002",
        "numCorrectly": {
            "value": 0,
            "total": 0
        },
        "isCorrectness": true,
        "correctness": 75.8,
        "averageScore": 57.5,
        "student": [
        ]
        },
        {
        "referenceId": "q-007",
        "numCorrectly": {
            "value": 0,
            "total": 0
        },
        "isCorrectness": false,
        "correctness": 0,
        "averageScore": 60.0,
        "student": [
            {
            "id": "s-001",
            "value": 3,
            "status": "a"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 8,
            "status": "a"
            }
        ]
        },
        {
        "referenceId": "q-011",
        "numCorrectly": {
            "value": 0,
            "total": 0
        },
        "isCorrectness": false,
        "correctness": 0,
        "averageScore": 30.0,
        "student": [
            {
            "id": "s-001",
            "value": 0,
            "status": "f"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 5,
            "status": "p"
            }
        ]
        },
        {
        "referenceId": "q-012",
        "numCorrectly": {
            "value": 6,
            "total": 10
        },
        "isCorrectness": true,
        "correctness": 60.0,
        "averageScore": 50.0,
        "student": [
            {
            "id": "s-001",
            "value": 5,
            "status": "p"
            },
            {
            "id": "s-002",
            "value": 0,
            "status": "n"
            },
            {
            "id": "s-003",
            "value": 5,
            "status": "p"
            }
        ]
        }
    ]
};