
import { Button } from 'react-bootstrap';
import SharedModalHeader from '../../AppExPFUser/_components/CpSharedModalHeader';
import * as UI from '../../libs/libUI';
import { gst_popAlert, gst_pushAlert } from '../../saga/globalState.saga';
import { useUILang } from '../utils/useUILang';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { CpSettingModalTabContainer, CpSettingModalSection } from '../../AppExPFUser/_components/CpSettingModalTabCps';
import { sanitize } from 'dompurify';
//import SharedModal from '../../poc/screens/pages/included/SharedModal';
import { toStr, isStr } from '../../libs/libType';

import CpSharedToast from '../../AppExPFUser/_components/CpSharedToast';
import { getUUID } from '../../AppExPFUser/_components/CpGetUUID';
import { useEffect } from 'react';
import CpSharedModal from '../../AppExPFUser/_components/CpSharedModal';
/*
const cssAlert = {display:'inline-block', verticalAlign:'middle', overflow:'auto', 
    width:'60vw', height:'40vh', backgroundColor:'#abc', borderColor:'1px solid #444', 
    padding:'16px'};
            {UI.Button0('Ok', UI.stopEventThen( e => { close(); if(onOk)onOk(); }), 'byes', 'm8')}
            {UI.Button0('cancel', UI.stopEventThen( e => { close();}), 'bno', 'm8')}    
*/
export const popConfirmV1 = (dispatch, txt, onOk, onCancel=()=>{}, t=0) => {
    
    const [holder, close] = alertHolder(dispatch);
    const okTxt = t?t("ok"):"ok";
    const cancelTxt = t?t("cancel"):"Cancel";
    holder.alert = maskAlert(<div className='cssAlert'>
        <div className='flexColBetween' style={{height:'40vh'}}><div></div>
            <div className='m8'>{txt}</div>
            <div className='flexRowCenter' style={{alignSelf:"flex-end"}}>
                {UI.colorButton('byes',okTxt, UI.stopEventThen( e => { close(); if(onOk)onOk(); }), "#0C8CE9", "80px",{margin:"5px"})}
                {UI.colorButton('bno',cancelTxt, UI.stopEventThen( e => { close(); if(onCancel)onCancel();}), "#0C8CE9", "80px", {margin:"5px"})}
            </div>            
        </div>
    </div>);
    dispatch(gst_pushAlert(holder.alert));
};

const defaultStyle = {
    header: "",
    cancel: "gray-body-color",
    confirm: "at-primary" // = blue
    //exercise-third-btn" = orange
};

const mkAlert = (dispatch, danger, msgHTML, onOk, onCancel) => {
    const [holder, close] = alertHolder(dispatch);
    holder.alert = <AlertJSX {...{holder, close, dispatch, danger, msgHTML, onOk, onCancel}} />;
    return holder.alert;
};
const mkAlertToast = (dispatch, msgType, txt, onOk, onCancel) => {
    const [holder, close] = alertHolder(dispatch);
    //console.log("mkAlertToast !!", {msgType});
    holder.alert = <AlertJSXToast {...{holder, close, dispatch, msgType, txt, onOk, onCancel}} />;
    return holder.alert;
};
const mkAlertExtra = (dispatch, danger, msgHTML, onOk, onCancel, onX, onClose, extra) => {
    const [holder, _close] = alertHolder(dispatch);
    const close = () => { if(onClose) onClose(); _close(); }; 
    holder.alert = <AlertJSXExtra {...{holder, close, dispatch, danger, msgHTML, onOk, onCancel, onX, extra}} />;
    return holder.alert;
};
const AlertJSX = props => {
    const {dispatch, danger, msgHTML, onOk, onCancel} = props;
    const {holder, close, } = props;
    
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    const style = defaultStyle; //props.style || defaultStyle;

    const iconPath = IconList.general.alert;
    const title = t('warning.warning_tips');
//console.log('msgHTML', msgHTML);
    const clickClose = e => { /*stopEvent(e);*/ close(); if(onCancel) onCancel(); };
    const clickOk = e => { close(); if(onOk) onOk(); };
    return <CpSharedModal show={true} scrollable={true} 
        header={<SharedModalHeader title={title} iconPath={iconPath} handleClose={clickClose} />} 
        footer={<div className={"d-flex gap-2 justify-content-center w-100"}>
            {onCancel? <Button variant={`${style.cancel || defaultStyle.cancel} w-100 border`} onClick={clickClose} >
                {t('cancel')}
            </Button>: ''}
            <Button variant={`${(danger? "exercise-third-btn": style.confirm) || defaultStyle.confirm} btn-block w-100`} onClick={clickOk}>
                {t("ok")}
            </Button>
        </div>} 
    >
        <CpSettingModalTabContainer gap={3}>
            <CpSettingModalSection className={"flex-column"}>
                <div className={"semi-bold"} dangerouslySetInnerHTML={{__html: sanitize(toStr(msgHTML))}}></div>
            </CpSettingModalSection>
        </CpSettingModalTabContainer>
    </CpSharedModal>;
};
const AlertJSXToast = props => {
    const {dispatch, msgType=0, txt, onOk, onCancel} = props; // 0 = success
    const {holder, close } = props;
    const clickClose = () => { close(); if(onCancel) onCancel(); };
    const myID = getUUID();
//console.log("myID", myID);
    return <CpSharedToast myID={myID} msgType={msgType} body={txt} handleClose={clickClose} autoClose={1} />
};
const AlertJSXExtra = props => {
    const {dispatch, danger, msgHTML, onOk, onCancel, onX, extra} = props;
    const {holder, close, } = props;
    
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    const style = defaultStyle; //props.style || defaultStyle;

    const exIcon = extra?extra.icon:0;
    const exTitle = extra?extra.title:"";

    const iconPath = exIcon?exIcon:IconList.general.alert;
    const title = exTitle?t(exTitle):t('warning.warning_tips');
//console.log('msgHTML', msgHTML);
    const clickClose = e => { /*stopEvent(e);*/ close(); if(onCancel) onCancel(); };
    const clickOk = e => { close(); if (onOk) onOk(); };
    const clickX = e => { close(); if (onX) onX(); };
    return <CpSharedModal show={true} scrollable={true} 
        header={<SharedModalHeader title={title} iconPath={iconPath} handleClose={clickX} />} 
        footer={<div className={"d-flex gap-2 justify-content-center w-100"}>
            {onCancel? <Button variant={`${style.cancel || defaultStyle.cancel} w-100 border`} onClick={clickClose} >
                {t('cancel')}
            </Button>: ''}
            <Button variant={`${(danger? "exercise-third-btn": style.confirm) || defaultStyle.confirm} btn-block w-100`} onClick={clickOk}>
                {t("ok")}
            </Button>
        </div>} 
    >
        <CpSettingModalTabContainer gap={3}>
            <CpSettingModalSection className={"flex-column"}>
                {isStr(msgHTML)?<div className={"semi-bold"} dangerouslySetInnerHTML={{__html: sanitize(toStr(msgHTML))}}></div>
                :msgHTML}
            </CpSettingModalSection>
        </CpSettingModalTabContainer>
    </CpSharedModal>
};
//{UI.Button0('Ok', UI.stopEventThen( e => { close(); if(onOk)onOk(); }), 'bok', 'm8')}
const saga_pushAlertV1 = (dispatch, txt, onOk) => {
    const [holder, close] = alertHolder(dispatch);
    holder.alert = maskAlert(<div className='cssAlert'>
        <div className='flexColBetween' style={{height:'40vh'}}><div></div> 
            <div className='m8'>{txt}</div>
            <div className='flexRowCenter' style={{alignSelf:"flex-end"}}>
                {UI.colorButton('byes','OK', UI.stopEventThen( e => { close(); if(onOk)onOk(); }), "#0C8CE9", "80px",{margin:"5px"})}
            </div>
        </div>
    </div>);
    return holder.alert;
};


export const popConfirm = (dispatch, danger, txt, onOk, onCancel=()=>{}) => dispatch(gst_pushAlert(mkAlert(dispatch, danger, txt, onOk, onCancel)));
export const popAlert = (dispatch, danger, txt, onOk) => dispatch(gst_pushAlert(mkAlert(dispatch, danger, txt, onOk)));
export const popAlertToast = (dispatch, msgType, txt, onOk) => dispatch(gst_pushAlert(mkAlertToast(dispatch, msgType, txt, onOk)));
export const popAlertExtra = (dispatch, danger, txt, onOk, onCancel, onX, onClose, extra) => dispatch(gst_pushAlert(mkAlertExtra(dispatch, danger, txt, onOk, onCancel, onX, onClose, extra)));

export const maskAlert = alert => UI.ScreenMask(<><div className='screenMask2'></div>{alert}</>,{backgroundColor:'rgba(0,0,0,0.75)',zIndex:1090});
export const alertHolder = (dispatch) => {
    const holder = {};
    const close = () => {  dispatch(gst_popAlert(holder.alert)); };
    return [holder, close];
}
