import { useEffect, useMemo, useState } from 'react';

import { ATDo_DelE, ATDo_EClone, ATDo_EDraftR2P, ATDo_EPub, ATDo_EUnpub, ATDo_Exer, _ATCanDo, _ATCanDos } from '../../consts/ATRoleRights';
import { aryLen, objKeys, objVals, strCmp, toInt, toObj, toStr, } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { apiCallLoad, apiCallLoad_ } from '../../libs/awsFuncs';
import { ReduxBind } from '../../saga/ReduxState';
import { _ATRoot } from '../../consts/ATConstReact';
import { exerQIds } from '../../consts/ATValidateExer';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import { ATErrsDiv, aTUITime, clickUrl, langDisplay } from '../AppUtil';
import { cpATIcoBtn_, svgIcon2 } from '../components/CpATIcoBtn';
import { QDPBtn } from '../components/CpDraftPubBtns';
import { useDraftPubList } from '../components/CpPubDraftList';
import LayerATExFilter, { initEFtrOpt } from './LayerATExFilter';
import { isIn, strIncludes, toStrLT } from '../ATMetaEdit/PageATMetas';
import { langCodeCt, langCodeEn, QP_PP } from '../../consts/ATConsts';

const PageATExers = ReduxBind((props) => { 
	const dispatch = props.dispatch;
	const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
	const letExer =  _ATCanDo(role, ATDo_Exer);

	const [allExers, setExers, err2d, setErr2d, ticks, cntTick, setTicks, setTick, tickAll, setTickAll, clickTickAll, 
		onLoad, onPub, confirmCall, EIds] = useDraftPubList('EId', dispatch);

	const [visEFilter, setVisEFilter] = useState(0);
	const clickFilter = e => setVisEFilter(!visEFilter);
	const EFilterState = useState(initEFtrOpt);
	const [eFilter, setEfilter] = EFilterState;

	const Exers = useMemo(() => filterExers(allExers, eFilter), [allExers, eFilter]);
	
	const sortIds = useMemo(()=> {
		const sorts = EIds.filter(id => Exers[id]).map( id => {
			const {draft:d, pub:p} = Exers[id]; 
			return toObj(d? d: p);
		}); 
		sorts.sort((a, b) => (b.dateMod - a.dateMod) || strCmp(a.EId, b.EId));
		return sorts.map(s => s.EId); 
	}, [Exers]);


	const reload = () => apiCallLoad_( dispatch, '/getATExers', onLoad, {}); 
	useEffect(() => { letExer? reload(): dispatch(urlPush_Replace(_ATRoot)); }, []);
	
	const [letClone, letR2P, letPub, letUnpub, letDelete] = _ATCanDos(role, [ATDo_EClone, ATDo_EDraftR2P, ATDo_EPub, ATDo_EUnpub, ATDo_DelE]);

	const clickR2P = letR2P && cntTick && confirmCall('Confirm Request to Publish', '/putATExerRequest2P', onLoad, { ticks });
	const clickPub = letPub && cntTick && confirmCall('Please confirm publish', '/putATExersPublish', onPub, {ticks});
	const clickUnpub = letUnpub && cntTick && confirmCall('Please confirm unpublish', '/putATExersUnpublish', onLoad, {ticks});
	const clickDel = letDelete && cntTick && confirmCall('Please confirm delete', '/deleteExers', onLoad, {ticks});
	const clickReload = UI.stopEventThen(reload);
	const clickNew =  letExer && clickUrl(dispatch, _ATRoot + 'exercise/new');
	const clickEdit = letExer && (cntTick===1) && clickUrl(dispatch, _ATRoot + 'exercise/edit/'+ticks[0]);
	const clickClone = letClone && (cntTick===1) && clickUrl(dispatch, _ATRoot + 'exercise/new/'+ticks[0]);


	return <div className="adminAccountTop">
		<div className="flexColStart w100 p8" style={{height:'100%'}}>
    	{/*<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> */}
		<div className='w100' style={{overflow:'auto'}}>
		<div className="f16">Exercises / Exercises</div>
		<div className="adminToolsContainer"><div style={{display:"flex"}}>
			<div className="adminTools1">
				{letExer? cpATIcoBtn_('labelDiagram/add', 'New', clickNew): ''}
				{letExer? cpATIcoBtn_('general/edit', 'Edit', clickEdit): ''}
				{letClone? cpATIcoBtn_('general/copy', 'Duplicate', clickClone): ''}
				{letDelete? cpATIcoBtn_('general/delete', 'Delete', clickDel): ''}
				{letR2P? cpATIcoBtn_('general/request', 'Request to Publish', clickR2P): ''}
				{letPub? cpATIcoBtn_('general/publish', 'Publish', clickPub): ''}
				{letUnpub? cpATIcoBtn_('general/unpublish', 'Unpublish', clickUnpub): ''}
				{letExer? cpATIcoBtn_('general/reload', 'Reload', clickReload): ''}
			</div>
			<div className="adminTools2" onClick={clickFilter} >
				{svgIcon2("general/filter","#2995cd")}Filter: ({aryLen(sortIds)} / {aryLen(objKeys(allExers))})
			</div>
		</div></div>
			<div className="execItemRowHeader f14">
			<div className="execItem1">{UI.CheckBox0(tickAll, clickTickAll, 'chkall')}All</div>
			<div className="execItem2">Exercise ID</div>
			<div className="execItem3">Exercise Title</div>
			<div className="execItem4">Languages</div>
			<div className="execItem5">No. of Questions</div>
			<div className="execItem6">Version</div>
			<div className="execItem7">Last Modified</div>
        </div>            
		<div className="metaListContainer">
			{sortIds.map( EId => (ExerItem(EId, Exers[EId], err2d[EId], ticks, setTick))) }         
        </div>		
	</div></div>
	{visEFilter? <LayerATExFilter {...{close:(e => setVisEFilter(0)), EFilterState }} />: ''}
	</div>
});
export default PageATExers;

const ExerItem = (EId, exer, errs, ticks, setTick ) => {
	const {draft:d, pub:p} = exer; 
	const dp = toObj(d? d: p);
	const on = ticks?.includes(EId);
	const _key = EId+'_'+(on?'Y':'N');
	
	return <label key={'qset_'+_key} style={{width:'100%'}}>
	<div className="itemRowBlk">
		<div className="adminItemRow f12">
		<div className="execItem1">{UI.CheckBox0(on, setTick(EId), 'chk'+_key)}</div>
		<div className="execItem2">{dp.EId}</div>
		<div className="execItem3">{dp.ENameEn}<br/>{dp.ENameCt}</div>
		<div className="execItem4">
			{d? langDisplay(d.ELangEn, d.ELangCt):(p? langDisplay(p.ELangEn, p.ELangCt): '')}
		</div><div className="execItem5">
			{UI.centerBox(d? exerQIds(d).length:'',p? exerQIds(p).length:'')}
		</div><div className="execItem6">
			{UI.centerBox(d? DPDisplay(d): '', p? DPDisplay(p): '')}
		</div><div className="execItem7"><div>{aTUITime(dp.dateMod)}</div><div>{dp.modLogin}</div>
		</div>
		<div></div>
    	</div>
		{ATErrsDiv(errs)}
	</div>
	</label>;
};
const DPDisplay = q => { return (q ? <div>{QDPBtn(q, 'EVer', UI.stopEvent, 0)}</div> : ''); };

const filterExers = (allExs, eFilter) => {
	const n = toStrLT(eFilter?.eName);
	const m1 = toInt(eFilter?.markMin);
	const m2 = toInt(eFilter?.markMax);
	const q1 = toInt(eFilter?.qMin);
	const q2 = toInt(eFilter?.qMax);
	const l = toStr(eFilter?.lang).trim();
	const p = toStrLT(eFilter?.pub);
	const s1 = n? objVals(allExs).filter(s => 
		[s.pub?.ENameCt, s.pub?.ENameEn, s.pub?.EId, s.draft?.ENameCt, s.draft?.ENameEn, s.draft?.EId ].some(s => strIncludes(s, n)) 
	): objVals(allExs);

	const s2 = s1.filter(s => ((isIn(s.pub?.aScore + s.pub?.mScore, m1, m2) && isIn(s.pub?.aQCnt + s.pub?.mQCnt, q1, q2) )) 
		|| ((isIn(s.draft?.aScore + s.draft?.mScore, m1, m2) && isIn(s.draft?.aQCnt + s.draft?.mQCnt, q1, q2) )));

	const s3 = (l === '*')? s2.filter(s => ((s.pub?.ELangEn && s.pub?.ELangCt)) || ((s.draft?.ELangEn && s.draft?.ELangCt)))
		: (l === langCodeEn)? s2.filter(s => ((s.pub?.ELangEn)) || ((s.draft?.ELangEn)))
		: (l === langCodeCt)? s2.filter(s => ((s.pub?.ELangCt)) || ((s.draft?.ELangCt)))
		: s2;
	const s4 =  (p === 'y')? s3.filter(s => (s.pub?.drpu === QP_PP)):
		(p === 'n')? s3.filter(s => (s.pub?.drpu !== QP_PP)):
		s3; 
	const ret = Object.fromEntries(s4.map(dp => [dp.draft?.EId || dp.pub?.EId, dp]));
//console.log('filterExers', {allExs, ret, s1, s2, s3, s4, F: {n, m1, m2, q1, q2, l, p, s1}, eFilter });
	return ret;
};
