import {useRef, useState} from "react";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { stopEvent } from "../../libs/libUI";
import { aryLen } from "../../libs/libType";

const ChartRadialSlider = (props) => { // base on poc RadialChartSlider
    const {dataLength, children} = props;
    const slideRef = useRef(null);
    let pos = {left: 0, x: 0};
    const [currentIndex, setCurrentIndex] = useState(0);

//console.log('ChartRadialSlider', aryLen(children), {children, dataLength} );

    const isTouch = (e) => { return e.type.startsWith('touch'); };
    const mouseDownHandler = (e) => {
        // Change the cursor and prevent user from selecting the text
        if (dataLength > 1 && slideRef.current) {
            pos = {
                // The current scroll
                left: slideRef.current.scrollLeft,
                // Get the current mouse position
                x: isTouch(e) ? e.touches[0].clientX : e.clientX,
            };
            if (isTouch(e)) {
                e.preventDefault();
                slideRef.current.addEventListener('touchmove', mouseMoveHandler);
                slideRef.current.addEventListener('touchend', mouseUpHandler);
                slideRef.current.addEventListener('touchcancel', mouseUpHandler);
            } else {
                slideRef.current.addEventListener('mousemove', mouseMoveHandler);
                slideRef.current.addEventListener('mouseup', mouseUpHandler);
            };
            updateDragIndex();
        };
    };
    const mouseMoveHandler = (e) => {
        // How far the mouse has been moved
        if (dataLength > 1 && slideRef.current) {
            if (isTouch(e)) {
                document.body.classList.add("disable-touch-scroll");
            };
            const dx = (isTouch(e) ? e.touches[0].clientX : e.clientX) - pos.x;
            // Scroll the element
            slideRef.current.scrollLeft = (dx > 0 ? -1 : 1) * (slideRef.current.offsetWidth / 2) + pos.left - dx;
        };
    };

    const mouseUpHandler = (e) => {
        if (dataLength > 1 && slideRef.current) {
            if (isTouch(e)) {
                document.body.classList.remove("disable-touch-scroll");
                slideRef.current.removeEventListener('touchmove', mouseMoveHandler);
                slideRef.current.removeEventListener('touchend', mouseUpHandler);
                slideRef.current.removeEventListener('touchcancel', mouseUpHandler);
            } else {
                slideRef.current.removeEventListener('mousemove', mouseMoveHandler);
                slideRef.current.removeEventListener('mousedown', mouseUpHandler);
            };
            updateDragIndex();
        };
    };

    const handleWheel = e => stopEvent(e);

    const scrollToNextItem = (move) => {
        if (slideRef.current) {
            const newIndex = currentIndex + move;
            if (newIndex >= 0 && newIndex <= dataLength * 2 - 1) {
                setCurrentIndex(newIndex);
                slideRef.current.scrollLeft = slideRef.current.clientWidth * newIndex;
            };
        };
    };

    const updateDragIndex = () => {
        // Calculate the active index based on the current scroll position
        setTimeout(() => {
            if (slideRef.current) {
                const scrollPos = slideRef.current.scrollLeft;
                const itemWidth = slideRef.current.clientWidth;
                const index = Math.round(scrollPos / itemWidth);
                setCurrentIndex(index);
            }
        }, 500);
    };

    const jumpTo = (index) => {
        if (slideRef.current) {
            scrollToNextItem(index - currentIndex);
        };
    };

    return <>
        {dataLength > 1 &&
            <span className={`radial-chart-btn prev fs-5 ${currentIndex === 0 ? "d-none" : ""}`}>
            <span role={"button"} className={"border rounded-light py-2 px-1"}
                  onClick={() => scrollToNextItem(-1)}>
                <CpIco src={IconList.general.previousPage}/>
            </span>
        </span>}
        <div className={`radial-chart-slider`} ref={slideRef}
            // ${dataLength > 1 ? "draggable" : ""}
            // onMouseDown={mouseDownHandler}
            // onTouchStart={mouseDownHandler}
            // onWheel={handleWheel}
        >
            {children}
        </div>
        {dataLength > 1 &&
            <span className={`radial-chart-btn next fs-5 ${currentIndex === dataLength - 1 ? "d-none" : ""}`}>
            <span role={"button"} className={"border rounded-light py-2 px-1"}
                  onClick={() => scrollToNextItem(1)}>
                <CpIco src={IconList.general.nextPage}/>
            </span>
        </span>}

        {dataLength > 1 && <span
            className={"radial-chart-dot-container"}>
            {
                [...Array(dataLength)].map((length, idx) => {
                    return <span key={idx} role={"button"}
                                 className={`radial-chart-dot-btn ${currentIndex === idx ? "active" : ""}`}
                                 onClick={() => jumpTo(idx)}></span>
                })
            }
        </span>
        }
    </>
};

export default ChartRadialSlider;