import { useEffect, useState, useMemo} from 'react';

import { ReduxBind } from '../../saga/ReduxState';

import { apiCallLoad, apiCallLoad_ } from '../../libs/awsFuncs';
import { aryLen, objKeys, objVals, strCmp, toAry, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { aTUITime, clickConfirm, clickUrl, dpDisplay, langDisplay, mergePubsDrafts } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';
import { _ATMetaTypeByCode } from '../../consts/ATMetaTypes';
import { ATDo_Meta, _ATCanDo } from '../../consts/ATRoleRights';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import { useTickAll } from '../ATQtnEdit/PageATQtns';
import { cpATIcoBtn_, svgIcon2 } from '../components/CpATIcoBtn';
import LayerATMetaFilter, { initMFtrOpt } from './LayerATMetaFilter';
import { QP_PP } from '../../consts/ATConsts';

const selColor = "#0C8CE9";

const PageATMetas = ReduxBind((props) => { 
	const dispatch = props.dispatch;
	const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
    const canMeta = _ATCanDo(role, ATDo_Meta); 
	
	const [visMFilter, setVisMFilter] = useState();
	const clickFilterM = UI.stopEventThen( e => setVisMFilter(!visMFilter) );

	const [allMSets, setAllMSets] = useState([]);

	const MFilterState = useState(initMFtrOpt);
	const [mFilter, setMfilter] = MFilterState;

	const metaSets = useMemo(() => filterMsets(allMSets, mFilter), [allMSets, mFilter]);

	const msids =  objKeys(metaSets);
	const sortIds = useMemo(()=> {
		const sorts = msids.map( id => {
			const {draft:d, pub:p} = metaSets[id]; 
			return toObj(d? d: p);
		}); 
		sorts.sort((a, b) => (b.dateMod - a.dateMod) || strCmp(a.metaSetId, b.metaSetId));
		return sorts.map(s => s.metaSetId); 
	}, [metaSets]);


	const [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks] = useTickAll(msids);
	const onLoad = (res, err) => {
		const pubs = res?.data?.metaSets || [];
		const drafts = res?.data?.metaSetDrafts || []; 
		//lj('ageATMetasLoad',{pubs}, {drafts});
		const merged = mergePubsDrafts(pubs, drafts, 'metaSetId'); 
		//lj('merged',merged);
		setAllMSets( merged );
		clearTicks();
	};

	const reload = () => apiCallLoad_( dispatch, '/getATMetaSets', onLoad, {}); 
	useEffect(() => { canMeta? reload(): dispatch(urlPush_Replace(_ATRoot)); }, [] );

	
	const clickNew = canMeta && clickUrl(dispatch, _ATRoot + 'meta/new');
	const clickEdit = canMeta && clickUrl(dispatch, _ATRoot + 'meta/edit/'+ticks[0]);
    const clickClone = canMeta && clickUrl(dispatch, _ATRoot + 'meta/new/'+ticks[0]);;
	const clickDelele = canMeta && clickConfirm(dispatch, 'Please confirm delete', () => apiCallLoad_( dispatch, '/deleteATMetaSets', onLoad, {ticks}) );
	const clickPub = canMeta && clickConfirm(dispatch, 'Please confirm publish', () => apiCallLoad_( dispatch, '/putATMetaSetsPublish', onLoad, {ticks}));
	const clickUnpub = canMeta && clickConfirm(dispatch, 'Please confirm unpublish', () => apiCallLoad_( dispatch, '/putATMetaSetsUnpublish', onLoad, {ticks}));
	const clickReload = e => { UI.stopEvent(e); reload(); };

	const cntTick = ticks?.length || 0;
	return <div className="PageATMetas adminAccountTop">
		<div className="flexColStart w100 p8" style={{height:'100%'}}>
    	{/*<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> */}
		<div className='w100' style={{overflow:'auto'}}>
		<div className="f16">Admin / Metadata Sets</div>
		<div className="adminToolsContainer"><div style={{display:"flex"}}>
			<div className="adminTools1">
				{canMeta && cpATIcoBtn_('labelDiagram/add', 'New', clickNew)}
				{canMeta && cpATIcoBtn_('general/edit', 'Edit', (cntTick===1) && clickEdit)}
				{canMeta && cpATIcoBtn_('general/copy', 'Duplicate', (cntTick===1) && clickClone)}
				{canMeta && cpATIcoBtn_('general/delete', 'Delete', (cntTick) && clickDelele)}
				{canMeta && cpATIcoBtn_('general/publish', 'Publish', (cntTick) && clickPub)}
				{canMeta && cpATIcoBtn_('general/unpublish', 'Unpublish', (cntTick) && clickUnpub)}
				{cpATIcoBtn_('general/reload', 'Reload', clickReload)}
			</div>
			<div className="adminTools2" onClick={clickFilterM} style={{color:selColor}}>
				{svgIcon2("general/filter","#2995cd")}Filter: ({aryLen(msids)})
			</div>
		</div></div>
			<div className="metaItemRowHeader f14">
			<div className="metaItem1">{UI.CheckBox0(tickAll, setTickAll, 'chkall')}All</div>
			<div className="metaItem2">Title</div>
			<div className="metaItem3">Code</div>
			<div className="metaItem4">Type</div>
			<div className="metaItem5">Languages</div>
			<div className="metaItem6">Version</div>
			<div className="metaItem7">Last Modified</div>
        </div>            
        <div className="metaListContainer">
			{ sortIds.map(msid => (metaSetItem2(msid, metaSets[msid].pub, metaSets[msid].draft, ticks, setTick ))) }             
        </div>
		
		{visMFilter? <LayerATMetaFilter {...{close:(e => setVisMFilter(0)), MFilterState }}/>: ''}		
	</div>
	</div></div>
});
export default PageATMetas;

const metaSetItem2 = (msid, pub, draft, ticks, setTick ) => {
	const ms = draft?draft:pub;
	const on = ticks?.includes(msid);
	const _key = msid+'_'+(on?'Y':'N');
    return <label key={'meta_'+_key} style={{width:'100%'}}>
		<div className="itemRowBlk">
		<div className="adminItemRow">
        <div className="metaItem1">
			{UI.CheckBox0(on, setTick(msid), 'chk'+_key)}
        </div>
        <div className="metaItem2 f15">
			<div>{ms.MSNameEn}</div><div>{ms.MSNameCt}</div>
		</div>
		<div className="metaItem3 f15"> {ms?.metaSetId}</div>
        <div className="metaItem4 f15"> {_ATMetaTypeByCode(ms?.MSType)}</div>
        <div className="metaItem5 f15">{langDisplay(ms?.MSLangEn, ms?.MSLangCt)}</div>
        <div className="metaItem6 f15">{dpDisplay(draft?.MSVer, draft?.drpu, pub?.MSVer, pub?.drpu)}</div>
		<div className="metaItem7 f15">
			<div>{aTUITime(ms.dateMod)}</div><div>{ms.modLogin}</div>
		</div>
    </div></div></label>;
};

export const toStrLT = s => toStr(s).toLowerCase().trim();
export const strIncludes = (str, find) => toStrLT(str).indexOf(toStrLT(find)) >= 0; 
export const isIn = (v, min, max) => (v >= min) && (v <= max);

const filterMsets = (allMSets, mFilter) => {
	const n = toStrLT(mFilter?.msName);
	const t = toStr(mFilter?.msType).trim();
	const p = toStrLT(mFilter?.pub);
	const s1 = n? objVals(allMSets).filter(s => 
		[s.pub?.MSNameCt, s.pub?.MSNameCt, s.pub?.metaSetId, s.draft?.MSNameCt, s.draft?.MSNameCt, s.draft?.metaSetId].some(s => strIncludes(s, n))
	): objVals(allMSets);
	const s2 = (t === '*')? s1: s1.filter(s => (s.draft?.MSType === t) || (s.pub?.MSType === t));
	const s3 = (p === 'y')? s2.filter(s => (s.pub?.drpu === QP_PP)):
		(p === 'n')? s2.filter(s => (s.pub?.drpu !== QP_PP)):
		s2;
	const ret = Object.fromEntries(s3.map(dp => [dp.draft?.metaSetId || dp.pub?.metaSetId, dp]));
//console.log('filterMsets', {allMSets, ret, s1, s2, s3, F: {n, t, p}, mFilter });
	return ret;
};

