import React, {useEffect, useRef, useMemo, useState} from "react";
import { langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import CpIco, { CpLangIco } from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState";
import { langTxt } from "./PageLibrary";
import { toStr, toObj } from "../../libs/libType";
import { subState } from '../../AppExPf/utils/useChain';
import { toIdStr } from "../../consts/ATValidate";
import { EpInputTxt0, stopEvent } from "../../libs/libUI";
import { Button } from "react-bootstrap";
import SharedModal from "../../poc/screens/pages/included/SharedModal";
import CpActionButton from "../_components/CpActionButton";
import { useResizeEffect, useResizeMemo } from "../../AppExPf/utils/useWinResize";
import { isOUPCnewEx } from "../EPExercise/CpExerciseCard";


const CpExerciseEditHeader = ReduxBind(props => {
    const {dispatch, pub, fields, showEn, ct, setPopExer, clickBack} = props;
    const [animation, newEx] = [true, 1];
    const [t] = useUILang();

    const ex = fields || pub;
    const inTitle = useMemo(() => langTxt(showEn, ex.ENameEn, ex.ENameCt), [ex, showEn]); 

    const [editTxt, setEditTxt] = useState(inTitle);     

    const [editing, setEditing] = useState(0);
    const [err, setErr] = useState('');

    const lock = !fields;
    useEffect(() => { 
        if(lock){
            editing && setEditing(0);
        }else{
            setEditTxt(inTitle);
        }  
    }, [lock]);

    const [refEdit, setRefEdit] = useState();
    const [ww, hh] = useResizeMemo();
    const small = useMemo(() => {
        
        return (refEdit && (200 > refEdit.getBoundingClientRect().width))
    }, [refEdit, ww, editing]);

    const saveTitle = t => setPopExer(subState('fields', fs => ({...toObj(fs), ENameEn:t, ENameCt:t})));
    const clickEditExHead = e => { stopEvent(e); setEditing(1); };
    const clickCancel = e => { stopEvent(e); setEditTxt(inTitle); setEditing(0); };
    const clickSave = e => { stopEvent(e); 
        const t = toIdStr(editTxt);
        const err = (t === '')? 'Must Input Name': '';
        setEditTxt(t);
        setErr(err);
        if(!err){
            saveTitle(t);
            setEditing(0);
        }
    }; 

    const ed = editing && (!lock);
    const edPop = ed && small; 
    const isNewTag = isOUPCnewEx(ex);

    return <div className={"d-flex align-items-center justify-content-between w-100 gap-3 text-truncate"}>
        <div className={"text-truncate"}>
           <span className={`d-flex justify-content-start semi-bold user-select-none`}>
                <div className={`${animation? "overflow-hidden" : ""} `}>
                    <div className={`d-flex align-items-center gap-1 text-truncate`}>
                        <span className={`fs-5 btn`} onClick={clickBack}><CpIco src={IconList.general.previous}/></span>
                        <span className={`d-flex align-items-center gap-2 text-truncate`}>
                        <><span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn"}
                                style={{width: "1.5rem", height: "1.5rem"}}>
                                {CpLangIco(showEn)}
                            </span>
                            {isNewTag? <span className={`fs-5`}><CpIco src={IconList.tagging.newTag}/></span>: ''}
                        </>
                        {ed  
                            ?TextRef(setRefEdit, editTxt, setEditTxt, 'edline', 'd-flex flex-grow-1 text-truncate vw-100')
                            :<span className={`fs-6 text-truncate ${animation? "animation-fade-up" : ""}`} >{inTitle}</span>
                        }
                        {(lock || ed)? '': <CpActionButton iconPath={IconList.general.edit} className={"exercise-small-action-btn edit"}
                            onClick={clickEditExHead} />}
                        {ed? <CpActionButton iconPath={IconList.general.cancelFull} className={"exercise-small-action-btn cancel"}
                            onClick={clickCancel} />: ''}
                        {ed? <CpActionButton iconPath={IconList.general.confirmFull} className={"exercise-small-action-btn confirm"}
                            onClick={clickSave} />: ''}
                        </span>
                    </div>
                </div>
            </span>
        </div>
        <SharedModal show={edPop} handleClose={clickCancel} 
            footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center"}>
                <Button variant="gray-body-color w-100 border" onClick={clickCancel}>{t("cancel")}</Button>
                <Button variant="exercise-third-btn w-100" onClick={clickSave}>{t("confirm")}</Button>
            </div>}>
            <div className={"px-3 bg-dim-100"}>
                <span className={"fs-5 semi-bold d-flex justify-content-start align-items-center gap-2 mb-3"}>
                    <CpIco src={IconList.general.edit}/>{t("rename")}</span>
                {t("rename-message")}
                {EpInputTxt0(editTxt, setEditTxt, 'edpop', 'd-flex flex-grow-1 text-truncate w-100')}
            </div>
        </SharedModal>
    </div>;


/*    
    return <div className={"d-flex align-items-center justify-content-between w-100 gap-3 text-truncate"}>
        <div className={"text-truncate"}>
            {lock?'':Button0('save', clickSave, 'BS')}
            {<CpHeaderTitle iconPath={IconList.general.previous} onClick={clickBack} animation={true} editable={!lock}
                title={title} titleIconPath={<span style={{width: "1.5rem", height: "1.5rem"}}
                className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn"}>
<CpIco src={showEn? IconList.language.en: IconList.language.zh}/></span>} />}
            
            <CpHeaderTitle title={('tttt' + title) || "-"} style={{width: "1.5rem", height: "1.5rem"}} iconPath={IconList.general.previous} 
                titleIconPath={<><span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn"}>
                    {CpLangIco(showEn)}
                </span><span className={`fs-5`}>
                <CpIco src={IconList.tagging.newTag}/>
            </span></>} 
            onClick={clickBack} animation={true} editable={!lock}/>
        </div>

    </div>
*/
});
export default CpExerciseEditHeader;

export const TextRef = (ref, val, setter, id, className='', style={width:'100%'}, placeHolder='') => {
    return <input ref={ref} name={id} id={id} className={className} style={style} type="text"
    onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} placeholder={placeHolder} />;
};

/*

import IconComponent from "../../schema/components/custom/icon/IconComponent";
import React, {HTMLProps, ReactNode, useContext, useEffect, useMemo, useRef, useState} from "react";
import {IconList} from "../../../static/icons/IconList";
import {ExerciseContext} from "../../../state/ExerciseProvider";
import {useTranslation} from "react-i18next";
import ActionButton from "./ActionButton";
import {mode} from "mathjs";
import SharedModal from "./SharedModal";
import {Button} from "react-bootstrap";

const HeaderTitle = (props: Props) => {
    const {t} = useTranslation(['schema']);
    const {state: exerciseState, dispatch: exerciseDispatch} = useContext(ExerciseContext)

    const {title, iconPath, titleIconPath, animation = false, editable = false, ...HeaderProps} = props
    const [enableEdit, setEnableEdit] = useState(false);
    const [open, setOpen] = useState(false)
    const refEdit = useRef<HTMLSpanElement>(null)

    const handleClose = () => setOpen(false);
    const handleEdit = () => {
        if (refEdit.current) {
            if (refEdit.current.getBoundingClientRect().width < 200) {
                setOpen(true)
            } else {
                setEnableEdit(true)
            }
        }
    }

    useEffect(() => { setEnableEdit(false) }, [mode])

}
*/
