import React, {useContext, useMemo, useEffect, useState } from 'react';

import { _WebRoot, _ATRoot } from '../../consts/ATConstReact';

import { debugMode, ReduxBind } from '../../saga/ReduxState';
import CpDropdown, { ent2DropdownItem, obj2DropdownItem, sortEnt } from '../_components/CpDropdown';
import { objEntries, objKeys, toAry, toFaction1, toObj, toStr } from '../../libs/libType';
import { subjMetaCombo, useYearClassItems } from '../EPAssign/TabAssignmentSettingClass';
import { expfRoles } from '../../saga/auth.saga';
import PageReportTeacher, { rpTime_keyStg, rpTime_year, rptTypeDDI } from './PageReportTeacher';
import PageReportStudent from './PageReportStudent';
import { toNum, toFloat } from '../../libs/libType';
import { useFields } from '../../AppExPf/utils/useEditDraftPub';
import { _ATMetaTransByCode } from '../../consts/ATMetaTypes';
import { toUniIdAry } from '../../consts/ATValidate';
import { mSetHasSubj } from '../EPExercise/CpMetadataEditorCTA';
import { rpt_keyStg } from '../../consts/ATSysQType';

const PageReport = ReduxBind(props => {
    const [isThr, isStt, uRole ] = expfRoles(props);
    return isThr? <PageReportTeacher />: <PageReportStudent />
});
export default PageReport;

export const useRptOpts = () => {
    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs] = useFields();  
    const [opts, setOpts] = useState();
    return {fields, setFields, setField, opts, setOpts};
};

export const useRptCtnOpts = (fields, opts, _subjMetas, isThr, grade, oneCls, oneStu, uiEn, t, ut) => {
    const {rpt, MSubj, MType, MSet, ML1, rpTime } = toObj(fields);
    const {rMSIds, rJOpts, rMSets} = toObj(opts);

    const rptClsDetail = isThr && oneCls;
    const rptDDI = useMemo(() => rptTypeDDI(t, rptClsDetail), [rptClsDetail, uiEn]);
    const r = rptDDI[rpt]? rpt: objKeys(rptDDI)[0];

    //const subjDDI = useMemo(() => ent2DropdownItem(objEntries(rJOpts).map(([id, j]) =>  [id, ut(j.nameEn, j.nameCt)])), [rJOpts, uiEn]);
    const subjDDI = useMemo(() => ent2DropdownItem(toAry(_subjMetas).map(j => [j.metaSetId+','+j.metaId, ut(j.nameEn, j.nameCt)])), [_subjMetas, uiEn]);  
    const j = subjDDI[MSubj]? MSubj: objKeys(subjDDI)[0];

    const jMsets = toAry(rMSets).filter(s => mSetHasSubj(s, j));

    //EX-1371 meta option translate
    const MTypeDDI = useMemo(() => ent2DropdownItem(sortEnt(
        toUniIdAry(jMsets.map(s => s.MSType)).map(x => [x, t(_ATMetaTransByCode(x))])
    )), [j, rMSets, uiEn]);
    const mt = MTypeDDI[MType]? MType: objKeys(MTypeDDI)[0];

    const MSetDDI = useMemo(() => ent2DropdownItem(sortEnt(
        jMsets.filter(s => (mt === s.MSType)).map( s => [s.metaSetId, ut(s.MSNameEn, s.MSNameCt)])
    )), [rMSets, mt, uiEn]);
    const ms = MSetDDI[MSet]? MSet: objKeys(MSetDDI)[0];

    const ML1DDI = useMemo(() => {
        const msets = toAry(rMSets).filter(mset => mset.metaSetId === ms);
        return ent2DropdownItem(sortEnt(MSetLv1Opts(msets, ut)));
    }, [rMSets, ms, uiEn]);
    const l1 = ML1DDI[ML1]? ML1: objKeys(ML1DDI)[0];
    
    const canKS = useMemo(() => rpt_keyStg && rptHasKS(grade) && ((oneStu && (RT_over === r)) || (oneCls && (RT_meta === r))), [oneCls, oneStu, r, grade, ]);    
    const hasKS = canKS;
    
    const rpTimeDDI = useMemo(() => ent2DropdownItem([
        [rpTime_year, t('report-this-year')],
        canKS && [rpTime_keyStg, t('report-whole-key-stage')],
    ].filter(d => d)
    ), [uiEn, canKS]);
    const rti = rpTimeDDI[rpTime]? rpTime: objKeys(rpTimeDDI)[0];

//useEffect(() => console.log("useRptCtnOpts ()", {isThr, r, grade, oneStu, oneCls, canKS}), [isThr, r, grade, oneStu, oneCls, canKS, hasKS]);

    return {
        subjDDI, MTypeDDI, MSetDDI, ML1DDI, rptDDI, rpTimeDDI,
        r, j, mt, ms, l1, rti,
        rpt, MSubj, MType, MSet, ML1, rpTime, canKS, hasKS
    };
};

const _grade2KSYear = g => ['offline', 'local'].includes(process.env.cfg)? 3: 
    ['s3','s6'].includes(g)? 3: ['s2','s5'].includes(g)? 2: 1;
export const grade2KSYear = grade => _grade2KSYear(toStr(grade).trim().toLowerCase()); 
const rptHasKS = g => grade2KSYear(g) > 1; 

export const MSetLv1Opts = (msets, ut) => {
    const e2d = toAry(msets).map( mset => {
        const msid = toStr(mset.metaSetId).trim();
        const metas = toObj(mset.metas);
        return objKeys(mset.tree2).map(L1 => {
            const m = toObj(metas[L1]); 
            return [msid+','+toStr(L1).trim(), ut(m.nameEn, m.nameCt)];
        });
    });
    return [].concat(...e2d);  
}; 

//JIRA EX-1654
export const toRptFix = (input, dig1=5, dig2=1, defaultValue = 0) => 
    parseFloat(toNum(parseFloat(input), defaultValue).toFixed(dig1)).toFixed(dig2);
export const toRptPer = (val, ttl) => toFaction1(val, ttl); //toFloat(ttl? toRptFix(val / ttl): 0);

export const TART_over = '**over'; //Teacher Asm Report Type: overview
export const TART_qtn = '**qtn'; //Teacher Asm Report Type: Reme
export const TART_detail = '**detail'; //Teacher Asm Report Type: Reme
export const TART_meta = '**meta'; //Teacher Asm Report Type: Reme
export const TART_reme = '**TRT_reme'; //Teacher Asm Report Type: Reme

export const RT_over = '**over'; // Report Type: over
export const RT_meta = '**meta'; // Report Type: meta
export const RT_detail = '**detail'; // Report Type: detail